import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { LinkImportCancelModal } from 'pages/datasources/components/LinkImportCancelModal';
import { useDefaultDashboardCreation } from 'pages/datasources/components/useDefaultDashboardCreation';
import { useDataStreamDefinitions } from 'queries/hooks/useDataStreamDefinitions';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { NavigateToDataSourcesPagePrompt } from './NavigateToDataSourcesPagePrompt';
import { NonLinkedPluginsList } from './NonLinkedPluginsList';
import { invalidateDataSourceAfterAddingDataSourceToWorkspace } from './invalidateDataSourceAfterAddingDataSourceToWorkspace';
import useAddDataSources from './useAddDatasources';
import { useUnlinkedDataSources } from './useUnlinkedDataSources';
import { useTileEditorContext } from 'ui/editor/dataStream/contexts/TileEditorContext';
import { useNavigate } from 'react-router';

interface AddDatasourceModalProps {
    workspaceId: string;
    onSelectedDataSource: (value: string | undefined) => void;
}

export const AddDataSourceModal = ({ workspaceId, onSelectedDataSource }: AddDatasourceModalProps) => {
    const navigate = useNavigate();

    const [selectedDataSource, setSelectedDataSource] = useState<PluginSourceConfig | undefined>();
    const [navigateToDataSourcesPage, setNavigateToDataSourcesPage] = useState(false);
    
    const { unlinkedDataSources, isLoading } = useUnlinkedDataSources(workspaceId);
    const { data: dataStreams } = useDataStreamDefinitions();
    const { mutate: createDashboards } = useDefaultDashboardCreation();
    const container = useDOMElement('dialogContent');
    const { isEdited } = useTileEditorContext(); 

    const queryClient = useQueryClient();

    const { addDataSources } = useAddDataSources({
        workspaceId
    });

    const handleConfirmAddDataSource = async (addSampleDashboards: boolean) => {
        if (selectedDataSource) {
            await addDataSources([selectedDataSource.id]);
            await invalidateDataSourceAfterAddingDataSourceToWorkspace(
                selectedDataSource,
                dataStreams,
                workspaceId,
                queryClient
            );

            if (addSampleDashboards) {
                createDashboards({
                    configId: selectedDataSource?.id ?? '',
                    pluginId: selectedDataSource?.plugin?.pluginId ?? '',
                    workspaceId
                });
            }

            onSelectedDataSource(selectedDataSource?.plugin?.pluginId);
        }
    };

    return (
        <Modal
            title='Data sources in my organization'
            container={container}
            close={() => onSelectedDataSource(undefined)}
        >
            {isLoading && (
                <div className='flex justify-center pb-4'>
                    <LoadingSpinner />
                </div>
            )}
            <NonLinkedPluginsList
                isLoading={isLoading}
                unlinkedDataSources={unlinkedDataSources}
                cancel={() => onSelectedDataSource(undefined)}
                onSelectedDataSource={setSelectedDataSource}
                navigateToDataSourcesPage={() => 
                    isEdited ? setNavigateToDataSourcesPage(true) : navigate(`/datasources/${workspaceId}`)
                }
            />

            {selectedDataSource && (
                <LinkImportCancelModal
                    dataSourceName={selectedDataSource.displayName as string}
                    sourcePluginId={selectedDataSource.plugin?.pluginId as string}
                    onClose={() => setSelectedDataSource(undefined)}
                    onSubmit={handleConfirmAddDataSource}
                />
            )}

            {navigateToDataSourcesPage && (
                <NavigateToDataSourcesPagePrompt
                    workspaceId={workspaceId}
                    onCancelNavigation={() => setNavigateToDataSourcesPage(false)}
                />
            )}
        </Modal>
    );
};
