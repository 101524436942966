/* eslint-disable no-console */
import {
    ClientDataStreamRequest,
    DataStreamOptions,
    DataStreamWarning,
    defaultFormatSpec,
    emptyGroupingSpec,
    emptySortSpec,
    FormattedStreamData,
    noData,
    StreamData
} from '@squaredup/data-streams';
import { getTimeframe } from '@squaredup/timeframes';
import { AxiosError } from 'axios';
import { DashboardContextValue } from 'contexts/DashboardContext';
import {
    ClientDataStreamsContextValue,
    useClientDataStreamsContext
} from 'dashboard-engine/dataStreams/clientDataStreams/ClientDataStreamsContext';
import stringify from 'fast-json-stable-stringify';
import { DATA_STREAM_RETRY_KEY, getDataStreamRetryAfter, getDataStreamRetryCount } from 'lib/retryAfter';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult, type QueryKey } from 'react-query';
import { requestData } from '../../services/DataStreamService';

/**
 * Default refetch interval to use if the server doesn't tell us what the interval should be via validFor
 * in the data stream response, or there is an error fetching the data stream.
 */
const DEFAULT_REFETCH_INTERVAL_MS = 5 * 60 * 1000; // 5 mins

export const emptyDataStreamOptions: DataStreamOptions = {
    group: emptyGroupingSpec,
    sort: emptySortSpec
};

export type Context = Pick<DashboardContextValue, 'timeframe'>;

export type UseDataStreamResult = Omit<UseQueryResult<Readonly<StreamData>, unknown>, 'data'> & {
    data: Readonly<StreamData>; // avoid data being optional
    /**
     * True if the data has been loaded, false if data is noData because we're waiting on a response
     */
    isLoaded: boolean;
    warnings?: (string | DataStreamWarning)[];
    errors?: unknown;
    isLoadingOrPreviousData: boolean;
    /** Number of seconds until the datastream request is retried, i.e. when rate limited */
    retryAfter?: number;
    /** Unix timestamp of the date and time that the overall request completed. */
    completedAt: number;
};

const enableFormattingIfMetadataConfigured = (options: DataStreamOptions): DataStreamOptions => {
    /**
     * SQL analytics sets noNumberFormatting to true by default, so if we set metadata
     * we need to explicitly disable it for the metadata formatting to have an effect on numbers.
     */
    if (options.metadata != null && options.metadata.length > 0) {
        return { ...options, format: { ...options.format, noNumberFormatting: false } };
    }

    return options;
};

export const parseUseDataStreamArgs = (
    request: ClientDataStreamRequest | undefined,
    queryOptions?: UseDataStreamQueryOptions
): { queryKey: QueryKey; resolvedOptions: UseDataStreamQueryOptions } & (
    | { isDataStreamSelected: false }
    | {
          isDataStreamSelected: true;
          dataStreamRequest: ClientDataStreamRequest;
      }
) => {
    if (!request) {
        return {} as ReturnType<typeof parseUseDataStreamArgs>;
    }

    const {
        dataStreamId,
        dataStreamName,
        options: dataStreamOptions,
        timeframe,
        scope,
        pluginConfigId,
        dataSourceConfig,
        context
    } = request ?? {};

    dataStreamOptions.format = {
        ...defaultFormatSpec,
        ...dataStreamOptions.format
    };

    const options = enableFormattingIfMetadataConfigured(dataStreamOptions) || emptyDataStreamOptions;
    const { extraKeys, ...resolvedOptions } = queryOptions ?? {};

    const queryKey = streamDataKeys.forRequest(request, extraKeys);

    // Resolve time frame based on enum
    // TODO: once time frames are made more flexible - amend how the time frame is resolved
    const resolvedTimeframe = getTimeframe(timeframe);

    if (!dataStreamId) {
        return { queryKey, resolvedOptions, isDataStreamSelected: false };
    }

    const dataStreamRequest: ClientDataStreamRequest = {
        dataStreamId,
        dataStreamName,
        options,
        timeframe: resolvedTimeframe,
        pluginConfigId,
        scope,
        dataSourceConfig,
        context
    };

    return {
        queryKey,
        resolvedOptions: {
            ...resolvedOptions,
            ...queryOptions?.dataStreamOptions
        },
        isDataStreamSelected: true,
        dataStreamRequest
    };
};

export const getDataStreamDataFn = (
    args:
        | { isDataStreamSelected: false }
        | {
              isDataStreamSelected: true;
              dataStreamRequest: ClientDataStreamRequest;
          },
    workspaceId: string | null | undefined,
    clientDataStreams?: ClientDataStreamsContextValue
) => {
    return async () => {
        try {
            if (!args.isDataStreamSelected) {
                throw new Error('No data stream selected');
            }

            // Bypass client datastream (for now) if there's any variables
            if (
                clientDataStreams?.isClientDataStreamsEnabled &&
                args.dataStreamRequest.options.noCacheRead !== true &&
                !args.dataStreamRequest.context
            ) {
                const clientResult = await clientDataStreams.runClientSide(args.dataStreamRequest);

                if (clientResult.succeeded) {
                    return clientResult.value;
                }
            }

            return await requestData(args.dataStreamRequest, workspaceId);
        } catch (e) {
            console.error('Data stream failed:', e);

            throw e;
        } finally {
            console.groupEnd();
        }
    };
};

export type UseDataStreamQueryOptions = Omit<
    UseQueryOptions<FormattedStreamData, unknown, FormattedStreamData, QueryKey>,
    'queryKey' | 'queryFn'
> & {
    extraKeys?: QueryKey;
    dataStreamOptions?: Omit<DataStreamOptions, 'sort' | 'filter' | 'group' | 'format' | 'metadata' | 'purpose'>;
};

type RetryEntry = {
    retryCount: number;
    retriedAt: number;
};

/**
 * Get when the server last completed the request.
 * Falls back to the last time the data was fetched if the server didn't provide a completedAt.
 * @param result The query result.
 * @returns The unix timestamp of when the request was completed.
 */
const getCompletedAt = (result: UseQueryResult<FormattedStreamData, unknown>): number => {
    if (result.error && result.error instanceof AxiosError && result.error.response?.data.completedAt) {
        return result.error.response.data.completedAt;
    }

    if (result.data?.metadata?.completedAt) {
        return result.data.metadata.completedAt;
    }

    return result.isError ? result.errorUpdatedAt : result.dataUpdatedAt;
};

export function useDataStream(
    request: ClientDataStreamRequest | undefined,
    workspaceId?: string | null,
    queryOptions?: UseDataStreamQueryOptions
): UseDataStreamResult {
    const args = parseUseDataStreamArgs(request, queryOptions);
    const clientDataStreams = useClientDataStreamsContext();
    const queryClient = useQueryClient();
    const [refetchInterval, setRefetchInterval] = useState(DEFAULT_REFETCH_INTERVAL_MS);

    const result = useQuery(
        args.queryKey ?? 'emptyDatastreamRequest',
        getDataStreamDataFn(args, workspaceId, clientDataStreams),
        {
            staleTime: 300_000,
            cacheTime: 60_000,
            enabled: Boolean(request),
            keepPreviousData: true,
            retry: 0,
            refetchInterval,
            ...args.resolvedOptions
        }
    );

    /** Unix timestamp of when the server request was completed. */
    const completedAt = getCompletedAt(result);
    // Server has given us a hint for how long to wait until we refetch the data.
    const validForSeconds = result?.data?.metadata?.validForSeconds as number | undefined;

    // how many times has this request failed to fetch completely
    const retryCount = getDataStreamRetryCount(queryClient, args.queryKey);
    const retryAfter = getDataStreamRetryAfter(result, retryCount);
    const refetchIntervalSeconds = retryAfter || validForSeconds;

    if (args.queryKey && retryAfter) {
        const retryEntry = queryClient.getQueryData<RetryEntry>([...args.queryKey, DATA_STREAM_RETRY_KEY]);
        // bump retry count if this is a new retry
        if (retryEntry?.retriedAt !== completedAt) {
            queryClient.setQueryData<RetryEntry>([...args.queryKey, DATA_STREAM_RETRY_KEY], (prev) => ({
                retryCount: (prev?.retryCount ?? 0) + 1,
                retriedAt: completedAt
            }));
        }
    }

    /**
     * Update the refetch interval based on the last time the data was fetched.
     * This ensures the refetch interval is correct when the data is cached.
     * For example, when switching between dashboards/data, the refetch interval is reset.
     * Note: We can't use react-query's refetchInterval as it doesn't handle errors properly.
     * Also, react-query's retry/retryDelay doesn't return the error until after it's complete.
     */
    const updateRefetchInterval = useCallback(() => {
        if (!refetchIntervalSeconds) {
            setRefetchInterval(DEFAULT_REFETCH_INTERVAL_MS);
            return;
        }

        // buffer to avoid refetching too often
        const minRefetchIntervalMs = 500;
        const msSinceLastFetch = Math.floor(Date.now() - (completedAt || Date.now()));
        const timeLeftMs = refetchIntervalSeconds * 1000 - msSinceLastFetch;
        setRefetchInterval(Math.max(minRefetchIntervalMs, timeLeftMs));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchIntervalSeconds, completedAt, stringify(args.queryKey)]);

    useEffect(() => {
        updateRefetchInterval();

        // update refetch interval when the page/tab is re-focused
        // acts like refetchIntervalInBackground, but only updates when the page is re-focused
        window.addEventListener('visibilitychange', updateRefetchInterval, false);
        window.addEventListener('focus', updateRefetchInterval, false);

        return () => {
            window.removeEventListener('visibilitychange', updateRefetchInterval);
            window.removeEventListener('focus', updateRefetchInterval);
        };
    }, [updateRefetchInterval]);

    if (
        clientDataStreams.isClientDataStreamsEnabled &&
        args.isDataStreamSelected &&
        /**
         * Don't associate data for a previous query key with
         * the new query key.
         */
        !result.isPreviousData &&
        result.data?.metadata.source === 'server'
    ) {
        clientDataStreams.setBase(args.dataStreamRequest, result.data);
    }

    return {
        ...result,
        data: result.data ?? noData,
        isLoaded: result.data != null,
        warnings: result.data?.metadata.warnings || [],
        isLoadingOrPreviousData: result.isLoading || result.isPreviousData,
        retryAfter,
        completedAt
    };
}

export default useDataStream;
