import Template from '@/components/Template';
import { useAppContext } from 'contexts/AppContext';
import { usePageTitle } from 'lib/usePageTitle';
import { useParams } from 'react-router';
import { useEnsureCorrectCurrentWorkspaceId } from 'ui/hooks/useEnsureCorrectCurrentWorkspaceId';
import { NetworkMap } from '../../components/map/NetworkMap';

function Explorer() {
    usePageTitle('Map');
    const { id } = useParams();
    const { currentWorkspaceID } = useAppContext();

    useEnsureCorrectCurrentWorkspaceId(id);

    if (!id || id !== currentWorkspaceID) {
        return null;
    }

    return (
        <Template title='Map' equalPadding flex>
            <div className='w-full h-full pb-4'>
                <NetworkMap filterInitalBasedOnHealth />
            </div>
        </Template>
    );
}

export default Explorer;
