import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from '../data/types';
import { useExpandNode } from './useExpandNode';

export function useHideNode() {
    const graph = useGraph();
    const expandNode = useExpandNode();

    return useCallback(
        (node: GraphologyNodeAttributes) => {
            expandNode(node, false);
            graph.updateNodeAttributes(node.id, (attr) => ({ ...attr, pinned: false, hidden: true }));
        },
        [graph, expandNode]
    );
}
