import { usePlugin } from 'pages/settings/plugins/components/usePlugin';
import { OverviewColumn } from './DisplayComponents';
import { OverviewDataStreamDetails } from './OverviewDataStreamDetails';
import { OverviewObjectDetails } from './OverviewObjectDetails';
import { useOverviewDataStreams } from './useOverviewDataStreams';

export const OverviewData = ({
    configId,
    pluginId,
    importStatus
}: {
    configId: string;
    pluginId: string;
    importStatus: string;
}) => {
    const { data: plugin, isLoading: loadingPlugin } = usePlugin(pluginId);
    const { data: dataStreamDetails, isLoading: loadingDataStreams } = useOverviewDataStreams(pluginId);

    if (loadingPlugin) {
        return null;
    }

    return (
        <div className='flex flex-1 w-full min-h-0 gap-2.5 pb-4'>
            {!plugin?.importNotSupported && (
                <OverviewColumn key={configId}>
                    <OverviewObjectDetails configId={configId} importStatus={importStatus} />
                </OverviewColumn>
            )}
            {(loadingDataStreams || (dataStreamDetails && dataStreamDetails.length > 0)) && (
                <OverviewColumn>
                    <OverviewDataStreamDetails 
                        dataStreamDetails={dataStreamDetails} 
                        isLoading={loadingDataStreams} 
                    />
                </OverviewColumn>
            )}
        </div>
    );
};
