import { cn } from '@/lib/cn';
import Text from 'components/primitives/components/Text';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';

interface DataStreamTagsFilterProps {
    tags?: string[];
}

export const DataStreamTagsFilter: React.FC<DataStreamTagsFilterProps> = ({ tags }) => {
    const { filters, dispatch } = useTileEditorStore((s) => ({
        filters: s.dataStream.filters,
        dispatch: s.dispatch
    }));

    return (
        <div className='flex flex-col overflow-auto border-r w-72 border-outlinePrimary scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
            <button
                key='allTags'
                className={cn(
                    'px-6 py-3 border-b border-outlinePrimary text-left text-textSecondary',
                    !filters.tag && 'text-textPrimary'
                )}
                data-testid='datastreamTag'
                onClick={() => dispatch({ 
                    type: 'dataStream.updateFilters', 
                    filters: { tag: undefined }
                })}
            >
                <Text.H4>All</Text.H4>
            </button>

            {tags?.map((tag) => 
                <button
                    key={tag}
                    className={cn(
                        'px-6 py-3 border-b border-outlinePrimary text-left text-textSecondary',
                        filters.tag === tag && 'text-textPrimary'
                    )}
                    data-testid='datastreamTag'
                    onClick={() => dispatch({ 
                        type: 'dataStream.updateFilters', 
                        filters: { tag: tag === filters.tag ? 
                            undefined : 
                            tag
                        }
                    })}
                >
                    <Text.H4>{tag}</Text.H4>
                </button>
            )}
        </div>
    );
};
