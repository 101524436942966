import { faExpand, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAnimateLayout } from 'components/map/hooks/useAnimateLayout';
import Tooltip from 'components/tooltip/Tooltip';
import { ControlButton, ReactFlowState, useReactFlow, useStore } from 'reactflow';
import { shallow } from 'zustand/shallow';

const selector = (s: ReactFlowState) => ({
    minZoomReached: s.transform[2] <= s.minZoom,
    maxZoomReached: s.transform[2] >= s.maxZoom
});

export const DefaultControls: React.FC = () => {
    const { minZoomReached, maxZoomReached } = useStore(selector, shallow);
    const { zoomIn, zoomOut } = useReactFlow();
    const animateLayout = useAnimateLayout();

    const onZoomInHandler = () => {
        zoomIn();
    };

    const onZoomOutHandler = () => {
        zoomOut();
    };

    const onFitViewHandler = () => {
        animateLayout(true);
    };

    return (
        <>
            <Tooltip title='Zoom in' placement='right'>
                <ControlButton onClick={onZoomInHandler} disabled={maxZoomReached}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                </ControlButton>
            </Tooltip>
            <Tooltip title='Zoom out' placement='right'>
                <ControlButton onClick={onZoomOutHandler} disabled={minZoomReached}>
                    <FontAwesomeIcon icon={faMinus} fixedWidth />
                </ControlButton>
            </Tooltip>
            <Tooltip title='Redraw' placement='right'>
                <ControlButton onClick={onFitViewHandler}>
                    <FontAwesomeIcon icon={faExpand} fixedWidth />
                </ControlButton>
            </Tooltip>
        </>
    );
};
