import { InfoTip } from '@/components/InfoTip';
import { cn } from '@/lib/cn';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { AnimatePresence } from 'framer-motion';
import { trackEventDebounced } from 'lib/analytics';
import { debounce } from 'lodash';
import { ScopeObjectCountLimitMessage } from 'pages/scope/scopeModal/ScopeObjectCountLimitMessage';
import { useCallback, useMemo, useState, type FC } from 'react';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { SearchBar } from '../SearchBar';
import { useConvertOobScopeToNodes } from '../hooks/useConvertOobScopeToNodes';
import { DataStreamLimitMessage } from '../objects/DataStreamLimitMessage';
import { ObjectDynamicToggle } from '../objects/ObjectDynamicToggle';
import { ObjectFilters } from '../objects/ObjectFilters';
import { ObjectTable } from '../objects/ObjectTable';
import { OobScopeLimitMessage } from '../objects/OobScopeLimitMessage';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { ObjectsFilterContextWrapper } from './ObjectsFilterContextWrapper';
import { SelectedObjectsCountButton } from './SelectedObjectsCountButton';
import { SelectedObjectsPanel } from './SelectedObjectsPanel';
import { StepProgressButton } from './StepProgressButton';
import { StepTitleAndControls } from './StepTitleAndControls';

export const ObjectsStepInner: React.FC = () => {
    const objectFilterState = useTileEditorObjectsFilterContext();

    const {
        scope,
        filteredObjects: { count, isFetchingObjects },
        isDynamic,
        filters,
        isConfigured,
        dynamicObjectsCount,
        selectedObjectsCount,
        isLoadingFilters,
        interactedObjects,
        updateScope
    } = objectFilterState;

    const { dispatch, selectedNodeIds } = useTileEditorStore((s) => ({
        dispatch: s.dispatch,
        selectedNodeIds: s.scope.isDynamic || s.scope.type === 'none' ? [] : s.scope.selectedNodeIds
    }));

    const [isObjectsPanelVisible, setIsObjectsPanelVisible] = useState(false);

    const onConverted = useCallback(() => {
        setIsObjectsPanelVisible(true);
    }, [setIsObjectsPanelVisible]);

    useConvertOobScopeToNodes(onConverted);

    // We only want to update the search query every 500ms to avoid sending multiple
    // requests too quickly
    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm: string) => {
                trackEventDebounced('Objects Searched For', { query: searchTerm });
                return updateScope({ filters: { searchString: searchTerm } });
            }, 500),
        [updateScope]
    );

    if (isLoadingFilters) {
        return (
            <div className='flex items-center justify-center flex-1 w-full h-full'>
                <LoadingSpinner className='m-auto' />
            </div>
        );
    }

    const handleObjectsPanelToggle = () => {
        if (!isDynamic) {
            setIsObjectsPanelVisible(!isObjectsPanelVisible);

            if (!isObjectsPanelVisible) {
                // Remove unselected nodes from the interacted nodes list
                // when the panel is closed
                dispatch({ type: 'setSelectedObjects', selectedNodeIds, pruneInteractedNodeIds: true });
            }
        }
    };

    return (
        <div className='flex min-h-0 h-[700px] max-h-full'>
            <div className={cn('flex flex-col flex-1 min-w-0 pl-6 pr-1 py-4 px-0')}>
                <div className='pb-4 pl-6 pr-5 mb-4 border-b border-dividerTertiary'>
                    <StepTitleAndControls title='Select objects' />
                </div>

                <div 
                    className={cn('flex flex-1 min-h-0 space-x-6 outline-none pl-6 pr-1')}
                    tabIndex={0}
                >
                    <ObjectFilters allowCreateScope={true} allowEditScope={true} />

                    <div className='flex flex-col flex-1 h-full min-w-0 min-h-0'>
                        <div className='flex mb-4 mr-4'>
                            <SearchBar
                                initialValue={filters.searchString}
                                placeholder='Search objects...'
                                onChange={debouncedSearch}
                                className='flex-1 min-w-0 mr-8'
                                showHelp={true}
                            />

                            <ObjectDynamicToggle />
                        </div>

                        <ObjectTable
                            onSelectedObjectsChange={(newSelectedNodeIds) => {
                                dispatch({
                                    type: 'setSelectedObjects',
                                    selectedNodeIds: newSelectedNodeIds,
                                    pruneInteractedNodeIds: !isObjectsPanelVisible
                                });
                            }}
                        />

                        <OobScopeLimitMessage />

                        {scope.isVariableScope && (
                            <InfoTip 
                                icon={faBarsFilter} 
                                iconClassName='text-textPrimary'
                                className='mr-4'
                            >
                                This tile uses a dashboard variable, users can set the variable when viewing the
                                dashboard.
                            </InfoTip>
                        )}

                        <div className={cn('flex items-center justify-end space-x-4', !scope.isVariableScope && 'mt-4')}>
                            <ScopeObjectCountLimitMessage className='pb-0' />

                            <div className='flex items-center flex-shrink-0 pr-4 justify-self-end'>
                                <div>
                                    <DataStreamLimitMessage />
                                </div>
                                <div className='flex items-center justify-end flex-1 text-textSecondary'>
                                    {!scope.isVariableScope && (
                                        <SelectedObjectsCountButton
                                            totalCount={count || 0}
                                            interactedObjectsCount={interactedObjects.length}
                                            selectedObjectsCount={selectedObjectsCount}
                                            dynamicObjectsCount={dynamicObjectsCount}
                                            isDynamic={isDynamic}
                                            onClick={() => {
                                                handleObjectsPanelToggle();
                                            }}
                                        />
                                    )}

                                    {isFetchingObjects && <LoadingSpinner size={20} className='mr-4' />}
                                </div>

                                {!isDynamic && (
                                    <Button
                                        disabled={selectedObjectsCount === 0}
                                        variant='tertiary'
                                        className='mr-2 px-md'
                                        onClick={() =>
                                            updateScope({
                                                selectedNodeIds: [],
                                                pruneInteractedNodeIds: !isObjectsPanelVisible
                                            })
                                        }
                                    >
                                        Clear
                                    </Button>
                                )}

                                <StepProgressButton disabled={!isConfigured} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {isObjectsPanelVisible && interactedObjects.length !== 0 && !isDynamic && (
                    <div className='overflow-hidden bg-tagBackground'>
                        <SelectedObjectsPanel handleObjectsPanelToggle={handleObjectsPanelToggle} />
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export const ObjectsStep: FC = () => {
    const { scope, selectedDataStream, dispatch } = useTileEditorStore((s) => ({
        selectedDataStream: s.dataStream.selectedDataStream,
        scope: s.scope,
        dispatch: s.dispatch
    }));

    return (
        <ObjectsFilterContextWrapper state={scope} dispatch={dispatch} selectedDataStream={selectedDataStream}>
            <ObjectsStepInner />
        </ObjectsFilterContextWrapper>
    );
};
