import { cn } from '@/lib/cn';
import { PanelResizeHandle } from 'react-resizable-panels';
import { useTileEditorStepsContext } from 'ui/editor/dataStream/TileEditor/contexts/TileEditorStepsContext';

interface ResizablePanelResizeHandleProps {
    direction: 'horizontal' | 'vertical';
    onResetLayout?: () => void;
}

export const ResizablePanelResizeHandle: React.FC<ResizablePanelResizeHandleProps> = ({ direction, onResetLayout }) => {
    const { currentEditorStep, isDocked } = useTileEditorStepsContext();
    
    return (
        <PanelResizeHandle className={cn(
            'z-0 group',
            currentEditorStep && !isDocked && 'opacity-30 pointer-events-none'
        )}>
            <div
                className={cn('flex items-center justify-center h-full p-[4px]', {
                    '!cursor-row-resize': direction === 'vertical',
                    '!cursor-col-resize': direction === 'horizontal'
                })}
                onDoubleClick={onResetLayout}
            >
                <div
                    className={cn(
                        'rounded bg-dividerTertiary transition-all group-data-[resize-handle-active]:bg-primaryButtonBackground',
                        {
                            'w-24 group-hover:w-32 group-data-[resize-handle-active]:w-32 h-[6px] !cursor-row-resize':
                                direction === 'vertical',
                            'h-24 group-hover:h-32 group-data-[resize-handle-active]:h-32 w-[6px] !cursor-col-resize':
                                direction === 'horizontal'
                        }
                    )}
                />
            </div>
        </PanelResizeHandle>
    );
};
