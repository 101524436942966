import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';

export function useCollapseNodes() {
    const graph = useGraph();

    return useCallback(() => {
        graph.updateEachNodeAttributes((_, attr) => ({
            ...attr,
            expanded: false,
            hidden: !attr.pinned
        }));
    }, [graph]);
}
