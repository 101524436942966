import {
    CloneStreamDataColumnDefinition,
    ComparisonStreamDataColumnDefinition,
    DATA_SOURCE_NAME_COLUMN,
    FormattedStreamValue,
    SingleStreamDataColumnDefinition,
    StreamData,
    StreamDataColumn,
    getColumnShape,
    getComparedColumnName,
    getExpandedColumnName,
    isNone,
    noneValue,
    type StreamMetadata
} from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import { PartialDeep } from 'type-fest';
import {
    DataStreamMetadataState,
    DataStreamMetadataStateColumn,
    MetadataOverrides,
    PatternStateColumn,
    createMetadataEditorState,
    getCloneColumnName,
    getComparisonColumnName,
    type ColumnDefinitionEntry
} from './MetadataEditorState';
import { TileConfigMetadataOverrideArray } from './MetadataEditorTileState';

/**
 * Create a function that returns sample values for columns
 */
const sampleValueFrom = (rows: FormattedStreamValue[][], metadata: StreamDataColumn[]) => (columnName: string) => {
    const columnDataIndex = metadata.findIndex((c) => c.name === columnName);

    if (columnDataIndex === -1 || (rows?.[0]?.length ?? 0) <= columnDataIndex) {
        return noneValue;
    }

    const sample = rows.find((r) => !isNone(r[columnDataIndex].value))?.[columnDataIndex] ?? rows[0][columnDataIndex];

    return sample ?? noneValue;
};

/**
 * Get the name of the column the given column was expanded from
 */
const splitExpandedColumnName = (fullColumnName: string): { sourceColumnName: string; expandedColumnName: string } => {
    const { sourceColumnName, expandedColumnName } = getExpandedColumnName(fullColumnName);

    if (sourceColumnName == null) {
        throw new Error(`${fullColumnName} is not a valid expanded column name`);
    }

    return { sourceColumnName, expandedColumnName };
};

const splitComparedColumnName = (fullColumnName: string): { sourceColumnName: string; comparedColumnName: string } => {
    const { sourceColumnName, comparedColumnName } = getComparedColumnName(fullColumnName);

    if (sourceColumnName == null) {
        throw new Error(`${fullColumnName} is not a valid expanded column name`);
    }

    return { sourceColumnName, comparedColumnName };
};

/**
 * Get the column definitions for the given column from the metadata.
 * Only matches based on name are included.
 */
const getColumnDefinitions = (
    columnDefinitions: StreamMetadata['columnDefinitions'],
    c: StreamDataColumn
): ColumnDefinitionEntry[] => {
    return Object.entries(columnDefinitions).flatMap(([k, ds]) => {
        const definition = ds.find((d): d is SingleStreamDataColumnDefinition => 'name' in d && d.name === c.name);

        if (definition == null) {
            return [];
        }

        return [{ source: k as keyof StreamMetadata['columnDefinitions'], definition }];
    });
};

/**
 * Convert data stream metadata to the equivalent metadata editor state
 */
export const fromDataStreamMetadata = (
    streamData: StreamData,
    tileMetadata: TileConfigMetadataOverrideArray
): DataStreamMetadataState => {
    const { rows, metadata } = streamData;

    const getSampleValue = sampleValueFrom(rows, metadata.columns);

    const tileMetadataOverrides = new Map(
        tileMetadata
            .filter(isDefined)
            .filter((c): c is typeof c & ({ name: string } | { pattern: string }) => 'name' in c || 'pattern' in c)
            .flatMap((c): [string, MetadataOverrides | undefined][] => {
                if ('pattern' in c) {
                    return [[c.pattern ?? '', c]];
                }

                const getClonesFromExpand = (
                    column: PartialDeep<SingleStreamDataColumnDefinition | CloneStreamDataColumnDefinition>,
                    parentColumnName: string = ''
                ): [string, MetadataOverrides][] => {
                    const columnName =
                        'name' in column ? column.name : 'cloneAs' in column ? column.cloneAs : undefined;

                    if (columnName == null) {
                        return [];
                    }

                    return (column.expand ?? [])
                        .filter(isDefined)
                        .filter((e): e is typeof e & { cloneAs: string } => 'cloneAs' in e)
                        .flatMap((e): [string, MetadataOverrides][] => {
                            const cloneColumnName = `${parentColumnName}${getCloneColumnName(columnName, e.cloneAs)}`;

                            return [[cloneColumnName, e], ...getClonesFromExpand(e, `${columnName}[Expanded].`)];
                        });
                };

                const getComparisonsFromExpand = (
                    column: PartialDeep<SingleStreamDataColumnDefinition | ComparisonStreamDataColumnDefinition>,
                    parentColumnName: string = ''
                ): [string, MetadataOverrides][] => {
                    const columnName =
                        'name' in column ? column.name : 'compareTo' in column ? column.compareTo : undefined;

                    if (columnName == null) {
                        return [];
                    }

                    return (column.expand ?? [])
                        .filter(isDefined)
                        .filter((e): e is typeof e & { compareTo: string; comparisonName: string } => 'compareTo' in e)
                        .flatMap((e): [string, MetadataOverrides][] => {
                            const compareColumnName = `${parentColumnName}${getComparisonColumnName(
                                columnName,
                                e.compareTo,
                                e.comparisonName
                            )}`;

                            return [
                                [compareColumnName, e],
                                ...getComparisonsFromExpand(
                                    e,
                                    `${columnName}.comparedTo[${e.compareTo}].${e.comparisonName}`
                                )
                            ];
                        });
                };

                const clonesFromExpand = getClonesFromExpand(c);
                const comparisonsFromExpand = getComparisonsFromExpand(c);

                return [[c.name, c], ...clonesFromExpand, ...comparisonsFromExpand];
            })
    );

    const streamMetadataColumns = new Map(
        metadata.columns.map((c): [string, DataStreamMetadataStateColumn] => {
            const metadataOverrides = tileMetadataOverrides.get(c.name);

            const isComparison = c.name.includes('.comparedTo');

            if (c.name.includes('[Expanded]') && !isComparison) {
                const { sourceColumnName, expandedColumnName } = splitExpandedColumnName(c.name);

                if (metadata.columns.some((sourceColumn) => sourceColumn.name === sourceColumnName)) {
                    return [
                        c.name,
                        {
                            isPattern: false,
                            columnName: c.name,
                            cloneName: expandedColumnName,
                            isClone: true,
                            isCloneable: false,
                            isBeingCloned: false,
                            isComparison: false,
                            isComparable: false,
                            isComputed: false,
                            sourceColumnName,
                            metadata: c,
                            // A cloned column isn't editable if it
                            // wasn't the result of a clone done by the metadata editor
                            isEditable: metadata.columnDefinitions.request.some(
                                (source) =>
                                    'name' in source &&
                                    source.name === sourceColumnName &&
                                    source.expand?.some(
                                        (expanded) => 'cloneAs' in expanded && expanded.cloneAs === expandedColumnName
                                    )
                            ),
                            definitions: getColumnDefinitions(metadata.columnDefinitions, c),
                            shape:
                                metadataOverrides?.shape != null
                                    ? getColumnShape(metadataOverrides.shape)
                                    : { name: c.shapeName, config: c.rawShapeConfig },
                            metadataOverrides,
                            sampleValue: getSampleValue(c.name)
                        }
                    ];
                }
            }

            if (isComparison) {
                const { sourceColumnName, comparedColumnName } = splitComparedColumnName(c.name);

                if (metadata.columns.some((sourceColumn) => sourceColumn.name === sourceColumnName)) {
                    return [
                        c.name,
                        {
                            isPattern: false,
                            columnName: c.name,
                            comparisonName: comparedColumnName,
                            isClone: false,
                            isCloneable: false,
                            isComparison: true,
                            isComparable: false,
                            isComputed: false,
                            sourceColumnName,
                            metadata: c,
                            isEditable: true,
                            definitions: getColumnDefinitions(metadata.columnDefinitions, c),
                            shape:
                                metadataOverrides?.shape != null
                                    ? getColumnShape(metadataOverrides.shape)
                                    : { name: c.shapeName, config: c.rawShapeConfig },
                            metadataOverrides,
                            sampleValue: getSampleValue(c.name)
                        }
                    ];
                }
            }

            if (c.computed) {
                return [
                    c.name,
                    {
                        isPattern: false,
                        columnName: c.name,
                        isClone: false,
                        isCloneable: false,
                        isComparison: false,
                        isComparable: false,
                        isComputed: true,
                        isEditable: true,
                        metadata: c,
                        definitions: getColumnDefinitions(metadata.columnDefinitions, c),
                        shape:
                            metadataOverrides?.shape != null
                                ? getColumnShape(metadataOverrides.shape)
                                : { name: c.shapeName, config: c.rawShapeConfig },
                        metadataOverrides,
                        sampleValue: getSampleValue(c.name)
                    }
                ];
            }

            return [
                c.name,
                {
                    isPattern: false,
                    columnName: c.name,
                    isClone: false,
                    isCloneable: !streamData.metadata.isGrouped,
                    isComparison: false,
                    isComparable: c.valueShapeName === 'shape_number',
                    isComputed: false,
                    metadata: c,
                    definitions: getColumnDefinitions(metadata.columnDefinitions, c),
                    shape:
                        metadataOverrides?.shape != null
                            ? getColumnShape(metadataOverrides.shape)
                            : { name: c.shapeName, config: c.rawShapeConfig },
                    metadataOverrides,
                    sampleValue: getSampleValue(c.name)
                }
            ];
        })
    );

    // Include pattern columns in the state so we don't
    // remove them from the tile config
    const patternColumns = [...tileMetadataOverrides.values()]
        .filter((c): c is Exclude<typeof c, undefined> & { pattern: string } => c != null && 'pattern' in c)
        .map((c): PatternStateColumn => {
            return {
                pattern: c.pattern,
                isPattern: true,
                isClone: false,
                isCloneable: false,
                isComparison: false,
                isComparable: false,
                isComputed: false,
                metadataOverrides: c
            };
        });

    patternColumns.forEach((c) => streamMetadataColumns.set(c.pattern, c));

    const dataStreamDefinitionColumn = metadata.columnDefinitions?.dataStreamDefinition ?? [];

    // Filter out the automatically added data source name column to avoid the editor thinking a column is defined
    const pluginResponseColumns =
        metadata.columnDefinitions?.pluginResponse.filter((p) => 'name' in p && p.name !== DATA_SOURCE_NAME_COLUMN) ??
        [];

    const ungroupedColumns = metadata.isGrouped ? metadata.columnDefinitions?.ungrouped ?? [] : [];

    const hasDefinedMetadata =
        dataStreamDefinitionColumn.length + pluginResponseColumns.length > 0 ||
        (metadata.isGrouped && ungroupedColumns.length > 0);

    return createMetadataEditorState({
        tileConfigUpdatePending: false,
        hasDefinedMetadata,
        columnMap: streamMetadataColumns
    });
};
