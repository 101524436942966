import { Serialised } from '@squaredup/ids';
import { ChannelTypeIds } from '@squaredup/monitoring';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { useNotificationChannelTypes } from 'components/hooks/useNotificationChannels';
import type { ProjectedChannel } from 'dynamo-wrapper';
import { useFlag } from 'lib/useFlag';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import { useWorkspacesForAdministration } from 'queries/hooks/useWorkspaceForAdministration';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IsTenantAdmin } from 'services/AccessControlService';
import { CHANNELS, Delete, List, Update } from 'services/NotificationChannelService';
import { TenantResponse, UpdateTenantSetting } from 'services/TenantService';
import { SettingsTemplate } from '../SettingsTemplate';
import { NotificationsChannelAddEditModal } from './components/NotificationsChannelAddEditModal';
import { NotificationsTable } from './components/NotificationsTable';
import { NotificationsWorkspacesModal } from './components/NotificationsWorkspacesModal';

interface OrganisationFormValues {
    emailEnterpriseFormatEnabled?: boolean;
}

const queryClientSettings = { refetchOnWindowFocus: false };

function NotificationSettingsForm({ tenant }: { tenant: TenantResponse }) {
    const isAdmin = IsTenantAdmin(tenant);

    const formProps = useForm<OrganisationFormValues>({
        defaultValues: {
            emailEnterpriseFormatEnabled: tenant.settings?.emailEnterpriseFormatEnabled
        }
    });

    const { handleSubmit } = formProps;

    const onNotificationSettingsChanged = handleSubmit(
        async ({ emailEnterpriseFormatEnabled }: OrganisationFormValues) => {
            if (isAdmin) {
                await UpdateTenantSetting('emailEnterpriseFormatEnabled', emailEnterpriseFormatEnabled);
            }
        }
    );

    return (
        <FormProvider {...formProps}>
            <form className='max-w-4xl my-6'>
                <FormToggle
                    disabled={!isAdmin}
                    name='emailEnterpriseFormatEnabled'
                    label='Enterprise email format'
                    onCheckedChange={() => onNotificationSettingsChanged()}
                    data-testid='emailEnterpriseFormatEnabled'
                />
            </form>
        </FormProvider>
    );
}

export default function Notifications() {
    const queryClient = useQueryClient();
    const { data: tenant, isLoading: isLoadingTenant } = useTenant({ refetchOnMount: 'always' });

    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState<Serialised<ProjectedChannel> | undefined>();
    const [deleting, setDeleting] = useState<Serialised<ProjectedChannel> | undefined>();
    const [inspecting, setInspecting] = useState<Serialised<ProjectedChannel> | undefined>();

    const { data: channels, isLoading: isLoadingChannels } = useQuery(CHANNELS, List, queryClientSettings);
    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspacesForAdministration({
        refetchInterval: 60_000
    });
    const { data: tier, isLoading: isLoadingTier } = useTier();
    const { channelTypes, isLoadingChannelTypes } = useNotificationChannelTypes();

    const handlePlayPauseChannel = async (id: string, enabled: boolean) => {
        await Update(id, {
            enabled
        });

        queryClient.invalidateQueries(CHANNELS);
    };

    const { mutateAsync: handleDeletion } = useMutation(Delete, {
        onSettled: () => queryClient.invalidateQueries(CHANNELS)
    });

    // TODO: once flag is removed, add enterprise pill & feature handling to form for non-ent users
    const enterpriseEmailFormatEnabled = useFlag('enterpriseEmailFormat');
    const isLoading = isLoadingChannels || isLoadingWorkspaces || isLoadingTier || isLoadingChannelTypes;
    const isSettingsFormEnabled = enterpriseEmailFormatEnabled && !isLoading && !isLoadingTenant && tenant && tier;

    return (
        <SettingsTemplate
            title='Notifications'
            description='Manage destination channels to receive state changes from your monitors.'
            learnMoreLink='https://squaredup.com/cloud/Health-notifications'
            flex
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div className='flex flex-col min-h-0'>
                    <div>
                        <Button onClick={() => setCreating(true)} data-testid='addDestination'>
                            Add destination
                        </Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <NotificationsTable
                            channels={channels ?? []}
                            setDeleting={(id) => setDeleting(channels?.find((c) => c.id === id))}
                            setEditing={(id) => setEditing(channels?.find((c) => c.id === id))}
                            setEnabledState={handlePlayPauseChannel}
                            workspaces={workspaces ?? []}
                            setInspectingWorkspaceCount={setInspecting}
                            tier={tier}
                            channelTypes={channelTypes ?? []}
                        />
                    </div>
                </div>
            )}

            {/* Add/Edit/Delete Modals */}
            {(editing || creating) && (
                <NotificationsChannelAddEditModal
                    existingChannelNames={channels?.map((c) => c.displayName ?? '') ?? []}
                    initialChannel={editing}
                    close={async () => {
                        setCreating(false);
                        setEditing(undefined);
                        await queryClient.invalidateQueries(CHANNELS);
                    }}
                    tier={tier}
                />
            )}

            {deleting && (
                <ConfirmationPrompt
                    title={`Delete Destination: ${deleting.displayName}`}
                    prompt={
                        deleting.channelTypeId !== ChannelTypeIds.Zapier
                            ? 'Are you sure you want to permanently delete this destination? All workspace notification rules using this destination will be disabled.'
                            : 'Are you sure you want to permanently delete this Zapier destination? All workspace notification rules using this destination will be disabled, and any Zapier applications using this destination will no longer work.'
                    }
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => {
                        await handleDeletion(deleting.id);
                    }}
                    onClose={() => setDeleting(undefined)}
                />
            )}

            {inspecting && (
                <NotificationsWorkspacesModal
                    channel={inspecting}
                    workspaces={workspaces ?? []}
                    close={async () => {
                        setInspecting(undefined);
                    }}
                />
            )}

            {isSettingsFormEnabled && <NotificationSettingsForm tenant={tenant} />}
        </SettingsTemplate>
    );
}
