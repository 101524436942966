import {
    FormattingContext,
    FormattingContextWithShape,
    StreamData,
    StreamDataColumn,
    StreamValueSchema,
    defaultFormatSpec,
    formatValueAsColumn,
    getGroupedShapeConfig,
    getResolvedShapeConfig,
    number,
    type ResolvedShapeConfig
} from '@squaredup/data-streams';

export const getDecimalPlaces = (value: number): number => {
    // Handle non-finite values
    if (!isFinite(value)) {
        return 0;
    }

    // Integer check
    if (Number.isInteger(value)) {
        return 0;
    }

    const str = value.toString();
    // If there's no decimal point, return 0
    if (!str.includes('.')) {
        return 0;
    }

    // Get the part after decimal and remove trailing zeros
    const decimals = str.split('.')[1].replace(/0+$/gu, '');
    return decimals.length;
};

/**
 * Format a number to the shortest possible string for rendering in chart axes
 * Use the fewest decimal places necessary with a maximum of 2
 * @param {number} v Value to be formatted
 * @returns {string} A locale formatted string
 */
export const toShortString = (v: number) => {
    const adjusted = v + Number.EPSILON * 100;

    const rounded = Math.round(adjusted * 100) / 100;

    return rounded.toLocaleString('en-US');
};

/**
 * Format a string to a maximum length and add 2 character ellipsis when needed
 * @param {string} v Value to be formatted
 * @param {number} maxLength The maximum number of characters
 * @returns {string} A formatted string
 */
export const toMaxLength = (v: string, maxLength: number) => {
    // +1 because we're going to add two characters anyway,
    // so if we're 1 over we're still using the same width as if
    // we had added the ellipsis (1 instead of 2 because dots are small)
    if (v.length <= maxLength + 1) {
        return v;
    } else {
        return `${v.slice(0, maxLength)}..`;
    }
};

/**
 * Format a number as a given column, removing all decimal places if the value is an integer
 * @param v The value to format
 * @param column The (number-based) column to format the value as
 */
const formatNumberTickAsColumn = (column: StreamDataColumn, v: number, context: FormattingContextWithShape) => {
    const formatResult = formatValueAsColumn(
        {
            value: v,
            column
        },
        context
    );

    return formatResult;
};

type FormatTickAsColumnOptions = {
    maxLength?: number;
};

type FormatTickAsColumnContext =
    | Omit<FormattingContext, 'formatSpec'>
    | {
          /**
           * Use an entire set of data as context - e.g. when formatting a tick that applies to the whole data set
           */
          data: StreamData;
          valueColumn: StreamDataColumn;
      }
    | undefined;

const toFormattingContext = (context: FormatTickAsColumnContext): FormattingContext => {
    if (context == null) {
        return { rows: [], columns: [], formatSpec: defaultFormatSpec };
    }

    if ('rows' in context) {
        return { ...context, formatSpec: defaultFormatSpec };
    }

    const { rows, metadata } = context.data;

    return {
        rows,
        columns: metadata.columns,
        formatSpec: defaultFormatSpec,
        groupedShapeConfig: getGroupedShapeConfig(metadata)?.map(
            (r) => r[metadata.columns.indexOf(context.valueColumn)]
        )
    };
};

/**
 * Format a value as a given column. If the value is an integer then decimal places are removed.
 * @param column The column to format the value as
 */
export const formatTickAsColumn = (
    column: StreamDataColumn,
    context?: FormatTickAsColumnContext,
    options?: FormatTickAsColumnOptions
) => {
    const maxLength = options?.maxLength ?? 11;
    const formattingContext = toFormattingContext(context);

    const resolvedShapeConfig = getResolvedShapeConfig(column?.rawShapeConfig, formattingContext);

    return (v: unknown, shapeConfigPatch?: Partial<ResolvedShapeConfig>) => {
        const parseResult = StreamValueSchema.safeParse(v);

        if (parseResult.success === false) {
            return toMaxLength((v ?? '').toString(), maxLength);
        }

        if (column.valueShapeName === number.name && typeof parseResult.data === 'number') {
            if (resolvedShapeConfig.failed) {
                return toShortString(v as number);
            }
            const formatted = formatNumberTickAsColumn(column, parseResult.data, {
                ...formattingContext,
                resolvedShapeConfig:
                    shapeConfigPatch == null
                        ? resolvedShapeConfig.value
                        : { ...resolvedShapeConfig.value, ...shapeConfigPatch }
            });

            return toMaxLength(formatted, maxLength);
        }

        if (resolvedShapeConfig.failed) {
            return toMaxLength(v as string, maxLength);
        }

        const formatResult = formatValueAsColumn(
            { value: parseResult.data, column },
            {
                ...formattingContext,
                resolvedShapeConfig: resolvedShapeConfig.value
            }
        );

        return toMaxLength(formatResult, maxLength);
    };
};

/**
 * @returns A number of ticks (numerics) that will be rendered, each tick will be given roughly 115px of space.
 * Note that d3's `ticks` function only takes this as a hint, we can't control the exact number of ticks.
 */
export const tickCount = (width: number) => Math.max(Math.floor(width / 115), 2);
