import Text from '@/components/Text';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultTimeframeEnum, TimeframeEnumValue } from '@squaredup/timeframes';
import Button from 'components/button/Button';
import { useDOMElement } from 'components/hooks/useDOMElement';
import trackEvent from 'lib/analytics';
import { useTier } from 'queries/hooks/useTier';
import type { FC } from 'react';
import TimeframeChooser from 'ui/timeframe/TimeframeChooser';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { StepTitleAndControls } from './StepTitleAndControls';
import { StepProgressButton } from './StepProgressButton';

export const TimeframeStep: FC = () => {
    const { setCurrentEditorStep } = useTileEditorStepsContext();
    const container = useDOMElement('dialogContent');
    const { data: tier } = useTier();

    const { selectedDataStream, timeframe, monitor, kpi, dispatch } = useTileEditorStore((s) => ({
        selectedDataStream: s.dataStream.selectedDataStream,
        timeframe: s.timeframe,
        monitor: s.monitor,
        kpi: s.kpi,
        dispatch: s.dispatch
    }));

    const supportedTimeframes = useDataStreamSupportedTimeframes(selectedDataStream?.id);

    const handleUpdateTimeframe = (newTimeframe: TimeframeEnumValue | null) => {
        const sanitisedTimeframe =
            monitor.isMonitoringEnabled || kpi.isKpiEnabled
                ? newTimeframe ?? defaultTimeframeEnum
                : newTimeframe ?? undefined;

        if (sanitisedTimeframe == null) {
            dispatch({ type: 'timeframe.setTimeframe', isDashboardTimeframe: true });
        } else {
            dispatch({ type: 'timeframe.setTimeframe', timeframe: sanitisedTimeframe, tier });
        }

        trackEvent('Tile Timeframe Selected', { timeframe: sanitisedTimeframe });
    };

    return (
        <div
            className='flex flex-col h-full px-0 py-4 pr-5 overflow-auto tile-scroll-overflow'
            data-testid='tileEditorTimeframeChooser'
        >
            <div className='flex flex-col'>
                <div className='pb-4 pl-6 pr-5 mb-4 border-b border-dividerTertiary'>
                    <StepTitleAndControls
                        title='Timeframe'
                        description={
                            <>
                                By default, tiles use the timeframe set at the dashboard level, but you can also set
                                a fixed timeframe here.{' '}
                                <a
                                    href='https://docs.squaredup.com/first-steps/dashboards#dashboard-timeframe'
                                    className='text-textLink'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Learn more.
                                </a>
                            </>
                        }
                    />
                </div>
            </div>

            <div className='flex flex-col flex-1 pl-6 pr-5'>
                <TimeframeChooser
                    container={container}
                    currentTimeframe={timeframe.isDashboardTimeframe ? undefined : timeframe.selectedTimeframe}
                    allowNullSelection={!monitor.isMonitoringEnabled && !kpi.isKpiEnabled}
                    supportsNoneTimeframe={selectedDataStream?.definition.supportsNoneTimeframe}
                    forceSelection={monitor.isMonitoringEnabled}
                    forceSelectionKPI={kpi.isKpiEnabled}
                    alignment='start'
                    onChange={handleUpdateTimeframe}
                    title={'Tile timeframe'}
                    supportedTimeframes={supportedTimeframes}
                />

                {selectedDataStream?.definition.requiresParameterTimeframe && (
                    <Text.Body className='mt-6'>
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className='h-8 mb-1 mr-2 align-middle text-statusWarningPrimary'
                            fixedWidth
                        />
                        This data stream requires you to include the timeframe in the&nbsp;
                        <Button onClick={() => setCurrentEditorStep(EditorSteps.parameters)} variant={'link'}>
                            Parameters
                        </Button>
                        &nbsp;step.
                        <a
                            href='https://docs.squaredup.com/first-steps/tiles/data-tiles#timeframe'
                            className='ml-2 text-textLink'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </Text.Body>
                )}
                <div className='flex items-end flex-1 mt-4 space-x-4 text-base md:justify-end'>
                    <StepProgressButton />
                </div>
            </div>
        </div>
    );
};
