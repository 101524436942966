import Text from '@/components/Text';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { useDOMElement } from 'components/hooks/useDOMElement';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons, useModal } from 'components/Modal';
import trackEvent from 'lib/analytics';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import Auth from 'services/Auth';
import { ProjectedTenantResponse, SendTrialRequest, TrialRequest } from 'services/TenantService';

type TrialFormData = Omit<TrialRequest, 'source'>;

type TrialModalProps = {
    source: string;
    onClose: () => void;
    tenant?: ProjectedTenantResponse
};

type TrialPanelProps = {
    organisationName: string;
    onClose: () => void;
    onSubmit: (data: TrialFormData) => Promise<void>;
};

const getContactHref = (organisationName = '') =>
    `mailto:sales@squaredup.com?subject=Trial%20Request&body=%0D%0A%0D%0A%E2%80%94-%0D%0AOrganisation%3A%20${organisationName}`;

type ErrorMessageProps = React.HTMLAttributes<HTMLDivElement> & { organisationName: string };

const ErrorMessage = ({ organisationName, ...rest }: ErrorMessageProps) => (
    <div className='flex items-center mt-6' {...rest}>
        <FontAwesomeIcon icon={faCircleXmark} className='mr-3 text-base text-statusErrorPrimary' />
        <Text.H4 aria-label='errorText'>
            An error occurred. Please contact{' '}
            <a className='text-textLink hover:underline focus:underline' href={getContactHref(organisationName)}>
                sales@squaredup.com
            </a>{' '}
            directly with your request.
        </Text.H4>
    </div>
);

const SuccessMessage = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
    <div className='flex items-center mt-6' {...props}>
        <Text.Body aria-label='successText'>
            A confirmation email will be sent to the address provided when the trial has been activated.
        </Text.Body>
    </div>
);

export const TrialPanel = ({ onClose, onSubmit, organisationName }: TrialPanelProps) => {
    const defaultValues: Partial<TrialFormData> = {
        emailAddress: Auth.user?.email ?? '',
        firstName: undefined,
        lastName: undefined,
        company: undefined,
        jobTitle: undefined
    };

    const form = useForm<TrialFormData>({
        defaultValues,
        shouldUnregister: true,
        mode: 'all'
    });

    const { isError, isSuccess, mutateAsync: handleSubmit } = useMutation(onSubmit);

    const isFormValid = Object.keys(form.formState.errors).length === 0 && form.formState.isValid;
    const isSubmitting = form.formState.isSubmitting;

    return (
        <FormProvider {...form}>
            <form className='flex flex-col flex-1 overflow-auto'>
                <hr className='border-dividerPrimary' />
                <div className='flex flex-col flex-1 px-8 py-4'>
                    {isSuccess ? (
                        <SuccessMessage />
                    ) : (
                        <>
                            <Field.Input
                                name='emailAddress'
                                type='email'
                                label='Email address'
                                validation={{ required: true }}
                                disabled={isSubmitting}
                            />
                            <Field.Input
                                name='firstName'
                                label='First name'
                                placeholder='Enter first name'
                                validation={{ required: true, maxLength: 256 }}
                                disabled={isSubmitting}
                            />
                            <Field.Input
                                name='lastName'
                                label='Last name'
                                placeholder='Enter last name'
                                validation={{ required: true, maxLength: 256 }}
                                disabled={isSubmitting}
                            />
                            <Field.Input
                                name='company'
                                label='Company'
                                placeholder='Enter company name'
                                validation={{ required: true, maxLength: 256 }}
                                disabled={isSubmitting}
                            />
                            <Field.Input
                                name='jobTitle'
                                label='Job Title'
                                placeholder='Enter job title'
                                validation={{ required: false, maxLength: 256 }}
                                disabled={isSubmitting}
                            />
                            {isError && <ErrorMessage organisationName={organisationName} />}
                        </>
                    )}
                </div>
                <ModalButtons>
                    {isSuccess ? (
                        <Button
                            type='button'
                            variant='primary'
                            onClick={() => onClose()}
                            data-testid='trial-request-close'
                        >
                            Close
                        </Button>
                    ) : (
                        <>
                            <Button
                                type='button'
                                variant='tertiary'
                                onClick={() => onClose()}
                                data-testid='trial-request-cancel'
                            >
                                Cancel
                            </Button>
                            <Button
                                // Can't use submit button, as ancestor could be a form and doing so
                                // will trigger form submission there too.
                                type='button'
                                onClick={form.handleSubmit((data) =>
                                    handleSubmit(data).catch(() => {
                                        /* required by hook */
                                    })
                                )}
                                data-testid='trial-request-send'
                                disabled={!isFormValid || isSubmitting}
                            >
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Start a trial'}
                            </Button>
                        </>
                    )}
                </ModalButtons>
            </form>
        </FormProvider>
    );
};

export const TrialModal = ({ onClose: close, source, tenant }: TrialModalProps) => {
    const [title, setTitle] = useState('Start a trial');
    const container = useDOMElement('dialogContent');
    const queryClient = useQueryClient();

    const submitTrialRequest = async (data: TrialFormData) => {
        const result = await SendTrialRequest({ 
            ...data,
            jobTitle: data.jobTitle !== '' ? data.jobTitle : undefined,
            source
        });

        if (!result.success) {
            // Trigger error handling in the form, but the action for the customer is the same with or without details.
            throw new Error('API did not return a successful response.');
        }

        setTitle('Activating trial');
        // Update settings immediately in case websocket notification is delayed
        queryClient.setQueryData(tenantQueryKeys.tenant, {
            ...tenant,
            licenceData: {
                ...tenant?.licenceData,
                upgradePending: true,
                trialRequested: true
            }
        });
    };
    
    const organisationName = (tenant?.displayName ?? tenant?.name) ?? '';

    return (
        <Modal title={title} fullWidth maxWidth='max-w-2xl' close={close} container={container}>
            <TrialPanel onClose={close} onSubmit={submitTrialRequest} organisationName={organisationName} />
        </Modal>
    );
};

export const useTrialModal = (tenant?: ProjectedTenantResponse, source = '') => {
    const { isOpen, open, close } = useModal();

    const onTrialModalOpen = () => {
        if (tenant) {
            trackEvent('Trial Modal Opened', { tenantId: tenant.id });
        }
        open();
    };

    const trialModal = isOpen ? (
        <TrialModal onClose={close} source={source} tenant={tenant} />
    ) : null;

    return {
        openTrialModal: onTrialModalOpen,
        trialModal
    };
};
