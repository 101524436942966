import { AccessControlType } from '@squaredup/constants';
import { ClientDataStreamRequest, DataStreamScope, StreamData } from '@squaredup/data-streams';
import { Node } from '@squaredup/graph';
import { Serialised } from '@squaredup/ids';
import axios from 'axios';
import type {
    DataStreamMatchResult,
    NodeDataStreamMatchResult,
    ProjectedDataStreamDefinitionEntity
} from 'dynamo-wrapper';
import API from './API';
import { getCurrentWorkspaceId } from './WorkspaceUtil';
import { handleError, handleResponse } from './util';

export const requestData = async (request: ClientDataStreamRequest, workspaceId?: string | null) => {
    const source = axios.CancelToken.source();
    workspaceId = workspaceId ?? (workspaceId === null ? undefined : getCurrentWorkspaceId());

    const promise: Promise<StreamData> & { cancel?: () => void } = API.post<StreamData>(
        '/datastreams/requests',
        request,
        {
            cancelToken: source.token,
            params: {
                workspaceId
            }
        }
    )
        .then(handleResponse)
        .catch((error) => handleError(error));

    // React Query will use this automatically
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
    };

    return promise;
};

export const matchDataStreams = async (
    scope: DataStreamScope
): Promise<{ matches: DataStreamMatchResult[]; templateMatches?: DataStreamMatchResult[] }> => {
    const workspaceId = getCurrentWorkspaceId();

    return API.post<{ matches: DataStreamMatchResult[] }>(
        '/datastreams/scopes',
        {
            scope,
            includeTemplates: true
        },
        {
            params: {
                workspaceId: 'workpace' in scope ? scope.workpace : workspaceId
            }
        }
    )
        .then(handleResponse)
        .catch(handleError);
};

export const matchDataStreamsToNodes = async (
    nodes: Node[],
    accessControlType?: AccessControlType
): Promise<{ matches: NodeDataStreamMatchResult[] }> => {
    const workspaceId = getCurrentWorkspaceId();

    return API.post<{ matches: NodeDataStreamMatchResult[] }>('/datastreams/nodes', nodes, {
        params: {
            workspaceId,
            accessControlType
        }
    })
        .then(handleResponse)
        .catch(handleError);
};

export const List = async () => {
    return API.get<Serialised<ProjectedDataStreamDefinitionEntity>[]>('/datastreams')
        .then(handleResponse)
        .catch(handleError);
};

export const GetDataStreamsForPlugin = async (id: string) => {
    return API.get(`datastreams/plugin/${id}`).then(handleResponse).catch(handleError);
};

export enum StreamScopeFilterType {
    All,
    ForPluginConfigId,
    None,
    InitiallyBlank
}

export type StreamScopeFilter = {
    filterType: StreamScopeFilterType;
    pluginConfigId?: `config-${string}`;
    pluginId?: `plugin-${string}`;
};
