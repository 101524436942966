import { cn } from '@/lib/cn';
import { isDefined } from '@squaredup/utilities';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useNodeHealth } from 'components/map/hooks/useNodeHealth';
import { TruncatedText } from 'components/TruncatedText';
import { healthStateTextColors } from 'constants/state';
import { scaleOrdinal } from 'd3-scale';
import { mapBackgroundColors, mapBorderColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { DownstreamOptions } from '../nodeToolbar/downstream';
import { NodeToolbar } from '../nodeToolbar/NodeToolbar';
import { UpstreamOptions } from '../nodeToolbar/upstream';

export const GROUP_NODE = 'groupNode';
export const GROUP_NODE_SIZE = 44;

const circleOffsets = [20, 13, 6];
const palette = scaleOrdinal([
    '#1C98C6',
    '#3ABACD',
    '#A64E88',
    '#7423B2',
    '#622CE8',
    '#842497',
    '#844DCE',
    '#7A65FA',
    '#994ABB',
    '#DB96B7'
]);

export const GroupNode = memo(({ id, data }: NodeProps<GraphologyNodeAttributes>) => {
    const background = useNetworkBackground();
    const { isHovered, blur, ...hoverProps } = useHover();

    const pinned = false;

    const groupHealthState = useNodeHealth(data.groupedData?.nodes.map((node) => node.sourceId?.[0]).filter(isDefined));

    const backgroundColor = palette(id);

    const label = `${data.groupedData?.nodes.length} objects`;

    return (
        <div
            className={cn('relative rounded-full', pinned && 'p-2')}
            style={{ width: GROUP_NODE_SIZE, height: GROUP_NODE_SIZE }}
            {...hoverProps}
        >
            <UpstreamOptions onClick={blur} node={data} isVisible={isHovered} />
            <DownstreamOptions onClick={blur} node={data} isVisible={isHovered} />
            <NodeToolbar onClick={blur} id={id} isVisible={isHovered} isGroup={true} data={data} />

            {(pinned || groupHealthState) && (
                <svg viewBox='0 0 100 100' className={cn('absolute w-full z-10 inset-0')}>
                    <circle
                        cx='50%'
                        cy='50%'
                        r='42'
                        vectorEffect='non-scaling-stroke'
                        strokeWidth={4}
                        className={cn(
                            'stroke-current fill-transparent',
                            groupHealthState ? healthStateTextColors[groupHealthState] : 'text-textPrimary'
                        )}
                        {...(!pinned && { strokeDasharray: '2 2' })}
                    />
                </svg>
            )}

            {circleOffsets.map((offset) => (
                <div
                    key={offset}
                    className={cn(
                        'absolute w-[18px] h-[18px] top-1/2 border-2 -translate-y-1/2 bg-current rounded-full flex items-center justify-center',
                        mapBorderColors[background],
                        groupHealthState ? healthStateTextColors[groupHealthState] : 'text-textPrimary'
                    )}
                    style={{
                        left: `${offset}px`,
                        ...(!groupHealthState ? { backgroundColor } : {})
                    }}
                />
            ))}

            <Handle
                type='target'
                position={Position.Top}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <Handle
                type='source'
                position={Position.Bottom}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <span className='sr-only'>{label}</span>

            <div className='text-primary flex flex-col items-center font-bold leading-tight text-[8px] max-w-[100px] absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 text-center min-w-0'>
                <TruncatedText title={label} className={cn('rounded-sm px-1 py-0.5', mapBackgroundColors[background])}>
                    {label}
                </TruncatedText>

                {data.groupedData?.type && (
                    <TruncatedText
                        title={data.groupedData?.type}
                        className={cn(
                            'max-w-[120px] text-textSecondary rounded-sm px-1 py-0.5 -mt-0.5',
                            mapBackgroundColors[background]
                        )}
                    >
                        {data.groupedData?.type}
                    </TruncatedText>
                )}
            </div>
        </div>
    );
});
