import { Button } from '@/components/Button';
import trackEvent from 'lib/analytics';
import { useState } from 'react';
import { ProjectedTenantResponse } from 'services/TenantService';
import { useContactSalesModal } from './ContactSalesModal';
import { PlanUpgradeModal } from './PlanUpgradeModal';

type UserPlanProps = {
    tenant?: ProjectedTenantResponse;
    tierCanBeUpgraded: boolean;
};

const commonButtonProps: Parameters<typeof Button>[0] & { 'data-testid': string } = {
    variant: 'upgrade',
    type: 'button',
    'data-testid': 'upgradePlanButton'
};

export const UpgradeButton = ({ tenant, tierCanBeUpgraded }: UserPlanProps) => {
    const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const { openContactSalesModal, contactSalesModal } = useContactSalesModal(
        'Plan and Usage',
        tenant?.id,
        tenant?.displayName ?? tenant?.name ?? ''
    );

    const handleButtonClick = () => {
        if (tenant == null) {
            return undefined;
        }

        setUpgradeModalOpen(true);
        trackEvent('Upgrade Modal Opened', { tenantId: tenant.id });
    };

    const isUpgradeInProgress = tenant?.licenceData?.upgradePending ?? false;

    return (
        <>
            {tenant && isUpgradeModalOpen && (
                <PlanUpgradeModal
                    onClose={() => setUpgradeModalOpen(false)}
                    onPurchase={() => setUpgradeModalOpen(false)}
                    tenant={tenant}
                />
            )}
            {tierCanBeUpgraded ? (
                <Button {...commonButtonProps} onClick={handleButtonClick} disabled={isUpgradeInProgress}>
                    {isUpgradeInProgress ? 'Upgrading...' : 'Compare plans'}
                </Button>
            ) : (
                <>
                    <Button {...commonButtonProps} onClick={openContactSalesModal}>
                        Contact sales
                    </Button>
                    {contactSalesModal}
                </>
            )}
        </>
    );
};
