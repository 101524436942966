import { cn } from '@/lib/cn';
import Autocomplete from 'components/forms/autocomplete/Autocomplete';

const EditorSelect = ({ className = '', ...props }) => (
    <Autocomplete
        className={cn(
            className,
            'ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'
        )}
        {...props}
        isMulti={false}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        selectOptionsAs='valueString'
        menuShouldBlockScroll
        menuShouldScrollIntoView
    />
);

export default EditorSelect;
