import { useCallback } from 'react';
import { useGraph } from '../context/NetworkMapStoreContext';
import { fetchMapData } from '../data/fetchMapData';
import { GraphologyNodeAttributes } from '../data/types';
import { insertQueryResults } from '../data/utils/insertQueryResults';
import { rewriteLargeNodeGroups } from '../data/utils/rewriteLargeNodeGroups';

/**
 * Map hook to expand or collapse a node and its neighbors
 * When expanding/collapsing a node, we also want to hide/show its neighbors
 * Expanding a node will also pin it
 */
export function useExpandNode() {
    const graph = useGraph();

    return useCallback(
        (node: GraphologyNodeAttributes, expand: boolean) => {
            // If we're collapsing, we need to ensure we have at least 1 pinned node
            // otherwise we could end up with an empty graph
            let pinNode = expand;
            if (!expand) {
                if (!graph.someNode((nodeId, { pinned, id }) => pinned && nodeId !== id)) {
                    pinNode = true;
                }
            }

            graph.updateNodeAttributes(node.id, (attr) => ({
                ...attr,
                expanded: expand,
                pinned: pinNode,
                hidden: false
            }));

            graph.forEachNeighbor(node.id, (neighborId) => {
                graph.updateNodeAttributes(neighborId, (attr) => {
                    return {
                        ...attr,
                        hidden: !(attr.expanded || attr.pinned || expand),
                        x: attr.hidden ? node.x : attr.x,
                        y: attr.hidden ? node.y : attr.y,
                        fixed: attr.hidden ? false : attr.fixed
                    };
                });
            });

            // Fetch & insert next set of data
            if (expand) {
                fetchMapData(
                    node.type === 'groupNode' ? node.groupedData!.nodes.map(({ id }) => id) : [node.id],
                    graph.filterNodes((_, attr) => attr.expanded)
                ).then((graphResults) => {
                    insertQueryResults(graph, graphResults);
                });
            }

            rewriteLargeNodeGroups(graph);
        },
        [graph]
    );
}
