import { cn } from '@/lib/cn';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { TruncatedText } from 'components/TruncatedText';
import { healthStateTextColors } from 'constants/state';
import { scaleOrdinal } from 'd3-scale';
import { mapBackgroundColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { DownstreamOptions } from '../nodeToolbar/downstream';
import { NodeToolbar } from '../nodeToolbar/NodeToolbar';
import { UpstreamOptions } from '../nodeToolbar/upstream';

export const OBJECT_NODE = 'objectNode';
export const OBJECT_NODE_SIZE = 28;

const palette = scaleOrdinal([
    '#1C98C6',
    '#3ABACD',
    '#A64E88',
    '#7423B2',
    '#622CE8',
    '#842497',
    '#844DCE',
    '#7A65FA',
    '#994ABB',
    '#DB96B7'
]);

export const ObjectNode = memo(({ id, data }: NodeProps<GraphologyNodeAttributes>) => {
    const background = useNetworkBackground();
    const { isHovered, blur, ...hoverProps } = useHover();

    const backgroundColor = palette(data.data?.type?.[0] ?? '');

    if (!data) {
        throw new Error('No data provided for ObjectNode');
    }

    const typeLabel = data.data?.type?.[0] || data.data?.sourceType?.[0];

    const label = data.data?.name;

    const healthState = undefined;

    return (
        <div
            className={cn('relative rounded-full', data.pinned && 'p-2')}
            style={{ width: OBJECT_NODE_SIZE, height: OBJECT_NODE_SIZE }}
            {...hoverProps}
        >
            <UpstreamOptions onClick={blur} node={data} isVisible={isHovered} />
            <DownstreamOptions onClick={blur} node={data} isVisible={isHovered} />

            <NodeToolbar onClick={blur} id={id} isVisible={isHovered} data={data} url={`/drilldown/node/${id}`} />

            {(data.pinned || healthState) && (
                <svg viewBox='0 0 100 100' className={cn('absolute w-full z-10 inset-0')}>
                    <circle
                        cx='50%'
                        cy='50%'
                        r='44'
                        vectorEffect='non-scaling-stroke'
                        strokeWidth={3}
                        className={cn(
                            'stroke-current fill-transparent',
                            data.pinned && 'text-textPrimary',
                            healthState && healthStateTextColors[healthState]
                        )}
                        {...(!data.pinned && { strokeDasharray: '1 1' })}
                    />
                </svg>
            )}

            <div style={{ backgroundColor }} className={cn('absolute inset-2 rounded-full')} />

            <Handle
                type='target'
                position={Position.Top}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <Handle
                type='source'
                position={Position.Bottom}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <span className='sr-only'>{label}</span>

            {label && (
                <div className='text-primary flex flex-col items-center font-bold leading-tight text-[8px] max-w-[100px] absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 text-center min-w-0'>
                    <TruncatedText
                        title={label}
                        className={cn('rounded-sm px-1 py-0.5 max-w-full', mapBackgroundColors[background])}
                    >
                        {label}
                    </TruncatedText>
                    {typeLabel && (
                        <TruncatedText
                            title={typeLabel}
                            className={cn(
                                'max-w-[120px] text-textSecondary rounded-sm px-1 py-0.5 -mt-0.5',
                                mapBackgroundColors[background]
                            )}
                        >
                            {typeLabel}
                        </TruncatedText>
                    )}
                </div>
            )}
        </div>
    );
});
