import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp, faEllipsisV, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { InlineTextEdit } from 'components/inlineTextEdit/InlineTextEdit';
import { InlineTextEditOverflowWrapper } from 'components/inlineTextEdit/InlineTextEditOverflowWrapper';
import Tooltip from 'components/tooltip/Tooltip';
import { forwardRef, useState } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';


const DatasetValidationMessage = () => {
    return (
        <Tooltip title='Data set name update failed. names should be **unique**, **alphanumeric**, and **cannot start with a number**.'>
            <FontAwesomeIcon icon={faExclamationCircle} className='text-statusErrorPrimary' />
        </Tooltip>
    );
};


interface DatasetTriggerProps {
    isActive: boolean;
    datasetName: string;
    index: number;
    isDocked?: boolean;
}
const alphaNumericRegex = /^[a-zA-Z][a-zA-Z0-9]*$/u;

export const DatasetTrigger = forwardRef<HTMLButtonElement, DatasetTriggerProps>((
    { isActive, datasetName, index, isDocked, ...props }, 
    ref
) => {
    const menuContainer = useDOMElement('dialogContent');
    
    const { 
        datasets,  
        renameDataset,
        cloneDataset, 
        removeDataset,
        setActiveDataset,
        setActiveDataTab
    } = useDatasetContext();

    const [isDatasetNameValid, setIsDatasetNameValid] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const existingDatasetNames = datasets.map(({ tableName }) => tableName);

    return (
        <button 
            className={cn(
                'w-full flex justify-between border-b border-tileEditorDatasetActive text-textSecondary space-x-4 items-center py-4 px-[23px] bg-tileEditorDataset group hover:text-textPrimary focus:text-textPrimary', 
                isActive && 'bg-tileEditorDatasetActive text-textPrimary border-none'
            )}
            ref={ref}
            {...props}
        >
            <InlineTextEdit
                allowEditing={isActive}
                changeTrigger='blur'
                value={datasetName}
                onChange={(newDatasetName) => renameDataset(index, newDatasetName)}
                placeholder='Rename dataset'
                containerClassName='font-semibold truncate group/dashboardName'
                displayComponent={
                    <InlineTextEditOverflowWrapper
                        className='group-hover/dashboardName:pr-2'
                        value={datasetName}
                        placeholder='Dataset name'
                    />
                }
                validate={(value) => {
                    const isAlphanumeric = alphaNumericRegex.test(value);
                    const duplicateName = existingDatasetNames.filter((n) => n !== datasetName).includes(value);
                    const valid = value !== null && value !== '' && isAlphanumeric && !duplicateName;

                    setIsDatasetNameValid(valid);
                    return valid;
                }}
            />

            {!isDatasetNameValid && <DatasetValidationMessage />}

            <div className='inline-flex space-x-2'>
                <DropdownMenu 
                    modal={false}
                    onOpenChange={(isOpen) => setIsMenuOpen(isOpen)}
                >
                    <DropdownMenu.Trigger>
                        <button
                            onClick={(e) => {
                                // Don't switch tab when clicking the ... button
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <FontAwesomeIcon
                                className={cn('transition hidden group-hover:block duration-200 ease-in-out text-textSecondary hover:text-textPrimary', isMenuOpen && 'block')}
                                icon={faEllipsisV}
                                fixedWidth
                            />
                        </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Menu
                        menuId='tileMenu'
                        className='divide-y divide-outlinePrimary bg-componentBackgroundPrimary'
                        container={menuContainer}
                        align='start'
                        side='bottom'
                    >
                        <DropdownMenu.Group>
                            <DropdownMenu.Item 
                                onClick={(e) => e.stopPropagation()}
                                onSelect={() => cloneDataset(index)}
                                key='clone'
                            >
                                Clone
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                                onClick={(e) => e.stopPropagation()}
                                onSelect={() => removeDataset(index, isDocked)}
                                key='delete'
                                className={cn(
                                    'text-statusErrorPrimary',
                                    existingDatasetNames.length === 1 && 'text-textDisabled cursor-default'
                                )}
                                disabled={existingDatasetNames.length === 1}
                            >
                                Delete
                            </DropdownMenu.Item>
                        </DropdownMenu.Group>
                    </DropdownMenu.Menu>
                </DropdownMenu>

                <Tooltip 
                    title='Collapsing unavailable while docked' 
                    disabled={!(isActive && isDocked)}
                >
                    <button 
                        className='ml-auto disabled:text-textDisabled'
                        disabled={isActive && isDocked}
                        {...isActive && !isDocked && {
                            onClick: (e) => {
                                e.stopPropagation();
                                setActiveDataset(-1);
                                setActiveDataTab(Number(isDocked) + datasets.length);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} />
                    </button>
                </Tooltip>
            </div>
        </button>
    );
});