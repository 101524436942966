import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { buildQuery } from '@squaredup/graph';
import { difference, omitBy } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import { getObjectFilters } from '../../utilities/getObjectFilters';
import { emptyFilters, type ObjectFilterState } from './ObjectFilterState';
import type { ScopeAction } from './ScopeActions';
import { emptyScopeState, type ScopeState } from './ScopeState';

/**
 * Dependencies used to produce side-effects.
 */
export type ScopeStateReducerExternals = {
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
};

export type ScopeStateInternal = ScopeState & {
    sideEffects?: (externals: ScopeStateReducerExternals) => void;
};

export type ScopeStateReducer = (state: ScopeStateInternal, action: ScopeAction) => ScopeStateInternal;

const getFilters = (state: ScopeState): ObjectFilterState => {
    if (state.canFilter) {
        return state.filters;
    }

    if (state.type === 'predefinedScope') {
        return {
            ...emptyFilters,
            scopeId: state.scopeId,
            hasScopeFilter: true,
            hasFilter: true,
            hasNonScopeFilter: state.searchString.length > 0,
            searchString: state.searchString
        };
    }

    return emptyFilters;
};

export const mergeFilterState = (
    stateA: ObjectFilterState,
    stateB: Partial<Omit<ObjectFilterState, 'properties'> & { properties: Record<string, string[] | undefined> }>
): ObjectFilterState => {
    const newFilters = {
        ...stateA,
        ...stateB,
        properties: {
            ...stateA.properties,
            ...stateB.properties
        }
    };

    const hasNonScopeFilter =
        newFilters.searchString !== '' ||
        newFilters.plugins.length > 0 ||
        newFilters.types.length > 0 ||
        Object.values(newFilters.properties).some((v) => v != null && v.length > 0);

    const scopeFilter = newFilters.scopeId
        ? { scopeId: newFilters.scopeId, hasScopeFilter: true as const }
        : { scopeId: undefined, hasScopeFilter: false as const };

    return {
        ...newFilters,
        ...{ properties: omitBy(newFilters.properties, (v) => v == null) as Record<string, string[]> },
        ...scopeFilter,
        hasNonScopeFilter,
        hasFilter: hasNonScopeFilter || scopeFilter.hasScopeFilter
    };
};

export const scopeStateReducer: ScopeStateReducer = (state, action): ScopeStateInternal => {
    switch (action.type) {
        case 'setObjectListScope':
        case 'setSelectedObjects': {
            if (action.type !== 'setObjectListScope' && state.type !== 'objectList' && state.type !== 'none') {
                throw new Error("Can't set selected node Ids on a non-objectList scope");
            }

            const selectedNodeIds = [...new Set(action.selectedNodeIds)];

            if (
                state.type === 'objectList' &&
                state.selectedNodeIds.length > 0 &&
                difference(selectedNodeIds, state.selectedNodeIds).length === 0 &&
                difference(state.selectedNodeIds, selectedNodeIds).length === 0
            ) {
                if ('pruneInteractedNodeIds' in action && action.pruneInteractedNodeIds) {
                    return { ...state, interactedNodeIds: selectedNodeIds };
                }
                return state;
            }

            const filters = getFilters(state);

            const interactedNodeIds =
                'pruneInteractedNodeIds' in action && action.pruneInteractedNodeIds
                    ? [...new Set([...selectedNodeIds])]
                    : [...new Set([...state.interactedNodeIds, ...selectedNodeIds])];

            if (selectedNodeIds.length === 0) {
                return {
                    ...emptyScopeState,
                    filters,
                    interactedNodeIds,
                    sideEffects: ({ setConfig }) => {
                        setConfig((c) => ({
                            ...c,
                            variables: undefined,
                            scope: undefined
                        }));
                    }
                };
            }

            const scopeContents = {
                ids: selectedNodeIds,
                ...getObjectFilters(filters.plugins, filters.types, filters.properties, filters.searchString)
            };
            const scopeQuery = buildQuery(scopeContents, '');

            return {
                type: 'objectList',
                isDynamic: false,
                canFilter: true,
                isVariableScope: false,
                filters,
                selectedNodeIds: selectedNodeIds,
                interactedNodeIds,
                sideEffects: ({ setConfig }) => {
                    setConfig((currentConfig) => ({
                        ...currentConfig,
                        variables: undefined,
                        scope: {
                            query: scopeQuery.gremlinQuery,
                            bindings: scopeQuery.bindings,
                            queryDetail: scopeContents
                        }
                    }));
                }
            };
        }

        case 'setPredefinedScope': {
            const variableScope = action.variables.find(({ scopeId }) => scopeId === action.scopeId);
            const isVariableScope = variableScope != null;

            const variableProperties = isVariableScope
                ? {
                      isVariableScope,
                      variableSelectedNodeIds: action.variables.flatMap((v) => v.selectedObjects).map((v) => v.id),
                      variableIsSelectedAll: action.variables.every((v) => v.selectedAll)
                  }
                : { isVariableScope };

            return {
                type: 'predefinedScope',
                canFilter: false,
                isDynamic: true,
                scopeId: action.scopeId,
                workspaceId: action.workspaceId,
                interactedNodeIds: state.interactedNodeIds,
                searchString: action.searchString ?? '',
                ...variableProperties,
                sideEffects: ({ setConfig }) => {
                    setConfig((currentConfig) => ({
                        ...currentConfig,
                        ...(isVariableScope ? { variables: [variableScope.id] } : { variables: undefined }),
                        scope: {
                            scope: action.scopeId,
                            workspace: action.workspaceId
                        }
                    }));
                }
            };
        }

        case 'setDynamicScope': {
            const filters = getFilters(state);

            const scopeContents = getObjectFilters(
                filters.plugins,
                filters.types,
                filters.properties,
                filters.searchString
            );

            const dynamicScope = buildQuery(scopeContents, action.baseQuery, { limit: true });

            return {
                type: 'dynamic',
                canFilter: true,
                isDynamic: true,
                isVariableScope: false,
                filters,
                interactedNodeIds: state.interactedNodeIds,
                sideEffects: ({ setConfig }) => {
                    setConfig((currentConfig) => ({
                        ...currentConfig,
                        variables: undefined,
                        scope: {
                            query: dynamicScope.gremlinQuery,
                            bindings: dynamicScope.bindings,
                            queryDetail: scopeContents
                        }
                    }));
                }
            };
        }

        case 'updateFilters': {
            if (!state.canFilter) {
                if (Object.values(action.filters).some((v) => v != null)) {
                    throw new Error("Can't update filters when using a predefined scope");
                }

                return state;
            }

            const newFilters = mergeFilterState(state.filters, action.filters);

            const scopeContents = getObjectFilters(
                newFilters.plugins,
                newFilters.types,
                newFilters.properties,
                newFilters.searchString
            );

            const dynamicScope = buildQuery(scopeContents, state.isDynamic ? action.baseQuery : '', {
                limit: true
            });

            return {
                ...state,
                filters: newFilters,
                sideEffects: ({ setConfig }) => {
                    setConfig((currentConfig) => ({
                        ...currentConfig,
                        variables: undefined,
                        scope:
                            state.isDynamic && (newFilters.hasNonScopeFilter || action.baseQuery)
                                ? {
                                      query: dynamicScope.gremlinQuery,
                                      bindings: dynamicScope.bindings,
                                      queryDetail: scopeContents
                                  }
                                : currentConfig.scope
                    }));
                }
            };
        }

        case 'clearFilters': {
            if (state.isDynamic) {
                if (state.type === 'predefinedScope') {
                    return { ...state, searchString: '' };
                }

                return {
                    type: 'dynamic',
                    canFilter: true,
                    isDynamic: true,
                    isVariableScope: false,
                    filters: emptyFilters,
                    interactedNodeIds: state.interactedNodeIds
                };
            }

            // Maintain the selected and interacted node Ids
            const nodeIds = state.type === 'objectList' ? state : { interactedNodeIds: [] };

            return {
                ...state,
                ...nodeIds,
                filters: emptyFilters
            };
        }

        case 'sort': {
            if (!state.canFilter) {
                return state;
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    sort: {
                        property: action.property,
                        descending: action.descending
                    }
                }
            };
        }

        case 'clearSort': {
            if (!state.canFilter) {
                return state;
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    sort: undefined
                }
            };
        }

        case 'clearScope': {
            return {
                ...emptyScopeState,
                sideEffects: ({ setConfig }) => {
                    setConfig((c) => ({
                        ...c,
                        variables: undefined,
                        scope: undefined
                    }));
                }
            };
        }

        default:
            return state;
    }
};
