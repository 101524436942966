import { isPresetOf } from '@squaredup/data-streams';
import { isMatchable } from 'services/DataStreamDefinitionService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { MATCHES_ADVANCED } from 'ui/editor/dataStream/TileEditor/constants';
import { useSourceTypesToTypesMap } from 'ui/editor/dataStream/TileEditor/hooks/useSourceTypesToTypesMap';
import { getMatchTypes } from 'ui/editor/dataStream/utilities/getMatchTypes';
import { useDataStreamDefinitionsForWorkspace } from './useDataStreamDefinitions';

export const useAvailableDataStreamDefinitionsForWorkspace = (
    sources?: PluginSourceConfig[], 
    workspace?: string,
    selectedDataStreamId?: string
) => {
    const {
        data: sourceTypesToTypes, 
        isLoading: isLoadingTypesMap 
    } = useSourceTypesToTypesMap(sources, undefined, workspace);

    const { data, isLoading } = useDataStreamDefinitionsForWorkspace({
        workspace: workspace ?? undefined,
        queryOptions: {
            enabled: Boolean(sources && sourceTypesToTypes && workspace),
            select: (allDataStreams) => allDataStreams?.filter((stream) => {
                return (
                    stream.definition?.provides !== 'templateData' &&
                    sources?.some((s) => s.plugin?.pluginId === stream.pluginId) && // If the plugin source config exists
                    ((isMatchable(stream) &&
                        getMatchTypes(stream.definition?.matches, sourceTypesToTypes)?.length) ||
                        stream.id === selectedDataStreamId)
                ); // If it's matchable and has objects that are in the graph OR current ID is a non matchable stream
            })
            .map((dataStream) => {
                const matches = dataStream.definition?.matches;
                const matchesTypes = matches ? getMatchTypes(matches, sourceTypesToTypes) : MATCHES_ADVANCED;
    
                return {
                    ...dataStream,
                    presetParentDataStream: dataStream.definition.presetOf
                        ? allDataStreams.find((parent) => isPresetOf(parent, dataStream))
                        : undefined,
                    definition: {
                        ...dataStream.definition,
                        matchesTypes: matchesTypes
                    }
                };
            })
            .map((dataStream) => {
                return {
                    ...dataStream,
                    displayNameFull: dataStream.presetParentDataStream?.displayName
                        ? `${dataStream.presetParentDataStream.displayName} / ${dataStream.displayName}`
                        : dataStream.displayName,
                    pluginName: sources?.find((s) => s.plugin?.pluginId === dataStream.pluginId)?.plugin?.name
                };
            })
        }
    });

    return {
        data,
        isLoading: isLoading || isLoadingTypesMap
    };
};
