import { cn } from '@/lib/cn';
import { ReactNode } from 'react';

function NoDataPlaceholder({ message = 'No data', icon, className }: { message?: string; icon?: ReactNode, className?: string; }) {
    return (
        <div className={cn('flex items-center justify-center w-full h-full text-center text-textIncomplete', className)}>
            {icon}
            <p className='text-2xl'>{message}</p>
        </div>
    );
}

export default NoDataPlaceholder;
