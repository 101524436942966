import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Accordion from '@radix-ui/react-accordion';
import Tooltip from 'components/tooltip/Tooltip';
import { useDatasetContext } from '../contexts/DatasetContext';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { AnalyticsStepsSelector } from './analytics/AnalyticsStepsSelector';
import { useTileEditorStepsContext } from './contexts/TileEditorStepsContext';
import { DatasetTrigger } from './analytics/DatasetTrigger';

const SQL_STEP = 'SQL';
const ADD_DATASET = 'addDataset';


const DataStreamDatasetStepController: React.FC = ({ children }) => {
    const { 
        datasets, 
        activeDataset, 
        isEditingSQL,
        addDataset,  
        setActiveDataset,
        setActiveDataTab,
        setIsEditingSQL
    } = useDatasetContext();

    const { isDocked } = useTileEditorStepsContext();

    return (
        <div 
            className='flex flex-col'
            data-testid='datasetSteps'
        >
            <Accordion.Root 
                value={isEditingSQL ? SQL_STEP : String(activeDataset)}
                type='single'
                onValueChange={(activeAccordion) => {
                    switch (activeAccordion) {
                        case SQL_STEP:
                            setIsEditingSQL?.(true);
                            setActiveDataset(-1);
                            setActiveDataTab(datasets.length + Number(isDocked));
                            return;
                        case ADD_DATASET:
                            setIsEditingSQL?.(false);
                            return addDataset(1 - Number(isDocked));
                        default:
                            setIsEditingSQL?.(false);
                            setActiveDataset(parseInt(activeAccordion));
                            return setActiveDataTab(parseInt(activeAccordion) + Number(isDocked));
                    }
                }}
            >
                {datasets.map((dataset, index) => {
                    const isActive = index === activeDataset;

                    return (
                        <Accordion.Item     
                            key={`dataset-${index}`}
                            value={String(index)}
                        >
                            <Accordion.Header>
                                <Accordion.Trigger asChild>
                                    <DatasetTrigger
                                        index={index}
                                        datasetName={dataset.tableName}
                                        isActive={isActive}
                                        isDocked={isDocked}
                                    />
                                </Accordion.Trigger>
                            </Accordion.Header>
                            
                            <Accordion.Content className='border-l-4 border-tileEditorDatasetActive'>
                                {children}
                            </Accordion.Content>
                        </Accordion.Item>
                    );
                })}

                <Accordion.Item     
                    key={ADD_DATASET}
                    value={ADD_DATASET}
                >
                    <Accordion.Header>
                        <Accordion.Trigger 
                            asChild
                            data-testid='addDatasetTrigger'
                        >
                            <button 
                                className='w-full flex justify-between items-center border-b border-tileEditorDatasetActive font-semibold py-4 px-[23px] bg-tileEditorDataset text-textSecondary hover:text-textPrimary focus:text-textPrimary'
                                data-testid='addDataset'
                            >
                                <span className='flex-1 font-semibold text-left'>Add dataset</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </Accordion.Trigger>
                    </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item     
                    key={SQL_STEP}
                    value={SQL_STEP}
                >
                    <Accordion.Header>
                        <Accordion.Trigger 
                            asChild
                            data-testid='SQLEditorTrigger'
                        >
                            <button className={cn(
                                'w-full flex justify-between items-center font-semibold border-b border-tileEditorDatasetActive py-4 px-[23px] bg-tileEditorDataset text-textSecondary hover:text-textPrimary focus:text-textPrimary', isEditingSQL && 'bg-filterActive text-textPrimary',
                                isEditingSQL && 'bg-tileEditorDatasetActive border-none'
                            )}>
                                <span>SQL</span>

                                <Tooltip 
                                    title='Collapsing unavailable while docked' 
                                    disabled={!(isEditingSQL && isDocked)}
                                >
                                    <button 
                                        className='ml-auto disabled:text-textDisabled'
                                        disabled={isEditingSQL && isDocked}
                                        {...isEditingSQL && !isDocked && {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                setIsEditingSQL(false);
                                                setActiveDataTab(Number(isDocked) + datasets.length);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={isEditingSQL ? faChevronUp : faChevronDown} />
                                    </button>
                                </Tooltip>
                            </button>
                        </Accordion.Trigger>
                    </Accordion.Header>

                    <Accordion.Content className='border-l-4 border-tileEditorDatasetActive'>
                        <AnalyticsStepsSelector />
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion.Root>
        </div>
    );
};

export const DataStreamTileEditorDatasetStepWrapper: React.FC = ({ children }) => {
    const { isDatasetMode } = useTileEditorContext();

    if (!isDatasetMode) {
        return <>{children}</>;
    }

    return (
        <DataStreamDatasetStepController>
            {children}
        </DataStreamDatasetStepController>
    );
};