import { monitorFrequencyDefaultMinutes } from '@squaredup/constants';
import { getAllowedMonitorFrequency } from '@squaredup/monitoring';
import { getFeatureLimit } from '@squaredup/tenants';
import { EditorSteps } from '../constants';
import { sanitiseFrequency } from '../monitoring/sanitiseFrequency';
import type { TileEditorAction } from './TileEditorActions';
import type {
    TileEditorReducerSideEffects,
    TileEditorStateInternal,
    TileEditorStateReducerExternals
} from './TileEditorState';
import type { DataStreamAction } from './dataStream/DataStreamActions';
import { dataStreamStateReducer } from './dataStream/DataStreamStateReducer';
import type { ScopeAction } from './scope/ScopeActions';
import { emptyScopeState } from './scope/ScopeState';
import { mergeFilterState, scopeStateReducer } from './scope/ScopeStateReducer';
import { timeframeStateReducer } from './timeframe/TimeframeStateReducer';

export type TileEditorStateWithDispatch = TileEditorStateInternal & {
    dispatch: (action: TileEditorAction) => void;
};

export type TileEditorStateReducer = (
    state: TileEditorStateInternal,
    action: TileEditorAction
) => TileEditorStateInternal;

const isDataStreamAction = (action: ScopeAction | DataStreamAction): action is DataStreamAction => {
    return action.type.startsWith('dataStream.');
};

const getFilterSyncAction = (action: DataStreamAction, state: TileEditorStateInternal): ScopeAction | undefined => {
    if (action.type !== 'dataStream.updateFilters') {
        return undefined;
    }

    const actionScopeId = action.filters.scopeId;

    if (state.scope.type === 'predefinedScope') {
        if (!('scopeId' in action.filters)) {
            /**
             * Can't update a non-scope filter when the scope is a predefined scope.
             */
            return undefined;
        }

        return actionScopeId
            ? /**
               * We have a new scope to set as the predefined scope.
               */
              {
                  type: 'setPredefinedScope',
                  scopeId: actionScopeId,
                  workspaceId: state.dashboard.workspaceId,
                  variables: state.dashboard.variables
              }
            : /**
               * We're clearing the data stream scope filter, so clear the predefined scope.
               */
              { type: 'clearScope' };
    }

    /**
     * The scope isn't predefined, so we can set the filters based on the data stream filters.
     */
    return {
        type: 'updateFilters',
        filters: mergeFilterState(state.scope.filters, {
            types: action.filters.objectType == null ? [] : [action.filters.objectType],
            scopeId: actionScopeId
        }),
        baseQuery: ''
    };
};

const combineSideEffects = (
    ...sideEffects: (TileEditorReducerSideEffects | undefined)[]
): TileEditorReducerSideEffects => {
    return (externals: TileEditorStateReducerExternals) => {
        sideEffects.forEach((sideEffect) => sideEffect?.(externals));
    };
};

/**
 * Create the reducer used in the tile editor store. This allows for testing
 * without having to render the store using `renderHook`.
 */
export const tileEditorStateReducer: TileEditorStateReducer = (state, action): TileEditorStateInternal => {
    switch (action.type) {
        case 'resetTileEditorState': {
            return action.newState;
        }

        case 'applyConfigUpdate': {
            return { ...state, hasPendingConfigUpdate: false };
        }

        case 'setActiveStep': {
            if (state.currentStep === action.step) {
                return state;
            }

            return {
                ...state,
                currentStep: action.step,
                visitedSteps: state.visitedSteps.includes(action.step)
                    ? state.visitedSteps
                    : [...state.visitedSteps, action.step]
            };
        }

        case 'tileEditor.selectPreset': {
            if (!action.dataStream.isPreset) {
                throw new Error('Cannot select a non-preset data stream as a preset. Use selectDataStream instead.');
            }

            /**
             * Selecting a preset is the same as selecting a data stream, except we
             * don't clear the scope, timeframe, or visualisation.
             */
            const isSelectingDifferentDataStream = state.dataStream.selectedDataStream?.id !== action.dataStream.id;

            if (!isSelectingDifferentDataStream) {
                return state;
            }

            const { sideEffects: dataStreamSideEffects, ...newDataStreamState } = dataStreamStateReducer(
                state.dataStream,
                { type: 'dataStream.selectDataStream', dataStream: action.dataStream }
            );

            return {
                ...state,
                dataStream: newDataStreamState,
                sideEffects: dataStreamSideEffects
            };
        }

        case 'dataStream.selectDataStream': {
            const isSelectingDifferentDataStream = state.dataStream.selectedDataStream?.id !== action.dataStream.id;

            if (!isSelectingDifferentDataStream) {
                return state;
            }

            const { sideEffects: dataStreamSideEffects, ...newDataStreamState } = dataStreamStateReducer(
                state.dataStream,
                action
            );

            /**
             * Reset the scope when the data stream changes.
             */
            const { sideEffects: scopeSideEffects, ...tempScopeState } =
                state.dataStream.filters.scopeId == null
                    ? scopeStateReducer(state.scope, { type: 'clearScope' })
                    : { sideEffects: undefined, ...state.scope };

            const { sideEffects: scopeSideEffects2, ...newScopeState } =
                /*
                 * If there is a scope or type filter we want to carry it through to the scope step.
                 * This happens here because the scope reducer itself doesn't know about the
                 * selected data stream, the orchestration of the two is a tile editor concern.
                 */
                state.dataStream.filters.scopeId == null
                    ? scopeStateReducer(tempScopeState, {
                          type: 'updateFilters',
                          filters: {
                              ...emptyScopeState.filters,
                              scopeId: undefined,
                              types:
                                  state.dataStream.filters.objectType == null
                                      ? []
                                      : [state.dataStream.filters.objectType]
                          },
                          baseQuery: ''
                      })
                    : scopeStateReducer(tempScopeState, {
                          type: 'setPredefinedScope',
                          scopeId: state.dataStream.filters.scopeId,
                          workspaceId: state.dashboard.workspaceId,
                          variables: state.dashboard.variables
                      });

            const isNoneTimeframe =
                newDataStreamState.selectedDataStream?.definition.defaultTimeframe === 'none' ||
                newDataStreamState.selectedDataStream?.definition.timeframes === false;

            // Reset the timeframe when the data stream changes
            const { sideEffects: timeframeSideEffects, ...newTimeframeState } = timeframeStateReducer(state.timeframe, {
                type: 'timeframe.setTimeframe',
                ...(isNoneTimeframe ? { timeframe: 'none', tier: undefined } : { isDashboardTimeframe: true })
            });

            /*
             * We reset the visualisation when the data stream changes.
             * The side-effects are separate to keep the separation of concerns,
             * and because we'll likely end up with a separate visualisation reducer in the future.
             */

            // Reset the visualisation when the data stream changes
            const visualisationSideEffects: TileEditorReducerSideEffects = ({ setConfig }) => {
                setConfig((c) => ({ ...c, visualisation: undefined }));
            };

            return {
                ...state,
                currentStep: action.forceStep ?? state.currentStep,
                visitedSteps: [EditorSteps.dataStream],
                dataStream: newDataStreamState,
                scope: newScopeState,
                timeframe: newTimeframeState,
                sideEffects: combineSideEffects(
                    dataStreamSideEffects,
                    scopeSideEffects,
                    scopeSideEffects2,
                    timeframeSideEffects,
                    visualisationSideEffects
                )
            };
        }

        case 'timeframe.setTimeframe': {
            const { sideEffects, ...timeframe } = timeframeStateReducer(state.timeframe, action);

            const newState = { ...state, timeframe, sideEffects };

            if (action.isDashboardTimeframe || !state.monitor.isMonitoringEnabled || !state.monitor.frequency) {
                return newState;
            }

            /**
             * If the user selects a new non-dashboard timeframe we need to
             * update the monitoring interval to the default for the new timeframe.
             */
            const suggestedDefaultFrequency = action.timeframe
                ? getAllowedMonitorFrequency(action.timeframe).defaultMinutes
                : undefined;

            const monitoringIntervalLimit = action.tier
                ? getFeatureLimit(action.tier, 'monitorFrequency', monitorFrequencyDefaultMinutes)
                : { value: monitorFrequencyDefaultMinutes };
            const monitoringIntervalLimitMins =
                'isUnlimited' in monitoringIntervalLimit && monitoringIntervalLimit.isUnlimited
                    ? 0
                    : 'value' in monitoringIntervalLimit
                    ? monitoringIntervalLimit.value
                    : monitorFrequencyDefaultMinutes;

            const newMonitoringInterval = sanitiseFrequency(
                action.timeframe,
                monitoringIntervalLimitMins,
                suggestedDefaultFrequency
            );

            return {
                ...newState,
                monitor: { ...state.monitor, frequency: newMonitoringInterval },
                sideEffects: combineSideEffects(newState.sideEffects, ({ setConfig }) => {
                    setConfig((c) => ({ ...c, monitor: { ...c.monitor, frequency: newMonitoringInterval } }));
                })
            };
        }

        default: {
            if (isDataStreamAction(action)) {
                /**
                 * Carry over the type filter to the scope step if there is no existing
                 * filter set (or the existing filter is the same as the old data stream filter),
                 * and if we're not going to affect a dynamic scope.
                 */
                const scopeAction = getFilterSyncAction(action, state);

                const { sideEffects: scopeSideEffects, ...scope } =
                    scopeAction == null || state.visitedSteps.includes(EditorSteps.objects)
                        ? { sideEffects: undefined, ...state.scope }
                        : scopeStateReducer(state.scope, scopeAction);

                const { sideEffects, ...dataStream } = dataStreamStateReducer(state.dataStream, action);

                return {
                    ...state,
                    dataStream,
                    scope: scope ?? state.scope,
                    sideEffects: combineSideEffects(sideEffects, scopeSideEffects)
                };
            }

            const { sideEffects, ...scope } = scopeStateReducer(state.scope, action);

            return {
                ...state,
                scope,
                sideEffects
            };
        }
    }
};
