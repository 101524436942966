import { cn } from '@/lib/cn';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import Tooltip from 'components/tooltip/Tooltip';
import { memo } from 'react';
import { useLocation } from 'react-router';
import { Position, NodeToolbar as ReactFlowToolbar } from 'reactflow';
import { NavLink } from 'ui/nav/components/NavLink';
import { ExpandButton } from './ExpandButton';
import { GroupNodeObjectSelector } from './GroupNodeObjectSelector';
import { HideButton } from './HideButton';

interface NodeToolbarProps {
    id: string;
    isVisible: boolean;
    isGroup?: boolean;
    data: GraphologyNodeAttributes;
    url?: string;
    onClick?: () => void;
}

const toolbarButtonClassName =
    'bg-secondaryButtonBackground hover:bg-secondaryButtonBackgroundHover focus-visible:bg-secondaryButtonBackgroundHover hover:text-tertiaryButton focus-visible:text-tertiaryButton text-secondaryButton hover:text-secondaryButton';

export const NodeToolbar: React.FC<NodeToolbarProps> = memo(({ id, isVisible, isGroup, data, url, onClick }) => {
    const { pathname } = useLocation();

    return (
        <ReactFlowToolbar
            position={Position.Top}
            className='flex z-10 text-sm border divide-x rounded divide-dividerPrimary bg-tileBackground border-dividerPrimary'
            offset={5}
            isVisible={isVisible}
            style={{ zIndex: 100 }}
        >
            <ExpandButton
                onClick={onClick}
                node={data}
                className={cn('p-xxxs rounded-l-[3px]', toolbarButtonClassName)}
            />

            <HideButton onClick={onClick} node={data} className={cn('p-xxxs', toolbarButtonClassName)} />

            {isGroup && (
                <GroupNodeObjectSelector
                    groupId={id}
                    groupNodes={data.groupedData!.nodes}
                    className={cn('p-xxxs', toolbarButtonClassName)}
                />
            )}

            {url && pathname !== url && (
                <Tooltip
                    title='Drilldown'
                    className='rounded-r-[3px] bg-primaryButtonBackground hover:bg-primaryButtonBackgroundHover focus:bg-primaryButtonBackgroundHover'
                >
                    <NavLink
                        to={url}
                        className='inline-flex items-center justify-center h-full py-xxxs px-1.5'
                        onClick={(e) => e.stopPropagation()}
                    >
                        <FontAwesomeIcon className='text-primaryButtonText' icon={faArrowRight} fixedWidth />
                    </NavLink>
                </Tooltip>
            )}
        </ReactFlowToolbar>
    );
});
