import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamScope } from '@squaredup/data-streams';
import { Serialised } from '@squaredup/ids';
import { UIConfig } from '@squaredup/utilities';
import Button from 'components/button/Button';
import type { Config, DataStreamDefinitionEntity, Plugin, ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import stringify from 'fast-json-stable-stringify';
import hash from 'object-hash';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { DataStreamTemplateModal } from '../../legacyEditorDataStreamComponents/DataStreamTemplateModal';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { EDITOR_MODE } from '../hooks/useTileEditorSteps';
import { DataStreamUserConfiguredReadOnly } from './DataStreamUserConfiguredReadOnly';
import { StepProgressButton } from './StepProgressButton';

export interface DataStreamUserConfiguredFormProps {
    dataStream: Serialised<DataStreamDefinitionEntity>;
    sourceDataStream: Serialised<DataStreamDefinitionEntity>;
    formTemplate: Serialised<UIConfig[]> | undefined;
    defaultValues: Record<string, any>;
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[];
    plugin: Serialised<Plugin> | undefined;
    pluginConfig: Serialised<Config<object>> | undefined;
}

export const DataStreamUserConfiguredForm: React.FC<DataStreamUserConfiguredFormProps> = ({
    dataStream,
    sourceDataStream,
    formTemplate,
    defaultValues,
    streamDefinitions,
    plugin,
    pluginConfig
}) => {
    const queryClient = useQueryClient();
    const [editDataStreamModalOpen, setEditDataStreamModalOpen] = useState(false);

    const { setCurrentEditorStep } = useTileEditorStepsContext();
    const { config, setConfig } = useDatasetContext();

    return (
        <>
            <div className='flex flex-col h-full min-h-0 pt-4'>
                <div className='shrink-0'>
                    <div className='flex items-start w-full pb-8 mb-2 space-x-4 border-b border-dividerPrimary'>
                        <div className='flex-1 space-y-2'>
                            <p>To make changes, you must edit the saved data stream</p>
                            <p className='font-bold text-textSecondary'>Warning: this may affect other tiles.</p>
                        </div>
                        <Button
                            variant='secondary'
                            className='space-x-2'
                            onClick={() => setEditDataStreamModalOpen(true)}
                        >
                            <FontAwesomeIcon icon={faPencil} />
                            <span>Edit</span>
                        </Button>
                    </div>
                </div>

                <DataStreamUserConfiguredReadOnly
                    key={hash(stringify(defaultValues))}
                    formTemplate={formTemplate}
                    defaultValues={defaultValues}
                    streamDefinitions={streamDefinitions}
                    plugin={plugin}
                    pluginConfig={pluginConfig}
                />

                <div className='flex items-end pt-4 mt-auto space-x-4 shrink-0 md:justify-end'>
                    <StepProgressButton />
                </div>
            </div>

            {editDataStreamModalOpen && (
                <DataStreamTemplateModal
                    sourceDataStreamId={sourceDataStream.id}
                    scope={config.scope as DataStreamScope}
                    setSelectedTemplate={() => undefined}
                    plugin={plugin!}
                    pluginConfigId={pluginConfig?.id}
                    dataStreamId={dataStream.id}
                    defaultDataSourceConfig={{
                        ...sourceDataStream.definition.dataSourceConfig,
                        ...defaultValues,
                        displayName: dataStream.displayName
                    }}
                    onClose={async (dataStreamId: `datastream-${string}` | undefined, deleted = false) => {
                        // Reset the tile config and move back to the data stream step if deleted
                        if (deleted) {
                            setConfig({ _type: 'tile/data-stream' });
                            setCurrentEditorStep(EditorSteps.dataStream);
                            queryClient.removeQueries(dataStreamDefinitionQueryKeys.byId(dataStreamId!));
                            queryClient.removeQueries([EDITOR_MODE, dataStreamId]);
                        }

                        if (dataStreamId) {
                            queryClient.invalidateQueries(dataStreamDefinitionQueryKeys.all);

                            /*
                             * DON'T REMOVE THE LINE BELOW
                             * THIS REFRESHES THE DATA STREAM DATA WHEN YOU SAVE THE UPDATED DATA STREAM
                             * OTHERWISE THE TILE DATA DOES NOT UPDATE FOR 60 SECONDS
                             */
                            queryClient.invalidateQueries(streamDataKeys.forDataStream(dataStreamId));
                        }

                        setEditDataStreamModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
