import Text from '@/components/Text';
import { Serialised } from '@squaredup/ids';
import { OpenAccessSettings, getSharingEnvironment } from '@squaredup/open-access';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import { CopyNotAvailablePrompt } from 'components/Copy';
import LoadingSpinner from 'components/LoadingSpinner';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { LimitReachedBanner } from 'components/plans/LimitReachedBanner';
import useCopyToClipboard from 'lib/useCopyToClipboard';
import { useActiveSharesCount } from 'queries/hooks/useActiveSharesCount';
import { useTenant } from 'queries/hooks/useTenant';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    DeleteShare,
    ListShares,
    OA_SHARES_DATA,
    OpenAccessShareWithDetails,
    UpdateShare
} from '../../../services/OpenAccessService';
import { UpdateTenantSettings } from '../../../services/TenantService';
import { SettingsTemplate } from '../SettingsTemplate';
import { OpenAccessForm } from './OpenAccessForm';
import OpenAccessTable from './OpenAccessTable';

export interface Share extends Serialised<OpenAccessShareWithDetails> {
    statusText: string;
    sharedWithText: string;
    lastLoginDate: Date | null;
}

function OpenAccess() {
    const queryClient = useQueryClient();
    const { data: tenant, isLoading: isTenantLoading } = useTenant();

    const { mutateAsync: updateOASettingsMutation } = useMutation(
        (value: OpenAccessSettings) => UpdateTenantSettings(value),
        {
            onSuccess: (modified) => {
                queryClient.setQueryData(tenantQueryKeys.tenant, modified);
            },
            onError: () => queryClient.invalidateQueries(tenantQueryKeys.tenant),
            onSettled: () => queryClient.invalidateQueries(OA_SHARES_DATA)
        }
    );

    const { mutateAsync: deleteShare } = useMutation(
        ({ id }: { id: string; dashId: string }) => {
            return DeleteShare(id);
        },
        {
            onSuccess: (data, variables) => {
                // Remove share from cache so other modals don't display incorrect data whilst a refetch occurs
                queryClient.removeQueries({
                    queryKey: dashboardQueryKeys.openAccessForDashboard(variables.dashId),
                    exact: true
                });
                // Invalidate everything else so a refetch can occur
                return queryClient.invalidateQueries(OA_SHARES_DATA);
            }
        }
    );

    const { mutateAsync: setShareState } = useMutation(
        ({ id, enabled }: { id: string; enabled: boolean; dashId: string }) => UpdateShare(id, enabled),
        {
            onSuccess: (data, variables) => {
                // perform an optimistic update of the share state
                const share = queryClient.getQueryData<Share>(
                    dashboardQueryKeys.openAccessForDashboard(variables.dashId)
                );
                if (share) {
                    queryClient.setQueryData(dashboardQueryKeys.openAccessForDashboard(variables.dashId), {
                        ...share,
                        properties: { ...share.properties, enabled: variables.enabled }
                    });
                }
            },
            onSettled: () => queryClient.invalidateQueries(OA_SHARES_DATA)
        }
    );

    const {
        shareLimit,
        tenantSharingSettings: { authenticatedSharesEnabled, publicSharesEnabled }
    } = getSharingEnvironment(tenant);

    const authenticatedDomainsOnly = authenticatedSharesEnabled && !publicSharesEnabled;

    const { data: activeShareCount } = useActiveSharesCount();
    const { data: shares, isLoading: isLoadingShareData } = useQuery(OA_SHARES_DATA, ListShares, {
        select: (oaShares: Serialised<OpenAccessShareWithDetails>[]) => {
            return oaShares
                .map((share) => {
                    const oaTenantEnabled = share.properties.requireAuthentication
                        ? authenticatedSharesEnabled
                        : publicSharesEnabled || authenticatedSharesEnabled;

                    const oaEnabled = oaTenantEnabled && (share.workspaceSharingEnabled ?? true);

                    const statusText = share.properties.enabled
                        ? oaEnabled
                            ? 'Active'
                            : 'Sharing disabled'
                        : 'Disabled';
                    const sharedWithText =
                        authenticatedDomainsOnly || share.properties.requireAuthentication ? 'Organization' : 'Anyone';
                    const lastLoginDate = share.properties.lastLogin ? new Date(share.properties.lastLogin) : null;

                    return {
                        ...share,
                        statusText,
                        sharedWithText,
                        lastLoginDate
                    } as Share;
                })
                .sort((a, b) => a.dashboardName?.localeCompare(b.dashboardName ?? '') ?? -1);
        }
    });

    const { canEditSettings: canEditWorkspace, isLoading: isLoadingPermissions } = useWorkspacePermissions();

    const isLoadingShares = isTenantLoading || isLoadingPermissions || isLoadingShareData || tenant === undefined;

    const [shareBeingDeleted, setShareBeingDeleted] = useState<Share>();
    const [shareBeingCopied, setShareBeingCopied] = useState<string>();

    const urlParams = new URLSearchParams();
    urlParams.set('tid', tenant?.id ?? ''); // For Segment tracking

    const getShareLink = (share: any) => {
        return `${window.location.protocol}//${window.location.host}/openaccess/${
            share.id.startsWith('openacc-') ? share.id.substring('openacc-'.length) : share.id
        }?${urlParams.toString()}`;
    };

    const { copy, supportsClipboard } = useCopyToClipboard({
        onNotSupported: (value: string) => setShareBeingCopied(value)
    });

    const handleOnToggle = (share: Share) =>
        setShareState({ id: share.id, enabled: !share.properties.enabled, dashId: share.targetId });
    const handleOnDelete = (share: Share) => setShareBeingDeleted(share);

    const canToggle = (share: Share) =>
        Boolean(
            canEditWorkspace({ id: share.workspaceId }) &&
                (share.properties.enabled || shareLimit.isUnlimited || (activeShareCount ?? 0) < shareLimit.value)
        );
    const canDelete = (share: Share) => Boolean(canEditWorkspace({ id: share.workspaceId }));

    return (
        <SettingsTemplate
            title='Sharing'
            description={'Configure and manage unlimited sharing of view-only dashboards.'}
            learnMoreLink='https://squaredup.com/cloud/openaccess'
            flex
        >
            {isTenantLoading ? (
                <div className='px-4'>
                    <LoadingSpinner />
                </div>
            ) : (
                <>{tenant && <OpenAccessForm tenant={tenant} updateSharingSettings={updateOASettingsMutation} />}</>
            )}
            <div className='flex flex-col flex-1 min-h-0 mt-10 mb-8' data-testid='openAccessDashboards'>
                <Text.H2 className='mb-4'>Manage shared dashboards</Text.H2>
                {isLoadingShares ? (
                    <div className='px-4'>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <>
                        <LimitReachedBanner
                            featureKey='openAccess'
                            currentUsage={activeShareCount}
                            className='mb-4'
                            content='subtle'
                            container='page'
                        />
                        <div className='flex flex-col min-h-0'>
                            <OpenAccessTable
                                shares={shares ?? []}
                                onCopy={(share) => copy(getShareLink(share) || '')}
                                onToggle={handleOnToggle}
                                onDelete={handleOnDelete}
                                canCopy={supportsClipboard}
                                canToggle={canToggle}
                                canDelete={canDelete}
                            />
                        </div>
                    </>
                )}
            </div>

            {shareBeingCopied && (
                <CopyNotAvailablePrompt value={shareBeingCopied} onClose={() => setShareBeingCopied(undefined)} />
            )}

            {shareBeingDeleted && (
                <ConfirmationPrompt
                    title={`Delete Shared Dashboard: ${shareBeingDeleted.dashboardName}`}
                    prompt='Are you sure you want to permanently delete this shared dashboard link?'
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => {
                        await deleteShare({ id: shareBeingDeleted.id, dashId: shareBeingDeleted.targetId });
                    }}
                    onClose={() => setShareBeingDeleted(undefined)}
                />
            )}
        </SettingsTemplate>
    );
}

export default OpenAccess;
