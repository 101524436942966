import { stateStrings } from '@squaredup/monitoring';

export const healthStateStyles = {
    [stateStrings.error]: 'bg-statusErrorPrimary',
    [stateStrings.warning]: 'bg-statusWarningPrimary',
    [stateStrings.success]: 'bg-statusHealthyPrimary',
    [stateStrings.unknown]: 'bg-statusUnknownPrimary',
    [stateStrings.unmonitored]: 'stroke-statusUnmonitoredPrimary'
};

export const strokeHealthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-statusUnknownPrimary',
    [stateStrings.unmonitored]: 'border-statusUnmonitoredPrimary'
} as const;

export const borderHealthStateStyles = {
    [stateStrings.error]: 'border-statusErrorPrimary',
    [stateStrings.warning]: 'border-statusWarningPrimary',
    [stateStrings.success]: 'border-statusHealthyPrimary',
    [stateStrings.unknown]: 'border-statusUnknownPrimary',
    [stateStrings.unmonitored]: 'border-statusUnmonitoredPrimary'
};
