import { faCodeMerge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCollapseNodes } from 'components/map/hooks/useCollapseNodes';
import Tooltip from 'components/tooltip/Tooltip';
import { ControlButton } from 'reactflow';

export const CollapseAllButton: React.FC = () => {
    const collapseAll = useCollapseNodes();

    return (
        <Tooltip placement='right' title='Collapse all'>
            <ControlButton className='relative' onClick={() => collapseAll()}>
                <FontAwesomeIcon icon={faCodeMerge} />
            </ControlButton>
        </Tooltip>
    );
};
