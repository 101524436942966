import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { ReactNode } from 'react';

interface DropdownOption {
    id: string;
    name: string;
}
interface SplashScreenDropdownProps {
    options: DropdownOption[];
    className?: string;
    option: ({ id, name }: DropdownOption) => ReactNode;
}

export const SplashScreenOptionsDropdown: React.FC<SplashScreenDropdownProps> = ({ 
    options,
    className,
    option
}) => {
    const container = useDOMElement('dialogContent');
    
    return (
        <DropdownMenu>
            <DropdownMenu.Trigger>
                <button className={cn(
                    'flex items-center justify-center flex-1 w-full text-textSecondary min-h-0 px-1 py-3.5 rounded-md bg-tileEditorDataset hover:bg-tileEditorDatasetActive focus:bg-tileEditorDatasetActive',
                    className
                )}>
                    <FontAwesomeIcon 
                        icon={faEllipsis} 
                        size='2x'
                    />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu
                container={container}
                align='start'
                className='w-auto overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary max-h-[272px]'
            >
                {options.map((dropdownOption) => option(dropdownOption))}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
