import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { useSortedDataStreams } from '../hooks/useSortedDataStreams';
import type { DataStreamId } from '../state/TileEditorState';
import { DataStreamOption } from './DataStreamOption';

interface DataStreamVirtualListProps {
    dataStreams: DataStreamFilterOption[];
    selectedDataStreamId?: DataStreamId;
    allowSort?: boolean;
    onItemClick?: (dataStream: DataStreamFilterOption) => void;
}

export const DataStreamVirtualList: React.FC<DataStreamVirtualListProps> = ({
    dataStreams,
    allowSort = false,
    selectedDataStreamId,
    onItemClick
}) => {
    const parentRef = useRef<HTMLDivElement>(null);

    const { sortedStreams } = useSortedDataStreams(dataStreams, allowSort);

    const rowVirtualizer = useVirtualizer({
        count: sortedStreams?.length || 0,
        overscan: 10,
        gap: 7,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 35
    });

    return (
        <div className='w-full min-h-[100px] flex flex-col h-full overflow-hidden pl-[15px] pr-5 pt-[15px] flex-1'>
            <div
                ref={parentRef}
                className='w-full h-full min-h-0 overflow-auto text-sm scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary !overflow-x-scroll'
                role='list'
            >
                <div className='relative h-full min-h-full' style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                        const dataStream = sortedStreams?.[virtualItem.index];

                        return (
                            <div
                                key={virtualItem.key.toString()}
                                className='absolute top-0 left-0 w-full'
                                style={{
                                    height: `${virtualItem.size}px`,
                                    transform: `translateY(${virtualItem.start}px)`
                                }}
                            >
                                {dataStream && (
                                    <DataStreamOption
                                        dataStream={dataStream}
                                        onClick={() => onItemClick?.(dataStream)}
                                        isSelected={dataStream.id === selectedDataStreamId}
                                    />
                                )}
                            </div>
                        );
                    })}

                    {dataStreams && dataStreams.length === 0 && (
                        <div className='text-textIncomplete'>No search results were found.</div>
                    )}
                </div>
            </div>
        </div>
    );
};
