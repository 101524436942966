import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import type { Dispatch, SetStateAction } from 'react';
import type { TimeframeAction } from './TimeframeActions';
import { dashboardTimeframeState, type TimeframeState } from './TimeframeState';

/**
 * Dependencies used to produce side-effects.
 */
export type TimeframeStateReducerExternals = {
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
};

export type TimeframeStateInternal = TimeframeState & {
    sideEffects?: (externals: TimeframeStateReducerExternals) => void;
};

export type TimeframeStateReducer = (state: TimeframeStateInternal, action: TimeframeAction) => TimeframeStateInternal;

export const timeframeStateReducer: TimeframeStateReducer = (state, action): TimeframeStateInternal => {
    switch (action.type) {
        case 'timeframe.setTimeframe': {
            if (action.timeframe == null) {
                return {
                    ...dashboardTimeframeState,
                    sideEffects: ({ setConfig }) => {
                        setConfig((c) => ({
                            ...c,
                            timeframe: undefined
                        }));
                    }
                };
            }

            if (action.timeframe === 'none') {
                return {
                    supportsTimeframes: false,
                    isDashboardTimeframe: false,
                    selectedTimeframe: 'none',
                    sideEffects: ({ setConfig }) => {
                        setConfig((c) => ({
                            ...c,
                            timeframe: 'none'
                        }));
                    }
                };
            }

            return {
                ...state,
                supportsTimeframes: true,
                isDashboardTimeframe: false,
                selectedTimeframe: action.timeframe,
                sideEffects: ({ setConfig }) => {
                    setConfig((c) => ({
                        ...c,
                        timeframe: action.timeframe
                    }));
                }
            };
        }

        default:
            return state;
    }
};
