import Text from '@/components/Text';
import Tooltip from 'components/tooltip/Tooltip';
import { TruncatedText } from 'components/TruncatedText';
import { getDataStreamType } from 'dashboard-engine/util/getDataStreamType';
import trackEvent from 'lib/analytics';
import PluginIcon from 'pages/scope/PluginIcon';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { useObjectFilterObjects } from '../hooks/objectFilters/useObjectFilterObjects';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { useScopeBaseQuery } from '../hooks/useDataStreamObjectFilters';
import { TileEditorDataStream } from '../state/TileEditorState';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';

interface SplashScreenDataStreamOptionProps {
    dataStream: DataStreamFilterOption;
    dataStreamInstance?: TileEditorDataStream;
}

export const SplashScreenDataStreamOption: React.FC<SplashScreenDataStreamOptionProps> = ({ 
    dataStream,
    dataStreamInstance 
}) => {
    const { dispatch } = useTileEditorStore((s) => ({ dispatch: s.dispatch }));
    const { setCurrentEditorStep } = useTileEditorStepsContext();

    /**
     * We want to know if there is exactly 1 object matching the selected data stream,
     * so we need to load the objects without any filters applied.
     * Some filters are carried over to the Objects step from the data stream step,
     * so it's possible for some to be set without the user having visited the Objects step.
     */
    const { scopeBaseQuery, isLoading: isLoadingScopeBaseQuery } = useScopeBaseQuery(dataStream, undefined);
    
    const {
        isLoadingObjects,
        objects
    } = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: {},
        isFilterQueryReady: !isLoadingScopeBaseQuery && dataStream != null,
        options: { keepPreviousData: false }
    });

    if (!dataStreamInstance) {
        return null;
    }

    const {
        supportsScope,
        isConfigurable
    } = getDataStreamType(dataStream);
    
    return (
        <button
            key={dataStream.id}
            className='flex items-center h-[35px] min-w-0 px-4 py-2 text-textSecondary space-x-2 bg-tileEditorDataset hover:bg-tileEditorDatasetActive focus:bg-tileEditorDatasetActive hover:text-textPrimary focus:text-textPrimary'
            disabled={isLoadingObjects}
            onClick={() => {
                trackEvent('Data Stream Selected', {
                    name: dataStream.displayName,
                    plugin: dataStream.pluginName,
                    configurable: Boolean(dataStream.template),
                    configured: Boolean(dataStream.sourceTemplate)
                });

                if (supportsScope && objects && objects?.length === 1) {
                    dispatch({ 
                        type: 'dataStream.selectDataStream', 
                        dataStream: dataStreamInstance
                    });
                    return setCurrentEditorStep(isConfigurable ? EditorSteps.parameters : EditorSteps.timeframe, true);
                } 
                
                if (supportsScope) {
                    dispatch({ 
                        type: 'dataStream.selectDataStream', 
                        dataStream: dataStreamInstance,
                        forceStep: EditorSteps.objects
                    });
                    return setCurrentEditorStep(EditorSteps.objects, true);
                }

                if (isConfigurable) {
                    dispatch({ 
                        type: 'dataStream.selectDataStream', 
                        dataStream: dataStreamInstance,
                        forceStep: EditorSteps.parameters
                    });
                    return setCurrentEditorStep(EditorSteps.parameters, true);
                }

                dispatch({ 
                    type: 'dataStream.selectDataStream', 
                    dataStream: dataStreamInstance,
                    forceStep: EditorSteps.timeframe
                });
                return setCurrentEditorStep(EditorSteps.timeframe, true);
            }}
        >
            <span className='h-[15px] w-[15px] flex-shrink-0'>
                <Tooltip title={dataStream.pluginName}>
                    <PluginIcon pluginName={dataStream.pluginName} />
                </Tooltip>
            </span>

            <Text.H5 className='min-w-0'>
                <TruncatedText 
                    title={dataStream.displayName} 
                    element='span' 
                />
            </Text.H5>
        </button>
    );
};
