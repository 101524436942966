import { cn } from '@/lib/cn';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import DropdownMenu from 'components/dropdownMenu';
import { useDOMElement } from 'components/hooks/useDOMElement';
import PluginIcon from 'pages/scope/PluginIcon';
import { useState } from 'react';
import { SearchBar } from '../SearchBar';
import { useFilterSearchItems } from '../hooks/useFilterSearchItems';

interface DataSourceFilterDropdownProps {
    sources?: { pluginId: string; pluginName: string; onPrem: boolean | undefined }[];
    selectedDataSourceId?: string;
    labelClassName?: string;
    setSelectedDataSourceId: (value: string | undefined) => void;
    onAddDataSource?: () => void;
}

const searchKeys = [{ name: 'name', weight: 10 }];

export const DataSourceFilterDropdown: React.FC<DataSourceFilterDropdownProps> = ({
    sources = [],
    selectedDataSourceId,
    labelClassName,
    setSelectedDataSourceId,
    onAddDataSource
}) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const menuContainer = useDOMElement('dialogContent');

    const dataSources = sources.map((s) => ({
        id: s.pluginId,
        name: s.pluginName ?? 'unknown',
        onPrem: s.onPrem
    }));

    const selectedDataSource = dataSources.find((s) => s.id === selectedDataSourceId);

    const { searchItems, setSearchTerm } = useFilterSearchItems({
        keys: searchKeys,
        items: dataSources
    });

    const handleDropdownOpenChange = (open: boolean) => {
        setSearchTerm('');
        setIsDropdownOpened(open);
    };

    return (
        <DropdownMenu onOpenChange={handleDropdownOpenChange} modal={false}>
            <DropdownMenu.Button
                className={cn('whitespace-nowrap border-outlinePrimary bg-secondaryButtonBackground', selectedDataSourceId && 'bg-secondaryButtonBackgroundHover border-outlineSecondary hover:border-outlineSecondary focus:hover:border-outlineSecondary')}
                dropdownOpen={isDropdownOpened}
                showChevron
                useFilterStyle
                data-testid='dataSourceFilter'
            >
                {selectedDataSource ? (
                    <div className='inline-flex align-middle'>
                        <div className='w-5 h-5 mr-3'>
                            <PluginIcon pluginName={selectedDataSource.name} />
                        </div>
                        <TruncatedText
                            title={selectedDataSource.name}
                            className={cn('flex-1 min-w-0 max-w-[80px] truncate', labelClassName)}
                        />
                    </div>
                ) : (
                    'Data Source'
                )}
            </DropdownMenu.Button>

            <DropdownMenu.Menu 
                align='start' 
                className='w-[260px]' 
                container={menuContainer} noScroll
            >
                <SearchBar placeholder='Search for a data source...' onChange={setSearchTerm} className='mb-3' />

                <DropdownMenu.Group className='max-h-[320px] overflow-y-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {searchItems?.map((source) => (
                        <DropdownMenu.Item
                            key={source.id}
                            className={cn({
                                'bg-dividerPrimary': selectedDataSourceId === source.id
                            })}
                            onSelect={() =>
                                setSelectedDataSourceId(selectedDataSourceId === source.id ? undefined : source.id)
                            }
                        >
                            <div className='flex'>
                                <div className='w-5 h-5 mr-3'>
                                    <PluginIcon pluginName={source.name} />
                                </div>
                                <TruncatedText
                                    title={source.name + (source.onPrem ? ' (On-Prem)' : '')}
                                    className='flex-1 min-w-0 mr-3 truncate'
                                />
                            </div>
                        </DropdownMenu.Item>
                    ))}
                </DropdownMenu.Group>

                {onAddDataSource && (
                    <>
                        <DropdownMenu.Separator />
                        <DropdownMenu.SecondaryItem onSelect={onAddDataSource}>
                            <FontAwesomeIcon icon={faPlus} className='w-4 h-4 mr-3' />
                            Add new data source
                        </DropdownMenu.SecondaryItem>
                    </>
                )}

                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem
                    disabled={!selectedDataSourceId}
                    onSelect={() => setSelectedDataSourceId(undefined)}
                >
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
