import { DirectedGraph } from 'graphology';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes, NodesAndEdges } from '../types';
import { addEdge, getNodeGroupID } from './addEdge';
import { getGraphologyNodeAttributes } from './convertToGraphology';
import { nodeExists } from './nodeExists';
import { rewriteLargeNodeGroups } from './rewriteLargeNodeGroups';
import { rewriteSourceToCanonical } from './rewriteSourceToCanonical';

export function insertQueryResults(
    graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>,
    queryResults?: NodesAndEdges,
    shownIds?: string[],
    rootNode?: GraphologyNodeAttributes
) {
    if (!queryResults) {
        return;
    }

    const { nodes, edges } = queryResults;

    nodes.forEach((node) => {
        if (!nodeExists(graph, node.id)) {
            graph.addNode(
                node.id,
                getGraphologyNodeAttributes(node, {
                    expanded: false,
                    pinned: false,
                    ungrouped: false,
                    hidden: !shownIds?.includes(node.id),
                    x: rootNode?.x,
                    y: rootNode?.y
                })
            );
        } else if (shownIds?.includes(node.id)) {
            const id = getNodeGroupID(graph, node.id) || node.id;

            graph.updateNodeAttributes(id, (attr) => ({ ...attr, hidden: false }));
        }
    });

    edges.forEach((edge) => addEdge(graph, edge));

    rewriteSourceToCanonical(graph);
    rewriteLargeNodeGroups(graph);
}
