import { ResponsiveBar } from '@nivo/bar';
import { StreamDataColumn, date } from '@squaredup/data-streams';
import GraphTooltip from 'components/GraphTooltip';
import NoDataPlaceholder from 'components/NoDataPlaceholder';

import { cn } from '@/lib/cn';
import { DataStreamVisualisation } from 'dashboard-engine/types/Visualisation';
import { opacityGradient } from 'dashboard-engine/util/nivoGradient';
import { formatTickAsDate } from 'dashboard-engine/util/tickDateFormatter';
import { formatTickAsColumn, getDecimalPlaces } from 'dashboard-engine/util/tickFormatter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrilldownContext } from 'ui/editor/dataStream/contexts/DrilldownContext';
import nivoTheme from '../../util/nivoTheme';
import { LegendWrapper } from '../components/LegendWrapper';
import { LegendPosition } from '../components/Legends';
import { getColor } from '../getColor';
import { NUM_Y_TICKS, getGridYValues } from '../getGridYValues';
import { useVisualizationTheme } from '../hooks/useVisualizationTheme';
import { BarChartCustomBottomAxis } from './BarChartCustomBottomAxis';
import { BarChartTotals } from './BarChartTotals';
import {
    DataStreamBarChartConfig,
    LegacyDataStreamBarChartConfig,
    barGraphBottomLegendPadding,
    barGraphBottomMarginPadding,
    barGraphDefaultBottomMargin,
    barGraphDefaultRightMargin
} from './Config';
import DataStreamBarItem from './DataStreamBarItem';
import { computedTooltipPoints, legendMaxLength } from './dataUtils';
import { performLegacyBarChartColumnMigration } from './performLegacyBarChartColumnMigration';
import { useColumns } from './useColumns';

interface YAxisDimensions {
    width: number; // The width of the Y-axis area
    labelOffset: number; // The offset for the Y-axis label
    tickOffset: number; // The offset for the Y-axis ticks
    maxCharsBeforeTruncation: number; // Maximum characters before truncation
}

/**
 * Hook to calculate and manage the Y-axis dimensions for horizontal bar charts
 *
 * @param isHorizontal Whether the chart is in horizontal layout
 * @param categories The categories displayed on the Y-axis
 * @param containerRef Reference to the container element
 * @param maxWidthPercentage Maximum percentage of container width to use (default: 0.5)
 * @returns The calculated Y-axis dimensions
 */
export function useYAxisDimensions(
    isHorizontal: boolean,
    categories: (string | null)[],
    containerRef: React.RefObject<HTMLDivElement>,
    maxWidthPercentage = 0.5
): YAxisDimensions {
    const [dimensions, setDimensions] = useState<YAxisDimensions>({
        width: 60,
        labelOffset: -95,
        tickOffset: 5,
        maxCharsBeforeTruncation: 10
    });

    useEffect(() => {
        if (!isHorizontal || !containerRef.current || categories.length === 0) {
            return;
        }

        const MIN_AXIS_WIDTH = 60; // Minimum width in pixels for the axis
        const LABEL_PADDING = 60; // Padding in pixels for labels
        const AVERAGE_CHAR_WIDTH = 5.5; // Average width of a character in pixels
        const LABEL_POSITION_FACTOR = 0.95; // Position factor for label (95% of axis width)
        const TICK_POSITION_FACTOR = 0.2; // Position factor for tick (20% of axis width)
        const MAX_TICK_OFFSET = 10; // Maximum tick offset in pixels

        const containerWidth = containerRef.current.clientWidth;

        // Calculate the maximum allowed width based on container percentage
        const maxAllowedWidth = Math.floor(containerWidth * maxWidthPercentage);

        // Get the longest category text length
        const longestCategory = categories.reduce(
            (longest, current) => (current && current.length > (longest?.length || 0) ? current : longest),
            null
        );

        // Calculate how many characters can fit in the maximum allowed width
        const maxPossibleChars = Math.floor((maxAllowedWidth - LABEL_PADDING) / AVERAGE_CHAR_WIDTH);

        // Determine if we need the full allowed width or less
        const longestCategoryLength = longestCategory?.length || 0;
        const needsFullWidth = longestCategoryLength > maxPossibleChars;

        // Calculate the optimal width based on the longest text or use minimum width
        const optimalWidth = needsFullWidth
            ? maxAllowedWidth
            : Math.max(
                  MIN_AXIS_WIDTH,
                  Math.min(longestCategoryLength * AVERAGE_CHAR_WIDTH + LABEL_PADDING, maxAllowedWidth)
              );

        // Calculate label offset based on the new width
        const newLabelOffset = -Math.floor(optimalWidth * LABEL_POSITION_FACTOR);

        // Calculate tick offset
        const newTickOffset = Math.min(MAX_TICK_OFFSET, Math.floor(optimalWidth * TICK_POSITION_FACTOR));

        // Calculate max characters before truncation
        const maxChars = Math.floor((optimalWidth - LABEL_PADDING) / AVERAGE_CHAR_WIDTH);

        // Update dimensions if they've changed significantly
        if (
            optimalWidth !== dimensions.width ||
            newLabelOffset !== dimensions.labelOffset ||
            dimensions.maxCharsBeforeTruncation !== maxChars
        ) {
            setDimensions({
                width: optimalWidth,
                labelOffset: newLabelOffset,
                tickOffset: newTickOffset,
                maxCharsBeforeTruncation: maxChars
            });
        }
    }, [isHorizontal, categories, dimensions, maxWidthPercentage, containerRef]);

    return dimensions;
}

const calculateMargins = (
    legendPosition: LegendPosition | undefined,
    xAxisLabelVisible: boolean,
    bottomMargin: number,
    leftMargin: number,
    rightMargin: number,
    topMargin: number
) => {
    let margins = {
        top: topMargin,
        right: rightMargin,
        bottom: barGraphBottomMarginPadding + bottomMargin,
        left: leftMargin
    };
    if (legendPosition === 'bottom') {
        margins.bottom -= 20;
        if (!xAxisLabelVisible) {
            margins.bottom -= 20;
        }
    }

    return margins;
};

export const DataStreamBarChart: DataStreamVisualisation<DataStreamBarChartConfig> = ({
    data,
    config: rawConfig,
    shapingConfig,
    onClick
}) => {
    // Perform migration from legacy bar chart configuration
    const config = performLegacyBarChartColumnMigration(rawConfig as LegacyDataStreamBarChartConfig, data);

    const [bottomMargin, setBottomMargin] = useState(barGraphDefaultBottomMargin);
    // store the right margin for the totals label
    const [totalsLabelRightMargin, setTotalsLabelRightMargin] = useState(barGraphDefaultRightMargin);
    const [borderSize, setBorderSize] = useState(0);
    const [hoveredId, setHoveredId] = useState<string | number | undefined>();
    const topMargin = config.showTotals && config.horizontalLayout !== 'horizontal' ? 20 : 6;
    const rightMargin =
        config.showTotals && config.horizontalLayout === 'horizontal'
            ? totalsLabelRightMargin
            : barGraphDefaultRightMargin;

    const displayMode = config?.displayMode ?? 'actual';
    const isHorizontal = config?.horizontalLayout === 'horizontal';
    const { isDrilldownEnabled } = useDrilldownContext();

    const graphRef = useRef<HTMLDivElement>(null);

    const { series, dataKeys, valueColumn, axes, hasSeriesColumn } = useColumns({
        data,
        config,
        displayMode,
        isHorizontal,
        hasSorting: Boolean(shapingConfig?.sort?.by)
    });

    const barGraphTheme = useVisualizationTheme('bar');

    let maxValue: 'auto' | number = 'auto';
    let minValue: 'auto' | number = 'auto';

    if (config.displayMode === 'percentage') {
        maxValue = 100;
    } else if (config.range?.type === 'custom') {
        maxValue = config.range?.maximum ?? 'auto';
        minValue = config.range?.minimum ?? 'auto';
    }

    const isHovered = hoveredId != null;

    const isLegendVertical = config.legendPosition === 'top' || config.legendPosition === 'bottom';
    const categories = series.map((d) => d.__group__);

    const yAxisDimensions = useYAxisDimensions(
        isHorizontal,
        categories,
        graphRef,
        0.5 // Max 50% of width
    );

    const yAxisWidth = yAxisDimensions.width;

    const marginLeft = isHorizontal ? yAxisWidth : Math.max(legendMaxLength(series) * 35, 60);
    const leftAxisLabelOffset = isHorizontal ? yAxisDimensions.labelOffset : -95; // Default value

    const xAxisColumnShape = axes?.x?.column?.shapeName;

    const groupValues = series.map((d) => d.groupValue);

    const formatColumnTick = useMemo(() => {
        const formatTick = (col: StreamDataColumn, maxCharsBeforeTruncation?: number) => {
            const formatter = formatTickAsColumn(
                col,
                {
                    data,
                    valueColumn: valueColumn.column
                },
                {
                    maxLength: maxCharsBeforeTruncation
                }
            );

            return (value: unknown) => {
                if (typeof value !== 'number') {
                    return formatter(value);
                }

                return formatter(value, {
                    decimalPlaces: getDecimalPlaces(value)
                });
            };
        };

        return formatTick;
    }, [data, valueColumn.column]);

    const xAxisNumericTickFormatter = formatColumnTick(axes.x.column);
    const valueFormatter = formatTickAsColumn(valueColumn?.column, { data, valueColumn: valueColumn?.column });

    const isGrouping = config.grouping;

    const bottomAxis = useCallback(
        (props) => (
            <BarChartCustomBottomAxis
                {...props}
                categories={groupValues}
                isHorizontal={isHorizontal}
                xAxisColumnShape={xAxisColumnShape}
                graphRef={graphRef}
                xAxisNumericTickFormatter={xAxisNumericTickFormatter}
                bottomMargin={bottomMargin}
                setBottomMargin={setBottomMargin}
                setBorderSize={setBorderSize}
                isGrouping={isGrouping}
                hasSeriesColumn={hasSeriesColumn}
                marginLeft={marginLeft}
            />
        ),
        [
            graphRef,
            groupValues,
            isHorizontal,
            isGrouping,
            marginLeft,
            bottomMargin,
            xAxisColumnShape,
            xAxisNumericTickFormatter,
            hasSeriesColumn
        ]
    );

    // Determine shape of yAxis if orientation has been flipped
    const horizontalColumnShape = isHorizontal ? axes?.y?.column?.shapeName : '';

    // If yAxis is of shape_date pass it through the D3 scaler, else use the pre-existing format
    const yAxisTickFormatter =
        horizontalColumnShape === date.name
            ? formatTickAsDate(categories)
            : formatColumnTick(axes.y.column, isHorizontal ? yAxisDimensions.maxCharsBeforeTruncation : undefined);

    const showTotals = config.showTotals && config.displayMode !== 'percentage' && !isGrouping;

    // get the formatter for the total values, so they match the value formatting.
    const totalsFormatter = formatTickAsColumn(
        isHorizontal ? axes.x.column : axes.y.column,
        {
            data,
            valueColumn: valueColumn?.column
        },
        // prevent truncating of value like axes have as bar can be wider.
        { maxLength: 50 }
    );

    const chartTotals = useCallback(
        (props) =>
            showTotals ? (
                <BarChartTotals
                    {...props}
                    format={totalsFormatter}
                    labelRightMargin={totalsLabelRightMargin}
                    setTotalsLabelRightMargin={setTotalsLabelRightMargin}
                />
            ) : null,
        [showTotals, totalsLabelRightMargin, totalsFormatter]
    );

    // Whether labels should be able to overflow the bar item to the right
    const overflowLabelsHorizontal =
        config.horizontalLayout === 'horizontal' && (!hasSeriesColumn || isGrouping) && !showTotals;
    // Whether labels should be able to overflow the bar item on top
    const overflowLabelsVertical =
        config.horizontalLayout === 'vertical' && (!hasSeriesColumn || isGrouping) && !showTotals;

    const barComponent = useCallback(
        (props) => (
            <DataStreamBarItem
                {...props}
                overflowLabelsVertical={overflowLabelsVertical}
                overflowLabelsHorizontal={overflowLabelsHorizontal}
                allowLink={isDrilldownEnabled}
            />
        ),
        [overflowLabelsHorizontal, overflowLabelsVertical, isDrilldownEnabled]
    );

    if (!series || series.length === 0) {
        return <NoDataPlaceholder />;
    }

    if (series.every((s) => s.value === 0)) {
        return <NoDataPlaceholder message='No non-zero values to display.' />;
    }

    const showGrid = config.showGrid ?? true;

    const legend = dataKeys.map((dataKey, index) => {
        return {
            id: dataKey,
            label: dataKey,
            color: getColor(config, barGraphTheme, dataKey, index),
            isFocused: hoveredId !== undefined ? hoveredId === dataKey : true
        };
    });

    const barChart = (
        <div
            data-visualization='data-stream-bar-chart'
            className={cn('absolute inset-0', onClick && 'cursor-pointer')}
            ref={graphRef}
            onClick={onClick}
        >
            <ResponsiveBar
                data={series}
                margin={calculateMargins(
                    config.showLegend ? config.legendPosition : undefined,
                    Boolean(axes.x.label),
                    bottomMargin,
                    marginLeft,
                    rightMargin,
                    topMargin
                )}
                colors={(datum) => getColor(config, barGraphTheme, datum.id, datum.data[`${datum.id}-index`] - 1)}
                layers={['grid', 'axes', 'bars', 'markers', 'legends', bottomAxis, chartTotals]}
                axisTop={null}
                axisRight={null}
                barComponent={barComponent}
                axisBottom={{
                    legend: axes.x.label,
                    legendOffset: bottomMargin + barGraphBottomLegendPadding,
                    legendPosition: 'middle',
                    format: xAxisNumericTickFormatter,
                    renderTick: () => null
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickValues: NUM_Y_TICKS,
                    legendPosition: 'middle',
                    format: yAxisTickFormatter,
                    legend: axes.y.label,
                    legendOffset: leftAxisLabelOffset
                }}
                theme={nivoTheme}
                maxValue={maxValue}
                minValue={minValue}
                layout={config.horizontalLayout}
                keys={dataKeys}
                groupMode={hasSeriesColumn && isGrouping ? 'grouped' : 'stacked'}
                enableLabel={config?.showValue}
                label={(d: { id?: string; data?: { [key: string]: string } }) => {
                    if (!d.data || !d.id) {
                        return '';
                    }

                    if (config?.displayMode === 'percentage') {
                        return `${valueFormatter(d.data[d.id])} (${d.data[`${d.id}-percentage`]})`;
                    }

                    return valueFormatter(d.data[d.id]);
                }}
                indexBy='__group__'
                borderWidth={borderSize}
                borderColor='transparent'
                padding={0.2}
                labelTextColor='white'
                labelSkipWidth={0}
                labelSkipHeight={0}
                enableGridY={!isHorizontal}
                enableGridX={isHorizontal}
                gridYValues={getGridYValues(showGrid, minValue, 0)}
                gridXValues={[0]} // Ensure there is a grid line at 0
                defs={[opacityGradient('grouped-bar-gradient', 0.2)]}
                fill={[
                    {
                        match: (d: { id?: string; data?: { [key: string]: string } }) =>
                            isHovered && d.data?.id !== hoveredId,
                        id: 'grouped-bar-gradient'
                    }
                ]}
                onMouseEnter={(e) => setHoveredId(e.id)}
                onMouseLeave={() => setHoveredId(undefined)}
                tooltip={(point) => {
                    const { color, id, indexValue, value } = point;
                    const formattedFromNivoData =
                        displayMode === 'cumulative' ? valueFormatter(value) : point.data[`${id}-formatted`];

                    let subValue: string | undefined;

                    if (displayMode === 'cumulative') {
                        subValue = `+${point.data[`${id}-formatted`]}`;
                    } else if (displayMode === 'percentage') {
                        subValue = `${point.data[`${id}-percentage`]}`;
                    }

                    return (
                        <GraphTooltip
                            points={[
                                computedTooltipPoints({
                                    color,
                                    id,
                                    indexValue,
                                    formattedFromNivoData,
                                    subValue
                                })
                            ]}
                            graphRef={graphRef}
                        />
                    );
                }}
            />
        </div>
    );

    if (config.showLegend && config.legendPosition) {
        return (
            <LegendWrapper
                legend={legend}
                position={config.legendPosition}
                containerSizePercentage={isLegendVertical ? 0.3 : 0.25}
                legendContainerClassname='p-5'
                vizRef={graphRef}
                onHover={(properties) => setHoveredId(properties.id)}
                onLeave={() => setHoveredId(undefined)}
            >
                {barChart}
            </LegendWrapper>
        );
    }

    return barChart;
};
