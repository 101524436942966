import { Divider } from '@/components/Divider';
import Field from 'components/forms/field/Field';
import ColumnList from 'components/visualisationOptions/ColumnList';
import HideAllColumns from 'components/visualisationOptions/HideAllColumns';
import { OptionToggle } from 'components/visualisationOptions/OptionToggle';
import RowLink from 'components/visualisationOptions/RowLink';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { sortBy } from 'lodash';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamTableConfig } from './types';

const DataStreamTableOptions: VisualisationOption<DataStreamTableConfig> = {
    initialPanels: (config) => {
        const panels = [{ name: 'columns', isOpen: true }];

        if (config?.rowLinkColumnName && config?.rowLinkColumnName?.value !== 'None') {
            panels.push({ name: 'rowLink', isOpen: true });
        }

        if (config?.transpose !== undefined) {
            panels.push({ name: 'options', isOpen: true });
        }

        return panels;
    },
    getDefaultConfig: (data) => (data && data.rows.length === 1 ? { transpose: true } : {}),
    configurationComponent: ({ columns, config, accordionControls, onChange }) => (
        <>
            <VisualisationConfigAccordion label='Columns' value='columns' accordionControls={accordionControls}>
                {columns.length ? (
                    <>
                        <HideAllColumns columns={columns} onChange={onChange} />
                        <ColumnList columns={columns} config={config} onChange={onChange} />
                    </>
                ) : (
                    <p>No columns to show.</p>
                )}
            </VisualisationConfigAccordion>

            <Divider />

            <VisualisationConfigAccordion label='Row link' value='rowLink' accordionControls={accordionControls}>
                <Field label='Column' help={'Hyperlinks each row in the table to the URL in the selected column'}>
                    <RowLink columns={columns} onChange={onChange} columnListData={config} />
                </Field>
            </VisualisationConfigAccordion>

            <Divider />

            <VisualisationConfigAccordion label='Options' value='options' accordionControls={accordionControls}>
                <OptionToggle
                    name='transpose'
                    label='Swap rows and columns'
                    help='Enabling this option transposes the table showing headings on the left, particularly useful for single row tables'
                    onChange={() => onChange({ action: 'toggleTranspose' })}
                    checked={config.transpose === true}
                />

                <Field label='Resizing columns'>
                    <p className='text-textSecondary'>
                        Use the handle on each column of the table to change the width. Changes will be saved while in
                        the tile editor or in dashboard edit mode.
                    </p>
                </Field>
            </VisualisationConfigAccordion>
        </>
    ),
    handlers: {
        moveColumn: (config, data) => {
            // Ignore drop outside droppable container
            if (!data.destination) {
                return config;
            }

            const updatedList = [...(config.columnOrder ?? [])];

            // Remove dragged item
            const [reorderedItem] = updatedList.splice(data.source.index, 1);

            // Add dropped item
            updatedList.splice(data.destination.index, 0, reorderedItem);

            return { ...config, columnOrder: updatedList };
        },
        renameColumn: (config, data) => {
            // If no value is passed we want to remove any custom displayName for the passed columnName.
            // This allows the user to reset a custom displayName
            if (data.displayName === null || data.displayName === '') {
                const { [data.columnName]: currentDisplayName, ...displayNames } = config.columnDisplayNames || {};

                return {
                    ...config,
                    columnDisplayNames: displayNames
                };
            }

            return {
                ...config,
                columnDisplayNames: {
                    ...config.columnDisplayNames,
                    [data.columnName]: data.displayName
                }
            };
        },
        hideAll: (config, data) => {
            return {
                ...config,
                hiddenColumns: data,
                columnOrder: []
            };
        },
        toggleVisibility: (config, data) => {
            const isHidden = config.hiddenColumns?.includes(data);

            if (isHidden) {
                return { ...config, hiddenColumns: config.hiddenColumns?.filter((c: any) => c !== data) };
            }

            return {
                ...config,
                hiddenColumns: [...(config.hiddenColumns ?? []), data],
                // We want to clear any reference of the hiddenColumn from the columnOrder to ensure the
                // UI for ordering doesn't break
                columnOrder: [...(config.columnOrder?.filter((col: string) => col !== data) ?? [])]
            };
        },
        changeRowLink: (config, data) => {
            if (data.label) {
                return { ...config, rowLinkColumnName: { value: data.value, label: data.label } };
            } else {
                const { rowLinkColumnName, ...newConfig } = config;
                return newConfig;
            }
        },
        toggleTranspose: (config) => {
            if (config.transpose === true) {
                const { transpose, ...rest } = config;
                return rest;
            }

            return {
                ...config,
                transpose: true
            };
        }
    },
    validate: (columns, config) => {
        const validated = { ...config };

        if (!config.hiddenColumns || !Array.isArray(config.hiddenColumns)) {
            validated.hiddenColumns = columns.filter((col) => !col.visible).map((col) => col.name);
        }

        // We only want to set order for columns that are visible otherwise the UI breaks when reordering
        const visibleColumns = [...columns.filter((col) => !validated.hiddenColumns?.includes(col.name))];
        if (!config.columnOrder || !Array.isArray(config.columnOrder)) {
            validated.columnOrder = sortBy(visibleColumns, 'displayIndex').map((c) => c.name);
        } else if (config.columnOrder.length !== columns.length) {
            const columnNames = visibleColumns.map((col) => col.name);
            const columnOrder = config.columnOrder?.filter((col) => columnNames.includes(col)) ?? [];
            validated.columnOrder = sortBy(
                [...columns.filter((col) => !config.hiddenColumns?.includes(col.name))],
                (col) =>
                    columnOrder?.length && columnOrder.includes(col.name)
                        ? columnOrder.findIndex((c) => c === col.name)
                        : col.displayIndex
            ).map((c) => c.name);
        }

        // We only want displayNames overrides that don't match the column's built-in displayName
        if (config.columnDisplayNames) {
            validated.columnDisplayNames = columns.reduce(
                (validatedColumnDisplayNames, { name, displayName }) => {
                    const columnDisplayName = config.columnDisplayNames?.[name];
                    if (columnDisplayName && columnDisplayName !== displayName) {
                        validatedColumnDisplayNames[name] = columnDisplayName;
                    }

                    return validatedColumnDisplayNames;
                },
                {} as Record<string, string>
            );
        }

        return validated;
    },
    matchesData: () => dataMatchCriteria()
};

export default DataStreamTableOptions;
