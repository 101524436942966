import type { DashboardType } from '@squaredup/dashboards';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { CreateShare, OA_SHARES_DATA } from 'services/OpenAccessService';
import { Workspace } from 'services/WorkspaceService';
import { useDashboardId } from 'ui/hooks/useDashboardId';

type Input = {
    workspaceId: Workspace['id'];
    dashboardId: DashboardType['id'];
    requireAuth: boolean;
};

type Options = UseMutationOptions<any, unknown, Input>;

/**
 * useMutation to create a new sharing link for a dashboard.
 * @param options The useMutation options e.g. refreshInterval
 * @returns a mutation variable
 */
export const useCreateDashboardSharingLink = (options?: Options) => {
    const queryClient = useQueryClient();
    const dashboardId = useDashboardId();

    return useMutation({
        ...options,
        mutationKey: dashboardQueryKeys.openAccessNewLink,
        mutationFn: async (props: Input) => {
            return CreateShare(props.workspaceId, props.dashboardId, {
                restrictToUsers: [],
                requireAuthentication: props.requireAuth
            });
        },
        onSettled: () => {
            if (dashboardId) {
                queryClient.invalidateQueries(OA_SHARES_DATA);
            }
        }
    });
};
