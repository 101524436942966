import { Button } from '@/components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';

interface StepProgressButtonProps {
    disabled?: boolean;
    isLoading?: boolean;
}

export const StepProgressButton: React.FC<StepProgressButtonProps> = ({ disabled, isLoading }) => {
    const { 
        isConfigured, 
        isConfiguredAfterCurrentStep, 
        currentEditorStep, 
        canMoveToNextStep, 
        isDocked,
        isEditingSQL,
        nextStep, 
        setCurrentEditorStep,
        setIsDocked
    } = useTileEditorStepsContext();

    const isDone = isConfigured || isConfiguredAfterCurrentStep || isEditingSQL;

    return (
        <Button
            disabled={disabled || (!canMoveToNextStep && currentEditorStep !== EditorSteps.metadata)}
            {...isLoading && { icon: <LoadingSpinner size={18} /> }}
            onClick={() => {
                isDone ? setCurrentEditorStep(undefined) : nextStep();

                // Make sure we undock the step if we've now finished configuration
                if (isDocked && isConfigured) {
                    setIsDocked?.(false);
                }
            }}
            data-testid='tileEditorProgressButton'
        >
            {isDone ? 'Done' : 'Next'}
        </Button>
    );
};
