import {
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    StreamData,
    StreamDataColumn,
    StreamValue
} from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import { getDrilldownUrlFor, getValueIfAllSame } from 'dashboard-engine/util/drilldown';
import { isValid, toDate } from 'date-fns';
import stringify from 'fast-json-stable-stringify';
import { groupBy, orderBy, pick } from 'lodash';
import { DataStreamLineGraphConfig } from './Config';
import { findLabelColumns, getLinegraphColumns } from './LineGraphColumns';
import { LineSeries } from './lineGraphTypes';

interface ToSeriesOptions {
    isDrilldownEnabled: boolean;
}

export interface LineGraphPoint {
    timestamp: Date;
    value: StreamValue;
    valueFormatted: string;
}

interface PointData {
    point: LineGraphPoint;
    label: string;
    sourceId?: string;
    pluginConfigId?: string;
}

export interface StreamDataSeries {
    label: string;
    points: LineGraphPoint[];
    drilldownUrl?: string;
}

export interface SeriesData {
    unitLabel?: string;
    valueColumn?: StreamDataColumn;
    series: StreamDataSeries[];
}

const isPointValid = ({ value, timestamp }: LineGraphPoint): boolean => typeof value === 'number' && isValid(timestamp);

const rowToPoint = (
    row: FormattedStreamValue[],
    valueColumnIndex: number,
    timestampColumnIndex: number
): LineGraphPoint => {
    return {
        timestamp: toDate(row[timestampColumnIndex].value as string),
        value: row[valueColumnIndex].value,
        valueFormatted: row[valueColumnIndex].formatted
    };
};

/**
 * Generate zero or more series of line graph points from the rows of some stream data.
 * @param data The data to group into series.
 * @param getPointFromRow A function that converts a single row into a line graph point.
 * @param getLabel A function that gets the label for a row. This is the value used to group rows into series.
 * @param getLabelColumnSourceId A function that gets the sourceId for the label of a row.
 * This is used to generate drilldown Urls.
 * @param options Controls whether drilldown Url generation is enabled.
 */
const seriesFromRows = (
    data: StreamData,
    getPointFromRow: (row: FormattedStreamValue[]) => LineGraphPoint,
    getLabel: (row: FormattedStreamValue[]) => string,
    { isDrilldownEnabled }: ToSeriesOptions
) => {
    const pointData = data.rows
        .map((r, i) => ({ row: r, rowData: data.metadata.rowData[i] }))
        .map(({ row: r, rowData }): PointData => {
            return {
                point: getPointFromRow(r),
                label: getLabel(r),
                sourceId: rowData.sourceIds ? getValueIfAllSame(rowData.sourceIds.map((s) => s.sourceId)) : undefined,
                pluginConfigId: getValueIfAllSame(rowData.pluginConfigIds)
            };
        })
        .filter(({ point }) => isPointValid(point));

    const series: StreamDataSeries[] = Object.entries(groupBy(pointData, 'label')).map(([label, values]) => {
        if (!isDrilldownEnabled) {
            return {
                label,
                points: values.map((v) => v.point)
            };
        }

        // All rows in the group must have the same sourceId and pluginConfigId for the drilldown to work
        const sourceId = getValueIfAllSame(values.map((v) => v.sourceId));
        const pluginConfigId = getValueIfAllSame(values.map((v) => v.pluginConfigId));

        if (sourceId != null && pluginConfigId != null) {
            return {
                label,
                points: values.map((v) => v.point),
                drilldownUrl: getDrilldownUrlFor(sourceId, pluginConfigId)
            };
        }

        return {
            label,
            points: values.map((v) => v.point)
        };
    });

    return series;
};

const getUnitLabelValue = (unitLabelColumnResult: Result<FoundColumn>, data: FormattedStreamData) =>
    unitLabelColumnResult.map((c) => data.rows[0]?.[c.dataIndex]?.formatted).getValue(undefined);

/**
 * Generate series by grouping the rows a label (created by concating values from one or more label columns)
 *  and reading values from a single value column
 */
export const getSeriesPerLabel = (
    valueColumn: FoundColumn,
    data: FormattedStreamData,
    labelColumns: FoundColumn[],
    timestampColumn: FoundColumn,
    unitLabelColumnResult: Result<FoundColumn>,
    options: ToSeriesOptions = { isDrilldownEnabled: true }
): SeriesData => {
    const getRowLabel = (r: FormattedStreamValue[]) =>
        orderBy(labelColumns, (c) => c.column.displayIndex)
            .map((c) => r[c.dataIndex].formatted)
            .join(' / ');

    const series = seriesFromRows(
        data,
        (r) => rowToPoint(r, valueColumn.dataIndex, timestampColumn.dataIndex),
        getRowLabel,
        options
    );

    const unitLabel = getUnitLabelValue(unitLabelColumnResult, data);

    return { unitLabel, valueColumn: valueColumn.column, series };
};

/**
 * Generate a series from each value column, where the label is the display name of the column
 */
export const getSeriesPerValueColumn = (
    valueColumns: FoundColumn[],
    labelColumns: FoundColumn[],
    data: FormattedStreamData,
    timestampColumn: FoundColumn,
    unitLabelColumnResult: Result<FoundColumn>,
    options: ToSeriesOptions = { isDrilldownEnabled: true }
): SeriesData => {
    const allSeries = valueColumns.flatMap((valueColumn) => {
        const getRowLabel = (r: FormattedStreamValue[]) =>
            orderBy(labelColumns, (c) => c.column.displayIndex)
                .map((c) => r[c.dataIndex].formatted)
                .concat(valueColumn.column.displayName)
                .join(' / ');

        const series = seriesFromRows(
            data,
            (r) => rowToPoint(r, valueColumn.dataIndex, timestampColumn.dataIndex),
            getRowLabel,
            options
        );

        return { valueColumn, series };
    });

    // Test whether two columns will format values the same way
    const valueColumnIsEquivalentTo = (columnA: StreamDataColumn) => {
        const columnAString = stringify(pick(columnA, 'shapeName', 'valueShapeName', 'rawShapeConfig'));

        return (columnB: StreamDataColumn) =>
            columnAString === stringify(pick(columnB, 'shapeName', 'valueShapeName', 'rawShapeConfig'));
    };

    const unitLabel = getUnitLabelValue(unitLabelColumnResult, data);

    return {
        unitLabel,
        valueColumn: allSeries
            .map((s) => s.valueColumn.column)
            .every(valueColumnIsEquivalentTo(allSeries[0].valueColumn.column))
            ? allSeries[0].valueColumn.column
            : undefined,
        series: allSeries.flatMap((c) => c.series)
    };
};

export const toSeries = (
    data: StreamData,
    config: DataStreamLineGraphConfig = {},
    options: ToSeriesOptions = { isDrilldownEnabled: true }
): SeriesData => {
    if (data.rows.length === 0) {
        return { series: [] };
    }

    const columns = getLinegraphColumns(data.metadata.columns, data.rows, config);

    if (!columns.success) {
        return { series: [] };
    }

    const { timestampColumn, valueColumns, labelColumnsResult, unitLabelColumnResult } = columns.value;

    const labelColumns = findLabelColumns(labelColumnsResult);
    const valueColumn = valueColumns.find((vc) => vc.column.name === config?.yAxisColumn) || valueColumns[0];

    if (
        labelColumns.length > 0 &&
        (typeof config?.yAxisColumn === 'string' || (config?.yAxisColumn?.length ?? 0) <= 1)
    ) {
        // the labels are in a column, so we group the first value column to get the series
        return getSeriesPerLabel(valueColumn, data, labelColumns, timestampColumn, unitLabelColumnResult, options);
    }

    return getSeriesPerValueColumn(valueColumns, labelColumns, data, timestampColumn, unitLabelColumnResult, options);
};


/**
 * Normalize stream data series into base LineSeries format
 * @param seriesData Stream data series
 * @returns Normalized LineSeries array without formatting
 */
export const normalizeSeriesData = (
    seriesData: StreamDataSeries[] | undefined
): LineSeries[] => {
    return seriesData?.map((s, idx) => {
        return {
            id: s.label,
            index: idx,
            drilldownUrl: s.drilldownUrl,
            data: orderBy(
                s.points.map((d) => {
                    return {
                        x: d.timestamp.getTime(),
                        y: d.value as number,
                        valueFormatted: d.valueFormatted
                    };
                }),
                'x'
            )
        };
    }) ?? [];
};

/**
 * Format series values with optional cumulative calculation
 * @param series Base LineSeries data
 * @param cumulative Whether to use cumulative values
 * @param formatTick Function to format the value
 * @returns Formatted LineSeries array
 */
export const formatSeriesValues = (
    series: LineSeries[],
    cumulative: boolean | undefined = false,
    formatTick: (value: unknown) => string
): LineSeries[] => {
    return series.map(s => {
        return {
            ...s,
            data: s.data.map(
                ((sum: number) => (d) => {
                    if (cumulative) {
                        sum += d.y;
                        return {
                            ...d,
                            y: sum,
                            valueFormatted: formatTick(sum)
                        };
                    }
                    return {
                        ...d,
                        valueFormatted: formatTick(d.y)
                    };
                })(0)
            )
        };
    });
};
