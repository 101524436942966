import Text from '@/components/Text';
import { Serialised } from '@squaredup/ids';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import Button from 'components/button/Button';
import { InlineTextEdit } from 'components/inlineTextEdit/InlineTextEdit';
import { InlineTextEditOverflowWrapper } from 'components/inlineTextEdit/InlineTextEditOverflowWrapper';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { DashboardVariableDropdown } from 'pages/dashboard/components/DashboardVariableDropdown';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Get as getDataStreamDefinition } from 'services/DataStreamDefinitionService';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import { useDraftSQLContext } from '../contexts/DraftSQLContext';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { DataStreamTileEditorTileOptions } from './DataStreamTileEditorTileOptions';
import { AnalyticsToggle } from './analytics/AnalyticsToggle';
import { useTileEditorSave } from './hooks/useTileEditorSave';
import { useDataStreamType } from './hooks/useDataStreamType';

export const DataStreamTileEditorHeader: React.FC = () => {
    const { savedTileConfig, tileConfig, containsError, isEdited, isDatasetMode, onClose } = useTileEditorContext();
    const [confirmDiscard, setConfirmDiscard] = useState(false);
    const queryClient = useQueryClient();
    const currentWorkspaceID = useCurrentWorkspaceId();
    const { hasQueryChanged, draftQuery } = useDraftSQLContext();

    const { variables = [] } = useDashboardContext();

    const [tileTitle, setTileTitle] = useState(tileConfig.title ?? '');
    const [tileDescription, setTileDescription] = useState(tileConfig.description ?? '');
    const { dataStreamType } = useDataStreamType(tileConfig);

    useEffect(() => setTileTitle(tileConfig.title ?? ''), [tileConfig.title]);
    useEffect(() => setTileDescription(tileConfig.description ?? ''), [tileConfig.description]);

    const saveTile = useTileEditorSave();

    const currentTileConfig = { ...tileConfig, title: tileTitle.trim(), description: tileDescription.trim() };

    const isConfigurableDataStream = dataStreamType && dataStreamType.isConfigurable;

    const onSave = async () => {
        let title = tileTitle.trim();
        const dataStreamId = tileConfig.dataStream?.id;

        // If the saved config already has a data stream, we don't want to overwrite the title if it's empty.
        // Otherwise we assume this a new tile and if there is no title set, automatically set it to the name
        // of the selected data stream.
        if (!savedTileConfig.dataStream && !title && dataStreamId) {
            // See if we've got the data stream definition already in the cache
            const dataStreamDefinitions = queryClient.getQueryData<Serialised<ProjectedDataStreamDefinitionEntity>[]>(
                dataStreamDefinitionQueryKeys.forWorkspace(currentWorkspaceID, { includeTemplates: true })
            );
            let dataStreamDefinition = dataStreamDefinitions?.find((d) => d.id === dataStreamId);

            // If we can't find it in the cache, fetch the definition
            if (!dataStreamDefinition) {
                dataStreamDefinition = await getDataStreamDefinition(dataStreamId);
            }
            if (dataStreamDefinition) {
                title = dataStreamDefinition.displayName;
            }
        }

        const updatedTileData = isDatasetMode
            ? {
                  ...tileConfig,
                  dataStream: {
                      ...tileConfig.dataStream,
                      dataSourceConfig: {
                          ...tileConfig.dataStream?.dataSourceConfig,
                          sql: draftQuery
                      }
                  }
              }
            : tileConfig;

        return saveTile({
            ...updatedTileData,
            description: tileDescription,
            title
        });
    };

    return (
        <>
            {/* Capture initial focus in this element, so the tile title isn't immediately in focus.
             *  If the list of data streams has been loaded before, then focus is automatically placed
             *  on the data stream filter input
             */}
            <header
                className='flex items-center pl-[38px] pr-[31px] py-[16px] gap-x-xxl bg-componentBackgroundSecondary text-textPrimary border-b border-dividerTertiary'
                tabIndex={0}
                data-theme='dark'
            >
                <div className='w-full space-y-0.5 overflow-hidden whitespace-nowrap text-ellipsis'>
                    <Text.H4>
                        <InlineTextEdit
                            changeTrigger='keyDown'
                            value={tileTitle}
                            onChange={(v) => setTileTitle(v ?? undefined)}
                            placeholder='Click to add a title'
                            displayComponent={
                                <InlineTextEditOverflowWrapper value={tileTitle} placeholder='Click to add a title' />
                            }
                        />
                    </Text.H4>

                    <p 
                        className='text-sm leading-[17px]'
                        data-testid='tileDescription'
                    >
                        <InlineTextEdit
                            changeTrigger='keyDown'
                            value={tileDescription}
                            onChange={(v) => setTileDescription(v ?? undefined)}
                            placeholder='Click to add a description'
                            displayComponent={
                                <InlineTextEditOverflowWrapper
                                    value={tileDescription}
                                    placeholder='Click to add a description'
                                />
                            }
                        />
                    </p>
                </div>
                <div className='flex items-center justify-end space-x-4'>
                    {variables.length > 0 && (
                        <>
                            {variables.map((variable) => (
                                <DashboardVariableDropdown
                                    key={variable.id}
                                    variable={variable}
                                    disabled={!tileConfig.variables?.length && !isConfigurableDataStream}
                                    theme='dark'
                                />
                            ))}
                            <div className='border-r-2 border-dividerPrimary h-[25px]'></div>
                        </>
                    )}

                    <AnalyticsToggle />
                    
                    <div className='border-r-2 border-dividerPrimary h-[25px]'></div>

                    <Button
                        variant='secondary'
                        onClick={() => (isEdited ? setConfirmDiscard(true) : onClose())}
                        data-testid='tileEditorClose'
                    >
                        {isEdited ? 'Discard' : 'Close'}
                    </Button>

                    <Button
                        variant='primary'
                        disabled={!(isEdited || hasQueryChanged) || containsError}
                        onClick={() => onSave()}
                        data-testid='tileEditorSave'
                    >
                        Save
                    </Button>

                    <DataStreamTileEditorTileOptions tileConfig={currentTileConfig} />
                </div>
            </header>

            {confirmDiscard && (
                <ConfirmationPrompt
                    title='Discard Changes'
                    prompt="Any changes you've made will be lost"
                    onConfirm={() => onClose()}
                    onClose={() => setConfirmDiscard(false)}
                    confirmButtonText='Discard'
                    confirmButtonVariant='destructive'
                />
            )}
        </>
    );
};
