import { cn } from '@/lib/cn';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isDefined } from '@squaredup/utilities';
import { useGraph, useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyEdgeAttributes } from 'components/map/data/types';
import { useNodeHealth } from 'components/map/hooks/useNodeHealth';
import { healthStateTextColors } from 'constants/state';
import { mapBackgroundColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { EdgeLabelRenderer, EdgeProps, getStraightPath } from 'reactflow';
import { strokeHealthStateStyles } from '../nodes/common/health';

export const FLOATING_EDGE = 'floatingEdge';

export const FloatingEdge = ({ id, source, target, style, data }: EdgeProps<GraphologyEdgeAttributes>) => {
    const background = useNetworkBackground();

    const graph = useGraph();

    const targetNode = graph.getNodeAttributes(target);
    const sourceNode = graph.getNodeAttributes(source);

    const targetHealthState = useNodeHealth(
        targetNode.type === 'groupNode'
            ? targetNode.groupedData?.nodes.map((n) => n.sourceId?.[0]).filter(isDefined)
            : targetNode.data?.sourceId?.[0]
    );

    const label = data?.label;

    if (
        sourceNode?.x === undefined ||
        targetNode?.x === undefined ||
        !sourceNode?.width ||
        !targetNode?.width ||
        sourceNode?.hidden ||
        targetNode?.hidden
    ) {
        return null;
    }

    const { x: sourceX, y: sourceY, width: sourceWidth, height: sourceHeight } = sourceNode;
    const { x: targetX, y: targetY, width: targetWidth, height: targetHeight } = targetNode;

    const sourceCenter = {
        x: sourceX + sourceWidth! / 2,
        y: sourceY + sourceHeight! / 2
    };

    const targetCenter = {
        x: targetX + targetWidth! / 2,
        y: targetY + targetHeight! / 2
    };

    let dx = targetCenter.x - sourceCenter.x;
    let dy = targetCenter.y - sourceCenter.y;
    const edgeLength = Math.sqrt(dx ** 2 + dy ** 2);

    const targetDistanceFromTheEndRatio = (edgeLength - targetWidth! / 2) / edgeLength;
    let targetEdgeX = sourceCenter.x + dx * targetDistanceFromTheEndRatio;
    let targetEdgeY = sourceCenter.y + dy * targetDistanceFromTheEndRatio;

    const sourceDistanceFromTheEndRatio = sourceWidth! / 2 / edgeLength;
    let sourceEdgeX = sourceCenter.x + dx * sourceDistanceFromTheEndRatio;
    let sourceEdgeY = sourceCenter.y + dy * sourceDistanceFromTheEndRatio;

    let [path, labelX, labelY] = getStraightPath({
        sourceX: sourceEdgeX,
        sourceY: sourceEdgeY,
        targetX: targetEdgeX,
        targetY: targetEdgeY
    });

    const labelAngle = Math.abs(
        180 - Math.atan2(targetCenter.x - sourceCenter.x, targetCenter.y - sourceCenter.y) * (180 / Math.PI)
    );
    const reversedLabel = labelAngle <= 180;

    return (
        <>
            <path
                id={id}
                d={path}
                markerEnd={`url(#arrow-head-${background}-${targetHealthState}`}
                markerWidth={8}
                fill='none'
                strokeWidth={2}
                className={cn(
                    'text-dividerSecondary transition-[stroke] duration-700 stroke-current',
                    strokeHealthStateStyles[targetHealthState]
                )}
                strokeDasharray='4 3'
                {...(style && { style })}
            />

            {label && (
                <EdgeLabelRenderer>
                    <div
                        className={cn(
                            'absolute text-xs origin-center text-[7px] font-bold',
                            targetHealthState && targetHealthState !== 'unmonitored'
                                ? healthStateTextColors[targetHealthState]
                                : 'text-statusUnknownPrimary'
                        )}
                        style={{ transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)` }}
                    >
                        <span
                            className={cn(
                                'inline-flex space-x-1 items-center align-middle px-1 leading-none origin-center',
                                mapBackgroundColors[background]
                            )}
                            style={{ transform: `rotate(${labelAngle - (reversedLabel ? 90 : -90)}deg)` }}
                        >
                            {!reversedLabel && <FontAwesomeIcon icon={faCaretLeft} />}
                            <span className='h-[1em] leading-none'>{label}</span>
                            {reversedLabel && <FontAwesomeIcon icon={faCaretRight} />}
                        </span>
                    </div>
                </EdgeLabelRenderer>
            )}
        </>
    );
};
