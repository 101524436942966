import { createContext, useContext } from 'react';
import { type TileEditorStepsValue } from '../hooks/useTileEditorSteps';

const TileEditorStepsContext = createContext<TileEditorStepsValue>({
    currentEditorStep: undefined,
    editorSteps: [],
    canMoveToNextStep: false,
    isConfiguredAfterCurrentStep: false,
    setCurrentEditorStep: () => undefined,
    nextStep: () => undefined
});

TileEditorStepsContext.displayName = 'TileEditorStepsContext';

export const useTileEditorStepsContext = () => useContext(TileEditorStepsContext);

export default TileEditorStepsContext;
