import { getTimeframeLabel, type TimeframeEnumValue } from '@squaredup/timeframes';
import LoadingSpinner from 'components/LoadingSpinner';
import DashboardContext from 'contexts/DashboardContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { DataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { MutableRefObject, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { AnalyticsQueryEditor } from '../analytics/AnalyticsQueryEditor';
import { EditorSteps } from '../constants';
import TileEditorStepsContext from '../contexts/TileEditorStepsContext';
import { useObjectFilterObjects } from '../hooks/objectFilters/useObjectFilterObjects';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { useScopeBaseQuery } from '../hooks/useDataStreamObjectFilters';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { useDataStreamType } from '../hooks/useDataStreamType';
import { StepWithCondition, useTileEditorSteps } from '../hooks/useTileEditorSteps';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { DataStreamConfigurationStep } from './DataStreamConfiguration/DataStreamConfigurationStep';
import { DataStreamConfigurationStepSubLabel } from './DataStreamConfiguration/DataStreamConfigurationStepSubLabel';
import { DataStreamDisplayName } from './DataStreamDisplayName';
import { DataStreamMetadata } from './DataStreamMetadata';
import { DataStreamObjectsSublabel } from './DataStreamObjectsSublabel';
import { DataStreamShapingLabels } from './DataStreamShapingLabels';
import { DataStreamStep } from './DataStreamStep';
import { countModifiedColumns } from './MetadataEditor/MetadataEditorTileState';
import { ObjectsStep } from './ObjectsStep';
import { ShapingStep } from './ShapingStep';
import { TimeframeStep } from './TimeframeStep';

const DSO_LINKED_PARAM = 'datastream';

const getTimeframeSteplabel = (
    supportedTimeframes: boolean | TimeframeEnumValue[],
    inheritedTimeframe: TimeframeEnumValue,
    configTimeframe?: TimeframeEnumValue
) => {
    if (supportedTimeframes === false) {
        return 'Not supported';
    }

    if (configTimeframe) {
        if (configTimeframe === 'none') {
            return 'None';
        }
        return `${getTimeframeLabel(configTimeframe, 'nonrelative')} (Fixed)`;
    }

    return `${getTimeframeLabel(inheritedTimeframe, 'nonrelative')} (Dashboard)`;
};

const getInitialEditorStep = (dataStreamType: DataStreamType) => {
    let step = undefined;
    
    if (dataStreamType.isConfigurable) {
        step = EditorSteps.parameters;
    }

    if (dataStreamType.supportsScope) {
        step = EditorSteps.objects;
    }

    return step;
};

interface TileEditorStepsContextWrapperProps {
    previouslyDocked?: MutableRefObject<boolean>;
    onDockEditorStep?: () => void;
    onReleaseEditorStep?: () => void;
}
export const TileEditorStepsContextWrapper: React.FC<TileEditorStepsContextWrapperProps> = ({ 
    previouslyDocked,
    onDockEditorStep,
    onReleaseEditorStep, 
    children 
}) => {
    const [searchParams] = useSearchParams();
    const isLinkedFromDSO = Boolean(searchParams.get(DSO_LINKED_PARAM));

    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { timeframe: inheritedTimeframe } = useContext(DashboardContext);

    const { config: datasetConfig, setConfig: setDatasetConfig, isEditingSQL } = useDatasetContext();
    const { data: datasetData, isConfigured } = useDataStreamConfig(datasetConfig);
    const { data: tileData } = useDataStreamConfig(tileConfig);
    const supportedTimeframes = useDataStreamSupportedTimeframes(datasetConfig.dataStream?.id);
    const { dataStreamType, isLoading } = useDataStreamType(datasetConfig);

    const { dataStreamState, scope, storeCurrentStep, workspaceId, dispatch } = useTileEditorStore((s) => ({
        storeCurrentStep: s.currentStep,
        dataStreamState: s.dataStream,
        scope: s.scope,
        workspaceId: s.dashboard.workspaceId,
        dispatch: s.dispatch
    }));

    const dataStreamFilters = useDataStreamFilters({ dispatch, ...dataStreamState });

    const { scopeBaseQuery, isLoading: isLoadingScopeBaseQuery } = useScopeBaseQuery(
        dataStreamState.selectedDataStream,
        undefined
    );

    const {
        isFetchingObjects,
        isPreviousData,
        count: objectCount
    } = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: {},
        isFilterQueryReady: !isLoadingScopeBaseQuery && dataStreamState.selectedDataStream != null && !scope.isDynamic,
        options: { keepPreviousData: false }
    });

    const hasDataStream = Boolean(datasetConfig.dataStream);
    const isConfigurableDataStream = dataStreamType && dataStreamType.isConfigurable;

    const hasScope = Object.keys(datasetConfig.scope || {}).length > 0;

    const editorSteps: StepWithCondition[] = [
        {
            name: EditorSteps.dataStream,
            isEnabled: !isLoading && !isEditingSQL,
            sublabel: <DataStreamDisplayName />,
            component: <DataStreamStep dataStreamFilters={dataStreamFilters} />
        },
        {
            name: EditorSteps.objects,
            isEnabled:
                dataStreamType.supportsScope &&
                (scope.isDynamic || storeCurrentStep === EditorSteps.objects ||
                    (!isLoading &&
                        !isPreviousData &&
                        hasDataStream &&
                        objectCount != null &&
                        // We might be about to auto-select if we're still loading objects
                        !(isFetchingObjects && scope.type === 'none') &&
                        !(
                            !isFetchingObjects &&
                            scope.type === 'objectList' &&
                            objectCount === 1 &&
                            scope.selectedNodeIds.length === 1
                        ))),
            sublabel: !hasDataStream ? (
                '-'
            ) : isFetchingObjects && storeCurrentStep !== EditorSteps.objects && !datasetConfig.scope ? (
                <LoadingSpinner size={24} />
            ) : (
                <DataStreamObjectsSublabel
                    scopeState={scope}
                    scopeConfig={datasetConfig.scope ?? []}
                    workspaceId={workspaceId}
                />
            ),
            component: <ObjectsStep />
        },
        {
            name: EditorSteps.parameters,
            isEnabled: 
                !isLoading && hasDataStream && isConfigurableDataStream && (hasScope || !dataStreamType.supportsScope),
            sublabel: isConfigurableDataStream ? <DataStreamConfigurationStepSubLabel /> : '-',
            component: <DataStreamConfigurationStep />
        },
        {
            name: EditorSteps.timeframe,
            isEnabled: !isLoading && hasDataStream && supportedTimeframes !== false && isConfigured,
            sublabel: getTimeframeSteplabel(supportedTimeframes, inheritedTimeframe, datasetConfig.timeframe),
            component: <TimeframeStep />
        },
        {
            name: EditorSteps.shaping,
            isEnabled: !isLoading && hasDataStream && isConfigured,
            sublabel: <DataStreamShapingLabels />,
            component: <ShapingStep />
        },
        {
            name: EditorSteps.metadata,
            isEnabled: !isLoading && hasDataStream && isConfigured,
            sublabel: (() => {
                const numberOfModifiedColumns = countModifiedColumns(
                    datasetConfig.dataStream?.metadata,
                    datasetData.metadata.columns.map((c) => c.name)
                );

                return numberOfModifiedColumns > 0 ? `${numberOfModifiedColumns} configured` : '';
            })(),
            component: <DataStreamMetadata 
                config={datasetConfig} 
                setConfig={setDatasetConfig} 
            />
        },
        {
            name: EditorSteps.query,
            isEnabled: isEditingSQL,
            sublabel: <span>Enter your query</span>,
            component: <AnalyticsQueryEditor /> 
        },
        {
            name: EditorSteps.tileMetadata,
            isEnabled: isEditingSQL,
            sublabel: (() => {
                const numberOfModifiedColumns = countModifiedColumns(
                    tileConfig.dataStream?.metadata,
                    tileData.metadata.columns.map((c) => c.name)
                );

                return numberOfModifiedColumns > 0 ? `${numberOfModifiedColumns} configured` : '';
            })(),
            component: <DataStreamMetadata 
                config={tileConfig} 
                setConfig={setTileConfig} 
            />
        }
    ];

    const stepsValue = useTileEditorSteps(
        editorSteps,
        isLinkedFromDSO ? 
            getInitialEditorStep(dataStreamType) : 
            undefined,
        isConfigured,
        isEditingSQL,
        previouslyDocked,
        dataStreamType,
        onDockEditorStep,
        onReleaseEditorStep,
        (newStep) => {
            if (newStep !== storeCurrentStep) {
                dispatch({ type: 'setActiveStep', step: newStep as EditorSteps });
            }
        }
    );

    return (
        <TileEditorStepsContext.Provider value={stepsValue}>
            {children}
        </TileEditorStepsContext.Provider>
    );
};
