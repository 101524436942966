import { isValidFrameSrcEntry } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { useTenant } from 'queries/hooks/useTenant';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { IsTenantAdmin } from 'services/AccessControlService';
import { TenantResponse, UpdateTenant, UpdateTenantSetting } from 'services/TenantService';
import { SettingsTemplate } from '../SettingsTemplate';

interface OrganisationFormValues {
    displayName: string;
    allowedDomains: string[];
}

function OrganisationForm({ tenant }: { tenant: TenantResponse }) {
    const isAdmin = IsTenantAdmin(tenant);

    const formProps = useForm<OrganisationFormValues>({
        defaultValues: {
            displayName: tenant.displayName,
            allowedDomains: tenant.settings?.cspFrameSrcEntries
        }
    });

    const queryClient = useQueryClient();

    const { mutateAsync, isLoading } = useMutation(
        async (data: OrganisationFormValues) => {
            const { displayName, allowedDomains } = data;
            await UpdateTenant({ displayName });
            await UpdateTenantSetting('cspFrameSrcEntries', allowedDomains);
            return data;
        },
        {
            onSettled: (res) => {
                queryClient.invalidateQueries([tenantQueryKeys.tenant]);
                formProps.reset(res);
            }
        }
    );

    if (!isAdmin) {
        return (
            <dl>
                <dt className='font-medium leading-6'>Name</dt>
                <dd className='mt-1 text-lg leading-6 text-textSecondary'>{tenant.displayName}</dd>
                <dt className='mt-6 font-medium leading-6'>Allowed domains for embedding</dt>
                <dd className='mt-1 text-lg leading-6 text-textSecondary'>
                    {tenant.settings?.cspFrameSrcEntries?.join(', ') || 'None'}
                </dd>
            </dl>
        );
    }

    return (
        <FormProvider {...formProps}>
            <form className='max-w-4xl' onSubmit={formProps.handleSubmit((data) => mutateAsync(data))}>
                <Field.Input
                    disabled={isLoading}
                    name='displayName'
                    label='Name'
                    data-testid='displayName'
                    validation={{
                        required: true,
                        maxLength: { value: 128, message: "Name can't be longer than 128 characters" }
                    }}
                />
                <Field.Input
                    isDisabled={isLoading}
                    name='allowedDomains'
                    description={
                        <>
                            Configure domains allowed for use with the Embed tile, e.g. *.squaredup.com.{' '}
                            <a
                                className='text-textLink'
                                href='https://docs.squaredup.com/first-steps/embed-tile'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Learn more
                            </a>
                        </>
                    }
                    label='Allowed domains for embedding'
                    type='autocomplete'
                    title='Allowed domains for embedding'
                    noOptionsMessage={() => 'Enter one or more domains'}
                    isMulti={true}
                    isClearable={false}
                    formatCreateLabel={(l: string) => `Add ${l.toLowerCase()}`}
                    onCreate={(v: string) => ({
                        label: v.toLowerCase(),
                        value: v.toLowerCase(),
                        isNew: true
                    })}
                    placeholder='Enter one or more domains'
                    isValidNewOption={(value: string) => value && isValidFrameSrcEntry(value)}
                    selectOptionsAs='valueString'
                    descriptionAbove={true}
                    validation={{
                        validate: (value: string[]) => {
                            if ((value || []).join(',').length > 500) {
                                return 'Domain list cannot exceed 500 characters. Try to use wildcards, e.g. *.squaredup.com';
                            }
                            return true;
                        }
                    }}
                />

                {formProps.getFieldState('allowedDomains').isDirty &&
                    !formProps.getFieldState('allowedDomains').invalid && (
                        <p className='pt-4 text-statusWarningPrimary'>
                            Warning: you may need to refresh the dashboard after modifying the allowed domains.
                        </p>
                    )}

                <Button
                    className='mt-8'
                    type='submit'
                    disabled={
                        isLoading || !formProps.formState.isDirty || formProps.getFieldState('allowedDomains').invalid
                    }
                >
                    {isLoading ? (
                        <>
                            <LoadingSpinner size={18} />
                        </>
                    ) : (
                        'Save'
                    )}
                </Button>
            </form>
        </FormProvider>
    );
}

export default function OrganisationPage() {
    const { data: tenant, isLoading } = useTenant({ refetchOnMount: 'always' });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!tenant) {
        return (
            <p>
                <strong>Oops!</strong> Something went wrong while loading your organization settings.
            </p>
        );
    }

    return (
        <SettingsTemplate title='Organization' description='Configure your organization settings.'>
            <OrganisationForm tenant={tenant} />
        </SettingsTemplate>
    );
}
