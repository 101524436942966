import { TooltipButton } from '@/components/Button';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGraph } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useHideNode } from 'components/map/hooks/useHideNode';

interface HideButtonProps {
    node: GraphologyNodeAttributes;
    className?: string;
    onClick?: () => void;
}

export const HideButton: React.FC<HideButtonProps> = ({ className, node, onClick }) => {
    const graph = useGraph();
    const hide = useHideNode();

    // We can't unpin the last pinned node
    if (!graph.someNode((id, n) => n.pinned && id !== node.id)) {
        return null;
    }

    return (
        <TooltipButton
            variant='tertiary'
            onClick={(e) => {
                hide(node);
                e.stopPropagation();
                onClick?.();
            }}
            title={'Hide'}
            className={className}
        >
            <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
        </TooltipButton>
    );
};
