import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import type { TileEditorDataStream } from '../TileEditorState';

export type DataStreamFilters = {
    dataSourceId?: string;
    objectType?: string;
    scopeId?: string;
    tag?: string;
    search?: string;
};

export type DataStreamState = {
    selectedDataStream?: TileEditorDataStream;
    filters: DataStreamFilters;
};

export const createDataStreamState = ({
    dataStreams,
    dataStreamConfig,
    defaultFilters,
    previousState
}: {
    dataStreams: TileEditorDataStream[];
    dataStreamConfig: DataStreamBaseTileConfig['dataStream'];
    defaultFilters?: Partial<DataStreamState['filters']>;
    previousState?: DataStreamState;
}): DataStreamState => {
    const selectedDataStream = dataStreams.find((ds) => ds.id === dataStreamConfig?.id);

    return {
        selectedDataStream,
        filters: {
            /**
             * Carry over the previous filters so they aren't lost
             * when recreating the state from tile config for a reset.
             * Initialize dataSourceId to datastream plugin id if it exists
             */
            ...selectedDataStream?.pluginId && { 
                dataSourceId: selectedDataStream.pluginId 
            },
            ...previousState?.filters,
            ...defaultFilters
        }
    };
};
