import { isDefined } from '@squaredup/utilities';
import { Query } from 'services/GraphService';
import { NodesAndEdges } from './types';

const nodeValues = [
    '__configId',
    '__canonicalType',
    'name',
    'sourceId',
    'tileName',
    'dashId',
    'tileId',
    'type',
    'sourceType',
    'state'
];

/**
 * Fetches all the connected nodes up/down stream from the given root objects.
 * E.g. if we have a root node which is a grandparent of another, this function
 * will fetch both the parent and child (more if we have other connections)
 *
 * @param {string[]} objectIds - The IDs of the root objects we're starting from.
 * @param {'out' | 'in'} direction - The direction to fetch the connected data ('out' for outgoing, 'in' for incoming).
 * @returns {Promise<NodesAndEdges | undefined>} A promise that resolves to the connected nodes and edges, or undefined if no data is found.
 */
export const fetchConnectedData = async (
    objectIds: string[],
    direction: 'out' | 'in'
): Promise<NodesAndEdges | undefined> => {
    const gremlinQuery = `g.V().has("id", within(objectIds))
    .aggregate('nodesVisited')
      .repeat(
          ${direction === 'out' ? 'in' : 'out'}E().aggregate('edgesVisited')
          .${direction}V().has("sourceType", without("squaredup/scope", "squaredup/tile"))
          .aggregate('nodesVisited')
        ).until(loops().is(32))
      .cap('nodesVisited').unfold().dedup().valueMap(true,${nodeValues
          .map((value) => `"${value}"`)
          .join()}).aggregate('nodes')
      .cap('edgesVisited').unfold().dedup().aggregate('edges')
      .cap('nodes', 'edges')`;

    const [graphResults] = (
        await Query(
            {
                gremlinQuery,
                bindings: { objectIds }
            },
            'directOrAnyWorkspaceLinks'
        )
    ).gremlinQueryResults;

    return {
        nodes: graphResults?.nodes?.filter(isDefined),
        edges: graphResults?.edges?.filter(isDefined)
    };
};

export const fetchMapData = async (
    objectIds?: string[],
    excludeObjectIds?: string[],
    times = 3
): Promise<NodesAndEdges | undefined> => {
    let gremlinQuery = 'g.V()';
    let bindings: Record<string, any> = {};

    // If we have no currentWorkspaceId and no objectIds we have no way of fetching
    // the correct data so simply return
    if (!objectIds) {
        return;
    }

    if (objectIds) {
        gremlinQuery += '.has("id", within(objectIds))';
        bindings.objectIds = objectIds;
    }

    gremlinQuery += `.aggregate("nodesVisited")
        .repeat(
            optional(
                inE("is").aggregate("edgesVisited").otherV().aggregate("nodesVisited")
                .bothE().aggregate("edgesVisited").otherV().aggregate("nodesVisited")
            )
            .bothE().aggregate("edgesVisited")
            .bothV()
                .simplePath()
                .has("sourceType", without("squaredup/scope", "squaredup/tile"))
                ${excludeObjectIds?.length ? '.has("id", without(excludeObjectIds))' : ''}
                .aggregate("nodesVisited")
            .optional(
                inE("is").aggregate("edgesVisited").otherV().simplePath().aggregate("nodesVisited")
                .bothE().aggregate("edgesVisited").otherV().aggregate("nodesVisited")
            )
        )
        .times(${times})
        .cap("edgesVisited").unfold().dedup().aggregate("edges")
        .cap("nodesVisited").unfold().dedup().valueMap(true,${nodeValues
            .map((value) => `"${value}"`)
            .join()}).aggregate("nodes")
        .cap("edges", "nodes")
    `;

    if (excludeObjectIds?.length) {
        bindings.excludeObjectIds = excludeObjectIds;
    }

    const [graphResults] = (
        await Query(
            {
                gremlinQuery,
                bindings
            },
            'directOrAnyWorkspaceLinks'
        )
    ).gremlinQueryResults;

    return graphResults;
};
