import { oneMinute } from 'queries/constants';
import { QueryClient, UseQueryOptions, useQuery } from 'react-query';
import { OA_SHARES_DATA } from 'services/OpenAccessService';
import { GetCountOfActiveShares } from 'services/UsageService';

type QueryReturnType = Awaited<ReturnType<typeof GetCountOfActiveShares>>;
type QuerySelectReturnType = QueryReturnType;

const queryKey = [OA_SHARES_DATA, 'ActiveCount'];

export const useActiveSharesCount = (
    options?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) =>
    useQuery({
        queryFn: GetCountOfActiveShares,
        queryKey,
        staleTime: oneMinute,
        cacheTime: oneMinute,
        ...options
    });

export const optimisticActiveShareCountUpdate = (queryClient: QueryClient, difference: number) => {
    const count = queryClient.getQueryData<QueryReturnType>(queryKey);
    if (count !== undefined) {
        queryClient.setQueryData(queryKey, count + difference);
    }
};
