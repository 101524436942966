import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { Dispatch, SetStateAction } from 'react';
import { DataSourceFilterDropdown } from '../dataStream/DataSourceFilterDropdown';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { DataStreamFilterMenu } from './DataStreamFilterMenu';
import { SearchFilter } from './SearchFilter';
import pluralize from 'pluralize';

interface DataStreamFiltersProps extends Pick<ReturnType<typeof useDataStreamFilters>, 'objectScopeFilterOptions' | 'pluginFilterOptions'> {
    scopes: any[];
    setShowAddDataSourceModal: Dispatch<SetStateAction<boolean>>;
    setScopeModalOpen: Dispatch<SetStateAction<boolean>>;
    setEditingScope: Dispatch<SetStateAction<undefined>>;
}

export const DataStreamFilters: React.FC<DataStreamFiltersProps> = ({ 
    objectScopeFilterOptions,
    scopes,
    pluginFilterOptions,
    setShowAddDataSourceModal,
    setScopeModalOpen
}) => {
    const { isGlobal } = useDataStreamWorkspaceContext();

    const { filters, dispatch } = useTileEditorStore((s) => ({
        filters: s.dataStream.filters,
        dispatch: s.dispatch
    }));

    const filtersApplied = Number(Boolean(filters.objectType)) + Number(Boolean(filters.scopeId));

    return (
        <div className='flex items-center justify-between py-2 pl-6 pr-5 gap-x-4 bg-componentTileBlock'>
            <DataSourceFilterDropdown
                sources={pluginFilterOptions}
                labelClassName='max-w-[120px]'
                selectedDataSourceId={filters.dataSourceId}
                setSelectedDataSourceId={(dataSourceId) =>
                    dispatch({ 
                        type: 'dataStream.updateFilters', 
                        filters: { dataSourceId } 
                    })
                }
                {...(!isGlobal && { onAddDataSource: () => setShowAddDataSourceModal(true) })}
            />

            <div className='flex items-center gap-2 ml-auto'>
                {Boolean(filtersApplied) &&
                    <p className='pr-2 text-textSecondary'>{filtersApplied} {pluralize('filters', filtersApplied)} active</p>
                }

                <SearchFilter />

                <DataStreamFilterMenu 
                    objectScopeFilterOptions={objectScopeFilterOptions}
                    scopes={scopes}
                    filters={filters}
                    filtersApplied={Boolean(filtersApplied)}
                    setScopeModalOpen={setScopeModalOpen}
                    dispatch={dispatch}
                />
            </div>
        </div>
    );
};
