import { cn } from '@/lib/cn';
import type { TileConfig } from '@squaredup/dashboards';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { DashboardId } from '@squaredup/ids';
import DashboardContext from 'contexts/DashboardContext';
import TileContext from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { motion, useInView } from 'framer-motion';
import { dashboardHasNoVariableObjectsSelected } from 'pages/dashboard/components/utils/variableDropdown';
import { SaveToDashboardModal } from 'pages/explore/SaveToDashboardModal';
import { TileState } from 'queries/types/types';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TileEditor } from './editor/TileEditor';
import { ConfigurationPrompt } from './editor/components/ConfigurationPrompt/ConfigurationPrompt';
import { useDashboardId } from './hooks/useDashboardId';
import { TileDataErrorFallBack } from './tile/TileDataErrorFallBack';
import { TileGenericErrorFallBack } from './tile/TileGenericErrorFallBack';
import { TileHeader } from './tile/tileHeader/TileHeader';

interface TileProps {
    id: string;
    title: string;
    description: string;
    children: JSX.Element;
    preview: boolean;
    config: TileConfig;
    supportsEditingTitle: boolean;
    draggable?: boolean;
    health?: TileState;
    isLoading?: boolean;
    onSave: (field: 'title' | 'description', value?: string) => void;
    onChange: (config: TileConfig) => void;
    onDelete?: () => void;
    onClone?: () => void;
    onDownloadAsImage?: () => Promise<void>;
    onOpenFullscreen?: (e: React.MouseEvent<HTMLElement>) => void;
    shouldHighlight?: boolean;
    onShouldHighlight?: (tileId?: string) => void;
}

function Tile({
    id: tileId,
    title,
    description,
    children,
    preview,
    health,
    config,
    supportsEditingTitle,
    draggable = true,
    isLoading,
    onSave,
    onChange,
    onDelete,
    onClone,
    onDownloadAsImage,
    onOpenFullscreen,
    onShouldHighlight,
    shouldHighlight
}: TileProps) {
    const dashboardId = useDashboardId();
    const { editing, variables } = useContext(DashboardContext);

    const tileReference = useRef<HTMLDivElement>(null);

    const isInView = useInView(tileReference, {
        once: true,
        amount: 0.5
    });

    const highlightVariants = {
        initial: {
            outline: '0px solid transparent'
        },
        animate: {
            outline: [
                '0px solid rgba(var(--blue-600), 0)',
                '3px solid rgba(var(--blue-600), 1)',
                '1px solid rgba(var(--blue-600), 0.3)',
                '0px solid rgba(var(--blue-600), 0)'
            ],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: 'easeInOut'
            }
        }
    };

    const [tileType, setTileType] = useState(config._type);
    const [tileToolbar, setTileToolbar] = useState<ReactNode>([]);
    const [tileData, setTileData] = useState<unknown[]>([]);
    const [tileProcessedData, setTileProcessedData] = useState<unknown[]>([]);
    const [tileEditingDrawerOpen, setTileEditingDrawerOpen] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const onEdit = () => setTileEditingDrawerOpen(true);
    const onClose = () => setTileEditingDrawerOpen(false);
    const onCopy = () => setIsCopying(true);

    useEffect(() => {
        if (shouldHighlight && tileReference.current) {
            const element = tileReference.current;
            // Scroll to tile after load
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

            if (isInView) {
                const timer = setTimeout(() => {
                    onShouldHighlight?.(undefined);
                }, 5000);
                return () => clearTimeout(timer);
            }
        }
    }, [onShouldHighlight, isInView, shouldHighlight]);

    useEffect(() => {
        setTileType(config._type);
    }, [config._type]);

    const noVariableObjectsSelected = dashboardHasNoVariableObjectsSelected(variables);

    const tileClassName = cn(
        'group/tile relative flex flex-col h-full overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
        !preview && 'px-4 py-2',
        editing && !preview && 'border border-tileOutline',
        !isLoading &&
            !editing &&
            noVariableObjectsSelected &&
            'variables' in config &&
            config.variables?.length &&
            'opacity-25',
        !(config?.noBackground || preview) && 'bg-tileBackground rounded-tileRadius'
    );
    return (
        <ErrorBoundary
            FallbackComponent={({ resetErrorBoundary }) => (
                <TileGenericErrorFallBack
                    className={tileClassName}
                    reset={() => {
                        // Reset the open dialogs to handle instances when the error originated there
                        setTileEditingDrawerOpen(false);
                        setIsCopying(false);
                        resetErrorBoundary();
                    }}
                />
            )}
        >
            <TileContext.Provider
                value={{
                    config,
                    tileId,
                    tileData,
                    tileProcessedData,
                    tileReference,
                    health,
                    preview,
                    isExporting,
                    setTileData,
                    setTileToolbar,
                    setTileProcessedData,
                    setIsExporting,
                    onEdit,
                    onClose,
                    onClone,
                    onCopy,
                    onDelete,
                    onChange
                }}
            >
                <motion.div
                    id={`tile-${tileId}`}
                    variants={highlightVariants}
                    initial='initial'
                    exit='initial'
                    animate={isInView && shouldHighlight ? 'animate' : 'initial'}
                    ref={tileReference}
                    data-tile={title}
                    className={tileClassName}
                >
                    {!config.noHeader && (
                        <TileHeader
                            tileId={tileId}
                            title={title}
                            description={description}
                            config={config}
                            editing={editing}
                            preview={preview}
                            health={health}
                            tileToolbar={tileToolbar}
                            draggable={draggable}
                            supportsEditingTitle={supportsEditingTitle}
                            onSave={onSave}
                            onDownloadAsImage={onDownloadAsImage}
                            onOpenFullscreen={onOpenFullscreen}
                        />
                    )}

                    <div className='h-full min-h-0 text-sm' data-testid='tileBody'>
                        <ErrorBoundary FallbackComponent={TileDataErrorFallBack}>
                            <ConfigurationPrompt
                                editing={editing}
                                isConfigured={Boolean(config?._type)}
                                setTileType={setTileType}
                            />
                            {children}
                        </ErrorBoundary>
                    </div>
                </motion.div>

                {tileEditingDrawerOpen && (
                    <TileEditor
                        config={config}
                        tileType={config._type || tileType || TileTypes.dataStream}
                        onClose={onClose}
                        tileId={tileId}
                    />
                )}

                {isCopying && tileType === TileTypes.dataStream && (
                    <SaveToDashboardModal
                        close={() => setIsCopying(false)}
                        config={config as DataStreamBaseTileConfig}
                        currentDashboardId={dashboardId as DashboardId['value']}
                    />
                )}
            </TileContext.Provider>
        </ErrorBoundary>
    );
}

Tile.propTypes = {};

export default Tile;
