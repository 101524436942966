import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import type { TimeframeEnumValue } from '@squaredup/timeframes';

export type TimeframeState =
    | {
          supportsTimeframes: true;
          isDashboardTimeframe: false;
          selectedTimeframe: Exclude<TimeframeEnumValue, 'none'>;
      }
    | {
          supportsTimeframes: true;
          isDashboardTimeframe: true;
      }
    | { supportsTimeframes: false; isDashboardTimeframe: false; selectedTimeframe: 'none' };

export const dashboardTimeframeState: TimeframeState = {
    supportsTimeframes: true,
    isDashboardTimeframe: true
};

export const createTimeframeState = ({
    timeframeConfig
}: {
    timeframeConfig?: DataStreamBaseTileConfig['timeframe'];
}): TimeframeState => {
    if (timeframeConfig == null) {
        return dashboardTimeframeState;
    }

    if (timeframeConfig === 'none') {
        return {
            supportsTimeframes: false,
            isDashboardTimeframe: false,
            selectedTimeframe: 'none'
        };
    }

    return {
        supportsTimeframes: true,
        isDashboardTimeframe: false,
        selectedTimeframe: timeframeConfig
    };
};
