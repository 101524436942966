import type { BarChartCategories } from 'dashboard-engine/visualisations/DataStreamBarChart/Config';
import { roundToNearestMinute } from 'dashboard-engine/visualisations/DataStreamBarChart/dataUtils';
import { min } from 'date-fns';
import { mapValues, uniq } from 'lodash';

type DateComponentName = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond';

type DateComponent = {
    name: DateComponentName;
    type: 'date' | 'time';
    getValue: (d: Date) => number;
    getUTCValue: (d: Date) => number;
};

const dateComponents: DateComponent[] = [
    {
        name: 'year',
        type: 'date',
        getValue: (d) => d.getFullYear(),
        getUTCValue: (d) => d.getUTCFullYear()
    },
    {
        name: 'month',
        type: 'date',
        getValue: (d) => d.getMonth(),
        getUTCValue: (d) => d.getUTCMonth()
    },
    {
        name: 'day',
        type: 'date',
        getValue: (d) => d.getDate(),
        getUTCValue: (d) => d.getUTCDate()
    },
    {
        name: 'hour',
        type: 'time',
        getValue: (d) => d.getHours(),
        getUTCValue: (d) => d.getUTCHours()
    },
    {
        name: 'minute',
        type: 'time',
        getValue: (d) => d.getMinutes(),
        getUTCValue: (d) => d.getUTCMinutes()
    },
    {
        name: 'second',
        type: 'time',
        getValue: (d) => d.getSeconds(),
        getUTCValue: (d) => d.getUTCSeconds()
    }
];

type DateComponentAnalysis =
    | {
          /**
           * Does this component change at all between ticks?
           */
          differs: true;
          /**
           * Does this component have different values for every tick?
           */
          isUnique: true;
          /**
           * The number of times the value of the date component
           * changes from one tick to the next.
           */
          numberOfValueChanges: number;
      }
    | {
          differs: false;
          isUnique: false;
          numberOfValueChanges: 0;
      }
    | {
          differs: true;
          isUnique: false;
          numberOfValueChanges: number;
      };

type DateRangeAnalysis = {
    [k in DateComponentName]: DateComponentAnalysis;
};

const analyseDateRangeComponent = (values: number[]): DateComponentAnalysis => {
    const numberOfValueChanges =
        uniq(values).length === 1
            ? 0
            : values.reduce((count, v, i) => {
                  if (i === 0) {
                      return 0;
                  }

                  return count + (v !== values[i - 1] ? 1 : 0);
              }, 0);

    if (numberOfValueChanges === 0) {
        return {
            isUnique: false,
            numberOfValueChanges,
            differs: false
        };
    }

    return {
        isUnique: numberOfValueChanges === values.length,
        numberOfValueChanges,
        differs: true
    };
};

/**
 * Determine which date/time components vary across a set of dates.
 */
const analyseDateRange = (dates: Date[], timeZone: 'UTC' | 'local'): DateRangeAnalysis => {
    const componentValues = dates.reduce(
        (a, d) => {
            dateComponents.forEach((c) => a[c.name].push(timeZone === 'UTC' ? c.getUTCValue(d) : c.getValue(d)));

            return a;
        },
        {
            /**
             * Always highlight when the date isn't the current date
             */
            year: [],
            month: [],
            day: [],
            hour: [],
            minute: [],
            second: [],
            millisecond: []
        } as {
            [k in DateComponentName]: number[];
        }
    );

    const analysis: DateRangeAnalysis = mapValues(componentValues, analyseDateRangeComponent);

    return analysis;
};

const getVisibleDateComponents = (
    range: DateRangeAnalysis
): { primaryComponent: DateComponent; secondaryComponents: DateComponent[] } => {
    /**
     * The primary date component is the largest component which is the closest to being unique
     * for every data point.
     *
     * If the primary isn't totally unique, or crosses a higher component boundary, a combination of
     * date components is needed (e.g. 2 months worth of daily data will have 2 copies of every
     * date value, but combined with the month component they are unique).
     *
     * We break these down into the component closest to being unique (the primary), and the
     * other component(s) which differ across the range (the secondaries).
     * E.g. for daily data crossing a month boundary 'day' is primary and 'month' is secondary.
     *
     * Primary and secondary components get rendered on every tick. Other components don't get
     * rendered at all because they don't change. Exceptions are months which are always included
     * with the date, and hours and minutes which are always rendered together.
     *
     * In the event of a tie we take the smaller component so if we have daily data where each point
     * has a different timestamp we include the timestamp in the formatted string.
     */
    const primaryComponent = dateComponents.reduce((mostUnique, c) => {
        if (range[c.name].numberOfValueChanges >= range[mostUnique.name].numberOfValueChanges) {
            return c;
        }

        return mostUnique;
    });

    /**
     * Time components can't be secondary because we always render the whole time for any primary
     * time component.
     */
    const secondaryComponents = dateComponents.filter(
        (c) => c.type === 'date' && c !== primaryComponent && range[c.name].differs
    );

    return { primaryComponent, secondaryComponents };
};

const getComponentFormatOptions = (
    component: DateComponent,
    variant: 'primary' | 'secondary'
): Intl.DateTimeFormatOptions => {
    switch (component.name) {
        case 'year':
            return { year: 'numeric' };

        case 'month':
            return variant === 'secondary' ? { month: '2-digit' } : { month: 'short' };

        case 'day':
            return { month: '2-digit', day: '2-digit' };

        case 'hour':
            return { hour: '2-digit', minute: '2-digit' };

        case 'minute':
            return { hour: '2-digit', minute: '2-digit' };

        case 'second':
            return { hour: '2-digit', minute: '2-digit', second: '2-digit' };

        default:
            throw new Error('Unknown component: ' + component?.name);
    }
};

const getFormatOptions = (
    primaryComponent: DateComponent,
    secondaryComponents: DateComponent[],
    timeZone?: string
): Intl.DateTimeFormatOptions => {
    return {
        ...Object.assign({}, ...secondaryComponents.map((c) => getComponentFormatOptions(c, 'secondary'))),
        ...getComponentFormatOptions(primaryComponent, 'primary'),
        /**
         * Ignore the time zone for monthly and yearly data, it'll just get confusing
         * if the time difference causes us to change from one month or year to another.
         */
        timeZone: primaryComponent.name === 'month' || primaryComponent.name === 'year' ? 'UTC' : timeZone
    };
};

/**
 * Create a function which formats the given date ticks.
 * @param ticks The ticks to format.
 * @param timeZone The name of the timezone the date should be formatted in. (Usually used for testing)
 * @param locale The name of the locale the ticks should be formatted in. (Usually used for testing)
 */
export const getDateTickFormatter = (ticks: Date[], locale?: string, timeZone?: string): ((date: Date) => string) => {
    if (ticks.length === 0) {
        return () => '';
    }

    const dateRangeAnalysis = analyseDateRange(ticks, 'local');

    const localVisibleDateComponents = getVisibleDateComponents(dateRangeAnalysis);

    /**
     * If we have yearly or monthly data, we format everything in UTC to avoid showing
     * ticks like '31/12, 30/01' instead of 'Jan, Feb' when in a time zone offset from UTC.
     */
    const useUtc =
        ['year', 'month'].includes(localVisibleDateComponents.primaryComponent.name) ||
        (localVisibleDateComponents.primaryComponent.name === 'day' &&
            !localVisibleDateComponents.secondaryComponents.some((c) => c.type === 'time'));

    const { primaryComponent, secondaryComponents } = useUtc
        ? getVisibleDateComponents(analyseDateRange(ticks, 'UTC'))
        : localVisibleDateComponents;

    /**
     * If we're showing data from the past, be sure that information is
     * included in the formatted values.
     */

    // Define the current date with full time information
    const now = new Date();

    // Create a date object set to the start of today (00:00:00) in the appropriate timezone
    const todayStart = useUtc
        ? new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))
        : new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const minTick = min(ticks);

    // Check if any tick is from a previous year
    const startsBeforeThisYear = useUtc
        ? now.getUTCFullYear() > minTick.getUTCFullYear()
        : now.getFullYear() > minTick.getFullYear();

    // Check if any tick is from before today
    const startsBeforeToday = minTick ? minTick.getTime() < todayStart.getTime() : false;

    if (
        startsBeforeThisYear &&
        primaryComponent.name !== 'year' &&
        !secondaryComponents.some((c) => c.name === 'year')
    ) {
        secondaryComponents.push(dateComponents.find((c) => c.name === 'year')!);
    } else if (
        startsBeforeToday &&
        primaryComponent.type === 'time' &&
        !secondaryComponents.some((c) => c.name === 'day')
    ) {
        secondaryComponents.push(dateComponents.find((c) => c.name === 'day')!);
    }

    return (inputDate: Date) => {
        return (
            new Intl.DateTimeFormat(
                locale,
                getFormatOptions(primaryComponent, secondaryComponents, useUtc ? 'UTC' : timeZone)
            )
                .format(inputDate)
                /**
                 * Intl.DateTimeFormat often puts a comma between the date and time, which we don't want.
                 */
                .replaceAll(', ', ' ')
        );
    };
};

/**
 * @returns Format a singular bar chart tick (category) as a date
 */
export const formatTickAsDate = (categories: BarChartCategories, locale?: string, timeZone?: string) => {
    const computedTickFormatter = getDateTickFormatter(
        categories.filter((d): d is string => Boolean(d)).map((d) => new Date(d)),
        locale,
        timeZone
    );

    return (category: string | number | Date) => computedTickFormatter(roundToNearestMinute(new Date(category)));
};
