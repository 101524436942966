import type { OpenAccessShare } from 'dynamo-wrapper';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { OA_SHARES_DATA, UpdateShare } from 'services/OpenAccessService';

type Input = { id: OpenAccessShare['id']['value']; requireAuth: boolean; enabled: boolean };
type Option = UseMutationOptions<any, unknown, Input>;

/**
 * useMutation to update an existing sharing link for a dashboard.
 * @param options The useMutation options e.g. refreshInterval
 * @returns a mutation variable
 */
export const useUpdateDashboardSharingLink = (option?: Option) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...option,
        mutationFn: async (props: Input) => {
            return UpdateShare(props.id, props.enabled, props.requireAuth, []);
        },
        onSettled: () => {
            queryClient.invalidateQueries(OA_SHARES_DATA);
        }
    });
};
