import { getDataStreamRetryAfter, getDataStreamRetryCount } from 'lib/retryAfter';
import { QueryKey, useQuery, useQueryClient } from 'react-query';

/**
 * Custom hook to determine if a query is currently awaiting a retry.
 *
 * @param queryKey - The unique key for the query to monitor.
 * @returns A boolean indicating if the query is in a retrying state.
 */
export const useIsRetryingQuery = (queryKey: QueryKey) => {
    const queryClient = useQueryClient();
    const result = useQuery(queryKey, async () => undefined, { enabled: false });
    const retryCount = getDataStreamRetryCount(queryClient, queryKey);
    const isRetrying = Boolean(getDataStreamRetryAfter(result, retryCount));

    return isRetrying;
};
