import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { Serialised } from '@squaredup/ids';
import { hasHandlebarsPlaceholders } from '@squaredup/utilities';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { useMemo } from 'react';

export const useParametersContext = (
    config: DataStreamBaseTileConfig,
    dataStreamDefinition?: Serialised<ProjectedDataStreamDefinitionEntity>
) => {
    const { variables = [], currentWorkspace } = useDashboardContext();

    return useMemo(() => {
        const sendParameters =
            (dataStreamDefinition?.template || dataStreamDefinition?.id === 'datastream-sql') &&
            variables.length > 0 &&
            hasHandlebarsPlaceholders(JSON.stringify(config.dataStream?.dataSourceConfig));

        if (!sendParameters) {
            return;
        }

        return {
            objectSelections: variables.map((v) => ({
                nodeIds: v.selectedObjects.map((o) => o.id).sort(),
                scope: {
                    workspace: currentWorkspace?.id ?? '',
                    scope: v.scopeId
                },
                selectedAll: v.selectedAll
            }))
        };
    }, [config, variables, currentWorkspace?.id, dataStreamDefinition?.id, dataStreamDefinition?.template]);
};
