import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { sortBy } from 'lodash';
import { useQuery, UseQueryOptions } from 'react-query';
import {
    ListPluginSourceConfigs,
    ListPluginSourceConfigsForAllWorkspaces,
    PLUGIN_SOURCES,
    PluginSourceConfig
} from 'services/SourceConfigService';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';
import { SqUpPluginConfig } from 'utilities/injectSqUpDataSource';


/**
 * Returns a list of data sources
 * If there is a current workspace, returns linked data sources
 * Otherwise returns all data sources
 */
export const useDataSources = <T = PluginSourceConfig[]>(
    options?: UseQueryOptions<PluginSourceConfig[], unknown, T, string[]>, 
    includeSqUp = true,
    workspace?: string
) => {
    const { workspace: workspaceIdFromContext } = useDataStreamWorkspaceContext();
    const { select, ...queryOptions } = options ?? {};

    const workspaceId = workspace ?? workspaceIdFromContext;

    return useQuery(
        [PLUGIN_SOURCES, workspaceId],
        workspaceId && workspaceId !== NO_ACTIVE_WORKSPACE
            ? ListPluginSourceConfigs
            : ListPluginSourceConfigsForAllWorkspaces,
        {
            select: (data) => {
                const sortedDataSources = sortBy(
                    [...data, ...includeSqUp ? [SqUpPluginConfig as PluginSourceConfig] : []],
                    [(ds) => ds.plugin?.displayName?.toLowerCase(), (ds) => ds.displayName?.toLowerCase()]
                );

                return (select ? select(sortedDataSources) : sortedDataSources) as T;
            },
            ...queryOptions
        }
    );
};
