import { DataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { MutableRefObject, useState, type ReactNode } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { EditorSteps } from '../constants';

export const EDITOR_MODE = 'editorMode';

export interface StepWithCondition {
    name: EditorSteps;
    isEnabled: boolean;
    sublabel?: ReactNode;
    component?: ReactNode;
}

export interface TileEditorStepsValue {
    currentEditorStep: EditorSteps | undefined;
    isConfiguredAfterCurrentStep: boolean;
    editorSteps: StepWithCondition[];
    canMoveToNextStep: boolean;
    isConfigured?: boolean;
    isEditingSQL?: boolean;
    previouslyDocked?: MutableRefObject<boolean>;
    isDocked?: boolean;
    setCurrentEditorStep: (step: EditorSteps | undefined, force?: boolean) => void;
    setIsDocked?: (isNowDocked: boolean) => void;
    nextStep: () => void;
}

/**
 * Handles state management for a set of steps
 */
export const useTileEditorSteps = (
    steps: StepWithCondition[], 
    initialStep: EditorSteps | undefined,
    isConfigured?: boolean,
    isEditingSQL?: boolean,
    previouslyDocked?: MutableRefObject<boolean>,
    dataStreamType?: DataStreamType,
    onDockEditorStep?: () => void,
    onReleaseEditorStep?: () => void,
    onStepChange?: (newStep: EditorSteps | undefined) => void
): TileEditorStepsValue => {
    const { datasets, setActiveDataTab } = useDatasetContext();
    const { isConfigurable, supportsScope} = dataStreamType ?? {}; 

    const [currentStepName, setCurrentStepName] = useState(function findInitialStep(): EditorSteps | undefined {
        // Editing an existing tile and therefore we can use undefined
        if (!initialStep && !previouslyDocked?.current) {
            return undefined;
        }

        return initialStep;
    });

    const handleSetCurrentStepName = (newEditorStep?: EditorSteps | undefined) => {
        setCurrentStepName(newEditorStep);
        onStepChange?.(newEditorStep);
    };

    const availableSteps = steps.filter((step) => step.isEnabled);
    const stepInFlow = availableSteps.findIndex((step) => step.name === currentStepName);

    const canMoveToNextStep = stepInFlow < availableSteps.length - 1;

    // We want to show 'done' when we are on a step that once completed will mean the tile is configured
    const isConfiguredAfterCurrentStep = 
        Boolean(!isConfigurable && supportsScope && currentStepName === EditorSteps.objects) ||
        Boolean(isConfigurable && currentStepName === EditorSteps.parameters);

    const [isDocked, setIsDockedState] = useState<boolean>(previouslyDocked?.current ?? false);

    const setIsDocked = (isNowDocked: boolean) => {
        // Handle layout based on docking/undocking
        if (isNowDocked) {
            onDockEditorStep?.();
        } else {
            onReleaseEditorStep?.();
        }

        setActiveDataTab(currentlyActiveTab => {
            if (datasets.length && isDocked !== isNowDocked) {
                return currentlyActiveTab + (isNowDocked ? 1 : -1);
            };

            return currentlyActiveTab;
        });

        // Keep track docking state so it is maintained when switching between datasets
        if (previouslyDocked) {
            previouslyDocked.current = isNowDocked;
        }

        setIsDockedState(isNowDocked);
    };

    const nextStep = () => {
        if (canMoveToNextStep) {
            handleSetCurrentStepName(availableSteps[stepInFlow + 1]?.name);
        }
    };

    return {
        currentEditorStep: currentStepName,
        isConfigured,
        isConfiguredAfterCurrentStep,
        canMoveToNextStep,
        isDocked,
        isEditingSQL,
        setCurrentEditorStep: (stepName, force) => {
            if (force) {
                // If we're forcing a step we don't want to do anything other than set the current editor step
                return setCurrentStepName(stepName);
            }
            const newCurrentStep = steps.find((s) => s.name === stepName);

            if (newCurrentStep && newCurrentStep?.isEnabled === false) {
                return;
            }

            return handleSetCurrentStepName(newCurrentStep?.name);
        },
        nextStep,
        setIsDocked,
        editorSteps: steps
    };
};
