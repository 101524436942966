import { Serialised } from '@squaredup/ids';
import { LINKED_PLUGINS } from 'components/hooks/useLinkedPluginConfigs';
import { type ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { uniqBy } from 'lodash';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { QueryClient } from 'react-query';
import { PLUGIN_SOURCES, PluginSourceConfig } from 'services/SourceConfigService';
import { SOURCE_TYPE_MAP } from '../../hooks/useSourceTypesToTypesMap';

export const invalidateDataSourceAfterAddingDataSourceToWorkspace = async (
    selectedDataSource: PluginSourceConfig,
    dataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[] | undefined,
    workspaceId: string,
    queryClient: QueryClient
) => {
    const parentPluginId = selectedDataSource.plugin?.pluginId;
    const matchedDataStreamsToDataSource = dataStreams?.filter((stream) => stream.parentPluginId === parentPluginId);

    queryClient.refetchQueries([PLUGIN_SOURCES, workspaceId]);
    queryClient.refetchQueries([LINKED_PLUGINS, workspaceId]);
    queryClient.refetchQueries(datasourceConfigQueryKeys.list);

    if (matchedDataStreamsToDataSource != null) {
        queryClient.setQueryData(
            dataStreamDefinitionQueryKeys.forWorkspace(workspaceId, { includeTemplates: true }),
            (previousDataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[] = []) => {
                return uniqBy(previousDataStreams.concat(matchedDataStreamsToDataSource), 'id');
            }
        );
    }

    // We don't want to await these as they're quite heavy!
    queryClient.refetchQueries([SOURCE_TYPE_MAP, workspaceId]);
    return queryClient.refetchQueries(
        dataStreamDefinitionQueryKeys.forWorkspace(workspaceId, { includeTemplates: true })
    );
};
