import { ConfigId } from '@squaredup/ids';
import { useMutation } from 'react-query';
import { AddPluginLinksToWorkspace } from 'services/WorkspaceService';

interface useAddDataSourceProps {
    workspaceId: string;
    onSuccess?: () => void;
}

const useAddDataSources = ({ workspaceId, onSuccess }: useAddDataSourceProps) => {
    const { mutateAsync: addPlugins } = useMutation(
        async (pluginIds: ConfigId['value'][]) => AddPluginLinksToWorkspace(workspaceId, pluginIds),
        {
            onSuccess
        }
    );

    const addDataSources = async (pluginIds: ConfigId['value'][]) => {
        if (!workspaceId) {
            return;
        }
        return addPlugins(pluginIds);
    };

    return { addDataSources };
};

export default useAddDataSources;
