import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import * as Popover from '@radix-ui/react-popover';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { getDataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { EditorSteps } from '../constants';
import { StepWithCondition } from '../hooks/useTileEditorSteps';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';


interface DataStreamTileEditorStepSelectorProps {
    editorSteps: StepWithCondition[];
    isDocked: boolean | undefined;
    currentEditorStep?: EditorSteps;
    setCurrentEditorStep: (step: EditorSteps | undefined) => void;
    onInteractOutside?: Popover.PopoverContentProps['onInteractOutside'];
}


/**
 * @returns Steps such as: Data Stream, Objects, Configure, Shaping (step names subject to change)
 */
export const DataStreamTileEditorStepSelector: React.FC<DataStreamTileEditorStepSelectorProps> = ({ 
    editorSteps, 
    currentEditorStep,
    isDocked,
    setCurrentEditorStep,
    onInteractOutside 
}) => {
    const container = useDOMElement('dialogContent');
    const { selectedDataStream, selectedNodeIds, dispatch } = useTileEditorStore((s) => ({ 
        selectedDataStream: s.dataStream.selectedDataStream,
        selectedNodeIds: s.scope.isDynamic || s.scope.type === 'none' ? [] : s.scope.selectedNodeIds,
        dispatch: s.dispatch
    }));
    const dataStreamType = getDataStreamType(selectedDataStream);

    return (
        <>
            {editorSteps
                .filter(({ name, isEnabled }) => 
                    isEnabled || (name === EditorSteps.objects && dataStreamType.supportsScope)
                )
                .map(({ name, sublabel, isEnabled, component }) => {
                    const isOpen = currentEditorStep === name;

                    return (
                        <Popover.Root 
                            key={name} 
                            open={isOpen}
                            onOpenChange={(isNowOpen) => {
                                if (!isNowOpen && name === EditorSteps.dataStream && !selectedDataStream) {
                                    dispatch({ type: 'clearScope' });
                                    
                                    // Clear all existing filters if leaving the data stream step with no datastream
                                    dispatch({ type: 'dataStream.resetFilters' });
                                } else if (name === EditorSteps.dataStream) {
                                    // Clear search when navigating away from data stream step
                                    dispatch({ type: 'dataStream.updateFilters', filters: { search: '' } });
                                }

                            }}
                        >
                            <Popover.Trigger
                                className={cn(
                                    'font-semibold outline-none w-full text-left py-4 px-[23px] shrink-0 h-[79px] text-textPrimary border-b-[1px] border-dividerTertiary disabled:text-textDisabled',
                                    name === currentEditorStep && 'bg-tileEditorEditorActiveStep'
                                )}
                                data-testid={`step-${name}`}
                                onClick={() => {
                                    // We don't want to close the open editor step if it's docked
                                    setCurrentEditorStep(isOpen && !isDocked ? undefined : name);
                                }}
                                disabled={!isEnabled}
                            >
                                {!(
                                    name === EditorSteps.objects && 
                                    dataStreamType.supportsScope && 
                                    !isEnabled &&
                                    !selectedNodeIds.length
                                ) &&
                                    <Text.H4 className={cn('text-textSecondary', !isEnabled && 'text-textDisabled')}>{
                                        [EditorSteps.metadata, EditorSteps.tileMetadata]
                                            .includes(name) ? EditorSteps.metadata : name
                                    }</Text.H4>
                                }
                                <Text.H4 
                                    className='font-semibold'
                                    data-testid={`step-${name}-description`}
                                >
                                    {sublabel}
                                </Text.H4>
                            </Popover.Trigger>

                            {/* When a step is pinned we don't want to show the popover 
                                as it will be in the main visualisation panel */}
                            {!isDocked && 
                                <Popover.Portal container={container} >
                                    <Popover.Content 
                                        side='right'
                                        align='start'
                                        sideOffset={3}
                                        alignOffset={-1}
                                        collisionPadding={14}
                                        onInteractOutside={onInteractOutside}
                                        className={cn(
                                            'border bg-tileBackground z-10 flex flex-col border-dividerTertiary min-h-[min(40vh,300px)] text-textSecondary shadow-even shadow-tileEditorShadow max-h-[min(60vh,650px)] w-[57.5vw] max-w-[900px)',
                                            currentEditorStep === EditorSteps.timeframe && 'max-w-[600px] min-h-0',
                                            currentEditorStep === EditorSteps.objects && 'min-h-[min(60vh,800px)]',
                                            currentEditorStep === EditorSteps.dataStream && 'min-h-[min(40vh,500px)]'
                                        )}
                                        data-testid={`editorStep-${name}`}
                                    >
                                        {component}
                                        <Popover.Arrow className='fill-dividerTertiary' />
                                    </Popover.Content>
                                </Popover.Portal>
                            }
                        </Popover.Root>
                    );
                })}
        </>
    );
};
