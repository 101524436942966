import { cn } from '@/lib/cn';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useNodeHealth } from 'components/map/hooks/useNodeHealth';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { DownstreamOptions } from '../nodeToolbar/downstream';
import { NodeToolbar } from '../nodeToolbar/NodeToolbar';
import { UpstreamOptions } from '../nodeToolbar/upstream';
import { healthStateStyles, strokeHealthStateStyles } from './common/health';

export const MONITOR_NODE = 'monitorNode';
export const MONITOR_NODE_SIZE = 18;

export const MonitorNode = memo(({ id, data }: NodeProps<GraphologyNodeAttributes>) => {
    const background = useNetworkBackground();
    const { isHovered, blur, ...hoverProps } = useHover();

    if (!data.data) {
        throw new Error('No data provided for MonitorNode');
    }

    const [dashId, tileId] = data.data.sourceId?.[0].split('/') ?? [];

    const healthState = useNodeHealth(data.data.sourceId?.[0]);

    const label = data.data.name;

    return (
        <div
            className={cn('relative rounded-full', mapBackgroundColors[background])}
            style={{ width: MONITOR_NODE_SIZE, height: MONITOR_NODE_SIZE }}
            {...hoverProps}
        >
            <UpstreamOptions onClick={blur} node={data} isVisible={isHovered} />
            <DownstreamOptions onClick={blur} node={data} isVisible={isHovered} />

            <NodeToolbar
                onClick={blur}
                id={id}
                isVisible={isHovered}
                data={data}
                url={dashId ? `/dashboard/${dashId}?tile=${tileId}` : `/drilldown/node/${id}`}
            />

            <svg
                viewBox='0 0 100 100'
                className={cn(
                    'absolute z-10 w-[calc(100%-2px)] left-px top-px opacity-0 transition-opacity',
                    healthState && 'opacity-100'
                )}
            >
                <circle
                    cx='50%'
                    cy='50%'
                    r='44'
                    vectorEffect='non-scaling-stroke'
                    strokeWidth={2}
                    className={cn(mapFillColors[background], strokeHealthStateStyles[healthState])}
                    {...(!data.pinned && { strokeDasharray: '1 1' })}
                />
            </svg>
            <div
                className={cn(
                    'absolute z-20 w-[8px] h-[8px] left-[5px] top-[5px] rounded-full bg-[#3ABACD] transition-colors',
                    healthState && healthStateStyles[healthState]
                )}
            />

            <Handle
                type='target'
                position={Position.Top}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <Handle
                type='source'
                position={Position.Bottom}
                className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
            />
            <span className='sr-only'>{label}</span>

            {label && (
                <div
                    className={cn(
                        'text-primary rounded-sm font-bold px-1 py-0.5 leading-tight text-[8px] max-w-[120px] absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 text-center',
                        mapBackgroundColors[background]
                    )}
                >
                    <TruncatedText title={label}>{label}</TruncatedText>
                </div>
            )}
        </div>
    );
});
