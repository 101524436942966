import type { TileConfig } from '@squaredup/dashboards';
import { SortingState } from '@tanstack/react-table';
import Ajv from 'ajv';
import LoadingSpinner from 'components/LoadingSpinner';
import DashboardContext from 'contexts/DashboardContext';
import { TileTypes } from 'dashboard-engine/constants';
import { FullScreenWrapper } from 'dashboard-engine/fullscreen/FullScreenWrapper';
import { useFullscreen } from 'dashboard-engine/fullscreen/hooks/useFullscreen';
import { useVisualizationState } from 'dashboard-engine/hooks/useVisualizationState';
import baseTileRepo from 'dashboard-engine/repositories/baseTileRepo';
import tileRepo from 'dashboard-engine/repositories/tileRepo';
import { construct } from 'dashboard-engine/schemas/schema';
import { getDashboardContents, setDashboardContents } from 'dashboard-engine/util/dashboard';
import { downloadTileAsImage } from 'dashboard-engine/util/dashboardImageSnapshots';
import { compactItem } from 'lib/gridLayout';
import merge from 'lodash/merge';
import { useDashboardMetricsContext } from 'pages/dashboard/DashboardMetrics';
import { useHandleSave } from 'pages/dashboard/hooks/useHandleSave';
import { optimisticMonitorCountUpdate } from 'queries/hooks/useMonitorsCount';
import { useTileHealthState } from 'queries/hooks/useTileHealthState';
import { TileState } from 'queries/types/types';
import { memo, Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CloneDashboardImage, DeleteDashboardImage } from 'services/ImageService';
import Tile from 'ui/Tile';
import { TileDeleteModal } from 'ui/tile/TileDeleteModal';
import { v4 as uuidv4 } from 'uuid';

const ajv = new Ajv();

const cacheQueryInfinitely = {
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY
};

/**
 * Initial state for visualisations that support it
 * Only used by the table visualisation currently.
 * Needs to be passed down in a ref to avoid re-renders.
 */
export interface VisualisationState {
    /** This is only used by the table visualisation */
    globalFilter?: string;
    /** This is only used by the table visualisation */
    sorting?: SortingState;
}

interface RenderTileProps {
    tileId: string;
    config: TileConfig;
    preview: boolean;
    previewHealth?: TileState;
    supportsDelete?: boolean;
    supportsClone?: boolean;
    supportsDragging?: boolean;
    supportsEditingTitle?: boolean;
    supportsFullscreen?: boolean;
    isLoading?: boolean;
}

const RenderTile: React.FC<RenderTileProps> = ({
    tileId,
    config,
    preview,
    previewHealth,
    supportsDelete = true,
    supportsClone = true,
    supportsDragging = true,
    supportsEditingTitle = true,
    supportsFullscreen = true,
    isLoading
}) => {
    const tileType = (config._type as string | undefined)?.split('/').pop() || '';
    const { dashboard, timeframe, updateTile, highlightedTile, setHighlightedTile } = useContext(DashboardContext);
    const [deletingTile, setDeletingTile] = useState(false);
    const { data: tileHealthState } = useTileHealthState(tileId, dashboard.id);
    const { visualizationState, onVisualizationStateChange } = useVisualizationState();

    const queryClient = useQueryClient();
    const { mutate: handleSave } = useHandleSave();

    const { reportTileStart } = useDashboardMetricsContext();

    // Use the preview health state if it exists, else use the actual health state data
    const health = previewHealth ?? tileHealthState;

    reportTileStart(tileId, Date.now());

    const baseTileConfig = tileRepo.get(tileType)?.defaultTileConfig;

    const BaseTile = baseTileConfig?.baseTile ? baseTileRepo.get(baseTileConfig.baseTile) : undefined;

    const { data: tileSchema = {} } = useQuery(['tileSchema'], construct, cacheQueryInfinitely);

    const tileConfig = useMemo(() => merge({}, baseTileConfig, config), [baseTileConfig, config]);

    const isValidTile = useMemo(() => {
        const validator = ajv.compile(tileSchema);
        return validator(tileConfig);
    }, [tileConfig, tileSchema]);

    // TODO: When new tile types are added extend this to make appropriate checks
    const isConfigured = Boolean(tileConfig?._type);
    const tileTimeframe = tileConfig.timeframe ?? timeframe;

    const save = (key: string, value: any) => {
        updateTile?.({ ...config, [key]: value }, tileId);
    };

    const isDataStreamTile = config._type === TileTypes.dataStream;
    const isFullscreenEnabled = supportsFullscreen && isDataStreamTile;

    const { isFullscreenOpen, openFullscreen, closeFullscreen } = useFullscreen(isFullscreenEnabled);

    const handleTileClickToOpenFullscreen = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        // Prevent opening fullscreen when clicking on a link/button within, i.e. drilldowns
        if (target?.matches('a, a *, button, button *')) {
            return;
        }

        openFullscreen?.();
    };

    const handleDelete = () => setDeletingTile(true);

    const handleOnChange = (updated: Record<string, any>) => updateTile?.(updated, tileId);

    const handleDownloadAsImage = () => downloadTileAsImage(tileId, config.title);

    const handleCloneTile = async () => {
        setHighlightedTile?.(undefined);

        const contents = getDashboardContents(dashboard);
        const cell = contents.find((x) => x.i === tileId);

        if (!cell) {
            return;
        }

        const id = uuidv4();
        setHighlightedTile?.(id);

        // 1. Clone the tile and add to a new layout
        const cloned = {
            ...cell,
            i: id
        };
        const layout = [...contents, cloned];

        // 2. Ensure the cell ends up in the nearest possible gap vertically
        // Note: cols is 0 here because we're only moving tiles vertically
        const compacted = compactItem(layout, cloned, 'vertical', 0, layout, false);

        // 3. Add the compacted cell, and cloned config
        const updated = [
            ...contents,
            {
                ...compacted,
                config: {
                    ...config,
                    title: config.title ? `Copy of ${config.title}` : ''
                }
            }
        ];

        if (tileType === 'image') {
            // Attempt to clone any stored dashboard image (it won't fail if one doesn't exist)
            await CloneDashboardImage(dashboard.workspaceId, dashboard.id, tileId, id);
        }

        if ('monitor' in cell.config && cell.config.monitor) {
            // optimistically update the number of monitors
            optimisticMonitorCountUpdate(queryClient, 1);
        }

        handleSave(setDashboardContents(dashboard, updated), {
            onSettled: () => {
                const element = document.getElementById(`tile-${id}`);
                if (element) {
                    // Scroll to element
                    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            }
        });
    };

    const handleDeleteTile = async () => {
        const contents = getDashboardContents(dashboard);
        const removedTile = contents.find((tile) => tile.i === tileId);
        const updatedContents = contents.filter(({ i }) => i !== tileId);

        if (removedTile && 'monitor' in removedTile.config && removedTile.config.monitor) {
            // optimistically update the number of monitors
            optimisticMonitorCountUpdate(queryClient, -1);
        }

        handleSave(setDashboardContents(dashboard, updatedContents));

        if (tileType === 'image') {
            // Attempt to delete any stored dashboard image (it won't fail if one doesn't exist)
            await DeleteDashboardImage(dashboard.workspaceId, dashboard.id, tileId);
        }
        setDeletingTile(false);
    };

    const handleSelectHighlightedTile = useCallback(
        (tileID?: string) => {
            setHighlightedTile?.(tileID);
        },
        [setHighlightedTile]
    );

    return (
        <>
            <Tile
                id={tileId}
                title={config.title ?? ''}
                description={config.description ?? ''}
                config={config}
                preview={preview}
                health={health}
                supportsEditingTitle={supportsEditingTitle}
                draggable={supportsDragging}
                isLoading={isLoading}
                onSave={save}
                onChange={handleOnChange}
                onDelete={supportsDelete ? handleDelete : undefined}
                onClone={supportsClone ? handleCloneTile : undefined}
                onDownloadAsImage={handleDownloadAsImage}
                onOpenFullscreen={openFullscreen}
                shouldHighlight={highlightedTile === tileId}
                onShouldHighlight={handleSelectHighlightedTile}
            >
                <>
                    <Suspense
                        fallback={
                            <div className='mt-5 text-center'>
                                <LoadingSpinner size='full' />
                            </div>
                        }
                    >
                        <div className='relative first:h-full'>
                            {isValidTile && BaseTile && (
                                <BaseTile
                                    tileId={tileId}
                                    config={tileConfig}
                                    timeframe={tileTimeframe}
                                    onClick={handleTileClickToOpenFullscreen}
                                    isLoading={isLoading}
                                    onVisualizationStateChange={onVisualizationStateChange}
                                />
                            )}

                            {isConfigured && !isValidTile && (
                                <div className='flex w-full h-full'>
                                    <pre className='self-center w-full text-center text-statusErrorPrimary'>
                                        Error: Invalid tile configuration JSON
                                    </pre>
                                </div>
                            )}
                        </div>
                    </Suspense>

                    {isFullscreenOpen && (
                        <FullScreenWrapper
                            isOpen={isFullscreenOpen}
                            visualizationState={visualizationState}
                            onClose={closeFullscreen}
                        />
                    )}
                </>
            </Tile>

            {deletingTile && (
                <TileDeleteModal
                    tileConfig={tileConfig}
                    close={(deleteConfirmed) => (deleteConfirmed ? handleDeleteTile() : setDeletingTile(false))}
                />
            )}
        </>
    );
};

export default memo(RenderTile);
