import { Button } from '@/components/Button';
import { Switch } from '@/components/Switch';
import Template from '@/components/Template';
import { faMagnifyingGlassChart, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import Tooltip from 'components/tooltip/Tooltip';
import DashboardContext from 'contexts/DashboardContext';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { EXPLORE_QUERY_KEY } from 'dashboard-engine/hooks/useNavigateToExplore';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { usePageTitle } from 'lib/usePageTitle';
import { noop } from 'lodash';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';
import { useDataStreamType } from 'ui/editor/dataStream/TileEditor/hooks/useDataStreamType';
import TileEditorContext from 'ui/editor/dataStream/contexts/TileEditorContext';
import { ExploreTileEditorWrapper } from './ExploreTileEditorWrapper';
import { SaveToDashboardModal } from './SaveToDashboardModal';
import { buildContext } from './builders';
import stringify from 'fast-json-stable-stringify';

/**
 * Standalone tile editing experience allowing the user to browse data without first building a dashboard
 */
function Explore() {
    usePageTitle('Data Explorer');
    const queryClient = useQueryClient();
    const [search] = useSearchParams();
    const configId = search.get('config');
    const datastream = search.get('datastream');
    const datasource = search.get('datasource');
    const global = search.get('global');
    const workspace = search.get('workspace');

    const [isGlobal, setIsGlobal] = useState(() => Boolean(global));
    const [isSaving, setIsSaving] = useState(false);
    const [containsError, setContainsError] = useState(false);

    const [tileConfig, setTileConfig] = useState(() => {
        if (configId) {
            // We want to remove any monitoring, variables or KPIs that are setup as they're not supported
            const { monitor, kpi, variables, ...config } =
                queryClient.getQueryData<DataStreamBaseTileConfig>([EXPLORE_QUERY_KEY, configId]) ??
                baseDataStreamConfig;

            return config;
        }

        return {
            ...baseDataStreamConfig,
            ...(datastream && {
                dataStream: {
                    id: datastream,
                    pluginConfigId: datasource
                }
            })
        } as DataStreamBaseTileConfig;
    });

    const handleSetTileConfig = useCallback(
        (state: ((config: DataStreamBaseTileConfig) => DataStreamBaseTileConfig) | DataStreamBaseTileConfig) =>
            setTileConfig((currentConfig) => {
                const newState = typeof state === 'function' ? state(currentConfig) : state;

                return {
                    ...newState,
                    dataStream: {
                        ...newState.dataStream,
                        ...(isGlobal && { accessControlType: 'directOrAnyWorkspaceLinks' })
                    }
                };
            }),
        [isGlobal, setTileConfig]
    );

    const isDatasetMode = tileConfig?.dataStream?.id === 'datastream-sql';
    const { dataStreamType, isLoading } = useDataStreamType(tileConfig);

    const { canWriteToAtLeastOneWorkspace } = useWorkspacePermissions();
    const canWriteToAWorkspace = canWriteToAtLeastOneWorkspace();
    const isWorkspaceScope = 'workspace' in (tileConfig.scope ?? {});

    return (
        <>
            <Template
                key={configId}
                title='Data Explorer'
                equalPadding
                flex
                icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} className='w-8 h-8' />}
                iconAlignment='title'
                actions={
                    <div className='flex items-center space-x-4'>
                        <div className='flex items-center space-x-4'>
                            <Tooltip
                                title='Locked to current workspace as this tile uses a workspace collection'
                                disabled={!isWorkspaceScope}
                            >
                                <Switch
                                    options={[
                                        {
                                            label: 'Current workspace',
                                            value: 'workspace'
                                        },
                                        {
                                            label: 'All workspaces',
                                            value: NO_ACTIVE_WORKSPACE
                                        }
                                    ]}
                                    onValueChange={(value) => {
                                        const globalMode = value === NO_ACTIVE_WORKSPACE;
                                        setIsGlobal(globalMode);

                                        // Ensure we add in the necessary access control when we change modes
                                        const { accessControlType, ...tileDataStreamConfig } =
                                            tileConfig.dataStream ?? {};
                                        setTileConfig({
                                            ...tileConfig,
                                            dataStream: {
                                                ...(globalMode
                                                    ? {
                                                          ...tileDataStreamConfig,
                                                          accessControlType: 'directOrAnyWorkspaceLinks'
                                                      }
                                                    : tileDataStreamConfig)
                                            }
                                        });
                                    }}
                                    value={isGlobal ? NO_ACTIVE_WORKSPACE : 'workspace'}
                                    data-testid='globalToggle'
                                    disabled={isWorkspaceScope}
                                />
                            </Tooltip>
                        </div>

                        {canWriteToAWorkspace && tileConfig.dataStream?.id && (
                            <>
                                <div className='border-r-2 border-dividerPrimary h-[25px]'></div>
                                <Button
                                    icon={<FontAwesomeIcon icon={faSave} />}
                                    variant='secondary'
                                    onClick={() => setIsSaving(true)}
                                    disabled={!tileConfig.dataStream || containsError}
                                >
                                    Save to dashboard
                                </Button>
                            </>
                        )}
                    </div>
                }
            >
                <div className='flex flex-col h-full min-h-0'>
                    <DashboardContext.Provider value={buildContext(tileConfig)}>
                        {!isLoading && (
                            <TileEditorContext.Provider
                                value={{
                                    inEditor: true,
                                    tileConfig,
                                    isDatasetMode,
                                    savedTileConfig: tileConfig,
                                    savedTileDataStreamType: dataStreamType,
                                    containsError,
                                    isEdited: false,
                                    setTileConfig: handleSetTileConfig,
                                    setContainsError,
                                    onClose: noop
                                }}
                            >
                                <ExploreTileEditorWrapper
                                    key={isDatasetMode ? 'analyticsMode' : 'basicMode'}
                                    workspace={workspace ?? undefined}
                                    isGlobal={isGlobal}
                                />
                            </TileEditorContext.Provider>
                        )}
                    </DashboardContext.Provider>
                </div>
            </Template>

            {isSaving && (
                <SaveToDashboardModal
                    isGlobal={isGlobal}
                    workspace={workspace ?? undefined}
                    close={() => setIsSaving(false)}
                    config={{
                        ...tileConfig,
                        title:
                            tileConfig.title ??
                            queryClient.getQueryData<ProjectedDataStreamDefinitionEntity>(
                                dataStreamDefinitionQueryKeys.byId(tileConfig.dataStream?.id!)
                            )?.displayName ??
                            ''
                    }}
                    title='Save to dashboard'
                    buttonText='Save'
                />
            )}
        </>
    );
}

export default Explore;
