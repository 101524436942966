import { Button, buttonVariants } from '@/components/Button';
import { Skeleton } from '@/components/Skeleton';
import Text from '@/components/Text';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Price, SubscriptionItem, TransactionLineItemPreview } from '@paddle/paddle-node-sdk';
import { AxiosError } from 'axios';
import Field from 'components/forms/field/Field';
import Input from 'components/forms/input/Input';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import { debounce } from 'lodash';
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PaddleSubscriptionInfo, SubscriptionUpdateRequest } from 'services/TenantService';
import { formatPaddleCurrency } from '../Paddle';
import { ENTERPRISE_PADDLE_ID, isMonthlyPrice, plans } from '../Plans';
import { useSubscriptionUpdatePreview } from '../useSubscriptionUpdatePreview';
import { BillingCycle, ModalPanel, useBillingCycleSwitch } from './common';

type TransactionItem = {
    title: string;
    productId: string;
    quantity: number;
    isoFrom?: string;
    isoEnd?: string;
    amount: string;
    isCredit?: boolean;
};

type Plan = (typeof plans)[0];

type UserSelection = {
    plan: Plan;
    users: number;
    discountCode?: string;
};

export type CheckoutPanelProps = Omit<ComponentPropsWithoutRef<'div'>, 'onSubmit'> & {
    initialPriceId: string;
    currentUserLimit: number;
    currentUsers: number | undefined;
    onBack: () => void;
    onSubmit: (request: SubscriptionUpdateRequest) => Promise<void>;
    subscription?: Pick<PaddleSubscriptionInfo, 'id' | 'managementUrls'>;
    currentPlan?: {
        price: Pick<SubscriptionItem['price'], 'billingCycle' | 'id'>;
        product: Pick<SubscriptionItem['product'], 'id'>;
    };
    prices?: Pick<Price, 'id' | 'quantity'>[];
};

const getPriceId = (cycle: BillingCycle, plan: Plan) =>
    cycle === 'monthly' ? plan?.monthlyPriceId : plan?.annualPriceId;

const getPlanTransactionItemName = (item: TransactionLineItemPreview) => {
    const produceName = item.product.name.replace('SquaredUp', '').trim();
    const billingCycle = isMonthlyPrice(item.priceId) ? 'Monthly' : 'Annual';

    return `${produceName} ${billingCycle} ${Math.abs(item.quantity)} Users`;
};

const getErrorMessage = (error: unknown) => {
    const errorMessage =
        error instanceof AxiosError ? error.response?.data.error : error instanceof Error ? error.message : error;
    const msgAsString = `${errorMessage}`;

    // normalize error message to not contain full stop, since we will render one.
    return msgAsString.length > 0 && msgAsString.endsWith('.')
        ? msgAsString.slice(0, msgAsString.length - 1)
        : msgAsString;
};

export const CheckoutPanel = ({
    initialPriceId,
    currentUsers,
    currentUserLimit,
    currentPlan,
    subscription,
    onBack,
    onSubmit,
    prices,
    ...props
}: CheckoutPanelProps) => {
    const { billingCycle, BillingCycleSwitch } = useBillingCycleSwitch({
        defaultValue:
            currentPlan?.price.billingCycle?.interval === 'year' || !isMonthlyPrice(initialPriceId)
                ? 'annual'
                : 'monthly',
        expand: true,
        disabled: currentPlan && currentPlan.price.billingCycle?.interval === 'year'
    });

    const [showDiscount, setShowDiscount] = useState(false);
    const [error, setError] = useState<string>();

    // calculate suggested user purchase based on selected plan, current/max users and plan minimum
    const initialPriceUserMin = prices?.find((p) => p.id === initialPriceId)?.quantity.minimum ?? 1;
    const defaultUsers = Math.max(
        initialPriceUserMin,
        // If we are over our user allowance, increase to that automatically
        currentUsers ?? 1,
        // If the selected plan is the same as the current, just adding more users so increase the limit
        currentPlan?.price.id !== initialPriceId ? currentUserLimit : currentUserLimit + 1
    );

    const currentTier = plans.find((p) => p.productId === currentPlan?.product.id);
    const planOptions = plans
        .filter(
            (plan) =>
                plan.productId !== ENTERPRISE_PADDLE_ID &&
                (currentTier === undefined || plan.tierTemplate.sortRank >= currentTier.tierTemplate.sortRank)
        )
        .map((plan) => ({
            ...plan,
            label:
                currentPlan?.product.id === plan.productId
                    ? `${plan.tierTemplate.displayName} (current)`
                    : plan.tierTemplate.displayName,
            value: plan.productId
        }));

    const defaultValues = {
        plan:
            planOptions.find((plan) => [plan.monthlyPriceId, plan.annualPriceId].includes(initialPriceId)) ??
            planOptions[0],
        users: defaultUsers,
        discountCode: undefined
    };

    const [userSelection, setUserSelection] = useState<UserSelection>(defaultValues);
    const debounceUserSelection = useMemo(
        () => debounce((u: UserSelection) => setUserSelection(u), 500, { trailing: true }),
        []
    );

    const formMethods = useForm({ mode: 'all', defaultValues, shouldUnregister: true });
    const { watch, trigger } = formMethods;

    // Manually trigger form validation after billing cycle changes, since that changes
    // the calculated plan even though the form inputs didn't change. Also want to trigger
    // if selected plan changes as that can change min/max
    useEffect(() => {
        trigger();
    }, [trigger, billingCycle, userSelection.plan]);

    // Watch and validate/debounce any remaining changes
    useEffect(() => {
        const watchSub = watch((data) => {
            if (data.plan !== undefined && data.users !== undefined) {
                setUserSelection((current) => {
                    // RHF actually types number inputs as string
                    const users = Number.parseInt(`${data.users || '0'}`);

                    if (current.plan.productId !== data.plan?.productId) {
                        // Plan changes should apply immediately
                        return { ...(data as UserSelection), users };
                    }
                    // Debounce everything else
                    debounceUserSelection({ ...(data as UserSelection), users });
                    return current;
                });
            }
        });

        return () => {
            debounceUserSelection.cancel();
            watchSub.unsubscribe();
        };
    }, [watch, debounceUserSelection]);

    const isValid = formMethods.formState.isValid && !formMethods.formState.isValidating;
    const isSubmitting = formMethods.formState.isSubmitting;

    const selectedPriceId = getPriceId(billingCycle, userSelection.plan);
    const request = {
        items: [
            {
                priceId: selectedPriceId ?? '',
                quantity: userSelection.users
            }
        ],
        discountCode: userSelection.discountCode !== '' ? userSelection.discountCode : undefined
    };
    const planChanged = currentPlan?.price.id !== selectedPriceId || currentUserLimit !== userSelection.users;
    const userDifference = userSelection.users - currentUserLimit;

    const {
        data: preview,
        isLoading: isLoadingPreview,
        error: previewError
    } = useSubscriptionUpdatePreview(subscription?.id ?? '', request, {
        enabled: Boolean(subscription?.id) && isValid && planChanged,
        retry: false
    });

    const handleSubmit = async () => {
        setError(undefined);
        try {
            await onSubmit(request);
        } catch (e) {
            // handle errors
            setError(getErrorMessage(e) ?? 'Failed to complete purchase.');
        }
    };

    const validatePlan = (value: (typeof planOptions)[0]) => value !== null || 'Current Plan selection is invalid';
    const isLoading = [prices, currentUsers].some((item) => item === undefined);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const validateUsers = (value: number) => {
        const planMin = selectedPriceId ? prices?.find((p) => p.id === selectedPriceId)?.quantity.minimum ?? 1 : 1;

        if (value < planMin) {
            return `Total users cannot be less than plan minimum of ${planMin}.`;
        }

        // No downgrade support for now
        if (value < currentUserLimit) {
            return 'Total users cannot be less than current limit.';
        }

        if (currentUsers && value < currentUsers) {
            return 'Total users cannot be less than current allocated users.';
        }

        return true;
    };

    const transactionItems =
        // reduce to combine credits of the same price, and add tax and discounts from items
        preview?.immediateTransaction?.details.lineItems.reduce<TransactionItem[]>((acc, item) => {
            const isCredit = item.quantity < 0;

            if (isCredit) {
                // Check if the credit is already present, and combine if so
                const existing = acc.find((exItem) => exItem.isCredit && exItem.productId === item.product.id);
                if (existing) {
                    existing.amount = (
                        Number.parseInt(existing.amount, 10) + Number.parseInt(item.totals.total, 10)
                    ).toString();
                    existing.quantity += item.quantity;
                    existing.title = getPlanTransactionItemName({ ...item, quantity: existing.quantity });
                    return acc;
                }
            }

            acc.push({
                productId: item.product.id,
                quantity: item.quantity,
                title: getPlanTransactionItemName(item),
                isoFrom:
                    item.proration?.billingPeriod.startsAt ?? preview?.immediateTransaction?.billingPeriod.startsAt,
                isoEnd: item.proration?.billingPeriod.endsAt ?? preview?.immediateTransaction?.billingPeriod.endsAt,
                amount: isCredit ? item.totals.total : item.totals.subtotal,
                isCredit
            });

            // add tax or discount if not credit and either was specified
            if (!isCredit && item.totals.discount && item.totals.discount !== '0') {
                acc.push({
                    productId: item.product.id,
                    quantity: item.quantity,
                    title: 'Discount',
                    amount: `-${item.totals.discount}`
                });
            }

            if (!isCredit && item.totals.tax && item.totals.tax !== '0') {
                acc.push({
                    productId: item.product.id,
                    quantity: item.quantity,
                    title: 'Tax',
                    amount: item.totals.tax
                });
            }

            return acc;
        }, []) ?? [];

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                <ModalPanel {...props}>
                    <BillingCycleSwitch />
                    <div className='flex flex-col w-full gap-6'>
                        <Field.Input
                            name='plan'
                            type='autocomplete'
                            label='Plan'
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            validateOnLoad
                            validation={{ validate: validatePlan }}
                            options={planOptions}
                            isDisabled={planOptions.length === 1}
                        />
                        <div>
                            {/* Need custom label behaviour and no required asterisk */}
                            <label htmlFor='users' className='mb-2'>
                                <span className='font-medium text-textPrimary'>Total users</span>
                            </label>
                            <Input
                                name='users'
                                validation={{ validate: validateUsers }}
                                type='number'
                                label='Total users'
                                min={1}
                                max={1000}
                                inputMode='numeric'
                                validateOnLoad
                            />
                            {userDifference !== 0 && isValid && (
                                <span className='mt-4 text-sm text-textSecondary'>
                                    {Math.abs(userDifference)} user{Math.abs(userDifference) !== 1 && 's'} will be{' '}
                                    {userDifference > 0 ? 'added to' : 'removed from'} your plan
                                </span>
                            )}
                        </div>
                        {showDiscount ? (
                            <Field.Label spacing='none' label='Discount code'>
                                <Input
                                    name='discountCode'
                                    data-testid='checkout-panel-discountCode'
                                    type='text'
                                    append={
                                        <FontAwesomeIcon
                                            role='button'
                                            icon={faTrash}
                                            fixedWidth
                                            className='cursor-pointer mr-md'
                                            onClick={() => setShowDiscount(false)}
                                            title='Remove discount'
                                        />
                                    }
                                />
                            </Field.Label>
                        ) : (
                            <Button
                                variant='link'
                                type='button'
                                onClick={() => setShowDiscount(true)}
                                className='text-sm max-w-fit'
                            >
                                + Add discount
                            </Button>
                        )}
                    </div>
                    <div className='flex flex-col w-full gap-4 min-h-96'>
                        {planChanged &&
                            isValid &&
                            (isLoadingPreview ? (
                                <Skeleton className='w-full h-96' />
                            ) : (
                                <>
                                    <TransactionBreakdown
                                        items={transactionItems}
                                        currencyCode={preview?.immediateTransaction?.details.totals.currencyCode}
                                        total={preview?.immediateTransaction?.details.totals.total}
                                        credit={preview?.immediateTransaction?.details.totals.credit}
                                        dueToday={preview?.immediateTransaction?.details.totals.balance}
                                    />
                                    <RecurringSummary
                                        isLoading={isLoadingPreview}
                                        currencyCode={preview?.recurringTransactionDetails?.totals.currencyCode}
                                        cost={preview?.recurringTransactionDetails?.totals.total}
                                        credit={
                                            preview?.updateSummary?.result.action === 'credit'
                                                ? preview.updateSummary.result.amount
                                                : undefined
                                        }
                                        paymentDate={preview?.nextBilledAt}
                                    />
                                </>
                            ))}
                    </div>
                    {(previewError || error) && (
                        <p className='text-textDestructive first-letter:capitalize'>
                            {previewError ? getErrorMessage(previewError) : error}.{' '}
                            <a
                                className={buttonVariants({ variant: 'link' })}
                                href='https://docs.squaredup.com/submit-ticket'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Contact support
                            </a>
                        </p>
                    )}
                    <PaymentDisclaimer
                        showDisclaimer={true}
                        managementUrl={subscription?.managementUrls.updatePaymentMethod}
                    />
                </ModalPanel>
                <ModalButtons hideTopMargin>
                    <Button variant='tertiary' type='button' onClick={onBack}>
                        Cancel
                    </Button>
                    <Button
                        variant='upgrade'
                        type='submit'
                        disabled={Boolean(previewError) || !isValid || isSubmitting || !planChanged}
                    >
                        {isSubmitting ? <LoadingSpinner size={18} /> : 'Confirm'}
                    </Button>
                </ModalButtons>
            </form>
        </FormProvider>
    );
};

type TransactionBreakdownProps = {
    items: TransactionItem[];
    currencyCode?: string;
    dueToday?: string;
    credit?: string;
    total?: string;
};

const TransactionBreakdown = ({
    items,
    currencyCode,
    dueToday,
    credit,
    total,
    ...props
}: ComponentPropsWithoutRef<'div'> & TransactionBreakdownProps) => {
    if (!currencyCode) {
        return <></>;
    }

    const formattedDue = formatPaddleCurrency(dueToday ?? '0', currencyCode);
    const creditItems = items.filter((item) => item.isCredit);
    const costItems = items.filter((item) => !item.isCredit);

    return (
        <div className='flex flex-col flex-grow gap-3' {...props}>
            <hr className='border-outlinePrimary' />
            {costItems.map((item) => (
                <TransactionItem
                    key={`${item.title}-cost-${item.isoFrom}`}
                    itemName={item.title}
                    isoFrom={item.isoFrom}
                    isoEnd={item.isoEnd}
                    currencyCode={currencyCode}
                    amount={item.amount}
                />
            ))}
            {creditItems.length > 0 && <hr className='border-outlinePrimary' />}
            {creditItems.map((item) => (
                <TransactionItem
                    key={`${item.title}-credit-${item.isoFrom}`}
                    itemName={item.title}
                    isoFrom={item.isoFrom}
                    isoEnd={item.isoEnd}
                    currencyCode={currencyCode}
                    amount={item.amount}
                />
            ))}
            <hr className='border-outlinePrimary' />
            <TransactionItem itemName='Total' currencyCode={currencyCode} amount={total} />
            <TransactionItem hideIfZero itemName='Account credit' amount={credit} currencyCode={currencyCode} />
            <div className='flex items-center mt-auto'>
                <Text.H4>Due Today</Text.H4>
                <Text.H2 className='ml-auto'>{formattedDue}</Text.H2>
            </div>
        </div>
    );
};

type TransactionItemProps = {
    itemName: string;
    isoFrom?: string;
    isoEnd?: string;
    amount?: string;
    currencyCode?: string;
    hideIfZero?: boolean;
};

const TransactionItem = ({
    itemName,
    amount = '0',
    currencyCode,
    hideIfZero,
    isoFrom,
    isoEnd,
    ...props
}: ComponentPropsWithoutRef<'div'> & TransactionItemProps) => {
    if (!currencyCode || (hideIfZero && amount === '0')) {
        return <></>;
    }

    const formattedAmount = formatPaddleCurrency(amount ?? '0', currencyCode);
    const fromDate = isoFrom ? new Date(Date.parse(isoFrom)) : undefined;
    const endDate = isoEnd ? new Date(Date.parse(isoEnd)) : undefined;
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    };

    const fromDisplayDate = fromDate?.toLocaleDateString(undefined, { ...dateFormatOptions });
    const endDisplayDate = endDate?.toLocaleDateString(undefined, dateFormatOptions);

    return (
        <div className='flex items-start' {...props}>
            <Text.Body className='leading-4'>
                <span>{itemName}</span>
                {fromDisplayDate && endDisplayDate && (
                    <>
                        <br />
                        <span className='text-[12px] text-textSecondary'>Until {endDisplayDate}</span>
                    </>
                )}
            </Text.Body>
            <Text.H4 className='ml-auto'>{formattedAmount}</Text.H4>
        </div>
    );
};

type RecurringSummaryProps = {
    isLoading: boolean;
    cost?: string;
    currencyCode?: string;
    paymentDate?: string | null;
    credit?: string;
};

const RecurringSummary = ({
    cost,
    paymentDate,
    currencyCode,
    credit,
    isLoading,
    ...props
}: ComponentPropsWithoutRef<'p'> & RecurringSummaryProps) => {
    if (isLoading || !paymentDate || !cost || !currencyCode) {
        return <></>;
    }

    const formattedCost = formatPaddleCurrency(cost, currencyCode);
    const formattedCredit = credit ? formatPaddleCurrency(credit, currencyCode) : undefined;

    const nextPaymentDay = new Date(Date.parse(paymentDate)).toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    return (
        <p {...props}>
            Then <span className='font-semibold'>{formattedCost}</span> inc. tax starting on{' '}
            <span className='font-semibold'>{nextPaymentDay}</span>.
            <br />
            {credit && credit !== '0' && (
                <span className='text-textSecondary'>
                    A credit of {formattedCredit} will be applied to your next bill.
                </span>
            )}
        </p>
    );
};

const PaymentDisclaimer = ({
    showDisclaimer,
    managementUrl,
    ...props
}: ComponentPropsWithoutRef<'p'> & { showDisclaimer: boolean; managementUrl?: string | null }) => {
    if (!showDisclaimer) {
        return <></>;
    }

    return (
        <p className='text-textSecondary' {...props}>
            Your payment details on file will be used.
            {managementUrl && (
                <>
                    {' '}
                    To update your payment information, please visit this{' '}
                    <a
                        href={managementUrl ?? ''}
                        className={buttonVariants({ variant: 'link' })}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        portal
                    </a>
                    .
                </>
            )}
        </p>
    );
};
