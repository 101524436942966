import {
    CloneStreamDataColumnDefinition,
    ComparisonStreamDataColumnDefinition,
    FormattedStreamValue,
    ShapeName,
    StreamDataColumn,
    StreamDataColumnDefinition,
    type SingleStreamDataColumnDefinition,
    type StreamMetadata
} from '@squaredup/data-streams';
import { Opaque, PartialDeep, UnwrapOpaque } from 'type-fest';

/**
 * Properties that can be set to override the values provided by the plugin
 */
export type MetadataOverrides = PartialDeep<
    StreamDataColumnDefinition | CloneStreamDataColumnDefinition | ComparisonStreamDataColumnDefinition
>;

/**
 * A single column definition, and where it came from.
 */
export type ColumnDefinitionEntry = {
    source: keyof StreamMetadata['columnDefinitions'];
    definition: SingleStreamDataColumnDefinition;
};

interface CommonColumnProps {
    metadata?: StreamDataColumn;
    metadataOverrides?: MetadataOverrides;
    /**
     * Definitions for the column from the metadata.
     * This gives us information about which column properties were set where,
     * e.g. in the data stream definition, plugin response, or request (metadata editor).
     */
    definitions?: ColumnDefinitionEntry[];
    shape: { name: ShapeName; config: Record<string, unknown> };
    sampleValue: FormattedStreamValue;
}

export type PatternStateColumn = Pick<CommonColumnProps, 'metadataOverrides'> & {
    isPattern: true;
    /**
     * The pattern which generated this column.
     * This matches the key of columnMap in the overall state object.
     */
    pattern: string;
    isClone: false;
    isCloneable: false;
    isComparison: false;
    isComparable: false;
    isComputed: false;
    /**
     * Pattern columns can have shapes when they're set in metadata overrides.
     */
    shape?: { name: ShapeName; config: Record<string, unknown> };
};

export type StateColumn = CommonColumnProps & {
    isPattern: false;
    /**
     * The name of the column.
     * This matches the key of columnMap in the overall state object.
     */
    columnName: string;
    isClone: false;
    isCloneable: boolean;
    isComparison: false;
    isComparable: boolean;
    isComputed: false;
};

export type CloneStateColumn = CommonColumnProps & {
    isPattern: false;
    /**
     * The name of the column, including any [Expanded] prefix.
     * This matches the key of columnMap in the overall state object.
     */
    columnName: string;
    /**
     * The name of the column, minus any [Expanded] prefix
     */
    cloneName: string;
    isClone: true;
    isCloneable: false;
    isComparison: false;
    isComparable: boolean;
    isComputed: false;
    // True if the clone column was created by the metadata editor and so can be edited or removed
    isEditable: boolean;
    /**
     * True if this column is in the process of being cloned -
     * i.e. we've set the metadata override to create the column and are waiting for a response
     */
    isBeingCloned: boolean;
    /**
     * The name of the column this column is a clone of
     */
    sourceColumnName: string;
};

export type ComparisonStateColumn = CommonColumnProps & {
    isPattern: false;
    /**
     * The name of the column, including any [Expanded] prefix.
     * This matches the key of columnMap in the overall state object.
     */
    columnName: string;
    /**
     * The name of the column, minus any [Expanded] prefix
     */
    comparisonName: string;
    isClone: false;
    isCloneable: false;
    isComparison: true;
    isComparable: false;
    isComputed: false;
    isEditable: boolean;
    sourceColumnName: string;
};

export type ComputedStateColumn = CommonColumnProps & {
    isPattern: false;
    /**
     * The name of the column.
     * This matches the key of columnMap in the overall state object.
     */
    columnName: string;
    isClone: false;
    isCloneable: false;
    isComparison: false;
    isComparable: false;
    isComputed: true;
    isEditable: boolean;
};

export type DataStreamMetadataStateColumn =
    | PatternStateColumn
    | StateColumn
    | CloneStateColumn
    | ComparisonStateColumn
    | ComputedStateColumn;
export type DataStreamMetadataState = Opaque<
    {
        /**
         * True if the state has not been flushed to the tile config since
         * the last time the metadata was edited
         */
        tileConfigUpdatePending: boolean;
        /**
         * True if the data stream we got data from has one or more columns defined in its
         * definition, or returned as metadata in the plugin response.
         *
         * False in the case of data streams that have no defined metadata, like Web API.
         */
        hasDefinedMetadata: boolean;
        /**
         * State object where the key is the column name as returned in data stream metadata
         * (meaning cloned columns will have their <sourceColumnName>[Expanded]. prefix)
         */
        columnMap: Map<string, DataStreamMetadataStateColumn>;
    },
    'MetadataEditorState'
>;

/**
 * Returns true if the column is a normal or clone column
 */
export const isNonPatternColumn = (
    c: DataStreamMetadataStateColumn
): c is StateColumn | CloneStateColumn | ComparisonStateColumn | ComputedStateColumn => {
    return !c.isPattern;
};

/**
 * Get the full column name of a clone column, as it appears in the
 * data stream metadata and in the key of the columnMap property of the reducer state.
 */
export const getCloneColumnName = (sourceColumnName: string, cloneName: string) =>
    `${sourceColumnName}[Expanded].${cloneName}`;

export const getComparisonColumnName = (sourceColumnName: string, compareTo: string, comparisonName: string) =>
    `${sourceColumnName}[Expanded].comparedTo[${compareTo}].${comparisonName}`;

/**
 * Create a new piece of metadata editor state,
 * setting required internal properties
 */
export const createMetadataEditorState = (state: UnwrapOpaque<DataStreamMetadataState>): DataStreamMetadataState => {
    /**
     * Include the map contents when stringifying the state (for hook dependencies)
     */
    Object.assign(state.columnMap, {
        toJSON: () => {
            return JSON.stringify([...state.columnMap.entries()]);
        }
    });

    return state as DataStreamMetadataState;
};
