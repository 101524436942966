import { FeatureDisplay, FeatureKey, getFeatureDisplay, listTierTemplates, TierTemplate, TierTemplateId } from '@squaredup/tenants';
import { isProd } from 'lib/environment';

// Product codes, used for certain lookups (or if you want to dynamically fetch prices)
/* cSpell:disable */
export const STARTER_PADDLE_ID = isProd ? 'pro_01jbxw3053bqe6wqn0wcszd4zw' : 'pro_01ja8dv9n3k0d7ckv0d9fmbcsr';
export const PRO_PADDLE_ID = isProd ? 'pro_01jbxw3a2gde98qe5nr68rw6v5' : 'pro_01ja8ee71jcdgefq6wfjxj3y8r';
export const ENTERPRISE_PADDLE_ID = isProd ? 'pro_01jbxw3qb58vj8mzz9q177m9tj' : 'pro_01ja8eeh4zt8ftbqt9e5jt0mcx';

// Prices, which is what users actually need to choose between.
export const STARTER_MONTHLY_PADDLE_ID = isProd ? 'pri_01jbxw7bm8k21msvcwqj477nrd' : 'pri_01ja8dynesc8y5e77d37k2h851';
export const STARTER_ANNUALLY_PADDLE_ID = isProd ? 'pri_01jbxw5w0jk3ak8vna48c9hdad' : 'pri_01ja8e0stmj3t1c6536yg4jxhb';
export const PRO_MONTHLY_PADDLE_ID = isProd ? 'pri_01jbxwervp9d0t59fssb9qq4n5' : 'pri_01ja8ej1zgtzz0zedkh7b6k9r8';
export const PRO_ANNUALLY_PADDLE_ID = isProd ? 'pri_01jbxwe91knqg0ycwhhwd35vc8' : 'pri_01ja8ek2rnx5yxnhq50qxz25zh';
export const ENTERPRISE_ANNUALLY_PADDLE_ID = isProd
    ? 'pri_01jbxwjcrs1m043xqjj4rwfqtd'
    : 'pri_01ja8egf58x4javw7h2s1ghg97';
/* cSpell:enable */

const tierTemplates = listTierTemplates().reduce(
    (acc, val) => ({
        ...acc,
        [val.id]: val
    }),
    {} as Record<TierTemplateId, TierTemplate>
);

type PlanDefinition = {
    productId: string;
    monthlyPriceId?: string;
    annualPriceId?: string;
    tierTemplate: TierTemplate;
    includes: string;
    items: {
        displayName?: string;
        feature?: FeatureKey;
        prefix?: string;
        suffix?: string;
    }[];
};

const planDefinitions: PlanDefinition[] = [
    {
        productId: STARTER_PADDLE_ID,
        monthlyPriceId: STARTER_MONTHLY_PADDLE_ID,
        annualPriceId: STARTER_ANNUALLY_PADDLE_ID,
        tierTemplate: tierTemplates['starter'],
        includes: 'Free',
        items: [
            { feature: 'dataSources' },
            { feature: 'monitors', suffix: '(usage limits apply)' },
            { feature: 'openAccess', suffix: '(usage limits apply)' },
            { displayName: 'Online support' },
            { feature: 'dataQueries', prefix: 'Usage limits:' }
        ]
    },
    {
        productId: PRO_PADDLE_ID,
        monthlyPriceId: PRO_MONTHLY_PADDLE_ID,
        annualPriceId: PRO_ANNUALLY_PADDLE_ID,
        tierTemplate: tierTemplates['pro'],
        includes: 'Starter',
        items: [
            { feature: 'dataSources' },
            { feature: 'workspaces' },
            { feature: 'accessControl', displayName: 'Role-based access control' },
            { feature: 'authenticatedSharing' },
            { feature: 'dataQueries', prefix: 'Usage limits:' }
        ]
    },
    {
        productId: ENTERPRISE_PADDLE_ID,
        annualPriceId: ENTERPRISE_ANNUALLY_PADDLE_ID,
        tierTemplate: tierTemplates['enterprise'],
        includes: 'Pro',
        items: [
            { displayName: 'On-prem data sources', feature: 'relayAgents' },
            { feature: 'apiUsage' },
            { displayName: 'Volume discounts' },
            { displayName: 'Dedicated support' },
            { feature: 'dataQueries', prefix: 'Usage limits:' }
        ]
    }
];

export const isMonthlyPrice = (priceId: string) => planDefinitions.some((plan) => plan.monthlyPriceId === priceId);

export const formatFeatureForPlanDisplay = (featureDisplay: FeatureDisplay, feature: TierTemplate['features'][number], prefix?: string, suffix?: string) => {
    const prefixString = prefix ? prefix + ' ' : '';
    const suffixString = suffix ? ' ' + suffix : '';
    
    if (feature.type === 'enabledLicenced') {
        return `${prefixString}${featureDisplay.displayName}${suffixString}`;
    }

    const featurePlural = featureDisplay.displayName.toLowerCase();
    if ('isUnlimited' in feature.limit) {
        return `${prefixString}Unlimited ${featurePlural}${suffixString}`;
    }

    const formattedValue = Intl.NumberFormat().format(feature.limit.value);

    return featureDisplay.overPeriod
        ? `${prefixString}${formattedValue} ${featurePlural} / ${featureDisplay.overPeriod}${suffixString}`
        : `${prefixString}Up to ${formattedValue} ${featureDisplay.unit ?? ''} ${featurePlural}${suffixString}`;
};

export const plans = planDefinitions.map((definition) => ({
    ...definition,
    items: definition.items
        .map(({ displayName, feature, prefix, suffix }) => {
            if (displayName) {
                return { displayName, feature };
            }

            const featureDisplay = feature ? getFeatureDisplay(feature) : undefined;
            const limit = definition.tierTemplate.features.find((f) => f.key === feature);
        
            if (!featureDisplay || !limit) {
                return undefined;
            }

            return { displayName: formatFeatureForPlanDisplay(featureDisplay, limit, prefix, suffix), feature };
        })
        .filter((item) => item !== undefined)
}));
