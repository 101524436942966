import { FeatureFlagName } from '@squaredup/feature-flags';
import { useFlag } from '../lib/useFlag';

export interface FeatureFlagProps {
    flagName: FeatureFlagName;
}

/**
 * Show the child nodes only if the specified flag is set
 */
export const BehindFlag: React.FC<FeatureFlagProps> = ({ flagName, children }) => {
    const flagIsSet = useFlag(flagName);

    return flagIsSet ? <>{children}</> : <></>;
};

/**
 * Hide the child nodes only if the specified flag is set
 */
export const HiddenByFlag: React.FC<FeatureFlagProps> = ({ flagName, children }) => {
    const flagIsSet = useFlag(flagName);

    return !flagIsSet ? <>{children}</> : <></>;
};

/**
 * Show the wrapped component only if the specified flag is set
 */
export function behindFlag<T extends JSX.IntrinsicAttributes>(
    flagName: FeatureFlagName,
    WrappedComponent: React.FC<T>
) {
    const ComponentBehindFlag: React.FC<T> = (props: T) => {
        return (
            <BehindFlag flagName={flagName}>
                <WrappedComponent {...props}></WrappedComponent>
            </BehindFlag>
        );
    };

    return ComponentBehindFlag;
}
