import { useRef } from 'react';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { DataStreamTileEditorStepSelector } from '../steps/DataStreamTileEditorStepSelector';

const radixPointerEventType = 'dismissableLayer.pointerDownOutside';

export const AnalyticsStepsSelector: React.FC = () => {
    const stepComponentRef = useRef<HTMLDivElement>(null);

    const { 
        currentEditorStep, 
        editorSteps, 
        isDocked,
        setCurrentEditorStep
    } = useTileEditorStepsContext();

    return (
        <div 
            className='flex flex-col'
            ref={stepComponentRef}
        >
            <DataStreamTileEditorStepSelector
                editorSteps={editorSteps}
                currentEditorStep={currentEditorStep}
                isDocked={isDocked}
                setCurrentEditorStep={setCurrentEditorStep}
                onInteractOutside={(e) => {
                    if (
                        e.type === radixPointerEventType && 
                        !stepComponentRef.current?.contains(e.target as any)
                    ) {
                        setCurrentEditorStep(undefined);
                    }
                }}
            />
        </div>
    );
};
