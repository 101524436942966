import { BuiltInGroups } from '@squaredup/constants';
import type { UserGroupModel, UserTenantProperties } from 'dynamo-wrapper';
import { sortBy } from 'lodash';
import { useQuery } from 'react-query';
import API from './API';
import { handleError, handleResponse } from './util';

export const TENANT_USERS_QUERY_KEY = 'tenant-users';
export const TENANT_USER_GROUPS_QUERY_KEY = 'tenant-user-groups';
export const USER_GROUPS_QUERY_KEY = 'user-groups';
export const EDITABLE_USER_GROUPS = 'editable-user-groups';
export const EVERYONE_GROUP_ID_QUERY_KEY = 'EVERYONE_GROUP_ID_QUERY_KEY';

export const RecordUserLogin = (tenant: string) =>
    API.put(
        'user/login',
        {},
        {
            headers: {
                'squp-tenant': tenant
            }
        }
    )
        .then(handleResponse)
        .catch(handleError);

// User management
export const GetUsers = async () =>
    sortBy(await API.get('/tenant/users').then(handleResponse).catch(handleError), (user) => user.name);

export const AddUser = (data: { email: string; groupIds?: string[]; dashboardId?: string }) =>
    API.post('/tenant/users', data)
        .then(handleResponse)
        .catch((error) => handleError(error));

export const DeleteUser = (email: string) =>
    API.delete('/tenant/users', { data: { email } }).then(handleResponse).catch(handleError);

// User Group management
export const GetGroups = () => API.get<UserGroupModel[]>('/tenant/groups').then(handleResponse).catch(handleError);

export const GetGroupsWithMembers = () =>
    API.get<UserGroupModel[]>('/tenant/groups?includeMembers=true').then(handleResponse).catch(handleError);

export const GetGroupsForUser = async (externalId: string) =>
    API.get<UserGroupModel[]>(`/tenant/groups?user=${encodeURIComponent(externalId)}`)
        .then(handleResponse)
        .catch(handleError);

export const GetEditableGroups = async () => {
    const allUserGroups = await API.get<UserGroupModel[]>('/tenant/groups').then(handleResponse).catch(handleError);
    return allUserGroups.filter((userGroup) => userGroup.name !== BuiltInGroups.Everyone.name);
};

export const CreateGroup = (data: { displayName: string; description?: string; users?: string[] }) =>
    API.post('/tenant/groups', data).then(handleResponse).catch(handleError);

export const UpdateGroup = (groupId: string, data: { displayName: string; description?: string }) =>
    API.put(`/tenant/groups/${groupId}`, data).then(handleResponse).catch(handleError);

export const DeleteGroup = (groupId: string) =>
    API.delete(`tenant/groups/${groupId}`).then(handleResponse).catch(handleError);

export const AddUserToGroup = (groupId: string, email: string) =>
    API.post(`/tenant/groups/${groupId}/users`, { email }).then(handleResponse).catch(handleError);

export const RemoveUserFromGroup = (groupId: string, email: string) =>
    API.delete(`/tenant/groups/${groupId}/users`, { data: { email } }).then(handleResponse).catch(handleError);

export const useEveryoneGroupId = () => {
    return useQuery(
        EVERYONE_GROUP_ID_QUERY_KEY,
        async () => {
            const groups = await GetGroups();
            return groups.find((group) => group.name === 'everyone')?.id;
        },
        {
            cacheTime: Number.POSITIVE_INFINITY
        }
    );
};

export const SetUserForTenantProperty = <T extends keyof UserTenantProperties>(
    key: T,
    value: UserTenantProperties[T]
) =>
    // Returns 204
    API.put<void>('/user/tenantproperties', { key, value })
        .then(handleResponse)
        .catch((e) => handleError(e));

export const GetUser = () => API.get('/user').then(handleResponse).catch(handleError);

export const GetTenantUserProperties = (signal?: AbortSignal): Promise<UserTenantProperties> =>
    API.get('/user/tenantproperties', {
        signal
    })
        .then(handleResponse)
        .catch(handleError);
