import { faArrowsSplitUpAndLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { BehindFlag } from 'components/BehindFlag';
import { useGraph, useHealthCache, useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { getNodeHealths } from 'components/map/hooks/useNodeHealth';
import { useNodeHealthCount } from 'components/map/hooks/useNodeHealthCount';
import { useSelectiveExpandNode } from 'components/map/hooks/useSelectiveExpandNode';
import { mapBackgroundColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { ButtonHTMLAttributes, DetailedHTMLProps, useEffect, useState } from 'react';
import { NodeToolbar, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';

export function DownLeftDottedArrow({ className }: { className?: string }) {
    return (
        <svg className={className} viewBox='0 0 32 74' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6 74L11.9562 64.1077L0.41113 63.8957L6 74ZM7.53839 65.4951C7.83269 63.4259 8.23837 61.6224 8.73216 60.0254L6.8214 59.4346C6.29321 61.1429 5.86593 63.0507 5.55831 65.2135L7.53839 65.4951ZM10.9165 54.9481C11.8844 53.2596 12.9893 51.8185 14.1856 50.4592L12.6843 49.1378C11.4265 50.5669 10.2339 52.1174 9.18138 53.9534L10.9165 54.9481ZM18.1309 46.3277C19.4207 45.004 20.747 43.5947 22.038 41.9604L20.4686 40.7207C19.2425 42.2729 17.9734 43.6235 16.6985 44.932L18.1309 46.3277ZM25.3174 37.0305C26.1896 35.4473 27.0103 33.6753 27.7578 31.6551L25.8821 30.9611C25.1682 32.8903 24.3887 34.5715 23.5656 36.0655L25.3174 37.0305ZM29.4657 26.0361C29.8971 24.2846 30.2827 22.385 30.6151 20.3163L28.6405 19.999C28.3161 22.018 27.9411 23.8636 27.5237 25.5577L29.4657 26.0361ZM31.3586 14.5037C31.5366 12.694 31.6801 10.7756 31.7859 8.73902L29.7886 8.63527C29.6843 10.6433 29.543 12.531 29.3682 14.308L31.3586 14.5037ZM31.9773 2.95387C31.9924 1.9925 32 1.00811 32 0H30C30 0.998005 29.9924 1.97196 29.9776 2.9226L31.9773 2.95387Z' />
        </svg>
    );
}

export function CircleMapButton({
    children,
    className,
    ...props
}: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
    const background = useNetworkBackground();

    return (
        <button
            {...props}
            className={clsx(
                'rounded-full text-xs size-6 border text-center relative',
                mapBackgroundColors[background],
                className
            )}
        >
            <span
                className='absolute top-1/2 left-1/2 size-[calc(100%+0.5rem)] -translate-x-1/2 -translate-y-1/2'
                aria-hidden='true'
            />
            {children}
        </button>
    );
}

export function UpstreamOptions({
    node,
    isVisible,
    onClick
}: {
    node: GraphologyNodeAttributes;
    isVisible: boolean;
    onClick?: () => void;
}) {
    const graph = useGraph();
    const cache = useHealthCache();

    const [hasRecursive, setHasRecursive] = useState(false);

    const expandNode = useSelectiveExpandNode();

    const hiddenUpstreamNodes = graph.filterOutNeighbors(
        node.id,
        (_, n) => n.hidden && Boolean(n.data || n.groupedData)
    );

    const unhealthyCount = useNodeHealthCount(node, 'error', 'out');

    const { isHovered: isHoveredUnhealthy, ...unhealthyProps } = useHover();

    const secondLevelUpstreamNodes = graph
        .mapOutNeighbors(node.id, (_, n1) =>
            n1.hidden && Boolean(n1.data || n1.groupedData)
                ? graph.filterOutNeighbors(n1.id, (__, n2) => n2.hidden && Boolean(n2.data || n2.groupedData))
                : []
        )
        .flat();

    useEffect(() => {
        getNodeHealths(
            cache,
            secondLevelUpstreamNodes.map((id) => {
                const n = graph.getNodeAttributes(id)!;
                return n.data! || n.groupedData!;
            })
        ).then((healths) => setHasRecursive(Object.values(healths).includes('error')));
    }, [secondLevelUpstreamNodes, cache, graph]);

    if (hiddenUpstreamNodes.length === 0) {
        return null;
    }

    return (
        <BehindFlag flagName='expandHealthMap'>
            <NodeToolbar isVisible={isVisible} position={Position.Top} offset={10}>
                <div className='grid grid-cols-12 w-[15.5rem] left-1/2 z-50 group'>
                    <div className='flex flex-col row-start-3 row-span-2 col-start-5 col-span-4'>
                        <div className='flex justify-end w-full items-end space-x-[2.2rem] pb-2'>
                            <CircleMapButton
                                {...unhealthyProps}
                                className={clsx('border-statusErrorPrimary', !unhealthyCount && 'opacity-0')}
                                disabled={!unhealthyCount}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    expandNode(node, 'out', true, false);
                                    onClick?.();
                                }}
                            >
                                {unhealthyCount}
                            </CircleMapButton>
                            <CircleMapButton
                                className={clsx(
                                    'border-statusUnknownPrimary',
                                    hiddenUpstreamNodes.length === unhealthyCount && 'opacity-0'
                                )}
                                disabled={hiddenUpstreamNodes.length === unhealthyCount}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    expandNode(node, 'out', false, false);
                                    onClick?.();
                                }}
                            >
                                {hiddenUpstreamNodes.length}
                            </CircleMapButton>
                        </div>
                        <div className='flex justify-center h-16 z-50'>
                            <DownLeftDottedArrow
                                className={clsx(
                                    'w-1/2 h-full fill-statusErrorPrimary -scale-y-100',
                                    !unhealthyCount && 'opacity-0'
                                )}
                            />
                            <DownLeftDottedArrow
                                className={clsx(
                                    'w-1/2 h-full fill-statusUnknownPrimary rotate-180',
                                    hiddenUpstreamNodes.length === unhealthyCount && 'opacity-0'
                                )}
                            />
                        </div>
                    </div>
                    {Boolean(unhealthyCount) && hasRecursive && isHoveredUnhealthy && (
                        <div
                            {...unhealthyProps}
                            className='col-span-4 row-start-1 row-span-2 px-2 col-start-1 -mb-5 flex flex-col items-end justify-start'
                        >
                            <div className=''>
                                <CircleMapButton
                                    className='border-statusErrorPrimary -ml-8'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        expandNode(node, 'out', true, true);
                                        onClick?.();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faArrowsSplitUpAndLeft} />
                                </CircleMapButton>
                            </div>
                            <svg
                                className='w-10 h-full'
                                viewBox='-20 0 46 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M26 30L15.9255 35.6425L16.0762 24.0965L26 30ZM17.0344 30.5625C14.7763 30.3012 12.803 29.9161 11.0814 29.3884L11.6676 27.4762C13.244 27.9594 15.0927 28.3245 17.2643 28.5758L17.0344 30.5625ZM5.51921 26.5244C3.85645 25.1333 2.69457 23.4055 1.88602 21.3412L3.74827 20.6118C4.45216 22.4089 5.43213 23.8439 6.80256 24.9905L5.51921 26.5244ZM0.494486 15.3677C0.270924 13.5515 0.149103 11.5601 0.0821705 9.38983L2.08122 9.32818C2.14715 11.4661 2.26624 13.3909 2.4795 15.1234L0.494486 15.3677ZM0.00247955 3.46373C0 2.50573 0 1.5176 0 0.5H2C2 1.51811 2 2.5036 2.00247 3.45855L0.00247955 3.46373Z'
                                    fill='#DE0038'
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </NodeToolbar>
        </BehindFlag>
    );
}
