import { Toggle } from '@/components/forms/Toggle';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StreamDataColumn, type DataStreamSortingConfig } from '@squaredup/data-streams';
import Button from 'components/button';
import Field from 'components/forms/field/Field';
import { useCallback } from 'react';
import { Controller, DeepPartial, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import type { PartialDeep } from 'type-fest';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import {
    SortingValues,
    getSortingColumns,
    getSortingFormDefaultValues,
    getValidSort
} from 'ui/editor/dataStream/utilities/sorting';
import { SortRow } from './SortRow';

interface SortingFormProps {
    columns: StreamDataColumn[];
    onChange?: (
        event:
            | { isValid: true; formData: DataStreamSortingConfig['sort'] }
            | { isValid: false; formData: PartialDeep<SortingValues> }
    ) => void;
    sortConfig: DataStreamSortingConfig['sort'];
}

export const SortingForm: React.FC<SortingFormProps> = ({ columns, onChange, sortConfig }) => {
    const allColumnOptions = getSortingColumns(columns);
    const defaultValues = getSortingFormDefaultValues(sortConfig, getSortingColumns(columns));

    const formProps = useForm<SortingValues>({
        defaultValues,
        mode: 'onChange'
    });

    const { control, watch, setValue } = formProps;
    const formValues = watch();

    const {
        fields: sortByFields,
        append: appendSortBy,
        remove: removeSortBy
    } = useFieldArray({
        control,
        name: 'by'
    });

    const selectedColumns = formValues.by?.map((field) => field?.column).filter(Boolean) || [];

    const availableColumnOptions = allColumnOptions.filter((option) => !selectedColumns.includes(option.value));

    const handleChange = useCallback(
        (values: DeepPartial<SortingValues>) => {
            const validSort = getValidSort(values);
            if (validSort) {
                onChange?.({
                    isValid: true,
                    formData: validSort
                });
            } else {
                onChange?.({
                    isValid: false,
                    formData: values
                });
            }
        },
        [onChange]
    );

    useFormChange(watch, handleChange);

    const selectedColumnIsValid = allColumnOptions.some((o) => o.value === formValues.by?.[0]?.column);

    const handleTopToggle = (enabled: boolean) => {
        setValue('enableTop', enabled);
        if (enabled && !formValues.top) {
            setValue('top', '10');
        }
    };

    const handleAddSort = () => {
        appendSortBy({ column: null, direction: 'asc' });
    };

    return (
        <FormProvider {...formProps}>
            <form className='space-y-4'>
                <div className='grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_1.5rem] gap-2'>
                    {sortByFields.length > 0 && (
                        <>
                            <Field.Label label='Sort by' spacing='none' className='col-start-1 mt-2 -mb-2' />
                            <Field.Label label='Direction' spacing='none' className='mt-2 -mb-2' />
                        </>
                    )}

                    {sortByFields.map((field, index) => (
                        <SortRow
                            key={field.id}
                            fieldIndex={index}
                            columns={columns}
                            allColumnOptions={allColumnOptions}
                            columnOptions={availableColumnOptions}
                            deleteSort={() => removeSortBy(index)}
                        />
                    ))}

                    <Button
                        onClick={handleAddSort}
                        variant='secondary'
                        className='col-start-1 mt-2 w-fit'
                        data-testid='grouping-addGroup'
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-3' />
                        <span>Add sort by</span>
                    </Button>
                </div>

                <Field.Label className='mt-2 text-textPrimary' spacing='none' label='Top'>
                    <div className='flex flex-row items-center col-start-1 mb-2 space-x-2 min-h-[2.92rem]'>
                        <Controller
                            name='enableTop'
                            render={({ field: { value } }) => (
                                <Toggle
                                    checked={value}
                                    disabled={!selectedColumnIsValid}
                                    aria-label='Enable top'
                                    data-testid='sorting-top'
                                    onCheckedChange={handleTopToggle}
                                />
                            )}
                        />
                        {formValues.enableTop && (
                            <div>
                                <Field.Input type='number' name='top' min={1} placeholder='Number of results' />
                            </div>
                        )}
                    </div>
                </Field.Label>
            </form>
        </FormProvider>
    );
};
