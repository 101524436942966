import { cn } from '@/lib/cn';
import NetworkSkeleton from 'components/map/NetworkSkeleton';
import { useAppContext } from 'contexts/AppContext';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { ReactNode, useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { LayoutFlow } from './components/LayoutFlow';
import { NetworkMapStoreProvider } from './context/NetworkMapStoreContext';
import { useInitalObjectMapData } from './data/useInitalObjectMapData';
import { NetworkMapStoreState } from './NetworkMapStore';
import { LayoutTypes } from './types';

export interface MapProps {
    className?: string;
    expandedNodeIds?: string[];
    layoutType?: LayoutTypes;
    background?: NetworkMapStoreState['background'];
    controls?: ReactNode;
    /**
     * If true, we'll only show unhealthy nodes when the root node is unhealthy
     * if the root node is healthy, we'll just show monitored nodes
     * if unmonitored, we'll show all nodes
     **/
    filterInitalBasedOnHealth?: boolean;
}

export const useMapId = () => {
    const [mapId] = useState(() => `map-${uuid()}`);
    return mapId;
};

export const NetworkMap: React.FC<MapProps> = ({
    className,
    expandedNodeIds: initialExpandedNodeIds,
    layoutType = LayoutTypes.hierarchyVertical,
    background = 'backgroundSecondary',
    filterInitalBasedOnHealth,
    controls
}) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace(currentWorkspaceID);

    const mapId = useMapId();

    const { data, isLoading } = useInitalObjectMapData(initialExpandedNodeIds ?? [workspace?.data.id]);

    // We need to wait for workspaces health data to be loaded before rendering the map
    // this is normally already done unless refreshing the page
    const { isLoading: isLoadingWorkspaces } = useWorkspacesWithHealthRollup();

    if (isLoadingWorkspace || isLoading || isLoadingWorkspaces) {
        return <NetworkSkeleton variant={layoutType === LayoutTypes.hierarchyVertical ? 'hierarchical' : 'central'} />;
    }

    return (
        <div id={mapId} className={cn('h-full w-full relative', className)}>
            <div className='absolute inset-0 w-full'>
                <NetworkMapStoreProvider
                    initalData={data}
                    initalExpandedNodeIds={initialExpandedNodeIds ?? [workspace?.data.id]}
                    filterInitalBasedOnHealth={filterInitalBasedOnHealth}
                    mapId={mapId}
                    layoutType={layoutType}
                    background={background}
                >
                    <ReactFlowProvider>
                        <LayoutFlow controls={controls} />
                    </ReactFlowProvider>
                </NetworkMapStoreProvider>
            </div>
        </div>
    );
};
