import DropdownMenu from '@/components/DropdownMenu';
import { cn } from '@/lib/cn';
import { faAdd, faChevronRight, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { Dispatch, SetStateAction } from 'react';
import { resolveEachMatchedType } from '../../utilities/resolveEachMatchedType';
import { SearchBar } from '../SearchBar';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { useFilterSearchItems } from '../hooks/useFilterSearchItems';
import { TileEditorAction } from '../state/TileEditorActions';
import { DataStreamFilters } from '../state/dataStream/DataStreamState';
import { sortBy } from 'lodash';

interface DataStreamFilterMenuProps extends Pick<ReturnType<typeof useDataStreamFilters>, 'objectScopeFilterOptions'> {
    scopes: any[];
    filters: DataStreamFilters;
    filtersApplied: boolean;
    setScopeModalOpen: Dispatch<SetStateAction<boolean>>;
    dispatch: (action: TileEditorAction) => void
}

interface Scope {
    id: string;
    displayName: string;
}
const scopeSearchKeys = [{ name: 'displayName', weight: 10 }];
const objectTypeSearchKeys = [{ name: 'name', weight: 10 }];

export const DataStreamFilterMenu: React.FC<DataStreamFilterMenuProps> = ({
    scopes,
    filters,
    filtersApplied,
    objectScopeFilterOptions,
    setScopeModalOpen,
    dispatch
}) => {
    const portalContainer = useDOMElement('dataStreamEditorSteps', null);

    const { 
        searchTerm: objectTypesSearchTerm, 
        searchItems: objectTypesToShow, 
        setSearchTerm: setObjectTypeSearchTerm 
    } = useFilterSearchItems({
        keys: objectTypeSearchKeys,
        items: sortBy(
            objectScopeFilterOptions.map((type) => ({
                type,
                ...resolveEachMatchedType([type])[0]
            })),
            'name'
        )
    });

    const { 
        searchTerm: scopesSearchTerm, 
        searchItems: scopesToShow, 
        setSearchTerm: setScopeSearchTerm 
    } = useFilterSearchItems({
        keys: scopeSearchKeys,
        items: scopes as Scope[]
    });

    return (
        <DropdownMenu onOpenChange={(isNowOpen) => {
            // Clear searches when closing the dropdown
            if (!isNowOpen) {
                setScopeSearchTerm('');
                setObjectTypeSearchTerm('');
            }
        }}>
            <DropdownMenu.Trigger>
                <button className={cn('px-3 text-white border rounded-md py-inputWithBorder border-dividerPrimary text-textPrimary leading-input bg-secondaryButtonBackground', filtersApplied && 'bg-secondaryButtonBackgroundHover border-outlineSecondary')}>
                    <FontAwesomeIcon icon={faFilter} />
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu 
                container={portalContainer}
                className='p-1 bg-tileBackground w-[220px]'
            >
                <DropdownMenu.Sub>
                    <DropdownMenu.SubTrigger className={cn(
                        'flex items-center justify-between gap-4 mb-[2px]', 
                        filters.objectType && 'bg-tileEditorComponentActive data-[highlighted]:bg-tileEditorComponentActive',
                        !filters.objectType && 'hover:bg-tileEditorComponent focus:bg-tileEditorComponent data-[state="open"]:bg-tileEditorComponent data-[highlighted]:bg-tileEditorComponent'
                    )}>
                        Type
                        <span className='flex items-center gap-4 whitespace-nowrap'>
                            {filters.objectType && <span className='text-sm text-textSecondary'>1 filter active</span>}
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </DropdownMenu.SubTrigger>

                    <DropdownMenu.SubMenu 
                        container={portalContainer}
                        className='p-1 bg-tileBackground w-[240px]'
                    >
                        <SearchBar 
                            placeholder='Search types' 
                            onChange={setObjectTypeSearchTerm} 
                            className='mb-2' 
                            initialValue={objectTypesSearchTerm}
                        />

                        <div className='max-h-[min(500px,80vh)] overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                            {objectTypesToShow?.map(({ name, icon, type }) =>
                                <DropdownMenu.Item 
                                    key={type}
                                    onSelect={() => dispatch({ 
                                        type: 'dataStream.updateFilters', 
                                        filters: { objectType: type === filters.objectType ? 
                                            undefined : 
                                            type
                                        }
                                    })}
                                    className={cn('p-2 hover:bg-tileEditorComponent focus:bg-tileEditorComponent', filters.objectType === type && 'bg-tileEditorComponentActive')}
                                >
                                    <div className='w-5 h-5 mr-3'>
                                        {icon && 
                                            <FontAwesomeIcon icon={icon} fixedWidth />
                                        }
                                    </div>
                                    <TruncatedText title={name ?? ''} className='flex-1 min-w-0 truncate' />
                                </DropdownMenu.Item>
                            )}
                        </div>

                        {!objectTypesToShow?.length && 
                            <DropdownMenu.Item disabled={true}>
                                No types to show
                            </DropdownMenu.Item>
                        }
                    </DropdownMenu.SubMenu>
                </DropdownMenu.Sub>

                <DropdownMenu.Sub>
                    <DropdownMenu.SubTrigger className={cn(
                        'flex items-center justify-between gap-4', 
                        filters.scopeId && 'bg-tileEditorComponentActive data-[highlighted]:bg-tileEditorComponentActive',
                        !filters.scopeId && 'hover:bg-tileEditorComponent focus:bg-tileEditorComponent data-[state="open"]:bg-tileEditorComponent data-[highlighted]:bg-tileEditorComponent'
                    )}>
                        <span className='mr-4'>
                            Collection
                        </span>

                        <span className='flex items-center gap-4 whitespace-nowrap'>
                            {filters.scopeId && <span className='text-sm text-textSecondary'>1 filter active</span>}
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </DropdownMenu.SubTrigger>

                    <DropdownMenu.SubMenu 
                        container={portalContainer}
                        className='p-1 bg-tileBackground w-[240px]'
                    >
                        <SearchBar 
                            placeholder='Search collections' 
                            onChange={setScopeSearchTerm} 
                            className='mb-2' 
                            initialValue={scopesSearchTerm}
                        />

                        <div className='max-h-[min(500px,80vh)] overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                            {scopesToShow?.map((scope) =>
                                <DropdownMenu.Item 
                                    key={scope.id}
                                    onSelect={() =>
                                        dispatch({ 
                                            type: 'dataStream.updateFilters', 
                                            filters: { scopeId: scope.id === filters.scopeId ? 
                                                undefined : 
                                                scope.id 
                                            }
                                        })
                                    }
                                    className={cn('p-2 hover:bg-tileEditorComponent focus:bg-tileEditorComponent', filters.scopeId === scope.id && 'bg-tileEditorComponentActive')}
                                >
                                    {scope.displayName}
                                </DropdownMenu.Item>
                            )}
                        </div>   
                        

                        {!scopesToShow?.length && 
                            <DropdownMenu.Item disabled={true}>
                                No collections to show
                            </DropdownMenu.Item>
                        }

                        <DropdownMenu.Separator className='my-1' />

                        <DropdownMenu.Item 
                            icon={<FontAwesomeIcon icon={faAdd} />}
                            onSelect={() => setScopeModalOpen(true)}
                        >
                            Create a collection
                        </DropdownMenu.Item>
                    </DropdownMenu.SubMenu>
                </DropdownMenu.Sub>

                <DropdownMenu.Separator className='my-1' />

                <DropdownMenu.Item onSelect={() => {
                    dispatch({ type: 'clearScope' });

                    dispatch({ 
                        type: 'dataStream.updateFilters', 
                        filters: {
                            objectType: undefined,
                            scopeId: undefined
                        }
                    });
                }} >
                    Clear filters
                </DropdownMenu.Item>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
