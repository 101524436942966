import { Price, Subscription, SubscriptionItem, SubscriptionPreview } from '@paddle/paddle-node-sdk';
import type { LicenceData, TenantSettings, Tier } from '@squaredup/tenants';
import { AxiosResponse } from 'axios';
import API from './API';
import Auth from './Auth';
import { handleError, handleResponse } from './util';
import { CURRENT_WORKSPACE } from './WorkspaceService';

/*
    react-query key for tenant data
 */
export const TENANT_FLAGS = 'tenantFlags';

// Defines the number of days before expiry that the user is notified
export const PAID_TENANT_RENEWAL_PERIOD = 7;

export type ProjectedTenantResponse = {
    id: string;
    name: string;
    displayName?: string;
    flags?: Record<string, unknown>;
    settings?: TenantSettings;
    licenceData?: LicenceData;
    tier?: Tier;
    callerPermissions: string[];
    stage?: string;
    debugUntil?: number;
};

export type TenantResponse = ProjectedTenantResponse & {
    debugEnabledUntil?: number;
};

export type TenantPutResquest = {
    displayName?: string;
};

type UpgradeRequest = {
    emailAddress: string;
    firstName: string;
    lastName: string;
    message: string;
    source: string;
};

export type TrialRequest = {
    emailAddress: string;
    firstName: string;
    lastName: string;
    company: string;
    jobTitle?: string;
    source: string;
};

export type PaddleSubscriptionInfo = {
    id: string;
    addressId: string;
    customerId: string;
    items: SubscriptionItem[];
    managementUrls: {
        updatePaymentMethod: string | null;
        cancel: string;
    };
};

export type SubscriptionUpdateRequest = {
    items: {
        priceId: string;
        quantity: number;
    }[];
    discountCode?: string;
};

/**
 * Fetch a list of all tenants, including their names
 */
export const Tenants = () => API.get<ProjectedTenantResponse[]>('/tenants').then(handleResponse).catch(handleError);

/**
 * Fetch the current tenant
 * @deprecated use the hook useTenant to get this data
 */
export const Tenant = () => API.get<TenantResponse>('/tenant').then(handleTenantResponse).catch(handleError);

/**
 * Update the current tenant
 */
export const UpdateTenant = (tenant: TenantPutResquest) =>
    API.put<TenantResponse>('/tenant', tenant).then(handleResponse).catch(handleError);

/**
 * Set the current tenant to a pending upgrade state
 */
export const SetUpgradePending = () =>
    API.put<TenantResponse>('/tenant/licence', { upgradePending: true }).then(handleResponse).catch(handleError);

export const UpdateTenantSetting = <T extends keyof TenantSettings>(key: T, value: TenantSettings[T]) =>
    UpdateTenantSettings({ [key]: value });

export const UpdateTenantSettings = (settings: Partial<TenantSettings>) =>
    API.put<TenantResponse>('tenant/setting', { settings: settings }).then(handleResponse).catch(handleError);

export const SendUpgradeRequest = (request: UpgradeRequest) =>
    API.post<{ success: boolean }>('/contact/upgrade', request).then(handleResponse).catch(handleError);

export const SendTrialRequest = (request: TrialRequest) =>
    API.post<{ success: boolean }>('/contact/trial', request).then(handleResponse).catch(handleError);

/**
 * Get subscription details
 */
export const GetSubscription = () =>
    API.get<PaddleSubscriptionInfo>('/subscription').then(handleResponse).catch(handleError);

export const PreviewUpdateSubscription = (request: SubscriptionUpdateRequest) =>
    API.post<SubscriptionPreview>('/subscription/preview', request).then(handleResponse).catch(handleError);

export const UpdateSubscription = (request: SubscriptionUpdateRequest) =>
    API.post<Subscription>('/subscription', request).then(handleResponse).catch(handleError);

export const GetSubscriptionPrices = (products: string[]) =>
    API.get<Price[]>('/subscription/prices', { params: { products } }).then(handleResponse).catch(handleError);

const handleTenantResponse = (response: AxiosResponse<TenantResponse>) => {
    const tenant = response.data;
    if (tenant?.licenceData?.disabled === true) {
        try {
            window.localStorage.removeItem('preferredTenant');
            window.localStorage.removeItem(CURRENT_WORKSPACE);
        } catch {
            // Local storage not available, probably.
        }
        Auth.logout();
    }
    return response.data;
};
