import type { DashboardVariable } from '@squaredup/dashboards';
import { type DataStreamBaseTileConfig, type DataStreamDefinitionId } from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import stringify from 'fast-json-stable-stringify';
import { omit } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import type { QueryClient } from 'react-query';
import type { DataStream } from 'services/DataStreamDefinitionService';
import { EditorSteps } from '../constants';
import { createDataStreamState, type DataStreamState } from './dataStream/DataStreamState';
import { createScopeState, type ScopeState } from './scope/ScopeState';
import { createTimeframeState, type TimeframeState } from './timeframe/TimeframeState';

export type DataStreamId = DataStreamDefinitionId['value'];

/*
 * Monitoring and KPI don't belong here, but aren't worth splitting out
 * into their own state slices yet as they have no actions of their own.
 */
type MonitorState =
    | {
          isMonitoringEnabled: false;
      }
    | {
          isMonitoringEnabled: true;
          frequency?: number;
      };

type KpiState = {
    isKpiEnabled: boolean;
};

export type TileEditorState = {
    /**
     * Data about the dashboard the tile editor is being used in.
     * This is safe to keep in the store as it does not change
     * while the editor is open.
     */
    dashboard: {
        variables: DashboardVariable[];
        workspaceId: string;
    };
    dataStream: DataStreamState;
    scope: ScopeState;
    timeframe: TimeframeState;
    monitor: MonitorState;
    kpi: KpiState;
    currentStep: EditorSteps | undefined;
    /**
     * The steps the user has visited since last selecting a data stream
     * (which resets the editor).
     */
    visitedSteps: EditorSteps[];
    /**
     * The current state of the tile editor converted to a tile config.
     * This is kept up to date automatically by TileEditorStateProvider.
     */
    asTileConfig: DataStreamBaseTileConfig;
};

/**
 * Dependencies used to produce side-effects.
 */
export type TileEditorStateReducerExternals = {
    queryClient: QueryClient;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
};

export type TileEditorReducerSideEffects = (externals: TileEditorStateReducerExternals) => void;

export type TileEditorStateInternal = TileEditorState & {
    /**
     * A function that performs any side-effects required as a result of an action.
     * This property can only be set in the reducer function and should not be executed
     * outside of `TileEditorStateProvider`.
     */
    sideEffects?: TileEditorReducerSideEffects;
    hasPendingConfigUpdate?: boolean;
};

/**
 * A data stream which has been extended for use in the tile editor state reducer.
 */
export type TileEditorDataStream = DataStream & {
    /**
     * Is this data stream a preset of a configurable data stream?
     */
    isPreset: boolean;
    /**
     * The display name of this data stream, prepended with
     * the name of the data stream this stream is a preset of (if any).
     */
    displayNameFull: string;
};

/**
 * Create an initial tile editor state object based on the given config.
 */
export const createTileEditorState = ({
    dataStreams,
    config,
    workspaceId,
    variables,
    previousState
}: {
    dataStreams: TileEditorDataStream[];
    config: DataStreamBaseTileConfig;
    workspaceId: string;
    variables: DashboardVariable[];
    /**
     * The previous state of the tile editor, if any.
     *
     * Any transient state that cannot be determined from the tile config
     * is taken from this state. This is useful when the tile config changes
     * and we reset the state but want to maintain transient state not
     * saved in the tile config like some filters or text the user has entered in search boxes.
     */
    previousState?: TileEditorState;
}): TileEditorStateInternal => {
    const scopeState = createScopeState({
        scopeConfig: config.scope,
        currentWorkspaceId: workspaceId,
        variables,
        configVariables: config.variables?.filter(isDefined) ?? [],
        previousState: previousState?.scope
    });

    const isNewTile = stringify(omit(config, ['title', 'description', 'variables', '_type'])) === stringify({});

    const tileNeedsVariablesCleared =
        config.variables != null && (variables.length === 0 || (!isNewTile && !scopeState.isVariableScope));

    const defaultScopeFilterValue = scopeState.type === 'predefinedScope' ? scopeState.scopeId : undefined;

    const dataStreamState = createDataStreamState({
        dataStreamConfig: config.dataStream,
        dataStreams,
        defaultFilters:
            defaultScopeFilterValue != null
                ? {
                      scopeId: defaultScopeFilterValue
                  }
                : undefined,
        previousState: previousState?.dataStream
    });

    const monitoringState: MonitorState =
        config.monitor == null
            ? { isMonitoringEnabled: false }
            : { isMonitoringEnabled: true, frequency: config.monitor.frequency };

    const kpiState = { isKpiEnabled: config.kpi == null ? false : true };

    return {
        dashboard: { variables, workspaceId },
        currentStep: previousState?.currentStep,
        visitedSteps: previousState?.visitedSteps ?? [],
        dataStream: dataStreamState,
        scope: scopeState,
        timeframe: previousState?.timeframe ?? createTimeframeState({ timeframeConfig: config.timeframe }),
        monitor: previousState?.monitor ?? monitoringState,
        kpi: previousState?.kpi ?? kpiState,
        asTileConfig: tileNeedsVariablesCleared ? {
            ...config,
            variables: undefined
        } : config,
        hasPendingConfigUpdate: tileNeedsVariablesCleared
    };
};
