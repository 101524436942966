import { HealthState } from '@squaredup/monitoring';
import { isDefined } from '@squaredup/utilities';
import { useEffect, useState } from 'react';
import { useGraph, useHealthCache } from '../context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from '../data/types';
import { getNodeHealths } from './useNodeHealth';

export function useNodeHealthCount(node: GraphologyNodeAttributes, healthState: HealthState, direction: 'in' | 'out') {
    const graph = useGraph();
    const cache = useHealthCache();

    const [count, setCount] = useState<number>();

    useEffect(() => {
        if (!node) {
            return;
        }

        Promise.all(
            graph[direction === 'in' ? 'inNeighbors' : 'outNeighbors'](node.id)
                .map((id) => {
                    const neighbor = graph.getNodeAttributes(id);
                    if (!(neighbor.data || neighbor.groupedData) || !neighbor.hidden) {
                        return undefined;
                    }

                    if (neighbor.type === 'groupNode') {
                        return getNodeHealths(cache, neighbor.groupedData!.nodes).then((d) => Object.values(d));
                    }

                    return getNodeHealths(cache, [neighbor.data!]).then((d) => d[neighbor.data!.id]);
                })
                .flat()
        ).then((results) => {
            setCount(results.filter(isDefined).filter((r) => r === healthState).length);
        });
    }, [node, healthState, graph, direction, cache]);

    return count;
}
