import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { GlobalDataStreamContext } from 'dashboard-engine/dataStreams/GlobalDataStreamContext';
import { ComponentRef, useCallback, useRef } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import DatasetContext from 'ui/editor/dataStream/contexts/DatasetContext';
import { DraftSQLContextProvider } from 'ui/editor/dataStream/contexts/DraftSQLContext';
import { useTileEditorContext } from 'ui/editor/dataStream/contexts/TileEditorContext';
import { DataStreamEditorSecondaryViewingPanel } from 'ui/editor/dataStream/TileEditor/DataStreamEditorSecondaryViewingPanel';
import {
    editorStepsHeightPercentage,
    vizConfigPanelWidthPixels
} from 'ui/editor/dataStream/TileEditor/DataStreamTileEditor';
import { DataStreamTileEditorVisualizationPanelWrapper } from 'ui/editor/dataStream/TileEditor/DataStreamTileEditorVisualizationPanelWrapper';
import { useDatasets } from 'ui/editor/dataStream/TileEditor/hooks/useDatasets';
import { DataStreamEditorPrimaryViewingPanel } from 'ui/editor/dataStream/TileEditor/DataStreamEditorPrimaryViewingPanel';
import { DataStreamTileEditorSteps } from 'ui/editor/dataStream/TileEditor/steps/DataStreamTileEditorSteps';
import { TileEditorStoreProviderFromDataSetContext } from 'ui/editor/dataStream/TileEditor/state/TileEditorStoreProvider';
import { TileEditorStepsContextWrapper } from 'ui/editor/dataStream/TileEditor/steps/TileEditorStepsContextWrapper';

const defaultSecondaryViewingPanelSize = 246;

interface ExploreTileEditorWrapperProps {
    workspace?: string;
    isGlobal?: boolean;
}

export const ExploreTileEditorWrapper: React.FC<ExploreTileEditorWrapperProps> = ({ workspace, isGlobal }) => {
    const key = `explore-${isGlobal ? 'global' : 'workspace'}`;

    if (isGlobal || workspace) {
        return (
            <GlobalDataStreamContext workspace={workspace}>
                <ExploreTileEditor key={`${key}-newLayout`} />
            </GlobalDataStreamContext>
        );
    }

    return <ExploreTileEditor key={`${key}-newLayout`} />;
};

/**
 * A stripped back version of the data stream tile editor used on the Analyze/Explore page
 * e.g. no monitoring/KPI tab
 */
export const ExploreTileEditor: React.FC<ExploreTileEditorWrapperProps> = () => {
    const { savedTileConfig } = useTileEditorContext();
    const datasetState = useDatasets();

    const horizontalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);
    const verticalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const handleResetHorizontalPanelGroupLayout = () => {
        if (!horizontalPanelGroupRef.current) {
            return;
        }
        const totalSize = horizontalPanelGroupRef.current.getLayout().reduce((acc, cur) => acc + cur.sizePixels, 0);
        horizontalPanelGroupRef.current.setLayout([
            { sizePixels: totalSize - vizConfigPanelWidthPixels },
            { sizePixels: vizConfigPanelWidthPixels }
        ]);
    };

    const handleResetVerticalPanelGroupLayout = () => {
        verticalPanelGroupRef.current?.setLayout([
            { sizePercentage: editorStepsHeightPercentage },
            { sizePercentage: 100 - editorStepsHeightPercentage }
        ]);
    };

    const onDockEditorStep = useCallback(() => {
        // Store pre docked sizing
        verticalPanelGroupRef.current?.setLayout([{ sizePercentage: 50 },{ sizePercentage: 50 }]);
    }, []);

    const onReleaseEditorStep = useCallback(() => {
        if (!verticalPanelGroupRef.current) {
            return;
        }

        const totalSize = verticalPanelGroupRef.current.getLayout().reduce((acc, cur) => acc + cur.sizePixels, 0);
        verticalPanelGroupRef.current.setLayout([
            { sizePixels: totalSize - defaultSecondaryViewingPanelSize },
            { sizePixels: defaultSecondaryViewingPanelSize }
        ]);
    }, []);

    const previouslyDocked = useRef(false);

    return (
        <div 
            className='flex h-full pb-4 overflow-hidden'
            id='dialogContent'
        >
            <DatasetContext.Provider value={datasetState}>
                <TileEditorStoreProviderFromDataSetContext>
                    <TileEditorStepsContextWrapper 
                        key={`${datasetState.activeDataset}-${datasetState.datasets.length}`}
                        previouslyDocked={previouslyDocked}
                        onDockEditorStep={onDockEditorStep}
                        onReleaseEditorStep={onReleaseEditorStep}
                    >
                        <DraftSQLContextProvider initialQuery={savedTileConfig.dataStream?.dataSourceConfig?.sql ?? ''}>
                            <DataStreamTileEditorSteps />
                            
                            <div className='flex-1 min-w-0 ml-4'>
                                <PanelGroup ref={horizontalPanelGroupRef} direction='horizontal'>
                                    <Panel collapsible={true} >
                                        <PanelGroup ref={verticalPanelGroupRef} direction='vertical'>
                                            <Panel 
                                                collapsible={true}
                                                minSizePixels={100} 
                                            >
                                                <DataStreamEditorPrimaryViewingPanel />
                                            </Panel>

                                            <ResizablePanelResizeHandle
                                                direction='vertical'
                                                onResetLayout={handleResetVerticalPanelGroupLayout}
                                            />

                                            <Panel 
                                                collapsible={true} 
                                                minSizePixels={100}
                                                defaultSizePixels={defaultSecondaryViewingPanelSize}
                                            >
                                                <DataStreamEditorSecondaryViewingPanel />
                                            </Panel>
                                        </PanelGroup>
                                    </Panel>

                                    <ResizablePanelResizeHandle
                                        direction='horizontal'
                                        onResetLayout={handleResetHorizontalPanelGroupLayout}
                                    />
                                    
                                    <Panel
                                        collapsible={true}
                                        minSizePixels={200}
                                        maxSizePercentage={50}
                                        defaultSizePixels={vizConfigPanelWidthPixels}
                                    >
                                        <DataStreamTileEditorVisualizationPanelWrapper />
                                    </Panel>
                                </PanelGroup>
                            </div>
                        </DraftSQLContextProvider>
                    </TileEditorStepsContextWrapper>
                </TileEditorStoreProviderFromDataSetContext>
            </DatasetContext.Provider>
        </div>
    );
};