import { Button, TooltipButton } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SqUpPluginId } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import Tooltip from 'components/tooltip/Tooltip';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { sortBy } from 'lodash';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import PluginIcon from 'pages/scope/PluginIcon';
import { useAvailableDataStreamDefinitionsForWorkspace } from 'queries/hooks/useAvailableDataStreamDefinitionsForWorkspace';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { useDataSources } from '../hooks/useDataSources';
import { useRecentDataStreamStore } from '../hooks/useRecentDataStreamStore';
import { useTileEditorScopes } from '../hooks/useScopes';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { useTileEditorDataStreams } from '../state/useTileEditorDataStreams';
import { AddDataSourceModal } from '../steps/DataSourceModal/AddDataSourceModal';
import { SplashScreenDataStreamOption } from './SplashScreenDataStreamOption';
import { SplashScreenOptionsDropdown } from './SplashScreenOptionsDropdown';
import { SplashScreenScopeOption } from './SplashScreenScopeOption';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';
import { useNavigate } from 'react-router';

interface BrowseAllDataStreamsButtonProps {
    className?: string;
}

const BrowseAllDataStreamsButton: React.FC<BrowseAllDataStreamsButtonProps> = ({ className }) => {
    const { setCurrentEditorStep } = useTileEditorStepsContext();

    return (
        <Button 
            onClick={() => setCurrentEditorStep(EditorSteps.dataStream)}
            className={cn(className)}
            variant='secondary'
        >
            Browse all data streams
        </Button>
    );
};

export const SplashScreen = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { isDocked, currentEditorStep, setCurrentEditorStep } = useTileEditorStepsContext();
    const { workspace } = useDataStreamWorkspaceContext();
    const { variables = [] } = useDashboardContext();

    const { dispatch } = useTileEditorStore((s) => ({ dispatch: s.dispatch }));

    const { recentDataStreams: recentDataStreamsIds } = useRecentDataStreamStore();
    const [isAddDataSourceModal, setIsAddDataSourceModal] = useState(false);
    const [isScopeModalOpen, setIsScopeModalOpen] = useState(false);

    const { data: datasources = [], isLoading: isLoadingDataSources } = useDataSources({}, true);
    const { data: allTenantDatasources = [], isLoading: isLoadingAllTenantDataSources } = useDataSources(
        {}, true, NO_ACTIVE_WORKSPACE
    );
    const { data: tileEditorDataStreams, isLoading: isLoadingTileEditorDataStreams } = 
        useTileEditorDataStreams(workspace);
    const { data: dataStreams = [], isLoading: isLoadingAvailableDataStreamDefinitionsForWorkspace } = 
        useAvailableDataStreamDefinitionsForWorkspace(
            datasources,
            workspace, 
            undefined
        );

    const { data: scopes = [], isLoading: isLoadingScopes } = useTileEditorScopes();

    const isLoading = isLoadingDataSources
        || isLoadingAllTenantDataSources
        || isLoadingTileEditorDataStreams
        || isLoadingAvailableDataStreamDefinitionsForWorkspace
        || isLoadingScopes;

    const recentDataStreams = sortBy(
        dataStreams.filter(({ id }) => id !== 'datastream-sql' && recentDataStreamsIds.includes(id)),
        ({ id }) => recentDataStreamsIds.indexOf(id)
    );

    // Get list of datasources from available data streams
    const belowFoldDatasources = [
        ...dataStreams.reduce(
            (datasourceInstances, { pluginId, pluginName }) => {
                if (
                    pluginId && pluginId !== SqUpPluginId.value && 
                    !datasourceInstances.some(({ id }) => id === pluginId
                )) {
                    datasourceInstances.push({  
                        id: pluginId ?? '',
                        name: pluginName ?? ''
                    });
                } 

                return sortBy(datasourceInstances, 'name');
            }, [] as { id: string, name: string }[]
        )
    ];

    const variablesScopeIds = variables.map((v) => v.scopeId);
    const aboveFoldDatasources = belowFoldDatasources.splice(0, 8);
    
    /** 
     * Calculate the number of recent datastreams and scopes to show
     * We show a min of 4 and a max of 6 items. We show a number that is proportional to the number of
     * shown data sources. i.e. if there are 8 data sources being shown, we'd show up to 6 recent data streams
     * and or scopes. This used to allow a lower min and higher max so is overly complicated given what it's calculating
     */
    const availableOptionsCount = Math.min(
        Math.max(
            (Math.ceil((aboveFoldDatasources.length + 1) / 3) * 2), 
            4
        ), 
        6
    );
    const evenDistribution = availableOptionsCount / 2;

    const [dataStreamsToBeShown, scopesToBeShown] = 
        scopes.length >= evenDistribution && recentDataStreams.length >= evenDistribution ?
            [evenDistribution, evenDistribution] :
            recentDataStreams.length > scopes.length ?
                [availableOptionsCount - scopes.length, scopes.length] :
                [recentDataStreams.length, availableOptionsCount - recentDataStreams.length];

    const belowFoldScopes = [
        ...sortBy(scopes, (scope) => !variablesScopeIds.includes(scope.id))
    ].map(({ id, displayName }) => ({ id, name: displayName }));
    const aboveFoldScopes = belowFoldScopes.splice(0, scopesToBeShown);

    const hasDatasources = Boolean(aboveFoldDatasources.length);
    const hasDataStreams = Boolean(recentDataStreams?.length);
    const hasScopes = Boolean(scopes.length);

    if (isLoading) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <>
            <div 
                className='relative flex flex-col justify-center w-full h-full min-w-0 min-h-0 isolate'
                id='dataStreamTileSpashScreen'
            >
                <div className='flex flex-col items-center w-full min-h-0 mx-auto max-w-7xl'>
                    <NoDataPlaceholder 
                        message="Let's get started" 
                        className='text-textPrimary'
                    />

                    {!isDocked && hasDatasources &&
                        <>
                            <div className={cn('flex w-full mt-8 mb-10 items-center max-w-6xl @container/datasources justify-center gap-10')}>
                                <div className={cn(
                                    'flex flex-row flex-wrap justify-center gap-3 @4xl/datasources:gap-5 max-w-[321px] @4xl/datasources:max-w-[395px]',
                                    [6,7].includes(aboveFoldDatasources.length) && '@4xl/datasources:max-w-[533px]',
                                    aboveFoldDatasources.length === 3 && 'max-w-[258px] @4xl/datasources:max-w-[258px]'
                                )}>
                                    {aboveFoldDatasources.map(({ id, name }) => 
                                        <button 
                                            key={id}
                                            className={cn(
                                                'flex items-center text-textSecondary px-5 py-2 @4xl/datasources:py-4 gap-2 shrink-0 rounded-md w-[100px] @4xl/datasources:w-[120px] flex-col bg-tileEditorDataset hover:bg-tileEditorDatasetActive focus:bg-tileEditorDatasetActive hover:text-textPrimary focus:text-textPrimary'
                                            )}
                                            onClick={() => {
                                                dispatch({ type: 'dataStream.updateFilters', filters: { dataSourceId: id } });
                                                setCurrentEditorStep(EditorSteps.dataStream);
                                            }}
                                        >
                                            <div className='w-full max-w-14 aspect-square @4xl/datasources:max-w-16'>
                                                <PluginIcon     
                                                    pluginName={name} 
                                                    className='w-full'
                                                />
                                            </div>
                                            
                                            <Text.H4 className='text-sm @4xl/datasources:text-[14px] text-center min-h-[35px] flex items-center'>{name}</Text.H4>
                                        </button>
                                    )}

                                    <div className={cn('grid gap-3 @4xl/datasources:gap-5 w-[100px] @4xl/datasources:w-[120px]', belowFoldDatasources.length && 'grid-rows-2')}>
                                        {Boolean(belowFoldDatasources.length) &&
                                            <SplashScreenOptionsDropdown 
                                                options={belowFoldDatasources} 
                                                option={({id, name}) =>
                                                    <DropdownMenu.Item 
                                                        key={id}
                                                        onSelect={() => {
                                                            dispatch({ type: 'dataStream.updateFilters', filters: { dataSourceId: id } });
                                                            setCurrentEditorStep(EditorSteps.dataStream);
                                                        }}
                                                        icon={<PluginIcon     
                                                            pluginName={name} 
                                                            className='w-7 h-7'
                                                        />}
                                                        className='text-nowrap'      
                                                    >
                                                        {name}
                                                    </DropdownMenu.Item>
                                                }
                                            />
                                        }

                                        <Tooltip title='Add data source'>
                                            <button 
                                                key='addDatasource'
                                                className='flex items-center justify-center w-full h-full px-5 py-3.5 space-y-3 rounded-md text-textSecondary bg-tileEditorDataset hover:bg-tileEditorDatasetActive focus:bg-tileEditorDatasetActive'
                                                onClick={() => {
                                                    setIsAddDataSourceModal(true);
                                                }}
                                            >
                                                <FontAwesomeIcon 
                                                    icon={faPlus} 
                                                    size='2x'
                                                />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>

                                {hasDatasources && (hasDataStreams || hasScopes) && (
                                    <div className='flex flex-col flex-1 max-w-md min-w-0 gap-10 pl-10 my-auto border-l border-dividerTertiary'>
                                        {hasDataStreams &&
                                            <div className='flex flex-col gap-2 flex-1 min-w-[200px] max-w-[340px]'>
                                                <Text.H5 className='text-textDisabled'>Recent</Text.H5>
                                                
                                                {recentDataStreams.slice(0, dataStreamsToBeShown).map((dataStream) => 
                                                    <SplashScreenDataStreamOption
                                                        key={dataStream.id}
                                                        dataStream={dataStream}
                                                        dataStreamInstance={
                                                            tileEditorDataStreams.dataStreamsById?.get(dataStream.id)
                                                        }
                                                    />
                                                )}
                                            </div>
                                        }

                                        <div 
                                            className='flex flex-col gap-2 flex-1 min-w-[200px] max-w-[340px]'
                                            data-testid='splashScreenCollections'
                                        >
                                            <Text.H5 className='flex items-center text-textDisabled'>
                                                <span className='mr-4'>Collections</span>
                                                <TooltipButton 
                                                    onClick={() => setIsScopeModalOpen(true)}
                                                    className='ml-auto text-inherit'
                                                    variant='tertiary'
                                                    title='Add collection'
                                                    asChild
                                                    icon={<FontAwesomeIcon icon={faPlus} />}
                                                />
                                            </Text.H5>
                                            
                                            {scopes.length ? (
                                                <>
                                                    {
                                                        aboveFoldScopes.map((scope) => 
                                                            <SplashScreenScopeOption
                                                                key={scope.id}
                                                                id={scope.id}
                                                                name={scope.name}
                                                                workspace={workspace}
                                                                variables={variables}
                                                                isVariable={variablesScopeIds.includes(scope.id)}
                                                                dispatch={dispatch}
                                                                setCurrentEditorStep={setCurrentEditorStep}
                                                            />
                                                    )}

                                                    {Boolean(belowFoldScopes.length) &&
                                                        <SplashScreenOptionsDropdown 
                                                            options={belowFoldScopes}
                                                            className='py-1.5 h-[35px] rounded-none'
                                                            option={({id, name}) => 
                                                                <DropdownMenu.Item 
                                                                    key={id}
                                                                    onSelect={() => {
                                                                        dispatch({
                                                                            type: 'dataStream.updateFilters',
                                                                            filters: {
                                                                                scopeId: id
                                                                            }
                                                                        });
                                                        
                                                                        dispatch({
                                                                            type: 'setPredefinedScope',
                                                                            scopeId: id,
                                                                            workspaceId: workspace,
                                                                            variables
                                                                        });
                                                        
                                                                        return setCurrentEditorStep(
                                                                            EditorSteps.dataStream
                                                                        );
                                                                    }}
                                                                    className='text-nowrap'      
                                                                >
                                                                    {name}
                                                                </DropdownMenu.Item>
                                                            } 
                                                        />
                                                    }
                                                </>
                                            ) : (
                                                <p className='text-textDisabled'>There are no collections.</p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>    

                            <BrowseAllDataStreamsButton 
                                className='mx-auto mt-8 text-base'
                            />
                        </>
                    }


                    {currentEditorStep !== EditorSteps.dataStream && !hasDatasources &&
                        <div className='flex-col justify-center mt-5 space-y-10 text-center'>
                            <p className='text-base text-textSecondary'>Add a data source to start surfacing insights.</p>

                            <Button 
                                onClick={() => allTenantDatasources.length ? 
                                    setIsAddDataSourceModal(true) :
                                    navigate(`datasources/${workspace}`)
                                }
                                className='mx-auto mt-10 text-base'
                                icon={<FontAwesomeIcon icon={faPlus} />}
                            >
                                Add data source
                            </Button>
                        </div>
                    }
                </div>
            </div>    
            
            {isAddDataSourceModal && (
                <AddDataSourceModal
                    workspaceId={workspace}
                    onSelectedDataSource={async (dataSourceId?: string) => {
                        setIsAddDataSourceModal(false);
                        
                        if (dataSourceId) {
                            // Remove the search to avoid getting stuck on the empty page
                            dispatch({
                                type: 'dataStream.updateFilters',
                                filters: { search: '', ...(dataSourceId ? { dataSourceId } : {}) }
                            });
    
                            setCurrentEditorStep(EditorSteps.dataStream);
                        }
                    }}
                />
            )}

            {isScopeModalOpen && (
                <CreateEditScope
                    onClose={(modifiedScope) => {
                        setIsScopeModalOpen(false);
                        if (modifiedScope && workspace) {
                            queryClient.invalidateQueries(scopeQueryKeys.workspace(workspace));
                            queryClient.removeQueries(variableObjectQueryKeys.all);
                            dispatch({ type: 'dataStream.updateFilters', filters: { scopeId: modifiedScope.id } });
                            setCurrentEditorStep(EditorSteps.dataStream);
                        }
                    }}
                />
            )}
        </>
    );
};
