import { findColumn, FormattedStreamValue, required, RowData, StreamDataColumn } from '@squaredup/data-streams';
import { Row } from '@tanstack/react-table';
import { getDrilldownUrlFor, getValueIfAllSame } from 'dashboard-engine/util/drilldown';

/**
 * Generate a link for a table row based on the row data and the column configuration
 */
export const getTableRowLink = (
    rowLinkColumn: StreamDataColumn,
    row: Row<FormattedStreamValue[]>,
    rowData?: RowData
) => {
    // grab the value of the link column for standard links
    if (rowLinkColumn?.targetShapeName === 'shape_url' || rowLinkColumn?.role === 'link') {
        return (row.getValue(rowLinkColumn.name) as FormattedStreamValue)?.value as string;
    }

    // if the link column is a sourceIdColumn, try to create a drilldown link
    if (!rowLinkColumn.sourceIdColumn || !rowData?.pluginConfigIds || !rowData?.sourceIds) {
        return null;
    }

    const sourceId = rowData.sourceIds.find(
        (sourceIdColumn) => sourceIdColumn.columnName === rowLinkColumn.sourceIdColumn
    )?.sourceId;

    const pluginConfigId = getValueIfAllSame(rowData.pluginConfigIds);

    if (!pluginConfigId || !sourceId) {
        return null;
    }

    return getDrilldownUrlFor(sourceId, pluginConfigId);
};

/**
 * Find the column that is configured to be the row link
 */
export const findTableRowLinkColumn = (columns: StreamDataColumn[], columnName?: string) => {
    if (!columnName || columnName === 'None') {
        return null;
    }

    const column = findColumn(columns, required('name', columnName));

    if (column.failed) {
        return null;
    }

    return column.value.column;
};
