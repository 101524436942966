import Text from '@/components/Text';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NetworkMap } from 'components/map/NetworkMap';
import Tooltip from 'components/tooltip/Tooltip';
import { type ComponentPropsWithoutRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ControlButton } from 'reactflow';
import { Panel, useWorkspaceId } from './WorkspaceHome';

export const WorkspaceMapOverviewPanel = () => {
    const navigate = useNavigate();
    const workspaceId = useWorkspaceId();

    return (
        <Panel className='row-span-2 overflow-hidden'>
            <Header>
                <Text.Body className='text-lg font-semibold capitalize'>map</Text.Body>
            </Header>

            <NetworkMap
                filterInitalBasedOnHealth
                background='tileBackground'
                controls={
                    <Tooltip title='Fullscreen'>
                        <ControlButton onClick={() => navigate(`/explorer/${workspaceId}/`)}>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                        </ControlButton>
                    </Tooltip>
                }
            />
        </Panel>
    );
};

const Header = (props: ComponentPropsWithoutRef<'header'>) => {
    return <header {...props} className='flex items-center gap-2' />;
};
