import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';

type DataStreamOptionNameProps = Pick<DataStreamFilterOption, 'displayName' | 'description'>

export const DataStreamOptionName: React.FC<DataStreamOptionNameProps> = ({ displayName, description, children }) => {
    const { ref, isOverflowing } = useOverflowing<HTMLParagraphElement>();

    const tooltipContent = isOverflowing ? `**${displayName}**: ${description}` : description;

    return (
        <Tooltip 
            title={tooltipContent} 
            disabled={!tooltipContent}
            className='min-w-0'
        >
            <p ref={ref} className='truncate'>{children}</p>
        </Tooltip>
    );
};
