import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import LoadingSpinner from 'components/LoadingSpinner';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import stringify from 'fast-json-stable-stringify';
import { pick } from 'lodash';
import { FC, useEffect, useReducer, useState, type Dispatch, type SetStateAction } from 'react';
import { FormProvider, useForm, type DeepPartial } from 'react-hook-form';
import { DataStreamMetadataForm, toFormData } from './MetadataEditor/MetadataEditorForm';
import { dataStreamMetadataReducer } from './MetadataEditor/MetadataEditorReducer';
import { fromDataStreamMetadata } from './MetadataEditor/MetadataEditorStreamData';
import { MetadataEditorTable } from './MetadataEditor/MetadataEditorTable';
import { toTileMetadata } from './MetadataEditor/MetadataEditorTileState';
import { StepTitleAndControls } from './StepTitleAndControls';
import { StepProgressButton } from './StepProgressButton';

export interface DataStreamMetadataProps {
    config: DataStreamBaseTileConfig;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
}

/**
 * Compare the values of two objects, ignoring properties that exist only in one object.
 */
export const propertiesInBothAreEqual = (a: object, b: object): boolean => {
    return Object.entries(a)
        .filter(([k]) => k in b)
        .every(([k, v]) => {
            const otherValue = (b as { [x: string]: unknown })[k];

            return (v == null && otherValue == null) || stringify(v) === stringify(otherValue);
        });
};

export const DataStreamMetadata: FC<DataStreamMetadataProps> = ({ config, setConfig }) => {
    const [dataLastUpdatedAt, setDataLastUpdatedAt] = useState(0);
    const { data, dataUpdatedAt, isLoaded, isConfigured } = useDataStreamConfig(config, { keepPreviousData: true });

    const getInitialReducerState = () => fromDataStreamMetadata(data, config.dataStream?.metadata ?? []);

    /**
     * Reset the reducer state so it matches the data and tile config
     */
    const resetState = () => {
        const initialState = getInitialReducerState();
        dispatch({ type: 'reset', state: initialState });
        methods.reset(toFormData(initialState));
    };

    const [state, dispatch] = useReducer(dataStreamMetadataReducer, undefined, getInitialReducerState);

    const defaultValues = toFormData(state);

    const methods = useForm<DataStreamMetadataForm>({
        defaultValues: defaultValues as DeepPartial<DataStreamMetadataForm>
    });
    const formData = methods.watch();

    // dataUpdatedAt may be less than dataLastUpdatedAt when loading cached data
    const dataHasChanged = dataUpdatedAt !== dataLastUpdatedAt;
    if (dataHasChanged) {
        setDataLastUpdatedAt(dataUpdatedAt);
        resetState();
    }

    if (
        !propertiesInBothAreEqual(formData, defaultValues) &&
        (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length > 0)
    ) {
        if (!state.tileConfigUpdatePending) {
            dispatch({
                type: 'overrideMetadata',
                formData: pick(formData, Object.keys(methods.formState.dirtyFields))
            });
        }
    }

    // useEffect to avoid updating config during rendering and triggering
    // 'Cannot update a component while rendering a different component'
    useEffect(() => {
        const asMetadata = toTileMetadata(state);

        if (state.tileConfigUpdatePending) {
            const asFormData = toFormData(state);

            // Only update the config if something has changed
            if (stringify(asMetadata) !== stringify(config.dataStream?.metadata)) {
                setConfig({
                    ...config,
                    dataStream: {
                        ...config.dataStream,
                        metadata: asMetadata
                    }
                });
            }

            // Always reset the form to handle cases where the form data isn't
            // being respected, e.g. setting a display name to an empty string
            methods.reset(asFormData);
            dispatch({ type: 'saveTileConfig' });
        } else if (stringify(asMetadata) !== stringify(config.dataStream?.metadata)) {
            // We didn't have an update pending, so the config must have been
            // changed elsewhere (e.g. JSON, other tile editor components)
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tileConfigUpdatePending, stringify(config.dataStream?.metadata)]);

    if (!isConfigured) {
        return (
            <div className='h-full p-sm'>
                <NoDataPlaceholder message='Column editing will be available after selecting objects and/or configuring parameters.' />
            </div>
        );
    }

    if (!isLoaded) {
        // Show loading spinner until first data load is complete
        return (
            <div className='flex items-center justify-center flex-1 w-full h-full'>
                <LoadingSpinner className='m-auto' />
            </div>
        );
    }

    return (
        <FormProvider {...methods}>
            <div className='flex flex-col w-full h-full min-h-0 py-4'>
                <div className='pb-4 pl-6 pr-5 mb-4 border-b border-dividerTertiary'>
                    <StepTitleAndControls title='Format columns' />
                </div>

                <div className='flex flex-col py-0 pl-6 pr-5 space-y-6 overflow-hidden'>
                    <div className='relative w-full h-full min-w-0 min-h-0 overflow-auto text-sm scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                        <MetadataEditorTable state={state} dispatch={dispatch} />
                    </div>
                </div>

                <div className='flex items-center justify-end flex-shrink-0 pr-5 mt-6'>
                    <StepProgressButton />
                </div>
            </div>
        </FormProvider>
    );
};
