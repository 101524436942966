import { faArrowsSplitUpAndLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { BehindFlag } from 'components/BehindFlag';
import { useGraph, useHealthCache } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { getNodeHealths } from 'components/map/hooks/useNodeHealth';
import { useNodeHealthCount } from 'components/map/hooks/useNodeHealthCount';
import { useSelectiveExpandNode } from 'components/map/hooks/useSelectiveExpandNode';
import { useEffect, useState } from 'react';
import { NodeToolbar, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { CircleMapButton, DownLeftDottedArrow } from './upstream';

export function DownstreamOptions({
    node,
    isVisible,
    onClick
}: {
    node: GraphologyNodeAttributes;
    isVisible: boolean;
    onClick?: () => void;
}) {
    const graph = useGraph();
    const cache = useHealthCache();

    const [hasRecursive, setHasRecursive] = useState(false);

    const expandNode = useSelectiveExpandNode();

    const hiddenDownStreamNodes = graph.filterInNeighbors(
        node.id,
        (_, n) => n.hidden && Boolean(n.data || n.groupedData)
    );

    const unhealthyCount = useNodeHealthCount(node, 'error', 'in');

    const { isHovered: isHoveredUnhealthy, ...unhealthyProps } = useHover();

    const secondLevelDownstreamNodes = graph
        .mapInNeighbors(node.id, (_, n1) =>
            n1.hidden && Boolean(n1.data || n1.groupedData)
                ? graph.filterInNeighbors(n1.id, (__, n2) => n2.hidden && Boolean(n2.data || n2.groupedData))
                : []
        )
        .flat();

    useEffect(() => {
        getNodeHealths(
            cache,
            secondLevelDownstreamNodes.map((id) => {
                const n = graph.getNodeAttributes(id)!;
                return n.data! || n.groupedData!;
            })
        ).then((healths) => setHasRecursive(Object.values(healths).includes('error')));
    }, [secondLevelDownstreamNodes, cache, graph]);

    if (hiddenDownStreamNodes.length === 0) {
        return null;
    }

    return (
        <BehindFlag flagName='expandHealthMap'>
            <NodeToolbar isVisible={isVisible} position={Position.Bottom} offset={10}>
                <div className='grid w-[15.5rem] grid-cols-12 left-1/2 z-50 mt-0.5'>
                    <div className='flex flex-col row-span-2 col-start-5 col-span-4'>
                        <div className='flex justify-center h-16 z-50'>
                            <DownLeftDottedArrow
                                className={clsx('w-1/2 h-full fill-statusErrorPrimary', !unhealthyCount && 'opacity-0')}
                            />
                            <DownLeftDottedArrow
                                className={clsx(
                                    'w-1/2 h-full fill-statusUnknownPrimary -scale-x-100',
                                    unhealthyCount === hiddenDownStreamNodes.length && 'opacity-0'
                                )}
                            />
                        </div>
                        <div className='flex justify-end w-full items-end space-x-[2.2rem] pt-2'>
                            <CircleMapButton
                                {...unhealthyProps}
                                className={clsx('border-statusErrorPrimary', !unhealthyCount && 'opacity-0')}
                                disabled={!unhealthyCount}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    expandNode(node, 'in', true, false);
                                    onClick?.();
                                }}
                            >
                                {unhealthyCount}
                            </CircleMapButton>
                            <CircleMapButton
                                className={clsx(
                                    'border-statusUnknownPrimary',
                                    hiddenDownStreamNodes.length === unhealthyCount && 'opacity-0'
                                )}
                                disabled={hiddenDownStreamNodes.length === unhealthyCount}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    expandNode(node, 'in', false, false);
                                    onClick?.();
                                }}
                            >
                                {hiddenDownStreamNodes.length}
                            </CircleMapButton>
                        </div>
                    </div>
                    {Boolean(unhealthyCount) && hasRecursive && isHoveredUnhealthy && (
                        <div
                            {...unhealthyProps}
                            className='col-span-4 row-span-2 row-start-3 px-2 -mt-5 flex flex-col items-end justify-start'
                        >
                            <svg
                                className='w-10 h-full -scale-y-100'
                                viewBox='-20 0 46 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M26 30L15.9255 35.6425L16.0762 24.0965L26 30ZM17.0344 30.5625C14.7763 30.3012 12.803 29.9161 11.0814 29.3884L11.6676 27.4762C13.244 27.9594 15.0927 28.3245 17.2643 28.5758L17.0344 30.5625ZM5.51921 26.5244C3.85645 25.1333 2.69457 23.4055 1.88602 21.3412L3.74827 20.6118C4.45216 22.4089 5.43213 23.8439 6.80256 24.9905L5.51921 26.5244ZM0.494486 15.3677C0.270924 13.5515 0.149103 11.5601 0.0821705 9.38983L2.08122 9.32818C2.14715 11.4661 2.26624 13.3909 2.4795 15.1234L0.494486 15.3677ZM0.00247955 3.46373C0 2.50573 0 1.5176 0 0.5H2C2 1.51811 2 2.5036 2.00247 3.45855L0.00247955 3.46373Z'
                                    fill='#DE0038'
                                />
                            </svg>
                            <div className='mr-2.5'>
                                <CircleMapButton
                                    className='border-statusErrorPrimary '
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        expandNode(node, 'in', true, true);
                                        onClick?.();
                                    }}
                                >
                                    <FontAwesomeIcon className='-scale-y-100' icon={faArrowsSplitUpAndLeft} />
                                </CircleMapButton>
                            </div>
                        </div>
                    )}
                </div>
            </NodeToolbar>
        </BehindFlag>
    );
}
