import { faBars, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StreamDataColumn } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { DataStreamTableConfig } from 'dashboard-engine/visualisations/DataStreamTable/types';
import stringify from 'fast-json-stable-stringify';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable, type DroppableProps } from 'react-beautiful-dnd';
import EasyEdit from 'react-easy-edit';

export interface ColumnListData {
    columnOrder: string[];
    hiddenColumns: string[];
    columnDisplayNames: Record<string, string>;
}

interface ColumnListParams {
    columns: StreamDataColumn[];
    config: DataStreamTableConfig;
    onChange: (action: VisualisationOptionAction) => void;
}

export default function ColumnList({ columns, config, onChange }: ColumnListParams) {
    const visibleColumns = columns.filter((col) => !config.hiddenColumns?.includes(col.name));
    const hiddenColumns = columns.filter((col) => config.hiddenColumns?.includes(col.name));

    const columnNames = visibleColumns.map((col) => col.name);
    const columnOrder = config.columnOrder?.filter((col) => columnNames.includes(col)) ?? [];

    const visibleColumnsInOrder = sortBy(
        [...visibleColumns],
        (col) =>
            columnOrder?.length && columnOrder.includes(col.name)
                ? columnOrder.findIndex((c) => c === col.name)
                : col.displayIndex
        // Pick only the properties we need to avoid invalidating the useMemos unnecessarily
    ).map((c) => ({ name: c.name, displayName: c.displayName }));

    const hiddenColumnsInOrder = sortBy([...hiddenColumns], 'displayIndex');

    const draggables = useMemo(() => {
        return visibleColumnsInOrder.map((item, index) => {
            const columnNameOverride = config.columnDisplayNames?.[item.name];
            const columnName = columnNameOverride || item.displayName;

            return (
                <Draggable key={item.name} draggableId={item.name} index={index}>
                    {/* This function is the reason for the useMemo, without the
                     * useMemo we end up with a new function every time which causes
                     * a non-trivial re-render. We can't extract just the function
                     * because it needs the items from visibleColumnsInOrder.
                     */}
                    {(providedItem) => (
                        <div
                            className='item-container mb-1.5 !top-auto !left-auto'
                            ref={providedItem.innerRef}
                            aria-label={`${item.name} controls`}
                            {...providedItem.dragHandleProps}
                            {...providedItem.draggableProps}
                        >
                            <div className='flex px-4 py-2 rounded-md text-textPrimary bg-filterActive'>
                                <span className='mr-2'>
                                    <FontAwesomeIcon icon={faBars} />
                                </span>

                                <EasyEdit
                                    type='text'
                                    value={columnName || item.name}
                                    saveOnBlur={true}
                                    cssClassPrefix='flex-1 columnRename '
                                    placeholder={item.displayName}
                                    validationMessage={false}
                                    onValidate={(value: string) => value !== null && value !== ''}
                                    onSave={(value: string) => {
                                        onChange({
                                            action: 'renameColumn',
                                            data: {
                                                columnName: item.name,
                                                displayName: value
                                            }
                                        });
                                    }}
                                />

                                <span
                                    className='cursor-pointer'
                                    onClick={() => onChange({ action: 'toggleVisibility', data: item.name })}
                                    role='button'
                                    aria-label={`hide ${item.name}`}
                                >
                                    <Tooltip title='Toggle visibility'>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Tooltip>
                                </span>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(config.columnDisplayNames), stringify(visibleColumnsInOrder), onChange]);

    const renderDraggables = useMemo((): DroppableProps['children'] => {
        return (providedList) => (
            <div
                key={providedList.droppableProps['data-rbd-droppable-id']}
                className='transform select-none list-container'
                {...providedList.droppableProps}
                ref={providedList.innerRef}
            >
                {draggables}
                {providedList.placeholder}
            </div>
        );
        // Same dependencies as draggables because we can't easily have
        // draggables itself as a dependency without many unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(config.columnDisplayNames), stringify(visibleColumnsInOrder), onChange]);

    return (
        <>
            <DragDropContext onDragEnd={(e) => onChange({ action: 'moveColumn', data: e })}>
                <Droppable droppableId='list-container'>{renderDraggables}</Droppable>
            </DragDropContext>

            {hiddenColumnsInOrder.map((item) => {
                const columnName = config.columnDisplayNames?.[item.name] || item.displayName;

                return (
                    <div
                        key={item.name}
                        className='item-container mb-1.5 !top-auto !left-auto'
                        aria-label={`${item.name} controls`}
                    >
                        <div className='flex px-4 py-2 rounded-md opacity-50 text-textPrimary bg-tagBackground'>
                            <EasyEdit
                                type='text'
                                value={columnName}
                                saveOnBlur={true}
                                cssClassPrefix='flex-1 columnRename '
                                placeholder={`${item.name} display name`}
                                onSave={(value: string) => {
                                    onChange({
                                        action: 'renameColumn',
                                        data: {
                                            columnName: item.name,
                                            displayName: value
                                        }
                                    });
                                }}
                            />

                            <span
                                className='cursor-pointer'
                                onClick={() => onChange({ action: 'toggleVisibility', data: item.name })}
                                role='button'
                                aria-label={`show ${item.name}`}
                            >
                                <Tooltip title='Toggle visibility'>
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                </Tooltip>
                            </span>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
