import { DataStreamBaseTileConfig, DataStreamSQLConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { getIsDataStreamConfigured } from 'dashboard-engine/util/getIsDataStreamConfigured';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

type ConfigSetter = ((newConfig: DataStreamBaseTileConfig) => DataStreamBaseTileConfig) | DataStreamBaseTileConfig;
type Datasets = DataStreamSQLConfig['dataSourceConfig']['tables'];

export const defaultSQL = 'SELECT * FROM dataset1';

export const useDatasets = () => {
    const queryClient = useQueryClient();
    const { variables } = useDashboardContext();
    const { isDatasetMode, tileConfig, savedTileConfig, setTileConfig } = useTileEditorContext();

    const datasets = (tileConfig.dataStream?.dataSourceConfig?.tables || []) as Datasets;
    const sql = tileConfig.dataStream?.dataSourceConfig?.sql;

    const isConfigured = datasets.some((dataset) => {
        const dataStreamDefinition = queryClient.getQueryData(
            dataStreamDefinitionQueryKeys.byId(dataset.config?.dataStream?.id!)
        );
        return getIsDataStreamConfigured(dataStreamDefinition, dataset.config as DataStreamBaseTileConfig);
    });

    const { limit } = useCheckScopeIsOobAndHasLimit(datasets?.[0]?.config as DataStreamBaseTileConfig);

    /**
     * If we're starting the editor in SQL mode or the user is switching with a complete data stream configuration
     * we take the user to the SQL tab, else, we take the user to the dataset editor to finish configuration.
     * */
    const [activeDataset, setActiveDataset] = useState<number>(() => {
        if (tileConfig?.dataStream?.id === 'datastream-sql' && isConfigured) {
            return -1;
        }

        return (tileConfig?.dataStream?.id === 'datastream-sql' && isConfigured) && limit === false ? 
            datasets.length : 
            0;
    });

    /**
     * By default show the Visualization tab if opening editor in SQL mode or default mode.
     * If user enables SQL Analytics afterwards, show Output tab if the tile was configured with a datastream.
     * Otherwise shows the dataset1 tab, if no datastream was configured on enabling SQL analytics.
     */
    const [activeDataTab, setActiveDataTab] = useState(
        isDatasetMode && savedTileConfig?.dataStream?.id !== 'datastream-sql'
            ? isConfigured
                ? datasets.length
                : 0
            : 0
    );

    /**
     * Control for editing SQL, at the moment the lines are blurred between datasets and the SQL editor steps
     * this is a worksaround to make it behave as expected
     * TODO: Break this out of the dataset state and manage it separately
     */
    const [isEditingSQL, setIsEditingSQL] = useState(tileConfig?.dataStream?.id === 'datastream-sql' && isConfigured);

    const activeDatasetTableName = datasets?.[activeDataset]?.tableName || `Dataset ${activeDataset + 1}`;
    const activeDatasetConfig = (datasets?.[activeDataset]?.config || baseDataStreamConfig) as DataStreamBaseTileConfig;

    /**
     * Get variables for root tile config if any dataset uses them.
     *
     * The SQL handler needs them present in the root to be able to resolve them.
     */
    const getTileConfigVariables = (newDatasets: DataStreamSQLConfig['dataSourceConfig']['tables']) => {
        const hasVariables = newDatasets.some((dataset) => dataset.config?.variables?.length);
        return hasVariables ? variables?.map((v) => v.id) : undefined;
    };

    const updateDatasets = (
        newDatasets: DataStreamSQLConfig['dataSourceConfig']['tables'],
        newActiveDataset: number
    ) => {
        const tileConfigVariables = getTileConfigVariables(newDatasets);

        setTileConfig((currentTileConfig) => ({
            ...currentTileConfig,
            ...tileConfigVariables?.length && { variables: tileConfigVariables },
            dataStream: {
                ...currentTileConfig.dataStream,
                dataSourceConfig: {
                    ...currentTileConfig.dataStream?.dataSourceConfig,
                    tables: newDatasets
                }
            }
        }));

        setActiveDataset(newActiveDataset);
    };

    const addDataset = (offset = 0) => {
        const newDatasets = [...datasets];

        // Generate dataset name
        let datasetNumber = 1;
        const existingTableNames = datasets.map(({ tableName }: { tableName: string }) => tableName);

        while (existingTableNames.includes(`dataset${datasetNumber}`)) {
            datasetNumber++;
        }

        newDatasets.push({
            tableName: `dataset${datasetNumber}`,
            config: {
                // If variable added to dashboard, add to dataset by default
                ...variables?.length && { variables: variables.map((v) => v.id) }
            }
        });

        updateDatasets(newDatasets, newDatasets.length - 1);

        // Go to the new datasets data preview 
        setActiveDataTab(newDatasets.length - offset);
    };

    const cloneDataset = (index: number) => {
        const existing = datasets[index];

        // Generate a new name
        const existingTableNames = datasets.map(({ tableName }: { tableName: string }) => tableName);
        const name = `${existing.tableName}Copy`;
        let count = 1;
        let newName = name;

        while (existingTableNames.includes(newName)) {
            newName = name + count;
            count++;
        }

        const newDatasets = [...datasets, { ...existing, tableName: newName }];
        updateDatasets(newDatasets, newDatasets.length - 1);
        setActiveDataTab(newDatasets.length - 1);
    };

    const removeDataset = (index: number, isDocked = false) => {
        const newDatasets = [...datasets];
        newDatasets.splice(index, 1);

        const newActiveDataset = (activeDataset === -1 || activeDataset < index) ? 
            activeDataset :
            Math.max(activeDataset - 1, 0);

        updateDatasets(newDatasets, newActiveDataset);

        const newTaboffset = Number(isDocked);
        const newActiveDataTab = 
            (newTaboffset + index) >= activeDataTab && 
            (newTaboffset + (newDatasets.length - 1) >= activeDataTab) ? 
                activeDataTab :
                activeDataTab - 1;

        setActiveDataTab(newActiveDataTab);
    };

    const renameDataset = (targetDataset: number, newDataName: string) => {
        const cleanedDatasetName = newDataName.trim();

        setTileConfig((currentTileConfig) => {
            const newDatasets = [...(datasets || [])];
            const oldDatasetName = datasets?.[targetDataset]?.tableName;
            const newSQL = sql === defaultSQL ? sql.replace(oldDatasetName, cleanedDatasetName) : sql;

            if (targetDataset !== undefined) {
                newDatasets[targetDataset] = {
                    tableName: cleanedDatasetName,
                    config: newDatasets[targetDataset].config
                };
            }

            return {
                ...currentTileConfig,
                dataStream: {
                    ...currentTileConfig.dataStream,
                    dataSourceConfig: {
                        ...currentTileConfig.dataStream?.dataSourceConfig,
                        tables: newDatasets,
                        sql: newSQL
                    }
                }
            };
        });
    };

    const setDatasetConfig = (newConfig: ConfigSetter) => {
        setTileConfig((currentTileConfig) => {
            const newDatasets = [...(datasets || [])];

            // If there is an active dataset we want to replace the config else we want to push the new one
            if (activeDataset !== undefined) {
                newDatasets[activeDataset] = {
                    tableName: activeDatasetTableName,
                    // If we're using a callback we want to resolve it with the current activeDatasetConfig, else
                    // we just insert the new dataset config
                    config: typeof newConfig === 'function' ? newConfig(activeDatasetConfig) : newConfig
                };
            }

            const tileConfigVariables = getTileConfigVariables(newDatasets);

            return {
                ...currentTileConfig,
                ...tileConfigVariables?.length && { variables: tileConfigVariables },
                dataStream: {
                    ...currentTileConfig.dataStream,
                    dataSourceConfig: {
                        ...currentTileConfig.dataStream?.dataSourceConfig,
                        tables: newDatasets
                    }
                }
            };
        });
    };

    const config = !isDatasetMode ? tileConfig : activeDatasetConfig;
    const setConfig = !isDatasetMode ? setTileConfig : setDatasetConfig;

    return {
        datasets,
        config,
        activeDataset,
        activeDataTab,
        isEditingSQL,
        addDataset,
        cloneDataset,
        removeDataset,
        renameDataset,
        setConfig,
        setActiveDataset,
        setActiveDataTab,
        setIsEditingSQL
    };
};
