import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import type { DataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { TileState } from 'queries/types/types';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export interface TileEditorContextValue {
    inEditor: boolean;
    savedTileConfig: DataStreamBaseTileConfig;
    tileConfig: DataStreamBaseTileConfig;
    savedTileDataStreamType?: DataStreamType;
    isDatasetMode: boolean;
    containsError: boolean;
    isEdited: boolean;
    previewHealth?: TileState;
    setPreviewHealth?: Dispatch<SetStateAction<TileState | undefined>>;
    onClose: () => void;
    setTileConfig: React.Dispatch<React.SetStateAction<DataStreamBaseTileConfig>>;
    setContainsError: (containsError: boolean) => void;
}

const TileEditorContext = createContext<TileEditorContextValue>({
    inEditor: false,
    savedTileConfig: {} as DataStreamBaseTileConfig,
    savedTileDataStreamType: {
        isConfigurable: false,
        supportsDatasets: false,
        requiresScope: false,
        supportsScope: false
    },
    tileConfig: {} as DataStreamBaseTileConfig,
    isDatasetMode: false,
    containsError: false,
    isEdited: false,
    onClose: () => undefined,
    setTileConfig: () => undefined,
    setContainsError: () => undefined
});

TileEditorContext.displayName = 'TileEditorContext';

/**
 *
 * @returns config, savedTileConfig, setConfig, onClose
 */
export const useTileEditorContext = () => useContext(TileEditorContext);

export default TileEditorContext;
