import { Edge } from '@squaredup/graph';
import { DirectedGraph } from 'graphology';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../types';
import { getGraphologyEdgeAttributes } from './convertToGraphology';

export function getNodeGroupID(
    graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>,
    nodeId: string
) {
    if (graph.hasNode(nodeId)) {
        // Node doesn't belong to a group
        return undefined;
    }

    return graph.findNode((_, node) => {
        if (!node.groupedData) {
            return false;
        }

        return node.groupedData.nodes.some((n) => n.id === nodeId);
    });
}

// Add an edge to the graph, handling the case where the edge is targeting a grouped node
export function addEdge(graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>, edge: Edge) {
    let inV = edge.inV;
    let outV = edge.outV;
    let id = edge.id;

    const inGroup = getNodeGroupID(graph, inV);
    if (inGroup) {
        // Edge is targeting a grouped node
        inV = inGroup;
        id = `group-${edge.id}`;

        graph.updateNodeAttribute(inGroup, 'groupedData', (attr) => ({
            ...attr!,
            edges: attr!.edges.concat(edge)
        }));
    }

    const outGroup = getNodeGroupID(graph, outV);
    if (outGroup) {
        // Edge is targeting a grouped node
        outV = outGroup;
        id = `group-${edge.id}`;

        graph.updateNodeAttribute(outGroup, 'groupedData', (attr) => ({
            ...attr!,
            edges: attr!.edges.concat(edge)
        }));
    }

    graph.mergeEdge(inV, outV, {
        ...getGraphologyEdgeAttributes(edge),
        id,
        sourceId: outV,
        targetId: inV,
        hidden: false
    });
}
