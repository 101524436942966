import { traverse } from '@squaredup/utilities';
import Handlebars from 'handlebars';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Register the raw helper so users can turn off Handlebars as needed
 */
Handlebars.registerHelper('raw', function (options) {
    return options.fn();
});

/**
 * Render the given template & view data to string
 * @param template Template to render
 * @param view Data for view
 */
const render = (template: string, view: any): string => Handlebars.compile(template)(view);

/**
 * Resolves label from data
 * Fallsback to label value
 * @param {any} data Data object/array
 * @param {string} template Template to resolve
 */
export const resolveLabel = (template: string, data: any, context: object, defaultValue = template) => {
    if (!template || template.indexOf('{{') === -1) {
        return defaultValue;
    }

    return render(template, { data, context }) || defaultValue;
};

/**
 * Recursively walk the config object, rendering any strings as mustache templates
 * @param config Config object
 * @param view View data
 */
export const deepResolveLabels = (config: any, view: any) => {
    const cloned = cloneDeep(config);
    return traverse(cloned, (v: string) => render(v, view));
};
