import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { fiveMinutes } from 'queries/constants';
import { useDataStreamDefinitionsForWorkspace } from 'queries/hooks/useDataStreamDefinitions';
import { useTileEditorDataStreams } from '../state/useTileEditorDataStreams';
import { useDataSources } from './useDataSources';
import { useSourceTypesToTypesMap } from './useSourceTypesToTypesMap';
import { useTileEditorScopes } from './useScopes';

const cacheDefaults = {
    cacheTime: fiveMinutes * 2,
    staleTime: fiveMinutes
};

export const usePreloadEditorData = () => {
    const { workspace } = useDataStreamWorkspaceContext();

    const { data: sources } = useDataSources(cacheDefaults, undefined);
    useSourceTypesToTypesMap(sources, cacheDefaults);
    useDataStreamDefinitionsForWorkspace({ workspace, queryOptions: cacheDefaults });
    useTileEditorDataStreams(workspace);
    useTileEditorScopes();
};
