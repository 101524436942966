import Text from '@/components/Text';
import { faBuildings, faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OpenAccessSettings, getSharingEnvironment } from '@squaredup/open-access';
import Field from 'components/forms/field/Field';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import deepEqual from 'fast-deep-equal/es6';
import { emailRegex } from 'lib/validation';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IsTenantAdmin } from 'services/AccessControlService';
import { TenantResponse } from '../../../services/TenantService';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';

interface OpenAccessFormConfig {
    publicEnabled: boolean;
    authenticatedEnabled: boolean;
    emailDomains: AutocompleteOption[];
    allowWorkspaceLevelDomains: boolean;
}

export const OpenAccessForm: React.FC<{
    tenant: TenantResponse;
    updateSharingSettings: (value: OpenAccessSettings) => Promise<unknown>;
}> = ({ tenant, updateSharingSettings }) => {
    const {
        isSharingInPlan,
        isAuthSharingInPlan,
        isSharingEnabled,
        tenantSharingSettings: {
            publicSharesEnabled,
            authenticatedDomains,
            authenticatedSharesEnabled,
            oaAllowWorkspaceLevelDomains
        }
    } = getSharingEnvironment(tenant);

    const isTenantAdmin = IsTenantAdmin(tenant);
    const [showEmailDomains, setShowEmailDomains] = useState(authenticatedSharesEnabled);

    const defaultValues = useMemo<OpenAccessFormConfig>(
        () => ({
            publicEnabled: publicSharesEnabled,
            authenticatedEnabled: showEmailDomains,
            emailDomains: authenticatedDomains.map((d) => ({ label: d, value: d })) ?? [],
            allowWorkspaceLevelDomains: oaAllowWorkspaceLevelDomains
        }),
        [publicSharesEnabled, showEmailDomains, authenticatedDomains, oaAllowWorkspaceLevelDomains]
    );

    const formProps = useForm<OpenAccessFormConfig>({ mode: 'all', defaultValues });
    const { watch, handleSubmit } = formProps;

    const onOaOptionsChanged = handleSubmit(
        async ({
            publicEnabled,
            authenticatedEnabled,
            emailDomains,
            allowWorkspaceLevelDomains
        }: OpenAccessFormConfig) => {
            const mappedDomains = emailDomains?.map((d) => d.value) ?? [];
            const current: OpenAccessSettings = {
                oaAuthenticatedDomains: authenticatedDomains,
                oaPublicSharingEnabled: publicSharesEnabled,
                oaAuthenticatedSharingEnabled: authenticatedSharesEnabled,
                oaAllowWorkspaceLevelDomains: oaAllowWorkspaceLevelDomains
            };

            const newSettings: OpenAccessSettings = {
                oaPublicSharingEnabled: publicEnabled,
                oaAuthenticatedDomains: mappedDomains,
                oaAuthenticatedSharingEnabled:
                    !authenticatedEnabled || mappedDomains.length > 0
                        ? authenticatedEnabled // always disable, or enable if domains have been set
                        : current.oaAuthenticatedSharingEnabled, // otherwise keep current newSettings
                oaAllowWorkspaceLevelDomains: allowWorkspaceLevelDomains
            };

            if (!deepEqual(newSettings, current)) {
                await updateSharingSettings(newSettings);
            }
        }
    );

    // watch for changes and trigger submission if valid
    useEffect(() => {
        const sub = watch((data) => {
            // allow the email domains input to show even though we aren't persisting
            // the value until domains have been populated
            setShowEmailDomains(data.authenticatedEnabled ?? false);
            onOaOptionsChanged();
        });
        return () => sub.unsubscribe();
    }, [watch, onOaOptionsChanged]);

    // Need to define a custom validator as the autocomplete has no label
    const validateDomains = (values: unknown[]) => {
        return values.length > 0 ? true : 'At least one email domain is required.';
    };

    if (!isTenantAdmin) {
        return isSharingEnabled ? (
            <></>
        ) : (
            <p className='text-sm text-textIncomplete' data-testid='nonAdminDisabledMessage'>
                The sharing feature is currently disabled by your organization administrator.
            </p>
        );
    }

    return (
        <FormProvider {...formProps}>
            <form data-testid='sharingForm' className='max-w-[655px] mt-2'>
                <Text.H2>Allowed sharing options</Text.H2>
                <div className='mt-3'>
                    <FormToggle
                        name='publicEnabled'
                        label='Anyone with the link can view (public)'
                        description='Unlimited viewers, no authentication required'
                        disabled={!isSharingInPlan}
                        icon={faGlobe}
                        testId='publicSharing'
                    />
                </div>
                <div className='mt-4'>
                    <FormToggle
                        name='authenticatedEnabled'
                        label='Anyone in my organization can view (restricted by email domains)'
                        description='Unlimited viewers, requires authentication'
                        disabled={!isAuthSharingInPlan}
                        icon={faBuildings}
                        testId='authSharing'
                        upgradePill={<FeatureUnavailablePill featureKey='authenticatedSharing' className='ml-2' />}
                    />
                </div>
                {showEmailDomains && (
                    <div>
                        <h2 className='mt-8 text-xl font-semibold'>Email domains</h2>
                        <div>
                            <Field.Input
                                name='emailDomains'
                                disabled={!isAuthSharingInPlan}
                                description={
                                    <span>
                                        Restrict who can sign-in to view a shared dashboard when "{' '}
                                        <FontAwesomeIcon icon={faBuildings} /> Anyone in my organization" is enabled.
                                    </span>
                                }
                                descriptionAbove={true}
                                type='autocomplete'
                                title='Email domains'
                                noOptionsMessage={() => 'Type a valid email domain e.g. contoso.com'}
                                validation={{ validate: validateDomains }}
                                isMulti={true}
                                isClearable={false}
                                formatCreateLabel={(l: string) => `Add ${l.toLowerCase()}`}
                                onCreate={(v: string) => ({
                                    label: v.toLowerCase(),
                                    value: v.toLowerCase(),
                                    isNew: true
                                })}
                                placeholder='Enter one or more email domains'
                                isValidNewOption={(value: string) => value && emailRegex.test(`user@${value}`)}
                            />
                        </div>

                        <FormToggle
                            name='allowWorkspaceLevelDomains'
                            label='Specify additional email domains at the workspace level'
                            disabled={!isAuthSharingInPlan || !authenticatedSharesEnabled}
                            help='When this option is selected, users with Full Control access to a workspace can specify additional email domains at the workspace level.'
                            wrapperClassName='justify-start items-center'
                        />
                    </div>
                )}
            </form>
        </FormProvider>
    );
};
