import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { trackEventDebounced } from 'lib/analytics';
import { useClickOutside } from 'lib/useClickOutside';
import { useRef, useState } from 'react';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';

export const SearchFilter = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { filters, dispatch } = useTileEditorStore((s) => ({
        filters: s.dataStream.filters,
        dispatch: s.dispatch
    }));

    const [isOpen, setIsOpen] = useState(true);

    // Handle clicks outside of panel
    useClickOutside([wrapperRef], () => !filters.search ? setIsOpen(false) : undefined);

    return (
        <div 
            className='flex items-center px-3 leading-none border rounded-md cursor-pointer bg-secondaryButtonBackground text-textPrimary py-inputWithBorder border-outlinePrimary'
            onClick={() => setIsOpen(isCurrentlyOpen => !isCurrentlyOpen)}
            data-testid='datastreamSearch'
            data-state={isOpen ? 'open' : 'closed'}
            ref={wrapperRef}
        >
            <FontAwesomeIcon 
                icon={faSearch} 
                className='text-[16px] py-px'
            />
            
            <AnimatePresence>    
                {(isOpen || filters.search) && 
                    <motion.div 
                        initial={{ maxWidth: 0 }}
                        animate={{ 
                            maxWidth: (isOpen || filters.search) ? 160 : 0,
                            transition: { type: 'tween' }
                        }}
                        exit={{ maxWidth: 0 }}
                        className='overflow-hidden'
                        data-testid='searchBar'
                    >
                        <input
                            type='text'
                            value={filters.search ?? ''} 
                            className='w-full min-w-0 p-0 pl-3 leading-none bg-transparent border-0 text-inherit placeholder:text-textIncomplete focus:ring-0'
                            placeholder='Search data streams'
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                trackEventDebounced('Data Stream Searched For', { query: e.target.value });
                                dispatch({ 
                                    type: 'dataStream.updateFilters', 
                                    filters: { 
                                        search: e.target.value 
                                    } 
                                });
                            }}
                            autoFocus
                        />
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    );
};
