import { Serialised } from '@squaredup/ids';
import { isDefined } from '@squaredup/utilities';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import type { DataStreamDefinitionEntity } from 'dynamo-wrapper';
import { flatMap, map, sortBy } from 'lodash';
import { useAvailableDataStreamDefinitionsForWorkspace } from 'queries/hooks/useAvailableDataStreamDefinitionsForWorkspace';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isMatchable } from 'services/DataStreamDefinitionService';
import { matchDataStreams } from 'services/DataStreamService';
import type { DataStreamStateWithDispatch } from '../state/dataStream/DataStreamStateReducer';
import { useDataSources } from './useDataSources';

export type DataStreamFilterOption = Serialised<DataStreamDefinitionEntity> & {
    pluginName?: string;
    /**
     * The configurable data stream that this data stream
     * is a preset of.
     */
    presetParentDataStream?: Omit<DataStreamFilterOption, 'displayNameFull'>;
    /**
     * The display name of this data stream, prepended with
     * the name of the data stream this stream is a preset of (if any).
     */
    displayNameFull: string;
    definition: {
        matchesTypes?: string[];
    };
};

/**
 * @param dataStreamId string
 * Gets the data sources for the workspace and generates filter criteria for selecting a
 * data stream (data source and/or type), manges the filter state and returns the list of
 * data streams for selection
 * @returns filter criteria, loading state and filter state getters/setters
 */
export const useDataStreamFilters = ({ dispatch, filters, selectedDataStream }: DataStreamStateWithDispatch) => {
    const { workspace: workspaceFromContext, isGlobal } = useDataStreamWorkspaceContext();
    const workspace = workspaceFromContext && !isGlobal ? workspaceFromContext : null;

    // Store id and pluginConfigId on mount so we can show the previously selected data stream at the top of the list
    const [dataStreamId] = useState(selectedDataStream?.id);

    const { data: sources, isLoading: isLoadingSources } = useDataSources(undefined, undefined, workspace ?? undefined);

    const { data: dataStreams, isLoading: isLoadingDataStreams } = useAvailableDataStreamDefinitionsForWorkspace(
        sources,
        workspace ?? undefined,
        selectedDataStream?.id
    );

    const { data: currentScopeDataStreams, isLoading: isLoadingScopeDataStreamMatches } = useQuery(
        ['streamMatches', filters.scopeId],
        () =>
            matchDataStreams({
                scope: filters.scopeId,
                workspace: workspaceFromContext,
                query: ''
            }),
        {
            staleTime: Number.POSITIVE_INFINITY,
            cacheTime: Number.POSITIVE_INFINITY,
            enabled: Boolean(filters.scopeId),
            select(data) {
                return Object.values(data).flat();
            }
        }
    );

    /**
     * The visible Data Source, Data Stream type, and Object Scope filters are affected by one another
     *
     * When a filters.dataSourceId is picked, any Object Scopes that don't appear in the
     * resulting dataStreams list will get filtered out, and vice-versa with filters.objectType
     * and Data Sources
     *
     * We need to filter the dataStreams list several different ways to get each variation to
     * compare against
     */
    const dataStreamsForScope = useMemo(() => {
        if (!filters.scopeId) {
            return dataStreams || [];
        }

        if (currentScopeDataStreams && currentScopeDataStreams.length > 0) {
            const scopeDataStreams = currentScopeDataStreams.map((scopeStream) => scopeStream.id);
            return (dataStreams || []).filter(
                // If matches the scope, or it's a non matchable stream that's currently selected
                (s) => scopeDataStreams.includes(s.id) || (!isMatchable(s) && dataStreamId === s.id)
            );
        }

        return dataStreams || [];
    }, [dataStreams, currentScopeDataStreams, filters.scopeId, dataStreamId]);

    const dataStreamsForPlugin =
        dataStreams?.filter(
            (stream) => stream.pluginId && (filters.dataSourceId ? filters.dataSourceId === stream.pluginId : true)
        ) || [];

    const dataStreamsForObjectScope =
        dataStreams?.filter((stream) =>
            filters.objectType
                ? stream.definition.matchesTypes?.some((type) => filters.objectType === type || type === 'all')
                : true
        ) || [];

    const dataStreamsForTags =
        dataStreams?.filter((stream) =>
            filters.tag ? stream.definition.tags?.includes(filters.tag) : true
        ) || [];

    const dataStreamsToShowForTags = dataStreamsForScope.filter(
        (stream) =>
            dataStreamsForPlugin.some(({ pluginId }) => stream.pluginId === pluginId) &&
            dataStreamsForObjectScope.some(({ id }) => stream.id === id)
    );

    /**
     * Data streams with all filters applied - scope, source, object scope,
     * matches none/scoped - these get displayed to the user
     */
    const dataStreamsToShow = dataStreamsToShowForTags.filter((stream) =>
        dataStreamsForTags.some(({ id }) => stream.id === id)
    );

    /**
     * We need to generate the list of filter options to show based on the currently selected
     * criteria and the filtered set of data streams
     */
    const pluginOptions = [
        ...new Set(
            map(
                dataStreamsForScope.filter(
                    (stream) =>
                        dataStreamsForObjectScope.some(({ id }) => stream.id === id) &&
                        dataStreamsForTags.some(({ id }) => stream.id === id)
                ),
                'pluginId'
            )
        )
    ];

    const pluginOptionsToShow = sortBy(
        pluginOptions
            .map((pluginId) => ({
                pluginId,
                pluginName: sources?.find((s) => s.plugin?.pluginId === pluginId)?.plugin?.name,
                onPrem: sources?.find((s) => s.plugin?.pluginId === pluginId)?.plugin?.onPrem
            }))
            .filter((o): o is typeof o & { pluginName: string } => o.pluginName != null),
        'pluginName'
    );

    // Get a list of object scopes to show based on the available data streams filtered by data source
    const objectScopeOptions = [
        ...new Set([
            ...(flatMap(
                dataStreamsForScope.filter((stream) => dataStreamsForPlugin.some(({ id }) => stream.id === id)),
                'definition.matchesTypes'
            ) as string[]),
            filters.objectType
        ])
    ].filter(isDefined);

    const objectScopesToShow = objectScopeOptions
        .filter((objectScope) => objectScope && !['all', 'none', 'advanced'].includes(objectScope))
        .sort();

    if (objectScopeOptions.includes('all')) {
        objectScopesToShow.unshift('all');
    }
    // Ensure advanced and all are at the top of the list
    if (objectScopeOptions.includes('advanced')) {
        objectScopesToShow.push('advanced');
    }

    const isLoading = isLoadingSources || isLoadingDataStreams;
    const isSelectedDatastreamNoLongerAvailable =
        selectedDataStream != null && !isLoading && !dataStreams?.some(({ id }) => selectedDataStream.id === id);

    // If the selected data stream isn't available in the current context (workspace or global) then clear the selection
    useEffect(() => {
        if (isSelectedDatastreamNoLongerAvailable) {
            dispatch({ type: 'dataStream.clearSelectedDataStream' });
        }
    }, [isSelectedDatastreamNoLongerAvailable, dispatch]);

    return {
        dataStreamFilterOptions: dataStreamsToShow,
        pluginFilterOptions: pluginOptionsToShow,
        objectScopeFilterOptions: objectScopesToShow,
        dataStreamsToShowForTags,
        isLoading,
        isLoadingScopeDataStreamMatches
    };
};
