import {
    findColumn,
    findColumns,
    FormattedStreamValue,
    required,
    RowData,
    StreamDataColumn
} from '@squaredup/data-streams';

/**
 * Get the drilldown url for a given source and plugin config
 *
 * @example
 * getDrilldownUrlFor('sourceId', 'pluginConfigId')
 * returns '/drilldown/node?sourceId=sourceId&__configId=pluginConfigId'
 */
export const getDrilldownUrlFor = (sourceId: string, pluginConfigId: string) =>
    `/drilldown/node?sourceId=${encodeURIComponent(sourceId)}&__configId=${encodeURIComponent(pluginConfigId)}`;

/**
 * Get the first value if all values are the same, otherwise undefined
 */
export const getValueIfAllSame = <T>(values: (T | undefined)[]): T | undefined =>
    values.filter((i) => i).reduce((value, curr) => (curr === value ? value : undefined), values[0]);

/**
 * Get source ids for a a given column.
 * Finds the source id column attached to the given column and returns the source id for each datastream row.
 */
export const getSourceIdsForColumn = (
    allColumns: StreamDataColumn[],
    column: StreamDataColumn,
    rows: FormattedStreamValue[][]
): (string | undefined)[] => {
    const sourceIdColumnName = column.sourceIdColumn;

    if (sourceIdColumnName == null) {
        return rows.map(() => undefined);
    }

    const sourceIdColumnIndexResult = findColumn(allColumns, required('name', sourceIdColumnName)).map(
        (c) => c.dataIndex
    );

    if (sourceIdColumnIndexResult.failed) {
        return rows.map(() => undefined);
    }

    return rows.map((r) => r[sourceIdColumnIndexResult.value]?.value as string | undefined);
};

/**
 * Find columns with a sourceIdColumn present.
 */
export const findColumnsWithSourceId = (allColumns: StreamDataColumn[]) => {
    return findColumns(
        allColumns,
        required.custom('columns with sourceIdColumn', (c) => Boolean(c.sourceIdColumn))
    );
};

/**
 * Generate drilldown url for a given row.
 * Must only point to one plugin.
 *
 * @param row The row data
 * @param rowData The row metadata with pluginConfigIds
 * @param sourceIdColumn The column which contains the source id
 */
export const getDrilldownUrlForRow = (rowData: RowData, sourceIdColumnName: string) => {
    if (!rowData.sourceIds || !rowData.pluginConfigIds) {
        return null;
    }

    const sourceId = rowData.sourceIds.find((s) => s.columnName === sourceIdColumnName)?.sourceId;

    if (!sourceId) {
        return null;
    }

    const pluginConfigId = getValueIfAllSame(rowData.pluginConfigIds);

    if (!pluginConfigId) {
        return null;
    }

    return getDrilldownUrlFor(sourceId, pluginConfigId);
};

/**
 * Find a sourceId column to use for drilldown.
 * If multiple different sourceId columns are present in data, returns null.
 *
 * @param columns All columns in the data, used to make sure only one unique sourceId column is present
 * @param candidateColumn The column to search for the sourceIdColumn column, if multiple first valid one found is used
 */
export const findDrilldownSourceIdColumn = (
    columns: StreamDataColumn[],
    candidateColumn?: StreamDataColumn | (StreamDataColumn | undefined)[]
) => {
    if (!candidateColumn) {
        return null;
    }

    const candidateColumns = Array.isArray(candidateColumn) ? candidateColumn : [candidateColumn];

    // all columns that have a sourceIdColumn property.
    const sourceIdColumns = findColumnsWithSourceId(columns);
    const candidateSourceIdColumns = findColumnsWithSourceId(candidateColumns.filter((c) => c !== undefined));

    if (
        sourceIdColumns.failed ||
        candidateSourceIdColumns.failed ||
        !candidateSourceIdColumns.value[0].column.sourceIdColumn
    ) {
        return null;
    }

    const uniqueSourceIdColumns = new Set(sourceIdColumns.value.map((c) => c.column.sourceIdColumn));

    // only grab the sourceId if columns point at only one object
    // otherwise we can't determine which object to drill down to
    if (uniqueSourceIdColumns.size !== 1) {
        return null;
    }

    const sourceIdColumn = candidateSourceIdColumns.value[0].column.sourceIdColumn;

    return sourceIdColumn;
};
