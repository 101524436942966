import stringify from 'fast-json-stable-stringify';
import { COUNT, DETAIL, LIST, MONITOR, ROLLUP, STATE } from './common';
import { OA_SHARES_DATA } from 'services/OpenAccessService';

const DASHBOARDS = 'dashboards';
const NEW = 'new';
const VARIABLES = 'variables';

export const dashboardQueryKeys = {
    deleteDashboardsMutation: 'DELETE_DASHBOARDS',
    all: [DASHBOARDS], // Use sparingly - invalidates data for every individual dashboard - maybe you only need list and/or states?
    list: [DASHBOARDS, LIST],
    states: [DASHBOARDS, STATE],
    monitorCount: [DASHBOARDS, STATE, MONITOR, COUNT],
    stateRollup: (dashboardId?: string) => [DASHBOARDS, STATE, ROLLUP, String(dashboardId)],
    stateRollups: (dashboardIds?: string[]) => [DASHBOARDS, STATE, ROLLUP, stringify(dashboardIds?.sort())],
    state: (dashboardIds?: string[]) => [DASHBOARDS, STATE, stringify(dashboardIds?.sort())],
    detail: (dashboardId?: string) => [DASHBOARDS, DETAIL, String(dashboardId)],
    variables: (dashboardId?: string, initial: object = { selectedObjects: [] }) => [
        DASHBOARDS,
        VARIABLES,
        String(dashboardId),
        stringify(initial)
    ],
    monitorsForDashboard: (dashboardIds?: string[]) => [DASHBOARDS, MONITOR, stringify(dashboardIds?.sort())],
    openAccessForDashboard: (dashboardId: string) => [OA_SHARES_DATA, dashboardId],
    openAccessNewLink: [OA_SHARES_DATA, NEW],
    /**
     * Key for storing default tile scopes generated when migrating
     * scopeless global data stream tiles to data source scopes.
     */
    defaultTileScope: (pluginConfigId: string) => ['defaultTileScope', pluginConfigId]
};
