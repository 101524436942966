import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import Tooltip from 'components/tooltip/Tooltip';
import PluginIcon from 'pages/scope/PluginIcon';
import { DataStreamListItem } from '../components/DataStreamListItem';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { DataStreamOptionName } from './DataStreamOptionName';
import { sortBy } from 'lodash';

interface DataStreamOptionProps {
    dataStream: DataStreamFilterOption;
    isSelected?: boolean;
    onClick?: () => void;
}

export const DataStreamOption: React.FC<DataStreamOptionProps> = ({
    dataStream,
    isSelected = false,
    onClick
}) => {
    const displayName =
        dataStream.presetParentDataStream == null ? (
            dataStream.displayName
        ) : (
            <>
                <span
                    className={cn('text-tileEditorPresetCategoryText', {
                        'text-tileEditorPresetCategoryTextActive': isSelected
                    })}
                >
                    {dataStream.presetParentDataStream.displayName} /{' '}
                </span>
                {dataStream.displayName}
            </>
        );

    return (
        <DataStreamListItem
            key={dataStream.id}
            className='flex'
            isSelected={isSelected}
            onClick={onClick}
            data-testid='dataStreamOption'
            aria-selected={isSelected}
            description={dataStream.description}
        >
            <div className='flex items-center flex-1 min-w-0 gap-4'>
                <span className='flex items-center space-x-3'>
                    <span className='h-[15px] w-[15px] flex-shrink-0'>
                        <Tooltip title={dataStream.pluginName}>
                            <PluginIcon pluginName={dataStream.pluginName} />
                        </Tooltip>
                    </span>
                </span>

                <div className='flex items-center min-w-0'>
                    <DataStreamOptionName 
                        displayName={dataStream.presetParentDataStream?.displayName ? 
                            `${dataStream.presetParentDataStream.displayName} / ${dataStream.displayName}` : 
                            dataStream.displayName
                        }  
                        description={dataStream.description}
                    >
                        {displayName}
                    </DataStreamOptionName>
                </div>
            </div>

            <div className='flex items-center justify-end gap-2 text-textSecondary'>
                {(sortBy(dataStream.definition.tags) ?? []).map((tag) =>
                    <span
                        key={tag}
                        className='inline-block bg-tileBackground border border-outlinePrimary rounded-full px-2 py-0.5'
                        data-testid='datastreamTag'
                    >
                        <Text.SmallBody>{tag}</Text.SmallBody>
                    </span>
                )}
            </div>
        </DataStreamListItem>
    );
};
