import { Edge, Node } from '@squaredup/graph';
import { DASHBOARD_NODE, DASHBOARD_NODE_SIZE } from 'components/map/components/nodes/DashboardNode';
import { KPI_NODE, KPI_NODE_SIZE } from 'components/map/components/nodes/KPINode';
import { MONITOR_NODE, MONITOR_NODE_SIZE } from 'components/map/components/nodes/MonitorNode';
import { OBJECT_NODE, OBJECT_NODE_SIZE } from 'components/map/components/nodes/ObjectNode';
import { ORGANISATION_NODE, ORGANISATION_NODE_SIZE } from 'components/map/components/nodes/OrganisationNode';
import { WORKSPACE_NODE, WORKSPACE_NODE_SIZE } from 'components/map/components/nodes/WorkspaceNode';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes, GraphologyNodeType } from '../types';

const graphologyNodeTypeMap: Record<string, GraphologyNodeType> = {
    'squaredup/dash': DASHBOARD_NODE,
    'squaredup/monitor': MONITOR_NODE,
    'squaredup/space': WORKSPACE_NODE,
    KPI: KPI_NODE,
    ten: ORGANISATION_NODE
};

const sizeMap: Record<string, number> = {
    [DASHBOARD_NODE]: DASHBOARD_NODE_SIZE,
    [MONITOR_NODE]: MONITOR_NODE_SIZE,
    [WORKSPACE_NODE]: WORKSPACE_NODE_SIZE,
    [OBJECT_NODE]: OBJECT_NODE_SIZE,
    [KPI_NODE]: KPI_NODE_SIZE,
    [ORGANISATION_NODE]: ORGANISATION_NODE_SIZE
};

export function getGraphologyNodeAttributes(
    node: Node,
    extras: {
        expanded: boolean;
        pinned: boolean;
        ungrouped: boolean;
        hidden?: boolean;
        x?: number;
        y?: number;
    }
) {
    const type =
        graphologyNodeTypeMap[node.sourceType?.[0] || ''] ||
        graphologyNodeTypeMap[node.type?.[0] || ''] ||
        'objectNode';

    return {
        ...extras,
        x: extras.x || 0,
        y: extras.y || 0,
        width: sizeMap[type],
        height: sizeMap[type],
        id: node.id,
        data: node,
        initalKPIs: node.kpis,
        type,
        hidden: extras.hidden ?? true
    } as GraphologyNodeAttributes;
}

export function getGraphologyEdgeAttributes(edge: Edge): GraphologyEdgeAttributes {
    return {
        label: edge.label,
        data: edge,
        id: edge.id!,
        sourceId: edge.outV,
        targetId: edge.inV,
        hidden: false
    };
}
