import { useCallback } from 'react';
import { useGraph, useHealthCache } from '../context/NetworkMapStoreContext';
import { fetchConnectedData, fetchMapData } from '../data/fetchMapData';
import { GraphologyNodeAttributes } from '../data/types';
import { insertQueryResults } from '../data/utils/insertQueryResults';
import { rewriteLargeNodeGroups } from '../data/utils/rewriteLargeNodeGroups';
import { getNodeHealths } from './useNodeHealth';

export function useSelectiveExpandNode() {
    const graph = useGraph();
    const nodeHealthCache = useHealthCache();

    return useCallback(
        async (node: GraphologyNodeAttributes, direction: 'out' | 'in', unhealthy: boolean, recursive: boolean) => {
            const nodeIds = node.type === 'groupNode' ? node.groupedData!.nodes.map(({ id }) => id) : [node.id];

            graph.setNodeAttribute(node.id, 'pinned', true);

            if (recursive) {
                fetchConnectedData(nodeIds, direction).then(async (graphResults) => {
                    let filteredNodes = graphResults?.nodes || [];

                    const healthMap = await getNodeHealths(nodeHealthCache, filteredNodes);

                    filteredNodes = filteredNodes.filter((n) => healthMap[n.id] === 'error');

                    insertQueryResults(
                        graph,
                        graphResults,
                        filteredNodes.map(({ id }) => id),
                        node
                    );

                    // We need to load the data for our filtered nodes
                    // depending on the direction, we may be missing data hence the
                    // need for this
                    fetchMapData(filteredNodes.map(({ id }) => id)).then((res) => {
                        insertQueryResults(graph, res);
                    });
                });
            } else {
                const neighbors = graph[direction === 'out' ? 'mapOutNeighbors' : 'mapInNeighbors'](
                    node.id,
                    (_, neighbor) => neighbor
                ).filter((neighbor) => Boolean(neighbor.data));

                const healthMap = await getNodeHealths(
                    nodeHealthCache,
                    neighbors.flatMap((neighbor) => neighbor.data!)
                );

                neighbors.forEach(({ id }) => {
                    const health = healthMap[id];
                    if (health === 'error' || !unhealthy) {
                        graph.updateNodeAttributes(id, (attr) => ({ ...attr, hidden: false }));
                    }
                });

                // check if all neighbors are now shown, if so we'll want to set the root node as expanded
                if (neighbors.every(({ id }) => !graph.getNodeAttributes(id).hidden)) {
                    graph.updateNodeAttributes(node.id, (attr) => ({ ...attr, expanded: true }));
                }

                rewriteLargeNodeGroups(graph);

                fetchMapData(
                    neighbors.map(({ id }) => id),
                    graph.filterNodes((_, attr) => attr.expanded)
                ).then((graphResults) => {
                    insertQueryResults(graph, graphResults);
                });
            }
        },
        [graph, nodeHealthCache]
    );
}
