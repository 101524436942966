import { HealthState } from '@squaredup/monitoring';
import { MapBackgroundColor } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { DirectedGraph } from 'graphology';
import { create } from 'zustand';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes, NodesAndEdges } from './data/types';
import { insertQueryResults } from './data/utils/insertQueryResults';
import { HealthCache } from './hooks/useNodeHealth';
import { LayoutTypes } from './types';

export type NetworkMapStoreInstance = ReturnType<typeof createNetworkMapStore>;

export interface NetworkMapStoreProps {
    mapId: string;
    background: MapBackgroundColor;
    layoutType: LayoutTypes;
    initalData?: NodesAndEdges;
    initalExpandedNodeIds?: string[];
    filterInitalBasedOnHealth?: boolean;
}

export interface NetworkMapStoreState extends Omit<NetworkMapStoreProps, 'initalNodes' | 'initalEdges'> {
    graph: DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>;

    nodeHealthMap: Map<string, HealthState>;
    setNodeHealth: (nodeId: string, health: HealthState) => void;

    getLayoutType: () => LayoutTypes;
    setLayoutType: (layoutType: LayoutTypes) => void;

    healthCache: HealthCache;
    setHealthCache: (cache: HealthCache) => void;
}

export const createNetworkMapStore = ({ mapId, background, layoutType, initalData }: NetworkMapStoreProps) => {
    const graph = new DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes>({});

    insertQueryResults(graph, initalData, []);

    const healthCache = {
        dashboardHealthData: new Map(),
        cache: new Map()
    };

    return create<NetworkMapStoreState>((set, get) => ({
        mapId,
        background,
        layoutType,
        graph,
        getLayoutType() {
            return get().layoutType;
        },
        setLayoutType(layout: LayoutTypes) {
            set({
                layoutType: layout
            });
        },
        nodeHealthMap: new Map<string, HealthState>(),
        setNodeHealth(nodeId: string, health: HealthState) {
            set((state) => ({
                nodeHealthMap: new Map(state.nodeHealthMap).set(nodeId, health)
            }));
        },
        healthCache,
        setHealthCache(cache: HealthCache) {
            set({
                healthCache: Object.assign({}, cache)
            });
        }
    }));
};
