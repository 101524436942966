import { Serialised } from '@squaredup/ids';
import { UIConfig } from '@squaredup/utilities';
import DisplayJsonUi from 'components/forms/jsonForms/DisplayJsonUi';
import PluginContext from 'contexts/PluginContext';
import type { Config, Plugin, ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { noop } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { useDataStreamForm } from '../hooks/useConfigurableDataStreamForm';

interface DataStreamUserConfiguredReadOnlyProps {
    formTemplate: Serialised<UIConfig[]> | undefined;
    defaultValues: Record<string, any>;
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[];
    plugin: Serialised<Plugin> | undefined;
    pluginConfig: Serialised<Config<object>> | undefined;
}

export const DataStreamUserConfiguredReadOnly: React.FC<DataStreamUserConfiguredReadOnlyProps> = ({
    formTemplate,
    defaultValues,
    streamDefinitions,
    plugin,
    pluginConfig
}) => {
    const { form, formFields } = useDataStreamForm({
        formTemplate,
        defaultValues,
        streamDefinitions,
        pluginId: plugin?.id,
        pluginConfigId: pluginConfig?.id
    });

    if (!formFields) {
        return null;
    }

    return (
        <PluginContext.Provider
            value={{
                plugin,
                config: pluginConfig! as Record<string, unknown>
            }}
        >
            <FormProvider {...form}>
                <form
                    className='flex flex-col flex-1 h-full min-w-0 min-h-0 pt-4 overflow-auto select-none scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                    onSubmit={form.handleSubmit(noop)}
                >
                    <div className='flex-1 w-full pr-4 pointer-events-none opacity-30'>
                        <DisplayJsonUi includeDisplayNameField={false} formFields={formFields} />
                    </div>
                </form>
            </FormProvider>
        </PluginContext.Provider>
    );
};
