import Text from '@/components/Text';
import { getTrialExpiry, validateTier } from '@squaredup/tenants';
import clsx from 'clsx';
import { differenceInHours } from 'date-fns';
import { useTenant } from 'queries/hooks/useTenant';
import { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { IsTenantAdmin } from 'services/AccessControlService';
import { PAID_TENANT_RENEWAL_PERIOD } from 'services/TenantService';

export const NavbarPlanDisplay = () => {
    const { data: tenant } = useTenant();

    // Stops the bar from appearing and then disappearing when the licence loads
    if (!tenant) {
        return <></>;
    }

    const tier = validateTier(tenant.tier);
    const isAdmin = IsTenantAdmin(tenant) ?? false;

    if (!tier.success) {
        return <PlanDisplay isAdmin={isAdmin} isTrial={false} expiry={new Date(0)} />;
    }

    // Check if a trial is currently active
    const trialExpiry = getTrialExpiry(tier.data);
    const licenceExpiry = tenant.licenceData?.licenceExpiry ? new Date(tenant.licenceData.licenceExpiry) : undefined;
    const isTrial = trialExpiry !== undefined;
    const expiry = isTrial ? trialExpiry : licenceExpiry;

    return <PlanDisplay isAdmin={isAdmin} isTrial={isTrial} expiry={expiry} />;
};

type PlanProps = {
    isAdmin: boolean;
    isTrial: boolean;
    expiry?: Date;
};

const PlanDisplay = ({ isAdmin, isTrial, expiry }: PropsWithChildren<PlanProps>) => {
    const daysLeft = expiry ? getDaysRemainingRoundUp(expiry) : undefined;

    // Handle non-trials outside renewal window
    if (!isTrial && daysLeft !== undefined && daysLeft > PAID_TENANT_RENEWAL_PERIOD) {
        return <></>;
    }

    return (
        <Container className={isTrial ? 'bg-upgrade' : undefined}>
            <LinkToUsage isAdmin={isAdmin}>
                <Text.H4 className={isTrial ? 'text-upgradeButtonTextPrimary' : 'text-textSecondary'}>
                    {getUpgradeText(isTrial, daysLeft)}
                </Text.H4>
            </LinkToUsage>
        </Container>
    );
};

const getDaysRemainingRoundUp = (expiry: Date) =>
    Math.ceil(differenceInHours(expiry, new Date(), { roundingMethod: 'ceil' }) / 24);

const getUpgradeText = (isTrial: boolean, daysLeft: number | undefined) => {
    if (daysLeft === undefined) {
        return 'Current Plan: Free';
    }

    const dayString = daysLeft === 1 ? 'day' : 'days';
    if (isTrial) {
        return `Trial (${daysLeft} ${dayString} left)`;
    }

    if (daysLeft < 1) {
        return 'Licence has expired';
    }

    return `${daysLeft} ${dayString} left until renewal`;
};

const Container = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        {...props}
        className={clsx('flex items-center justify-center h-16 border-dividerPrimary border-t-2', props.className)}
    />
);

const LinkToUsage = (props: PropsWithChildren<{ isAdmin: boolean }>) =>
    props.isAdmin ? <NavLink to='/usage' children={props.children} /> : <>{props.children}</>;
