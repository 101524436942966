import {
    DataStreamGroupingConfig,
    DataStreamGroupingConfigSchema,
    ShapeAggregator,
    ShapeName,
    StreamDataColumn,
    allAggregators,
    getAggregator,
    getShapeGroupers
} from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import stringify from 'fast-json-stable-stringify';
import { orderBy, uniqBy, zip } from 'lodash';
import { UseFormSetValue } from 'react-hook-form';
import { PartialDeep } from 'type-fest';
import { ColumnOption } from './filters';

export interface AggregationFormData {
    aggregationColumns?: string[];
    aggregationType: string;
}

type GroupingGroup = { groupBy?: string | null; bucketBy?: string | null };

export interface GroupingUpdateData {
    groups: GroupingGroup[];
    aggregations: AggregationFormData[];
}

export const aggregatorOptions = orderBy(
    allAggregators.map((a) => ({
        aggregator: a,
        value: a.name,
        label: a.displayName
    })),
    'label'
);

/**
 * Ensure that the aggregation form fields are in a valid state,
 * e.g. that we aren't trying to display an option that no longer exists,
 * or display an empty value in a field that requires one
 * @param setValue Set a value on the form
 * @param validAggregatorColumns The valid columns for each aggregator
 * @param aggregations The current state of the aggregation form fields
 */
export const validateAggregations = (
    setValue: UseFormSetValue<GroupingUpdateData>,
    validAggregatorColumns: Map<string, ColumnOption[][]>,
    aggregations: AggregationFormData[]
) => {
    aggregations.forEach((aggregation, aggregationIndex) => {
        // Tuples of the selected columns and the valid columns, for easy comparison
        const pairs = zip(
            aggregation.aggregationColumns ?? [],
            validAggregatorColumns.get(aggregation.aggregationType) ?? []
        );

        pairs.forEach(([selectedColumnName, validColumns = []], columnFieldIndex) => {
            const noSelectableColumns = validColumns.length === 0;
            const hasSelectedColumn = selectedColumnName != null;

            if (noSelectableColumns) {
                if (hasSelectedColumn) {
                    // There are no columns to select, but we have a value
                    const newAggregationColumns = [...(aggregation.aggregationColumns ?? [])];

                    newAggregationColumns.splice(columnFieldIndex, 1);

                    // Remove this selected column as it isn't required by this aggregator
                    setValue(`aggregations.${aggregationIndex}.aggregationColumns`, newAggregationColumns);
                }

                return;
            }

            const selectedColumnIsAValidOption = validColumns.some((o) => o.value === selectedColumnName);

            if (!selectedColumnIsAValidOption) {
                // Default any unset or invalid aggregation columns to the first option in the list
                setValue(
                    `aggregations.${aggregationIndex}.aggregationColumns.${columnFieldIndex}`,
                    validColumns[0].value
                );
            }
        });
    });
};

export const getGroupingColumns = (columns: StreamDataColumn[]): ColumnOption[] =>
    orderBy(
        columns
            .filter((c) => !c.name.includes('[Expanded].') && !c.computed)
            .map((c) => ({
                value: c.name,
                label: c.displayName,
                column: c
            })),
        'label'
    );

export const getBucketByOptions = (currentColumnShape?: ShapeName) => {
    const groupers = currentColumnShape ? getShapeGroupers(currentColumnShape) : [];

    return groupers.map((g) => ({
        label: g.displayName,
        value: g.name
    }));
};

export const toGroupingConfig = (data: PartialDeep<GroupingUpdateData>): DataStreamGroupingConfig['group'] => {
    const by = data.groups
        ?.filter(isDefined)
        .filter(({ groupBy }) => isDefined(groupBy))
        .map(
            ({ groupBy, bucketBy }) => [groupBy, bucketBy ?? 'uniqueValues'] as [string, string]
        );
    const aggregations = uniqBy(
        data.aggregations?.filter(isDefined).map((a) => ({ names: a.aggregationColumns, type: a.aggregationType })),
        (a) => stringify(a)
    );

    const groupingConfig = {
        by,
        aggregate: aggregations
    };

    const allAggregatorsHaveColumns = aggregations.every((a) => {
        const aggregator = getAggregator(a.type);

        return aggregator != null && aggregator.requiredColumns === (a.names?.length ?? 0);
    });

    if (allAggregatorsHaveColumns && DataStreamGroupingConfigSchema.safeParse(groupingConfig).success) {
        return groupingConfig;
    }

    return undefined;
};

export const toFormData = (config: DataStreamGroupingConfig['group']): PartialDeep<GroupingUpdateData> => {
    let groups: GroupingGroup[] = [{ groupBy: null, bucketBy: null }];

    if (typeof config?.by === 'string') {
        groups = [{ groupBy: config?.by, bucketBy: null }];
    } else if (config?.by && Array.isArray(config?.by) && Array.isArray(config?.by?.[0])) {
        // Grouping is in a nested array format, e.g. [[col1, undefined], [col2, undefined]]
        groups = config.by.filter(isDefined).map((by) => ({ groupBy: by[0], bucketBy: by[1] }));
    } else if (config?.by) {
        // Grouping is in single array format, e.g. [col1, undefined]
        groups = [{ groupBy: config?.by[0], bucketBy: config?.by[1] }] as GroupingGroup[];
    }
    return {
        groups,
        aggregations: config?.aggregate?.map((a) => ({ aggregationColumns: a?.names, aggregationType: a?.type })) ?? []
    };
};

/**
 * Get an array of valid columns the user must select
 * (e.g. most aggregators require a single column, while count requires none)
 */
const getRequiredColumnOptions = (aggregator: ShapeAggregator, options: ColumnOption[]): ColumnOption[][] => {
    if (aggregator.requiredColumns === 0) {
        return [];
    }

    const result = Array(aggregator.requiredColumns).fill([]);

    return result.map((_, i) =>
        options.filter((option) => {
            return aggregator.canAggregate(option.column.valueShapeName)[i];
        })
    );
};

/**
 * Get a map of aggregator names to valid column options.
 * This lets you find out which options can be selected for a given aggregator without
 * working it out on the fly.
 */
export const getAllRequiredColumnOptions = (options: ColumnOption[]): Map<string, ColumnOption[][]> => {
    return new Map(
        allAggregators.map((a) => {
            return [a.name, getRequiredColumnOptions(a, options)];
        })
    );
};
