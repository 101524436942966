import { Node } from '@squaredup/graph';
import { HealthState, stateValues } from '@squaredup/monitoring';
import { isDefined } from '@squaredup/utilities';
import stringify from 'fast-json-stable-stringify';
import { fiveMinutes } from 'queries/constants';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { useEffect } from 'react';
import { ListDashboardHealthByIds } from 'services/HealthService';
import { useGraph, useHealthCache, useSetHealthCache } from '../context/NetworkMapStoreContext';

interface CacheData<T = unknown> {
    lastUpdated: number;
    data: T;
}

export interface HealthCache {
    // We're caching these seperatley since multiple nodes may need this data
    dashboardHealthData: Map<
        string,
        CacheData<Awaited<ReturnType<typeof ListDashboardHealthByIds>>['dashboardStates'][0]>
    >;
    cache: Map<string, HealthState>;
}

const STALE_TIME = fiveMinutes;

async function getDashboardHealths(cache: HealthCache, dashIds: string[]) {
    const cachedData = dashIds.map((dashId) => cache.dashboardHealthData.get(dashId));

    const notCachedIds = cachedData
        .map((data, i) => {
            if (!data || data.lastUpdated + STALE_TIME < Date.now()) {
                return dashIds[i];
            }
            return undefined;
        })
        .filter(isDefined);

    if (notCachedIds.length > 0) {
        const dashboards = await ListDashboardHealthByIds(notCachedIds).then(({ dashboardStates }) => dashboardStates);

        dashboards.forEach((dashboard) => {
            cache.dashboardHealthData.set(dashboard.dashboardId, {
                data: dashboard,
                lastUpdated: Date.now()
            });
        });
    }

    return dashIds.map((dashId) => ({
        state: cache.dashboardHealthData.get(dashId)?.data?.state || 'unmonitored',
        unhealthyTileStates: cache.dashboardHealthData.get(dashId)?.data?.unhealthyTileStates,
        dashboardId: cache.dashboardHealthData.get(dashId)?.data?.dashboardId
    }));
}

function useSyncWorkspaceHealths() {
    const cache = useHealthCache();
    const setCache = useSetHealthCache();

    const workspaceHealths = useWorkspacesWithHealthRollup();

    useEffect(() => {
        if (workspaceHealths.data) {
            workspaceHealths.data.forEach((workspace) => {
                cache.cache.set(workspace.id, workspace.state || 'unmonitored');
            });

            setCache(cache);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceHealths.data]);
}

function useSyncDashboardHealths() {
    const cache = useHealthCache();
    const setCache = useSetHealthCache();
    const graph = useGraph();

    const dashIds = graph
        .filterNodes((_, { type }) => type === 'dashboardNode')
        .map((nodeId) => graph.getNodeAttribute(nodeId, 'data')?.sourceId?.[0])
        .filter(isDefined)
        .sort();

    useEffect(() => {
        const sync = async () => {
            const healths = await ListDashboardHealthByIds(dashIds);

            healths.dashboardStates.forEach((state) => {
                cache.cache.set(state.dashboardId, state.state);
            });

            setCache(cache);
        };

        sync();

        const interval = setInterval(sync, STALE_TIME);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(dashIds)]);
}

async function getMonitorHealth(cache: HealthCache, monitorIds: string[]) {
    const splitMonitorIds = monitorIds.map((id) => id.split('/'));

    const dashboards = await getDashboardHealths(
        cache,
        splitMonitorIds.map(([dashId]) => dashId)
    );

    return splitMonitorIds.map(([dashId, tileId]) => {
        const dashboard = dashboards.find((dash) => dash.dashboardId === dashId);
        if (!dashboard) {
            return 'unmonitored';
        }

        const tileStates = dashboard.unhealthyTileStates || [];
        const tileState = tileStates.find((ts) => ts.tileId === tileId);
        return tileState ? tileState.state : 'unmonitored';
    });
}

function useSyncMonitorHealths() {
    const cache = useHealthCache();
    const graph = useGraph();
    const setCache = useSetHealthCache();

    const monitorIds = graph
        .filterNodes((_, { type }) => type === 'monitorNode' || type === 'groupNode')
        .flatMap((nodeId) => {
            const node = graph.getNodeAttributes(nodeId);

            if (node.type === 'groupNode') {
                return node.groupedData?.nodes
                    .map((n) => (n.sourceType?.[0] === 'squaredup/monitor' ? n.sourceId?.[0] : undefined))
                    .filter(isDefined);
            }

            return node.data?.sourceId;
        })
        .sort()
        .filter(isDefined);

    useEffect(() => {
        const sync = async () => {
            await Promise.all(
                monitorIds.map(async (monitorId) => {
                    const state = await getMonitorHealth(cache, [monitorId]);

                    cache.cache.set(monitorId, state[0]);
                })
            );

            setCache(cache);
        };

        sync();

        const interval = setInterval(sync, STALE_TIME);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(monitorIds)]);
}

// Top level hook to sync all node healths
// This will run a function every 5 mins to sync all node healths
// this is preferred over react-query since we need to easily access
// the health state of nodes outside of a 'hook' context, e.g. when selectivly expanding
// the downstream unhealthy nodes.
// This is sync the health for both visible and hidden nodes.
export function useSyncNodeHealths() {
    useSyncWorkspaceHealths();
    useSyncDashboardHealths();
    useSyncMonitorHealths();
}

/**
 * Hook to get the health state of a node based on its source ID.
 *
 * We use a simple caching mechanism under the hood.
 *
 * @param {string | string[]} [sourceId] - The source ID(s) of the node(s) to get the health state for.
 * @returns {HealthState} - The health state of the node(s).
 * Returns 'unmonitored' if no source ID is provided or if the node is not found in the cache.
 */
export function useNodeHealth(sourceId?: string | string[]): HealthState {
    const cache = useHealthCache();

    if (!sourceId) {
        return 'unmonitored';
    }

    if (Array.isArray(sourceId)) {
        return sourceId
            .map((id) => cache.cache.get(id) || 'unmonitored')
            .sort((a: HealthState, b: HealthState) => stateValues[b] - stateValues[a])[0];
    }

    return cache.cache.get(sourceId) || 'unmonitored';
}

// Gets the health state of a node based on its source ID.
// If you're calling from a component context, use the useNodeHealth hook instead.
export async function getNodeHealths(cache: HealthCache, nodes: Node[]) {
    const groups = nodes.reduce(
        (acc, node) => {
            if (node.sourceType?.[0] === 'squaredup/monitor') {
                acc.monitor.push(node);
            } else if (node.sourceType?.[0] === 'squaredup/dash') {
                acc.dashboard.push(node);
            } else {
                acc.unknown.push(node);
            }
            return acc;
        },
        {
            unknown: [],
            monitor: [],
            dashboard: []
        } as Record<'unknown' | 'monitor' | 'dashboard', Node[]>
    );

    const data = await Promise.all([
        ...groups.unknown.map((node) =>
            Promise.resolve({ id: node.id, state: cache.cache.get(node.sourceId?.[0]!) || 'unmonitored' })
        ),
        getMonitorHealth(
            cache,
            groups.monitor.map((node) => node.sourceId?.[0]!)
        ).then((states) => states.map((state, i) => ({ id: groups.monitor[i].id, state: state }))),
        getDashboardHealths(
            cache,
            groups.dashboard.map((node) => node.sourceId?.[0]!)
        ).then((states) => states.map((state, i) => ({ id: groups.dashboard[i].id, state: state.state })))
    ]);

    return data.flat().reduce((acc, item) => ({ ...acc, [item.id]: item.state }), {} as Record<string, HealthState>);
}
