import Text from '@/components/Text';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardVariable } from '@squaredup/dashboards';
import { TruncatedText } from 'components/TruncatedText';
import { EditorSteps } from '../constants';
import { TileEditorAction } from '../state/TileEditorActions';
import Tooltip from 'components/tooltip/Tooltip';

interface SplashScreenScopeOptionProps {
    id: string;
    name: string;
    workspace: string;
    isVariable?: boolean;
    variables: DashboardVariable[];
    dispatch: (action: TileEditorAction) => void;
    setCurrentEditorStep: (step: EditorSteps | undefined, force?: boolean) => void;
};

export const SplashScreenScopeOption: React.FC<SplashScreenScopeOptionProps> = ({ 
    id,
    name,
    isVariable,
    variables = [],
    workspace,
    dispatch,
    setCurrentEditorStep
}) => {
    return (
        <button
            key={id}
            className='flex items-center justify-between h-[35px] min-w-0 px-4 py-2 text-textSecondary bg-tileEditorDataset hover:bg-tileEditorDatasetActive focus:bg-tileEditorDatasetActive hover:text-textPrimary focus:text-textPrimary'
            onClick={() => {
                dispatch({
                    type: 'dataStream.updateFilters',
                    filters: {
                        scopeId: id
                    }
                });

                dispatch({
                    type: 'setPredefinedScope',
                    scopeId: id,
                    workspaceId: workspace,
                    variables
                });

                return setCurrentEditorStep(EditorSteps.dataStream);
            }}
        >
            <Text.H5 className='min-w-0 mr-x'>
                <TruncatedText 
                    title={name} 
                    element='span' 
                />
            </Text.H5>

            {isVariable && (
                <Tooltip title='This collection is being used as a dashboard variable'>
                    <FontAwesomeIcon
                        icon={faBarsFilter}
                        fixedWidth={true}
                        className='w-4 h-4 text-textPrimary'
                    />
                </Tooltip>
            )}
        </button>
    );
};
