import { TooltipButton } from '@/components/Button';
import { faCodeBranch, faCodeCompare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGraph } from 'components/map/context/NetworkMapStoreContext';
import { GraphologyNodeAttributes } from 'components/map/data/types';
import { useExpandNode } from 'components/map/hooks/useExpandNode';

interface ExpandButtonProps {
    node: GraphologyNodeAttributes;
    className?: string;
    onClick?: () => void;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({ className, node, onClick }) => {
    const expandNode = useExpandNode();
    const graph = useGraph();

    const hiddenConnections = graph.filterNeighbors(
        node.id,
        (_, neighbor) => neighbor.hidden && Boolean(neighbor.data || neighbor.groupedData)
    ).length;

    const visibleCollapsibleConnections = graph.filterNeighbors(
        node.id,
        (_, neighbor) => !neighbor.hidden && !neighbor.pinned && Boolean(neighbor.data || neighbor.groupedData)
    ).length;

    const disabled = hiddenConnections === 0 && visibleCollapsibleConnections === 0;

    return (
        <span
            onClick={(e) => {
                if (disabled) {
                    e.stopPropagation();
                }
            }}
            className={className}
        >
            <TooltipButton
                variant='tertiary'
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    expandNode(node, !node.expanded);
                    onClick?.();
                }}
                title={node.expanded ? 'Collapse' : 'Expand'}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={node.expanded ? faCodeCompare : faCodeBranch} fixedWidth />
                {node.expanded && Boolean(hiddenConnections) && <span className='ml-1'>{hiddenConnections}</span>}
            </TooltipButton>
        </span>
    );
};
