import { isDefined } from '@squaredup/utilities';
import { DirectedGraph } from 'graphology';
import { createContext, useContext, useEffect, useRef } from 'react';
import { useStore } from 'zustand';
import {
    createNetworkMapStore,
    NetworkMapStoreInstance,
    NetworkMapStoreProps,
    NetworkMapStoreState
} from '../NetworkMapStore';
import { GraphologyEdgeAttributes, GraphologyNodeAttributes } from '../data/types';
import { getNodeHealths } from '../hooks/useNodeHealth';

type StoreProviderProps = React.PropsWithChildren<NetworkMapStoreProps>;

const NetworkMapStoreContext = createContext<NetworkMapStoreInstance | null>(null);

export function NetworkMapStoreProvider({ children, ...props }: StoreProviderProps) {
    const storeRef = useRef<NetworkMapStoreInstance>();

    if (!storeRef.current) {
        storeRef.current = createNetworkMapStore(props);
    }

    useEffect(() => {
        const graph = storeRef.current!.getState().graph as DirectedGraph<
            GraphologyNodeAttributes,
            GraphologyEdgeAttributes
        >;
        // Shows nodes based on the health of our inital nodes
        if (props.filterInitalBasedOnHealth && props.initalExpandedNodeIds?.length) {
            const healthCache = storeRef.current!.getState().healthCache;

            props.initalExpandedNodeIds.forEach((rootId) => {
                const rootNode = graph.getNodeAttributes(rootId);

                getNodeHealths(healthCache, [rootNode.data!]).then(async (rootHealth) => {
                    if (rootHealth[rootNode.id] === 'unmonitored') {
                        // root node has no health so we just show everything
                        graph.updateNodeAttributes(rootNode.id, (attr) => ({
                            ...attr,
                            expanded: true,
                            pinned: true,
                            hidden: false
                        }));
                        graph.forEachNeighbor(rootNode.id, (neighborId) => {
                            graph.setNodeAttribute(neighborId, 'hidden', false);
                        });
                    } else {
                        const neighbors = graph.mapNeighbors(rootNode.id, (_, n) => n);
                        const healthMap = await getNodeHealths(
                            healthCache,
                            neighbors.map((n) => n.data).filter(isDefined)
                        );

                        neighbors.forEach((neighbor) => {
                            const neighborHealth = healthMap[neighbor.id];

                            if (
                                ['error', 'warning'].includes(neighborHealth) ||
                                (['unknown', 'success'].includes(rootHealth[rootNode.id]) &&
                                    ['unknown', 'success'].includes(neighborHealth))
                            ) {
                                graph.setNodeAttribute(neighbor.id, 'hidden', false);
                            }
                        });

                        graph.updateNodeAttributes(rootNode.id, (attr) => ({
                            ...attr,
                            pinned: true,
                            hidden: false
                        }));
                    }
                });
            });
        } else {
            props.initalExpandedNodeIds?.forEach((nodeID) => {
                graph.updateNodeAttributes(nodeID, (attr) => ({
                    ...attr,
                    expanded: true,
                    pinned: true,
                    hidden: false
                }));

                graph.neighbors(nodeID).forEach((neighborID) => {
                    graph.updateNodeAttributes(neighborID, (attr) => ({
                        ...attr,
                        hidden: false
                    }));
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <NetworkMapStoreContext.Provider value={storeRef.current}>{children}</NetworkMapStoreContext.Provider>;
}

export function useNetworkMapStoreContext<T>(selector: (state: NetworkMapStoreState) => T) {
    const store = useContext(NetworkMapStoreContext);

    if (!store) {
        throw new Error('Missing NetworkMapStoreContext.Provider in the tree');
    }

    return useStore(store, selector);
}

const graphSelector = (state: NetworkMapStoreState) => state.graph;
const mapIdSelector = (state: NetworkMapStoreState) => state.mapId;
const backgroundSelector = (state: NetworkMapStoreState) => state.background;
const layoutTypeSelector = (state: NetworkMapStoreState) => state.layoutType;
const getlayoutTypeSelector = (state: NetworkMapStoreState) => state.getLayoutType;
const setlayoutTypeSelector = (state: NetworkMapStoreState) => state.setLayoutType;
const setNodeHealthSelector = (state: NetworkMapStoreState) => state.setNodeHealth;
const getNodeHealthMap = (state: NetworkMapStoreState) => state.nodeHealthMap;
const healthCacheSelector = (state: NetworkMapStoreState) => state.healthCache;
const setHealthCacheSelector = (state: NetworkMapStoreState) => state.setHealthCache;

export const useGraph = (): DirectedGraph<GraphologyNodeAttributes, GraphologyEdgeAttributes> =>
    useNetworkMapStoreContext(graphSelector);
export const useMapId = () => useNetworkMapStoreContext(mapIdSelector);
export const useNetworkBackground = () => useNetworkMapStoreContext(backgroundSelector);
export const useLayoutType = () => useNetworkMapStoreContext(layoutTypeSelector);
export const useGetLayoutType = () => useNetworkMapStoreContext(getlayoutTypeSelector);
export const useSetLayoutType = () => useNetworkMapStoreContext(setlayoutTypeSelector);
export const useSetNodeHealth = () => useNetworkMapStoreContext(setNodeHealthSelector);
export const useNodeHealthMap = () => useNetworkMapStoreContext(getNodeHealthMap);
export const useHealthCache = () => useNetworkMapStoreContext(healthCacheSelector);
export const useSetHealthCache = () => useNetworkMapStoreContext(setHealthCacheSelector);
