import { cn } from '@/lib/cn';
import { forwardRef, type HTMLProps } from 'react';

export type DataStreamListItemProps = HTMLProps<HTMLButtonElement> & {
    isSelected: boolean;
    description?: string;
};

export const DataStreamListItem = forwardRef<HTMLButtonElement, DataStreamListItemProps>(
    ({ className, children, isSelected, ...rest }, ref) => {
        return (
            <button
                ref={ref}
                className={cn(
                    'h-[35px] w-full pl-4 pr-2 select-none cursor-pointer flex items-center',
                    {
                        'bg-tileEditorComponent': !isSelected,
                        'bg-tileEditorComponentActive text-textPrimary': isSelected
                    },
                    className
                )}
                {...rest}
                type='button'
                role='option'
                aria-selected={isSelected}
            >
                {children}
            </button>
        );
    }
);
