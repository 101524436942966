import { CheckoutEventNames, initializePaddle, Paddle, PaddleEventData, PricePreviewParams } from '@paddle/paddle-js';
import { code } from 'currency-codes';
import { isProd } from 'lib/environment';
import { useEffect, useState } from 'react';
import {
    ENTERPRISE_ANNUALLY_PADDLE_ID,
    PRO_ANNUALLY_PADDLE_ID,
    PRO_MONTHLY_PADDLE_ID,
    STARTER_ANNUALLY_PADDLE_ID,
    STARTER_MONTHLY_PADDLE_ID
} from './Plans';

// Typescript trick to allow callers to use strings rather than import the enum
type PaddleEvents = `${CheckoutEventNames}`;
const token = isProd ? 'live_47799fab1cfae3d0e273ce6b654' : 'test_7232a9e2d3607a01af86f4356b9';
const paddleTokenQueryKey = `PADDLE_TOKEN_${token}`;

export const usePaddleCheckout = () => {
    const [paddle, setPaddle] = useState<Paddle>();
    const [handlers] = useState(new Map<PaddleEvents, (data: PaddleEventData) => void>());

    // Will download and initialize Paddle instance from CDN
    // This ensures compliance with any security updates
    useEffect(() => {
        initializePaddle({
            environment: isProd ? 'production' : 'sandbox',
            // these tokens are not sensitive, and are fine to be client facing
            token,
            checkout: {
                settings: {
                    allowLogout: false,
                    displayMode: 'overlay'
                }
            },
            eventCallback: (data) => {
                if (data.name) {
                    handlers.get(data.name)?.(data);
                }
            }
        }).then((instance: Paddle | undefined) => {
            if (instance) {
                setPaddle(instance);
            }
        });
    }, [handlers]);

    return {
        paddle,
        handlers
    };
};

const priceItems = [
    STARTER_MONTHLY_PADDLE_ID,
    STARTER_ANNUALLY_PADDLE_ID,
    PRO_MONTHLY_PADDLE_ID,
    PRO_ANNUALLY_PADDLE_ID,
    ENTERPRISE_ANNUALLY_PADDLE_ID
].map((priceId) => ({ quantity: 10, priceId }));

const priceItemsQueryKey = JSON.stringify(priceItems);
export const paddlePricePreviewKey = 'PADDLE_PRICE_PREVIEW';

export type PricePreview = {
    id: string;
    productId: string;
    price: string;
    currencyCode: string;
    priceRaw: number;
    minUsers: number;
    billingCycle?: string;
};

type CustomerDetails = Pick<PricePreviewParams, 'addressId' | 'customerId' | 'discountId'>;

export type CurrencyCode = 'USD' | 'EUR' | 'GBP';

export type PricePreviewOptions = {
    customerDetails?: CustomerDetails;
    currency?: CurrencyCode;
};

export const getPaddlePricePreviewQueryKey = (details: PricePreviewOptions) => [
    paddlePricePreviewKey,
    paddleTokenQueryKey,
    priceItemsQueryKey,
    `${details.currency}-${details.customerDetails?.customerId}-${details.customerDetails?.addressId}-${details.customerDetails?.discountId}`
];

// Extract this into a function that can then be used by a caller _within_ use hook.
export const getPaddlePricePreview = async (paddle: Paddle | undefined, options: PricePreviewOptions) => {
    const ignoreCurrencyCode = Boolean(options.customerDetails?.addressId);

    // Ignore currency code if we have customer details, as that will report the
    // payment currency correctly and also include tax information
    const previewOptions = {
        items: priceItems,
        currencyCode: ignoreCurrencyCode ? undefined : options.currency,
        ...options.customerDetails
    };

    const preview = await paddle?.PricePreview(previewOptions);

    return preview?.data.details.lineItems
        .filter((item) => item.price.status === 'active')
        .reduce<Record<string, PricePreview>>((acc, item) => {
            // if we have customer details, no need to use price overrides as paddle will have done this for us
            // Otherwise, treat default currency (USD) as a price override for consistency
            const overrides = ignoreCurrencyCode
                ? []
                : item.price.unitPriceOverrides
                      .map((p) => p.unitPrice)
                      .concat({ currencyCode: 'USD', amount: item.price.unitPrice.amount });

            // check if price is overridden for the requested currency
            const priceOverride = overrides.find((p) => p.currencyCode === options.currency)?.amount;

            const formattedPrice =
                priceOverride === undefined
                    ? item.formattedUnitTotals.total
                    : formatPaddleCurrency(priceOverride, preview.data.currencyCode);
            const priceRaw = priceOverride === undefined ? item.unitTotals.total : priceOverride;

            const price = {
                id: item.price.id,
                productId: item.price.productId,
                price: formattedPrice,
                minUsers: item.price.quantity.minimum,
                // unit total is always in lowest currency denomination (cannot be float)
                priceRaw: Number.parseInt(priceRaw, 10),
                currencyCode: preview.data.currencyCode,
                billingCycle: item.price.billingCycle?.interval
            };

            return {
                ...acc,
                [price.id]: price
            };
        }, {});
};

const formatterCache = new Map<string, (amount: string) => string>();
export const formatPaddleCurrency = (amount: string, currencyCode: string) => {
    if (!formatterCache.has(currencyCode)) {
        // Paddle returns amounts in the minimum denomination, so we'll need to reduce
        // it as necessary to get into the main unit (e.g pence to pounds, cents to dollars)
        const divisor = Math.pow(10, code(currencyCode)?.digits ?? 2);
        const { format } = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencyCode,
            currencyDisplay: 'narrowSymbol'
        });
        formatterCache.set(currencyCode, (a) => format(Number.parseInt(a, 10) / divisor));
    }

    return formatterCache.get(currencyCode)?.(amount) ?? amount;
};
