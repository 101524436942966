import { Button } from '@/components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { SearchIcon } from 'components/SearchIcon';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import Fuse from 'fuse.js';
import trackEvent from 'lib/analytics';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import { useMemo, useState, type FC } from 'react';
import { useQueryClient } from 'react-query';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { DataStreamFilters } from '../dataStream/DataStreamFilters';
import { DataStreamVirtualList } from '../dataStream/DataStreamVirtualList';
import { useDataStreamFilters, type DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { useTileEditorScopes } from '../hooks/useScopes';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { useTileEditorDataStreams } from '../state/useTileEditorDataStreams';
import { AddDataSourceModal } from './DataSourceModal/AddDataSourceModal';
import { DataStreamTagsFilter } from './DataStreamTagsFilter';
import { StepTitleAndControls } from './StepTitleAndControls';
import { sortBy, uniq } from 'lodash';
import { StepProgressButton } from './StepProgressButton';

export const DataStreamStep: FC<{
    dataStreamFilters: ReturnType<typeof useDataStreamFilters>;
}> = ({ dataStreamFilters }) => {
    const [showAddDataSourceModal, setShowAddDataSourceModal] = useState(false);
    const [scopeModalOpen, setScopeModalOpen] = useState(false);
    const [editingScope, setEditingScope] = useState();
    
    
    const queryClient = useQueryClient();
    const { workspace } = useDataStreamWorkspaceContext();
    const { canMoveToNextStep } = useTileEditorStepsContext();
    const { isLoading: isLoadingScopes, data: scopes } = useTileEditorScopes();

    const {
        dataStreamFilterOptions,
        pluginFilterOptions,
        objectScopeFilterOptions,
        dataStreamsToShowForTags,
        isLoading,
        isLoadingScopeDataStreamMatches
    } = dataStreamFilters;

    const { selectedDataStreamId, filters, dispatch } = useTileEditorStore((s) => ({
        selectedDataStreamId: s.dataStream.selectedDataStream?.id,
        filters: s.dataStream.filters,
        dispatch: s.dispatch
    }));

    const { data } = useTileEditorDataStreams();

    const fuse = useMemo(() => {
        if (isLoading || isLoadingScopes) {
            return undefined;
        }

        return new Fuse(dataStreamFilterOptions!, {
            keys: [
                { name: 'displayNameFull', weight: 10 },
                { name: 'description', weight: 2 },
                { name: 'pluginConfigName', weight: 1 },
                { name: 'definition.matchesTypes', weight: 2 },
                { name: 'definition.tags', weight: 2 },
                { name: 'definition.matches.sourceType.value', weight: 2 }
            ],
            threshold: 0.4
        });
    }, [dataStreamFilterOptions, isLoading, isLoadingScopes]);

    const tagsFuse = useMemo(() => {
        if (isLoading || isLoadingScopes) {
            return undefined;
        }

        return new Fuse(dataStreamsToShowForTags!, {
            keys: [
                { name: 'displayNameFull', weight: 10 },
                { name: 'description', weight: 2 },
                { name: 'pluginConfigName', weight: 1 },
                { name: 'definition.matchesTypes', weight: 2 },
                { name: 'definition.tags', weight: 2 },
                { name: 'definition.matches.sourceType.value', weight: 2 }
            ],
            threshold: 0.4,
            findAllMatches: true
        });
    }, [dataStreamsToShowForTags, isLoading, isLoadingScopes]);

    const dataStreamsFilteredBySearch = filters.search
        ? fuse?.search(filters.search).map(({ item }) => item)
        : dataStreamFilterOptions;

    const dataStreamsWithoutTagFilterFilteredBySearch = filters.search
        ? tagsFuse?.search(filters.search).map(({ item }) => item)
        : dataStreamsToShowForTags;

    const tagFilterOptions = sortBy(
        uniq([
            ...(dataStreamsWithoutTagFilterFilteredBySearch ?? []).flatMap((ds) => ds.definition.tags ?? []),
            // Currently selected tags may not be in any of the currently shown data streams,
            // add them in so they are still visible
            ...filters.tag ? [filters.tag] :[]
        ])
    );

    if (isLoading || isLoadingScopes) {
        return (
            <div className='flex items-center justify-center flex-1 w-full h-full'>
                <LoadingSpinner className='m-auto' />
            </div>
        );
    }

    const onSelectDataStream = (dataStream: DataStreamFilterOption) => {
        trackEvent('Data Stream Selected', {
            name: dataStream.displayName,
            plugin: dataStream.pluginName,
            configurable: Boolean(dataStream.template),
            configured: Boolean(dataStream.sourceTemplate)
        });

        if (dataStream.id !== selectedDataStreamId) {
            return dispatch({ 
                type: 'dataStream.selectDataStream', 
                dataStream: data!.dataStreamsById.get(dataStream.id)!
            });
        }

        dispatch({ type: 'dataStream.clearSelectedDataStream' });
    };

    const isFiltered = Boolean(
        filters.objectType || 
        filters.scopeId || 
        filters.tag || 
        filters.search || 
        filters.dataSourceId
    );

    return (
        <>
            <div className='flex flex-col flex-1 h-full min-w-0 min-h-0 pt-4'>
                <div className='pb-4 pl-6 pr-5 border-b border-dividerTertiary'>
                    <StepTitleAndControls title='Select a data stream' />
                </div>

                <DataStreamFilters 
                    pluginFilterOptions={pluginFilterOptions}
                    objectScopeFilterOptions={objectScopeFilterOptions}
                    scopes={scopes ?? []}
                    setShowAddDataSourceModal={setShowAddDataSourceModal}
                    setScopeModalOpen={setScopeModalOpen}
                    setEditingScope={setEditingScope}
                />
                
                <div className='flex flex-1 min-h-0'>
                    <DataStreamTagsFilter tags={tagFilterOptions} />
                    
                    <div className='flex flex-col flex-1 min-w-0 min-h-0'>
                        {isLoadingScopeDataStreamMatches ? (
                            <div className='w-full h-full pl-6 pr-5 mt-6'>
                                <LoadingSpinner />
                            </div>
                        ) : dataStreamsFilteredBySearch?.length === 0 ? (
                            <div className='flex h-full mt-8 place-content-center text-textPrimary'>
                                <div className='m-auto text-center h-fit w-fit'>
                                    <SearchIcon className='m-auto w-fit h-fit' />

                                    <div className='-mt-8'>
                                        <p className='mb-2'>Couldn't find what you are looking for?</p>
                                        <Button variant={'link'} onClick={() => setShowAddDataSourceModal(true)}>
                                            Add a data source
                                        </Button>{' or '} 
                                        {isFiltered &&
                                            <Button variant={'link'} onClick={() => dispatch({ type: 'dataStream.resetFilters' })}>
                                                clear filters
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // Disable the sort to avoid losing the search order if a search term is present
                            <DataStreamVirtualList
                                dataStreams={dataStreamsFilteredBySearch ?? []}
                                selectedDataStreamId={selectedDataStreamId}
                                allowSort={!filters.search}
                                onItemClick={onSelectDataStream}
                            />
                        )}

                        <div className='flex self-end mt-5 mb-4 mr-5 space-x-4'>
                            <StepProgressButton 
                                disabled={!selectedDataStreamId}
                                isLoading={Boolean(!canMoveToNextStep && selectedDataStreamId)}
                            />
                        </div>

                        {showAddDataSourceModal && (
                            <AddDataSourceModal
                                workspaceId={workspace}
                                onSelectedDataSource={(dataSourceId?: string) => {
                                    setShowAddDataSourceModal(false);
                                    // Remove the search to avoid getting stuck on the empty page
                                    dispatch({
                                        type: 'dataStream.updateFilters',
                                        filters: { search: '', ...(dataSourceId ? { dataSourceId } : {}) }
                                    });
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>

            {scopeModalOpen && (
                <CreateEditScope
                    scope={editingScope}
                    onClose={(modifiedScope) => {
                        setScopeModalOpen(false);
                        setEditingScope(undefined);
                        if (modifiedScope && workspace) {
                            queryClient.invalidateQueries(scopeQueryKeys.workspace(workspace));
                            queryClient.removeQueries(variableObjectQueryKeys.all);
                            dispatch({ type: 'dataStream.updateFilters', filters: { scopeId: modifiedScope.id } });
                        }
                    }}
                />
            )}
        </>
    );
};
