import Text from '@/components/Text';
import { FeatureKey, FeatureLimit, getFeatureDisplay } from '@squaredup/tenants';
import { LimitPriorityDisplay } from './LimitPriorityDisplay';
import { cn } from '@/lib/cn';
import Tooltip from 'components/tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';

type UsageFeatureKey = FeatureKey | 'dashboards' | 'workspaces';

export interface LimitIfPresent {
    value: number;
    upgradeThreshold?: number;
}

export type UsageMetric =
    | {
          name: UsageFeatureKey;
          count: number;
          limit?: FeatureLimit | undefined;
          loading: false;
      }
    | {
          name: UsageFeatureKey;
          count?: number;
          limit?: FeatureLimit;
          loading: true;
      };

type LimitedMetric = {
    name: UsageFeatureKey;
    count: number;
    limit: LimitIfPresent;
};

const featurePriorityList: UsageFeatureKey[] = [
    'users',
    'dataSources',
    'monitors',
    'openAccess',
    'indexedObjects',
    'dataQueries',
    'dashboards',
    'workspaces'
] as const;

const prioritiseMetrics = (metrics: UsageMetric[]) => {
    const priorityMetrics: LimitedMetric[] = [];
    const remainingMetrics: UsageMetric[] = [];

    featurePriorityList.forEach((feature) => {
        const metric = metrics.find((m) => m.name === feature);
        if (!metric) {
            return;
        }

        if (metric.limit && 'value' in metric.limit && priorityMetrics.length < 3) {
            priorityMetrics.push(metric as LimitedMetric);
            return;
        }

        remainingMetrics.push(metric);
    });
    return {
        priorityMetrics,
        remainingMetrics
    };
};

type MetricArrayProp = {
    metrics: UsageMetric[];
};

type KnownMetricProp = {
    metrics: LimitedMetric[];
};

const customKeyDisplayNames = {
    dashboards: 'Dashboards',
    workspaces: 'Workspaces',
    indexedObjects: 'Objects',
    openAccess: 'Shared dashboards'
} as const;

const getDisplay = (key: UsageFeatureKey) => {
    if (key === 'dashboards' || key === 'workspaces' || key === 'indexedObjects' || key === 'openAccess') {
        return customKeyDisplayNames[key];
    }

    return getFeatureDisplay(key)?.displayName;
};

const getLimitDescription = (key: UsageFeatureKey) => {
    if (key === 'dataQueries') {
        return 'per month';
    }
    return undefined;
};

const getSimpleDescription = (key: UsageFeatureKey) => {
    if (key === 'dataQueries') {
        return 'this month';
    }
    return undefined;
};

const getOverLimitTooltipText = (metric: UsageMetric) => {
    if (metric.name === 'indexedObjects') {
        return 'Object limit reached. Please contact our support team.';
    }

    // Still loading, and to rule out unlimited metrics for Typescript
    if (metric.loading || metric.limit === undefined || !('value' in metric.limit)) {
        return undefined;
    }

    if (metric.name === 'dashboards' || metric.name === 'workspaces' ) {
        return `${getDisplay(metric.name)} limit reached (${metric.limit.value})`;
    }

    // Fallback in case of no specific limit text
    const featureDisplay = getFeatureDisplay(metric.name);
    if (featureDisplay) {
        return `${featureDisplay.singular ?? featureDisplay.displayName} limit reached (${metric.limit.value})`;
    }
};

const PriorityDisplay = ({ metrics }: KnownMetricProp) => (
    <>
        {metrics.map((metric) => {
            return (
                <LimitPriorityDisplay
                    key={`priority-${metric.name}`}
                    title={getDisplay(metric.name) ?? ''}
                    name={metric.name}
                    value={metric.count}
                    limit={metric.limit.value}
                    description={getLimitDescription(metric.name)}
                    warningRange={metric.limit.upgradeThreshold}
                />
            );
        })}
    </>
);

const NUMBER_FORMATTER = new Intl.NumberFormat('en-us');
const SimpleMetricDisplay = ({ metric }: { metric: UsageMetric }) => {
    const numberDisplay = !metric.loading ? NUMBER_FORMATTER.format(metric.count) : '-';
    const isOverLimit = !metric.loading && metric.limit !== undefined && 'value' in metric.limit && metric.count >= metric.limit.value;
    return (
        <div data-testid={`simple-${metric.name}`}>
            <div className='flex items-center gap-2'>
                <Text.Body>{getDisplay(metric.name)}</Text.Body>
                {isOverLimit && (<ErrorIcon title={getOverLimitTooltipText(metric)}/>)}
            </div>
            <div className='flex'>
                <Text.H2 className={cn(isOverLimit && 'text-statusErrorPrimary')}>{numberDisplay}</Text.H2>
                <Text.SmallBody className='content-end pb-1.5 ml-2'>{getSimpleDescription(metric.name)}</Text.SmallBody>
            </div>
        </div>
    );
};

const ErrorIcon: FC<{title: string | React.ReactNode}> = ({ title }) => {
    return (
        <Tooltip title={title}>
            <FontAwesomeIcon
                className='text-statusErrorPrimary'
                icon={faCircleXmark}
            />
        </Tooltip>
    );
};

const RemainderDisplay = ({ metrics }: MetricArrayProp) => (
    <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-y-4'>
        {metrics.map((metric) => (
            <SimpleMetricDisplay metric={metric} key={metric.name} />
        ))}
    </div>
);

export const MetricPanel = ({ metrics }: MetricArrayProp) => {
    const { priorityMetrics, remainingMetrics } = prioritiseMetrics(metrics);
    return (
        <>
            <PriorityDisplay metrics={priorityMetrics} />
            <hr className='mt-4 mb-9 border-dividerPrimary' />
            <RemainderDisplay metrics={remainingMetrics} />
        </>
    );
};
