import { cn } from '@/lib/cn';
import { DataStreamBaseTileConfig, VisualisationHint } from '@squaredup/data-streams';
import TileContext from 'contexts/TileContext';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

interface DataTableProps {
    config?: DataStreamBaseTileConfig;
    rawHeaderLabels?: boolean;
    className?: string;
    notConfiguredError?: JSX.Element;
}

export const DataTable: React.FC<DataTableProps> = ({ config, className, rawHeaderLabels, notConfiguredError }) => {
    const { tileConfig } = useTileEditorContext();
    const tableConfig = config ?? tileConfig;

    const dataPreviewConfig = {
        ...tableConfig,
        visualisation: {
            type: 'data-stream-table' as VisualisationHint,
            config: {
                'data-stream-table': {
                    showShapeInTooltip: true,
                    useAccessorHeaderLabels: rawHeaderLabels
                }
            }
        }
    };
    
    return (
        <div 
            className={cn('h-full', className)}
            data-testid='dataPreview'
        >
            <TileContext.Provider value={{ 
                config: dataPreviewConfig,
                preview: false,
                onEdit: () => undefined,
                onChange: () => undefined
            }}>
                <DataStreamBaseTile 
                    config={dataPreviewConfig} 
                    notConfiguredError={notConfiguredError}
                />
            </TileContext.Provider>
        </div>
    );
};
