import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from '@squaredup/graph';
import LoadingSpinner from 'components/LoadingSpinner';
import { Button } from 'components/button';
import { motion } from 'framer-motion';
import pluralize from 'pluralize';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { useSelectedObjects } from '../hooks/useSelectedObjects';
import { SelectedObjectsOption } from '../objects/SelectedObjectsOption';
import { getNewFilterValue } from '../utilities/getNewFilterValue';

interface SelectedObjectsPanelProps {
    handleObjectsPanelToggle: () => void;
}

export const SelectedObjectsPanel = ({ handleObjectsPanelToggle }: SelectedObjectsPanelProps) => {
    const { isDynamic, selectedObjectIds, selectedObjectsCount, interactedObjects, objectLimit, updateScope } =
        useTileEditorObjectsFilterContext();

    const { objects, isLoading } = useSelectedObjects(interactedObjects);

    const atLimit = Boolean(objectLimit) && selectedObjectIds.length >= (objectLimit ?? 0);

    return (
        <motion.div
            className='flex h-full overflow-hidden'
            initial={{ width: 0 }}
            animate={{ width: 'auto' }}
            exit={{ width: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className='flex flex-col flex-shrink-0 h-full border-l w-72 border-dividerPrimary'>
                <div className='flex'>
                    <div className='flex px-[21px] pt-[34px] pt- pb-6 justify-between w-full h-max items-center'>
                        <p className='text-base font-semibold' aria-label='Selected Objects Count'>
                            {selectedObjectsCount} {pluralize('object', selectedObjectsCount)} selected
                        </p>
                        <Button
                            variant='tertiary'
                            aria-label='Exit object selection panel'
                            onClick={() => {
                                handleObjectsPanelToggle();
                            }}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </Button>
                    </div>
                </div>
                {isLoading && (
                    <div className='flex items-center justify-center'>
                        <LoadingSpinner />
                    </div>
                )}
                <div className='flex-1 px-[21px] text-sm overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {objects?.map((object: Node, index: number) => (
                        <SelectedObjectsOption
                            key={`${object.id}${object.__configId}${index}`}
                            object={object}
                            isActive={selectedObjectIds.includes(object.id)}
                            disabled={atLimit ? !selectedObjectIds.includes(object.id) : false}
                            {...(!isDynamic && {
                                onClick: () => {
                                    updateScope({ selectedNodeIds: getNewFilterValue(selectedObjectIds, object.id) });
                                }
                            })}
                        />
                    ))}
                </div>
                <div>
                    <button
                        aria-label='Clear object selection panel'
                        onClick={() => {
                            updateScope({ selectedNodeIds: [] });
                        }}
                        className='px-[21px] text-textSecondary font-semibold py-6 text-sm hover:text-textPrimary transition ease-in-out duration-300'
                    >
                        Clear
                    </button>
                </div>
            </div>
        </motion.div>
    );
};
