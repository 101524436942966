import { isDataSourceScopedDataStream } from '@squaredup/data-streams';
import { Serialised, SqUpPluginId } from '@squaredup/ids';
import type { DataStreamDefinitionEntity } from 'dynamo-wrapper';

export interface DataStreamType {
    /**
     * True if this data stream is configurable, or is a
     * preset configuration of a configurable data stream.
     */
    isConfigurable: boolean;
    supportsDatasets: boolean;
    /**
     * True if the data stream needs a scope to run.
     * False if the data stream can run with only a plugin config Id
     * from the `dataStream` property of the tile config.
     */
    requiresScope: boolean;
    /**
     * True if this stream can accept a scope or plugin config Id.
     * False if this stream doesn't need a scope or plugin config Id to run.
     */
    supportsScope: boolean;
}

/**
 * This function works out what the data stream type is based on the match criteria and template. Because this
 * function is called when we are fetching data stream data as well as when working out the steps to show in the tile
 * editor, we have an `isEditing` optional param - isEditing is a temporary workaround to handle legacy configured tiles
 * in the new editor. If `isEditing` is `false` we ignore the presense of `sourceTemplate`. `sourceTemplate` is not
 * relevant when fetching data as the configuration is held within the data stream definition.
 * @param {object} dataStream The data stream definition
 * @param {boolean} isEditing Whether we are calling this function to check the data stream type while editing
 * @returns the Data Stream type. e.g. 'scopedConfigurable'.
 */
export const getDataStreamType = (
    dataStream: Serialised<DataStreamDefinitionEntity> | undefined,
    isEditing = true
): DataStreamType => {
    if (dataStream == null) {
        return { supportsDatasets: false, isConfigurable: false, requiresScope: false, supportsScope: false };
    }

    const supportsDatasets = dataStream.id === 'datastream-sql';
    const isConfigurable =
        dataStream.template != null ||
        (isEditing && Boolean(dataStream.sourceTemplate)) ||
        dataStream.definition.presetOf != null;
    const supportsScope = dataStream.definition.matches !== 'none';

    // We require a scope if:
    // - datastream needs a scope
    // - it's not a global stream (scoped to data source) - these can operate with just a pluginConfigId
    // - it's not the squaredup plugin - streams in this plugin can be truly global
    const requiresScope =
        supportsScope &&
        !(
            isDataSourceScopedDataStream(dataStream.pluginId, dataStream.definition.matches ?? {}) &&
            dataStream.pluginId !== SqUpPluginId.value
        );

    return { supportsDatasets, isConfigurable, requiresScope, supportsScope };
};
