import { Button } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faArrowDownWideShort, faArrowUpWideShort, faSliders, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scopeLimitMaximum } from '@squaredup/constants';
import { Node } from '@squaredup/graph';
import LoadingSpinner from 'components/LoadingSpinner';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';
import stringify from 'fast-json-stable-stringify';
import { uniq } from 'lodash';
import { MouseEvent, useRef } from 'react';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { DEFAULT_OBJECTS_SORT_ORDER_PROPERTY } from '../hooks/objectFilters/useObjectFilterObjects';
import { ObjectList } from './ObjectList';
import { ObjectOption } from './ObjectOption';
import { AddPropertyFilter } from './filter/AddPropertyFilter';

interface ObjectTableProps {
    onSelectedObjectsChange?: (selectedNodeIds: string[]) => void;
}

const tableHeaders = [
    { label: 'Name', id: '__name', sortable: true },
    // can't sort as the value shown is different on client vs server, i.e. aws (server) -> AWS - PROD (client)
    { label: 'Data Source', id: '__configId' },
    // can't sort as the value shown is different on client vs server, i.e. scope (server) -> Object collection (client)
    { label: 'Type', id: 'type' }
];

export const ObjectTable: React.FC<ObjectTableProps> = ({ onSelectedObjectsChange }) => {
    const {
        scope,
        filteredObjects: {
            objects,
            isFetchingObjects,
            isFetchingNextObjectsPage,
            isLoadingObjects,
            hasNextObjectsPage,
            count,
            fetchNextObjectsPage
        },
        filters,
        objectLimit,
        pluginLookup,
        selectedObjectIds,
        selectedObjectsCount,
        isFiltered,
        updateScope,
        resetFilters,
        sortOrder,
        setSortOrder
    } = useTileEditorObjectsFilterContext();

    const canSelectObject = !scope.isDynamic;

    const lastChecked = useRef<number | null>(null);

    const resetScrollKey = stringify({
        filterQuery: filters.searchString,
        filterSources: filters.plugins,
        filterTypes: filters.types,
        filterProperties: filters.properties
    });

    if ((!objects || objects?.length === 0) && isLoadingObjects) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 w-full min-h-0'>
                <LoadingSpinner size={20} />
            </div>
        );
    }

    if (!objects || !count || objects?.length === 0) {
        return (
            <p className='flex-1 text-sm'>
                No objects available.{' '}
                {isFiltered && (
                    <Button variant='tertiary' onClick={resetFilters} className='text-textLink'>
                        Reset filters
                    </Button>
                )}
            </p>
        );
    }

    const filterPropertiesColumns = Object.keys(filters.properties).map((i) => ({
        label: i,
        id: i,
        sortable: true
    }));

    const headers = [...tableHeaders, ...filterPropertiesColumns];

    const handleObjectClick = (e: MouseEvent<HTMLButtonElement>, index: number) => {
        const object = objects[index];

        // Shift click allows selection of multiple objects at once
        if (lastChecked.current !== null && e.nativeEvent.shiftKey) {
            const start = Math.min(lastChecked.current, index);
            const end = Math.max(lastChecked.current, index);

            const shiftSelectedObjects = objects.slice(start, end + 1).map(({ id }) => id);

            // The object the user is clicking is already selected, deselect it and all objects in between
            if (selectedObjectIds.includes(object.id)) {
                onSelectedObjectsChange?.(
                    uniq([...selectedObjectIds.filter((id) => !shiftSelectedObjects.includes(id))])
                );
            } else {
                // Add all the objects between the last check object and this one
                onSelectedObjectsChange?.(uniq([...selectedObjectIds, ...shiftSelectedObjects]).slice(0, objectLimit));
            }
            return;
        }

        lastChecked.current = index;
        if (!selectedObjectIds.includes(object.id)) {
            onSelectedObjectsChange?.([...selectedObjectIds, object.id]);
        } else {
            onSelectedObjectsChange?.(selectedObjectIds.filter((id) => id !== object.id));
        }
    };

    return (
        <div className='flex flex-col h-full min-h-0 text-sm'>
            {objects.length > 0 && (
                <>
                    <div
                        className='grid self-stretch gap-6 p-1 px-2 mr-4 font-semibold text-left select-none [scrollbar-gutter:stable]'
                        style={{
                            gridTemplateColumns: `minmax(10rem, 1fr) repeat(${
                                headers.length - 1
                            }, minmax(0, 1fr)) 0.875rem`
                        }}
                    >
                        {headers.map((header) => {
                            const isPropertyHeader = filterPropertiesColumns.find((col) => col.id === header.id);
                            const isDefaultSortProperty = header.id === DEFAULT_OBJECTS_SORT_ORDER_PROPERTY;
                            const isSorted = sortOrder?.property === header.id || (!sortOrder && isDefaultSortProperty);
                            const isDescending = sortOrder?.descending;
                            const isSortable = header.sortable;

                            let tooltipTitle = isDescending ? 'Clear sort' : 'Sort descending';

                            if ((isDefaultSortProperty && isDescending) || !isSorted) {
                                tooltipTitle = 'Sort ascending';
                            }

                            return (
                                <div key={header.id} className={cn('flex items-center space-x-2 group/header')}>
                                    {isSortable ? (
                                        <button
                                            className='inline-flex items-center space-x-2'
                                            onClick={() => {
                                                // clear sort if already sorted in descending order
                                                if (isSorted && isDescending) {
                                                    setSortOrder(undefined);
                                                    return;
                                                }

                                                // set sorting to property, and toggle descending if already sorted
                                                setSortOrder({
                                                    property: header.id,
                                                    descending: isSorted
                                                });
                                            }}
                                        >
                                            <TruncatedText
                                                key={header.id}
                                                title={header.label}
                                                className='min-w-0 capitalize align-middle'
                                            />
                                            <Tooltip title={tooltipTitle}>
                                                <FontAwesomeIcon
                                                    icon={!isDescending ? faArrowUpWideShort : faArrowDownWideShort}
                                                    className={cn(
                                                        'align-middle',
                                                        !isSorted && 'hidden group-hover/header:inline-flex'
                                                    )}
                                                />
                                            </Tooltip>
                                        </button>
                                    ) : (
                                        <TruncatedText
                                            key={header.id}
                                            title={header.label}
                                            className='min-w-0 capitalize align-middle'
                                        />
                                    )}
                                    {isPropertyHeader && (
                                        <button
                                            className='items-center hidden h-full group-hover/header:inline-flex'
                                            onClick={() => {
                                                // reset sort order if the property is being removed
                                                if (sortOrder?.property === header.id) {
                                                    setSortOrder(undefined);
                                                }

                                                const { [header.label]: _, ...newFilterProperties } =
                                                    filters.properties;
                                                updateScope({ filters: { properties: newFilterProperties } });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} className='align-middle' />
                                        </button>
                                    )}
                                </div>
                            );
                        })}

                        <AddPropertyFilter
                            triggerClassName='inline-flex items-center justify-end h-full disabled:bg-secondaryButtonBackgroundDisabled disabled:text-secondaryButtonTextDisabled'
                            triggerTooltip={
                                scope.canFilter
                                    ? 'Add column'
                                    : "Can't filter by property when a collection is selected"
                            }
                            triggerContent={<FontAwesomeIcon icon={faSliders} />}
                            triggerTestid='AddColumnToObjectTable'
                            align='end'
                            forceEnabled={scope.canFilter}
                            key='AddColumnToObjectTable'
                        />
                    </div>

                    <ObjectList
                        objects={objects}
                        resetScrollKey={resetScrollKey}
                        hasNextObjectsPage={hasNextObjectsPage}
                        isFetchingNextObjectsPage={isFetchingNextObjectsPage}
                        isFetchingObjects={isFetchingObjects}
                        fetchNextObjectsPage={fetchNextObjectsPage}
                        renderObjectRow={(object: Node, index: number) => {
                            // selectedObjectIds accounts for dynamic and variable scopes etc.
                            const isSelected = selectedObjectIds.includes(object.id);

                            return (
                                <ObjectOption
                                    object={object}
                                    isActive={isSelected}
                                    className={index % 2 === 0 ? 'bg-tagBackground' : ''}
                                    checkboxDisabled={
                                        (selectedObjectsCount >= scopeLimitMaximum && !isSelected) ||
                                        (Boolean(objectLimit) &&
                                            selectedObjectIds.length >= (objectLimit ?? 0) &&
                                            !isSelected)
                                    }
                                    filterPropertiesColumns={filterPropertiesColumns.map((i) => i.id)}
                                    pluginLookup={pluginLookup}
                                    // We only want to pass onClick if we're selecting a
                                    // fixed scope (!dynamic)
                                    {...(canSelectObject && {
                                        onClick: (e) => handleObjectClick(e, index)
                                    })}
                                />
                            );
                        }}
                    />
                </>
            )}
        </div>
    );
};
