import { sortBy } from 'lodash';
import { useDataStreamDefinitionsForWorkspace } from 'queries/hooks/useDataStreamDefinitions';
import { isMatchable } from 'services/DataStreamDefinitionService';
import { MATCHES_ADVANCED } from 'ui/editor/dataStream/TileEditor/constants';
import { useDataSources } from 'ui/editor/dataStream/TileEditor/hooks/useDataSources';
import { useSourceTypesToTypesMap } from 'ui/editor/dataStream/TileEditor/hooks/useSourceTypesToTypesMap';
import { getMatchTypes } from 'ui/editor/dataStream/utilities/getMatchTypes';

export const useOverviewDataStreams = (pluginId: string) => {
    const { data: sources, isLoading: isLoadingSources } = useDataSources();
    const { data: sourceTypesToTypes, isLoading: isLoadingTypesMap } = useSourceTypesToTypesMap(sources);

    const { data: datastreamData, isLoading: isLoadingData } = useDataStreamDefinitionsForWorkspace({
        workspace: undefined,
        queryOptions: {
            select: (dataStreams) => dataStreams.filter(
                ({ pluginId: dataStreamPluginId }) => dataStreamPluginId === pluginId
            )
        }
    });

    const allStreamsWithMatchCriteria = datastreamData
        ?.filter(
            (stream) => {
                return stream.definition?.provides !== 'templateData' &&
                    isMatchable(stream) && 
                    getMatchTypes(
                        stream.definition?.matches, 
                        sourceTypesToTypes
                    )?.length;
            }
        )
        .map((dataStream) => {
            const matches = dataStream.definition?.matches;
            const matchesTypes = matches ? getMatchTypes(matches, sourceTypesToTypes) : MATCHES_ADVANCED;

            return {
                ...dataStream,
                definition: {
                    ...dataStream.definition,
                    matchesTypes: matchesTypes
                }
            };
        });

    const sortedData = sortBy(allStreamsWithMatchCriteria, (ds) => ds.displayName?.toLowerCase());

    return {
        isLoading: isLoadingData || isLoadingSources || isLoadingTypesMap,
        data: sortedData
    };
};

export type DataStreamDetails = ReturnType<typeof useOverviewDataStreams>['data'][number];
