
import { cn } from '@/lib/cn';
import { useTileEditorStepsContext } from './contexts/TileEditorStepsContext';
import { VisualizationPreview } from './preview/VisualizationPreview';

export const DataStreamEditorPrimaryViewingPanel = () => {
    const { editorSteps, currentEditorStep, isDocked } = useTileEditorStepsContext();
    const editorStep = isDocked ? editorSteps.find(({ name }) => name === currentEditorStep)?.component : undefined;

    return (
        <div 
            className={cn(
                'h-full min-h-0 relative isolate bg-tileBackground', 
                editorStep && 'border flex flex-col bg-tileBackground border-dividerTertiary',
                currentEditorStep && !isDocked && 'opacity-30 pointer-events-none'
            )}
            {...!isDocked && { 'data-testid': 'visualisationPreviewPanel' }}
        >
            {/* Click shield when step popover is open */}
            {currentEditorStep && !isDocked &&
                <div className='absolute inset-0 z-50 pointer-events-auto' />
            }
            
            {editorStep ?? <VisualizationPreview />}
        </div>
    );
};